import Logo from "atoms/logo/logo";
import React, { ReactNode } from "react";
import "./header-only-layout.scss";

interface HeaderOnlyLayoutProps {
    children: ReactNode;
}

const BASE_CLASS = "c-header-only-layout";

const HeaderOnlyLayout = ({ children }: HeaderOnlyLayoutProps) => {
    return (
        <div className={`${BASE_CLASS} h-full`}>
            <div
                className={`${BASE_CLASS}__header bg-primary flex items-center justify-center bg-primary-900 text-white`}>
                <Logo className="w-48" />
            </div>
            {children}
        </div>
    );
};

export default HeaderOnlyLayout;
