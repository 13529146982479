import { Label } from "atoms/typography/label";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import React from "react";
import "./personalized-price-details.scss";
import MemberCart from "models/interfaces/member-cart";
import usePriceDetails from "../../utilities/hooks/use-price-details";
import StringUtils from "utilities/string-utils";

// Interfaces
export interface PersonalizedPriceDetailsProps {
    cart?: MemberCart;
    hasInsurance?: boolean;
    grossCharges?: number;
}

// Constants
const COMPONENT_CLASS = "c-personalized-price-details";
const getCurrencyIfNotNull = (x: number | null) =>
    x != null ? StringUtils.formatCurrency(x) : "N/A";

// Component
export const PersonalizedPriceDetails: React.FC<
    PersonalizedPriceDetailsProps
> = ({ cart, grossCharges, hasInsurance }) => {
    const c = StringUtils.formatCurrency;
    const priceDetails = usePriceDetails({
        cart,
        hasInsurance,
        grossCharges,
    });

    const copayAmount = getCurrencyIfNotNull(priceDetails.copayAmount);
    const deductibleAmount = getCurrencyIfNotNull(
        priceDetails.deductibleAmount
    );
    const coinsuranceAmount = getCurrencyIfNotNull(
        priceDetails.coinsuranceAmount
    );
    const yourSubTotal = getCurrencyIfNotNull(priceDetails.yourSubTotal);
    const yourPersonalizedDiscount =
        priceDetails.yourPersonalizedDiscount > 0
            ? c(priceDetails.yourPersonalizedDiscount)
            : "Not Eligible";

    return (
        <div className={COMPONENT_CLASS}>
            <div className={`${COMPONENT_CLASS}__section white`}>
                <div
                    className={`${COMPONENT_CLASS}__section__header ${
                        !hasInsurance ? "no-margin" : ""
                    }`}>
                    <Heading size="h4">Your Benefits</Heading>
                </div>
                {!hasInsurance && (
                    <div className={`${COMPONENT_CLASS}__section__content one`}>
                        <div>
                            <Paragraph>
                                Insurance benefits cannot be applied because
                                insurance was not provided.
                            </Paragraph>
                        </div>
                    </div>
                )}
                {hasInsurance && (
                    <div className={`${COMPONENT_CLASS}__section__content`}>
                        <div>
                            <Label>
                                Amount
                                <br />
                                Charged
                            </Label>
                            <Paragraph>
                                {c(priceDetails.amountCharged)}
                            </Paragraph>
                        </div>
                        <div>
                            <Label>
                                Network
                                <br />
                                Savings
                            </Label>
                            <Paragraph>
                                {c(priceDetails.networkSavings)}
                            </Paragraph>
                        </div>
                        <div>
                            <Label>
                                Amount
                                <br />
                                Allowed
                            </Label>
                            <Paragraph>
                                {c(priceDetails.amountAllowed)}
                            </Paragraph>
                        </div>
                        <div>
                            <Label>
                                Health Plan
                                <br />
                                Responsibility
                            </Label>
                            <Paragraph>
                                {c(priceDetails.healthPlanResponsibility)}
                            </Paragraph>
                        </div>
                    </div>
                )}
            </div>
            <div className={`${COMPONENT_CLASS}__section white`}>
                <div className={`${COMPONENT_CLASS}__section__header`}>
                    <Heading size="h4">Your Responsibility</Heading>
                </div>
                <div className={`${COMPONENT_CLASS}__section__content`}>
                    <div>
                        <Label>
                            Copay
                            <br />
                            Amount
                        </Label>
                        <Paragraph>{copayAmount}</Paragraph>
                    </div>
                    <div>
                        <Label>
                            Deductible
                            <br />
                            Amount
                        </Label>
                        <Paragraph>{deductibleAmount}</Paragraph>
                    </div>
                    <div>
                        <Label>
                            Coinsurance
                            <br />
                            Amount
                        </Label>
                        <Paragraph>{coinsuranceAmount}</Paragraph>
                    </div>
                    <div>
                        <Label>
                            Your
                            <br />
                            Subtotal
                        </Label>
                        <Paragraph>{yourSubTotal}</Paragraph>
                    </div>
                </div>
            </div>
            <div className={`${COMPONENT_CLASS}__section primary`}>
                <div className={`${COMPONENT_CLASS}__section__content two`}>
                    <div>
                        <Paragraph type="white">
                            Your Personalized Discount
                        </Paragraph>
                        <Paragraph
                            cssClassName={`${COMPONENT_CLASS}__amount`}
                            size="large"
                            type="white">
                            {yourPersonalizedDiscount}
                        </Paragraph>
                    </div>
                    <div>
                        <Paragraph type="white">You Pay</Paragraph>
                        <Paragraph size="large" type="white">
                            {c(priceDetails.youPay)}
                        </Paragraph>
                    </div>
                </div>
            </div>
        </div>
    );
};
