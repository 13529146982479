import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import React, { FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { useCartState } from "utilities/contexts/cart-state-context";
import StringUtils from "utilities/string-utils";
import PaymentPlanSlider from "./payment-plan-slider";
import usePaymentPlans from "./use-payment-plans";

interface CareNowPayLaterProps {}

const COMPONENT_CLASS = "c-personalize-pay";

const CareNowPayLater: React.FC<CareNowPayLaterProps> = () => {
    const {
        selectedPlanIndex,
        selectedPlan,
        filteredPaymentPlanOptions,
        minPaymentTermLength,
        maxPaymentTermLength,
        setSelectedPlanIndex,
    } = usePaymentPlans();
    const { setState: setCartState } = useCartState();
    const history = useHistory();

    const handleSliderInput = (e: FormEvent<HTMLInputElement>) => {
        const newPaymentPlanIndex = parseInt(e.currentTarget.value, 10);
        setSelectedPlanIndex(newPaymentPlanIndex);
    };

    const handleSelectPaymentClick = () => {
        setCartState((prev) => ({
            ...prev,
            paymentTerms: selectedPlan.termInMonths,
        }));

        history.push(`/payment`);
    };

    const showCareNowPayLater = filteredPaymentPlanOptions.length > 0;
    return !showCareNowPayLater ? null : (
        <div className={`${COMPONENT_CLASS}__section white`}>
            <div className={`${COMPONENT_CLASS}__pay-later`}>
                <div className={`${COMPONENT_CLASS}__pay-later__intro`}>
                    <Heading size="h4">Care Now, Pay Later</Heading>
                    <Paragraph size="small">
                        You were pre-approved for a payment plan. Use the slider
                        to select your preferred term length and monthly
                        payment.
                    </Paragraph>
                </div>
                <div className={`${COMPONENT_CLASS}__pay-later__details`}>
                    <div
                        className={`${COMPONENT_CLASS}__pay-later__term-selection`}>
                        <div className="mb-12">
                            <Label type="light">Select your term length</Label>
                        </div>
                        <PaymentPlanSlider
                            paymentPlans={filteredPaymentPlanOptions}
                            selectedPlanIndex={selectedPlanIndex}
                            selectedPlan={selectedPlan}
                            handleSliderInput={handleSliderInput}
                        />
                        <div className="flex flex-row justify-between">
                            <Label type="light">
                                Min. {minPaymentTermLength} months
                            </Label>
                            <Label type="light">
                                Max. {maxPaymentTermLength} months
                            </Label>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__pay-later__breakdown`}>
                        <Label type="light">Your Monthly Payment</Label>
                        <Paragraph
                            cssClassName={`${COMPONENT_CLASS}__pay-later-amount`}
                            size="medium"
                            type="primary">
                            {StringUtils.formatCurrency(
                                selectedPlan.monthlyInstallmentAmount
                            )}
                        </Paragraph>
                        <Paragraph size="small">0% Interest</Paragraph>
                        <Button onClick={handleSelectPaymentClick}>
                            Select &gt;
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareNowPayLater;
