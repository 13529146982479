import { Heading } from "atoms/typography/heading";
import { MemberRelationship } from "models/enumerations/member-relationship";
import React from "react";
import { useCartState } from "utilities/contexts/cart-state-context";
import "./personalize-progress.scss";

const COMPONENT_CLASS = "c-personalize-progress";

interface PersonalizeProgressProps {
    title: string;
    currentStep: number;
    showProgress?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const PersonalizeProgress = ({
    title,
    currentStep = 1,
    showProgress = true,
}: PersonalizeProgressProps) => {
    const { state: cartState } = useCartState();
    const totalSteps =
        cartState?.member?.relationship != null
            ? cartState.member?.relationship === MemberRelationship.Self
                ? 3
                : 4
            : 4;

    return (
        <div className={COMPONENT_CLASS}>
            <Heading size="h2" type="white">
                {title}
            </Heading>
            {showProgress && (
                <div className={`${COMPONENT_CLASS}__progress__wrapper`}>
                    <div
                        className={`${COMPONENT_CLASS}__progress`}
                        style={{
                            width: `${(currentStep / totalSteps) * 100}%`,
                        }}></div>
                </div>
            )}
        </div>
    );
};
