import { AxiosResponse } from "axios";
import { Address } from "models/interfaces/address";
import { FormValidation } from "models/interfaces/form-validation";
import MemberOrder from "models/interfaces/member-order";
import { PaymentType, Purchase } from "models/interfaces/purchase";
import { RecurringPaymentPurchase } from "models/recurring-payment-purchase";
import { useState } from "react";
import CartService from "services/cart-service";
import OrdersService from "services/orders-service";
import CognitoUtils from "utilities/cognito-utils";
import { parseErrorResponse } from "utilities/error-utils";
import useCart from "utilities/hooks/use-cart";
import StringUtils from "utilities/string-utils";

type PaymentResponse<T = any> = {
    response?: AxiosResponse<T, any>;
    errorMessage?: string;
    fieldValidation?: FormValidation[];
};

const usePayment = () => {
    const { cart, member, paymentTerms, isPaymentPlanPurchase } = useCart();
    const [loading, setLoading] = useState(false);

    const validatePurchase = (purchase: Purchase, address: Address) => {
        const validationErrors = [];

        // Bank Account Field Validations
        if (purchase.paymentType === PaymentType.ACH) {
            if (purchase.bankAccountType == null) {
                validationErrors.push({
                    field: "AccountType",
                    error: "Account type is required",
                });
            }

            if (StringUtils.isEmpty(purchase.firstName)) {
                validationErrors.push({
                    field: "FirstName",
                    error: "First name is required",
                });
            }

            if (StringUtils.isEmpty(purchase.lastName)) {
                validationErrors.push({
                    field: "LastName",
                    error: "Last name is required",
                });
            }

            if (StringUtils.isEmpty(purchase.accountNumber)) {
                validationErrors.push({
                    field: "BankAccountNumber",
                    error: "Bank account number is required",
                });
            }

            if (StringUtils.isEmpty(purchase.accountNumberConfirmation)) {
                validationErrors.push({
                    field: "BankAccountNumberConfirmation",
                    error: "Confirm account number is required",
                });
            }

            if (
                !StringUtils.isEmpty(purchase.accountNumber) &&
                !StringUtils.isEmpty(purchase.accountNumberConfirmation) &&
                purchase.accountNumber.trim() !==
                    purchase.accountNumberConfirmation.trim()
            ) {
                validationErrors.push({
                    field: "BankAccountNumberConfirmation",
                    error: "Bank account number fields must match",
                });
            }

            if (StringUtils.isEmpty(purchase.routingNumber)) {
                validationErrors.push({
                    field: "BankRoutingNumber",
                    error: "Bank routing number is required",
                });
            }

            return validationErrors;
        }

        // Credit/Debit Card Field Validations
        if (StringUtils.isEmpty(purchase.name)) {
            validationErrors.push({
                field: "NameOnCard",
                error: "Name on card is required",
            });
        }
        if (StringUtils.isEmpty(purchase.cardNumber)) {
            validationErrors.push({
                field: "CardNumber",
                error: "Card number is required",
            });
        }
        if (StringUtils.isEmpty(purchase.expirationDate)) {
            validationErrors.push({
                field: "ExpirationDate",
                error: "Exp Date is required",
            });
        }
        if (StringUtils.isEmpty(purchase.code)) {
            validationErrors.push({
                field: "CVV",
                error: "Security code is required",
            });
        }

        // Address field validations
        if (StringUtils.isEmpty(address.line1)) {
            validationErrors.push({
                field: "Address1",
                error: "Address is required",
            });
        }
        if (StringUtils.isEmpty(address.city)) {
            validationErrors.push({ field: "City", error: "City is required" });
        }
        if (StringUtils.isEmpty(address.zipCode)) {
            validationErrors.push({
                field: "ZipCode",
                error: "Zip is required",
            });
        }
        if (StringUtils.isEmpty(address.state)) {
            validationErrors.push({
                field: "State",
                error: "State is required",
            });
        }

        return validationErrors;
    };

    const completePurchase = async (purchase: Purchase, address: Address) => {
        purchase.address = address;

        if (isPaymentPlanPurchase) {
            return await purchaseCartRecurring(purchase);
        } else {
            return await purchaseCart(purchase);
        }
    };

    const purchaseCart = async (
        purchase: Purchase
    ): Promise<PaymentResponse> => {
        setLoading(true);
        try {
            const response = await CartService.purchaseCart(
                member!,
                cart!,
                purchase
            );

            return { response };
        } catch (error) {
            const { errorMessage, fieldValidation } = parseErrorResponse(error);
            return { errorMessage, fieldValidation };
        } finally {
            setLoading(false);
        }
    };

    const purchaseCartRecurring = async (
        purchase: Purchase
    ): Promise<PaymentResponse> => {
        const recurringPaymentPurchase: RecurringPaymentPurchase = {
            ...purchase,
            termInMonths: paymentTerms ?? 0,
        };

        setLoading(true);
        try {
            const response = await CartService.purchaseCartRecurring(
                member!,
                cart!,
                recurringPaymentPurchase
            );

            return { response };
        } catch (error) {
            const { errorMessage, fieldValidation } = parseErrorResponse(error);
            return { errorMessage, fieldValidation };
        } finally {
            setLoading(false);
        }
    };

    const updatePayment = async (
        memberOrderId: string,
        purchase: Purchase,
        address?: Address
    ): Promise<PaymentResponse<MemberOrder>> => {
        try {
            const idToken = await CognitoUtils.getIdToken();
            const response = await OrdersService.updateOrder(
                memberOrderId,
                {
                    ...purchase,
                    address:
                        purchase.paymentType === PaymentType.CreditCard
                            ? address
                            : undefined,
                },
                idToken
            );

            return { response };
        } catch (error) {
            const { errorMessage, fieldValidation } = parseErrorResponse(error);
            return { errorMessage, fieldValidation };
        }
    };

    return {
        loading,
        validatePurchase,
        completePurchase,
        updatePayment,
    };
};

export default usePayment;
