import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { MailIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import AccountService from "services/account-service";

interface UpdatePasswordFormProps {
    email: string;
}

const UpdatePasswordForm = ({ email }: UpdatePasswordFormProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasResetPassword, setHasResetPassword] = useState(false);

    const handleChangePasswordBtnClick = async () => {
        setIsLoading(true);
        try {
            await AccountService.forgotPassword(email);
        } catch {
            // TODO: Add error handling here
        } finally {
            setIsLoading(false);
        }
        setHasResetPassword(true);
    };

    return (
        <div className="space-y-4">
            <Heading size="h3" className="text-3xl">
                Your Password
            </Heading>
            <Paragraph>
                This is the password you use to log in to your account. You’ll
                be required to verify this change before you can log in using
                your new password.
            </Paragraph>
            {!hasResetPassword ? (
                <Button
                    disabled={isLoading}
                    size="medium"
                    onClick={handleChangePasswordBtnClick}>
                    Change Password
                </Button>
            ) : (
                <div className="flex w-full items-center justify-between rounded-md bg-primary-400 py-2 px-4 font-semibold text-primary-900">
                    <div className="flex items-center space-x-2">
                        <MailIcon className="h-8 w-8" />
                        <p>We sent a password reset link to {email}</p>
                    </div>
                    <button
                        onClick={handleChangePasswordBtnClick}
                        className="font-semibold hover:underline">
                        Resend
                    </button>
                </div>
            )}
        </div>
    );
};

export default UpdatePasswordForm;
