import { Button } from "atoms/buttons/button";
import { Checkbox } from "atoms/forms/checkbox";
import { Dropdown } from "atoms/forms/dropdown";
import { Error } from "atoms/forms/error";
import { TextInput } from "atoms/forms/text-input";
import { Heading } from "atoms/typography/heading";
import { InsuranceLevel } from "models/enumerations/insurance-level";
import { MemberRelationship } from "models/enumerations/member-relationship";
import { InsuranceRelationshipOptions } from "models/insurance-relationship-options";
import { Address, emptyAddress } from "models/interfaces/address";
import { FormValidation } from "models/interfaces/form-validation";
import { ListOptions } from "models/interfaces/list-options";
import Member, { emptyMember } from "models/interfaces/member";
import MemberInsurance, {
    emptyMemberInsurance,
} from "models/interfaces/member-insurance";
import { MemberRelationshipOptions } from "models/member-relationship-options";
import { StateOptions } from "models/state-options";
import React, { useEffect, useState } from "react";
import { useAuth } from "utilities/contexts/auth-state-context";
import FormUtils from "utilities/form-utils";
import SearchUtils from "utilities/search-utils";
import StringUtils from "utilities/string-utils";
import ReadonlyMemberEmail from "organisms/member-form/readonly-member-email";
import AccountService from "services/account-service";
import "./member-form.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-member-form";

interface MemberFormProps {
    error?: string;
    isEditing: boolean;
    isSelf: boolean;
    member?: Member;
    onSubmit: (member: Member) => void;
    saveButtonText?: string;
    setIsEditing?: (isEditing: boolean) => void;
    showAuthorizedCheck: boolean;
}

const MemberForm = ({
    error,
    isEditing = true,
    isSelf = false,
    onSubmit,
    saveButtonText = "Continue >",
    setIsEditing,
    showAuthorizedCheck = false,
    ...props
}: MemberFormProps) => {
    const [hasInsurance, setHasInsurance] = useState(true);
    const [member, setMember] = useState<Member>(emptyMember());
    const [address, setAddress] = useState<Address>(emptyAddress());
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [primaryInsurance, setPrimaryInsurance] = useState<MemberInsurance>(
        emptyMemberInsurance()
    );
    const [primaryInsuranceOptions, setPrimaryInsuranceOptions] = useState<
        ListOptions[]
    >([]);
    const [fieldValidation, setFieldValidation] = useState<FormValidation[]>();
    const { settings } = useAuth();

    useEffect(() => {
        async function initializeWithUserEmail() {
            if (isEditing && isSelf && !props.member) {
                const idToken = await CognitoUtils.getIdToken();
                AccountService.getUser(idToken).then((user) => {
                    setMember((prev) => ({
                        ...prev,
                        email: user.data.email ?? "",
                    }));
                });
            }
        }
    }, [isEditing, isSelf, props.member]);

    useEffect(() => {
        if (!props.member || isEditing) {
            return;
        }

        const propMember = props.member ?? emptyMember();
        let propHasInsurance = true;
        let propPrimaryInsurance = emptyMemberInsurance();
        let propPrimaryInsuranceOptions: ListOptions[] = [];

        if (propMember.memberInsurances) {
            propHasInsurance = propMember.memberInsurances.length > 0;
            if (propMember.memberInsurances.length >= 1) {
                propPrimaryInsurance = propMember.memberInsurances[0];
                propPrimaryInsuranceOptions = [
                    {
                        value: propPrimaryInsurance.insurancePlanKey,
                        label: propPrimaryInsurance.insurancePlanName || "",
                    },
                ];
            }
        }

        setMember(propMember);
        if (propMember.address) {
            setAddress(propMember.address);
        }
        setPrimaryInsurance(propPrimaryInsurance);
        setPrimaryInsuranceOptions(propPrimaryInsuranceOptions);
        setHasInsurance(propHasInsurance);
    }, [props.member, isEditing]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const validationErrors = [];
        const isValidDateFormat =
            /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

        // Member field validations
        if (StringUtils.isEmpty(member.firstName)) {
            validationErrors.push({
                field: "FirstName",
                error: "First name is required",
            });
        }
        if (StringUtils.isEmpty(member.lastName)) {
            validationErrors.push({
                field: "LastName",
                error: "Last name is required",
            });
        }
        if (StringUtils.isEmpty(member.dob)) {
            validationErrors.push({
                field: "DOB",
                error: "Date of Birth is required",
            });
        } else if (!isValidDateFormat.test(member.dob)) {
            validationErrors.push({
                field: "DOB",
                error: "Invalid date format. Please use MM/DD/YYYY",
            });
        } else {
            const [month, day, year] = member.dob.split("/");
            const isValidDate =
                new Date(`${month}/${day}/${year}`).toString() !==
                "Invalid Date";
            if (!isValidDate) {
                validationErrors.push({
                    field: "DOB",
                    error: "Invalid date format. Please use MM/DD/YYYY",
                });
            }
        }
        if (StringUtils.isEmpty(member.ssn)) {
            validationErrors.push({ field: "SSN", error: "SSN is required" });
        } else if (member.ssn && member.ssn.length < 11) {
            validationErrors.push({ field: "SSN", error: "SSN invalid" });
        }
        if (StringUtils.isEmpty(member.email) /*&& !isSelf*/) {
            validationErrors.push({
                field: "Email",
                error: "Email is required",
            });
        }
        if (StringUtils.isEmpty(member.phone)) {
            validationErrors.push({
                field: "Phone",
                error: "Phone number is required",
            });
        }
        if (!member.relationship && !isSelf) {
            validationErrors.push({
                field: "Relationship",
                error: "Relationship is required",
            });
        }

        // Address field validations
        if (StringUtils.isEmpty(address.line1)) {
            validationErrors.push({
                field: "Address1",
                error: "Address is required",
            });
        }
        if (StringUtils.isEmpty(address.city)) {
            validationErrors.push({ field: "City", error: "City is required" });
        }
        if (StringUtils.isEmpty(address.zipCode)) {
            validationErrors.push({
                field: "ZipCode",
                error: "Zip Code is required",
            });
        }
        if (StringUtils.isEmpty(address.state)) {
            validationErrors.push({
                field: "State",
                error: "State is required",
            });
        }

        // Insurance field validations
        if (hasInsurance) {
            if (StringUtils.isEmpty(primaryInsurance.firstName)) {
                validationErrors.push({
                    field: "PrimaryPolicyFirstName",
                    error: "First Name is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.lastName)) {
                validationErrors.push({
                    field: "PrimaryPolicyLastName",
                    error: "Last Name is required",
                });
            }
            if (primaryInsurance.relationship == null) {
                validationErrors.push({
                    field: "PrimaryRelationship",
                    error: "Relationship is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.insurancePlanKey)) {
                validationErrors.push({
                    field: "PrimaryInsurancePlanKey",
                    error: "Insurance Plan is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.insuranceMemberId)) {
                validationErrors.push({
                    field: "PrimaryInsuranceMemberId",
                    error: "Member ID is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.groupNum)) {
                validationErrors.push({
                    field: "PrimaryGroupNum",
                    error: "Group Number is required",
                });
            }
        }

        if (showAuthorizedCheck === true && !isAuthorized) {
            validationErrors.push({
                field: "IsAuthorized",
                error: "You must be authorized to add this person to your account",
            });
        }

        setFieldValidation(validationErrors);
        if (validationErrors.length > 0) {
            return;
        }

        const newMember = Object.assign({}, member);
        newMember.address = address;
        if (hasInsurance) {
            newMember.memberInsurances = [primaryInsurance];
        } else {
            newMember.memberInsurances = [];
        }
        if (isSelf) {
            newMember.relationship = MemberRelationship.Self;
        }
        if (StringUtils.isEmpty(newMember.middleInitial)) {
            newMember.middleInitial = undefined;
        }
        if (StringUtils.isEmpty(address.line2)) {
            address.line2 = undefined;
        }

        newMember.address = address;

        if (hasInsurance) {
            primaryInsurance.insuranceLevel = InsuranceLevel.Primary;
            newMember.memberInsurances = [primaryInsurance];
        }

        onSubmit(newMember);
    };

    return (
        <div className={COMPONENT_CLASS}>
            <form onSubmit={handleSubmit}>
                <div className={`${COMPONENT_CLASS}__section`}>
                    <Heading size="h3">
                        {isSelf
                            ? "Your Basic Information"
                            : "Basic Information for Household Member"}
                    </Heading>
                    <div
                        className={`${COMPONENT_CLASS}__section__wrapper triple`}>
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            isRequired={true}
                            maxLength={50}
                            name="FirstName"
                            label="First Name"
                            placeholder="First Name"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    member,
                                    { firstName: value },
                                    setMember
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "FirstName"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "FirstName"
                                        ),
                                    ]);
                                }
                            }}
                            value={member.firstName}
                        />
                        <TextInput
                            isLocked={!isEditing}
                            maxLength={10}
                            name="MiddleInitial"
                            label="MI"
                            placeholder="MI"
                            onChange={(value) =>
                                FormUtils.handleValueChange(
                                    member,
                                    { middleInitial: value },
                                    setMember
                                )
                            }
                            value={member.middleInitial}
                        />
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            isRequired={true}
                            maxLength={75}
                            name="LastName"
                            label="Last Name"
                            placeholder="Last Name"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    member,
                                    { lastName: value },
                                    setMember
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "LastName"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "LastName"
                                        ),
                                    ]);
                                }
                            }}
                            value={member.lastName}
                        />
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            displayFunction={(args) => {
                                let result = "";
                                if (args.length > 0) {
                                    result += `${args[0]}`;
                                }
                                if (args.length > 1) {
                                    result += `/${args[1]}`;
                                }
                                if (args.length === 3) {
                                    result += `/${args[2]}`;
                                }
                                return result;
                            }}
                            inputFormat={/^(\d{0,2})\/?(\d{0,2})\/?(\d{0,4})$/}
                            isRequired={true}
                            maxLength={10}
                            name="DOB"
                            label="Date Of Birth"
                            placeholder="MM/DD/YEAR"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    member,
                                    { dob: value },
                                    setMember
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "DOB"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "DOB"
                                        ),
                                    ]);
                                }
                            }}
                            value={member.dob}
                        />
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            displayFunction={(args) => {
                                let result = "";
                                if (args.length > 0) {
                                    result += `${args[0]}`;
                                }
                                if (args.length > 1) {
                                    result += `-${args[1]}`;
                                }
                                if (args.length === 3) {
                                    result += `-${args[2]}`;
                                }
                                return result;
                            }}
                            inputFormat={/^(\d{0,3})-?(\d{0,2})-?(\d{0,4})$/}
                            isRequired={true}
                            maxLength={11}
                            name="SSN"
                            label="Social Security #"
                            placeholder="000-00-0000"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    member,
                                    { ssn: value },
                                    setMember
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "SSN"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "SSN"
                                        ),
                                    ]);
                                }
                            }}
                            value={member.ssn}
                        />
                        {!isSelf && (
                            <Dropdown
                                fieldValidation={fieldValidation}
                                isLocked={!isEditing}
                                isRequired={true}
                                name="Relationship"
                                isSearchable={false}
                                placeholder="Select"
                                label="Relationship"
                                options={MemberRelationshipOptions.filter(
                                    (opt) =>
                                        opt.value !==
                                        MemberRelationship.Self.toString()
                                )}
                                onChange={(value: string) => {
                                    FormUtils.handleValueChange(
                                        member,
                                        { relationship: value },
                                        setMember
                                    );
                                    if (
                                        fieldValidation?.find(
                                            (v) => v.field === "Relationship"
                                        )
                                    ) {
                                        setFieldValidation([
                                            ...fieldValidation.filter(
                                                (v) =>
                                                    v.field !== "Relationship"
                                            ),
                                        ]);
                                    }
                                }}
                                value={member.relationship?.toString()}
                            />
                        )}
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__section`}>
                    <Heading size="h3">
                        {isSelf
                            ? "Your Contact Information"
                            : "Contact Information for Household Member"}
                    </Heading>
                    <div
                        className={`${COMPONENT_CLASS}__section__wrapper double`}>
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            isRequired={true}
                            maxLength={100}
                            name="Address1"
                            label="Address 1"
                            placeholder="Address 1"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    address,
                                    { line1: value },
                                    setAddress
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "Address1"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "Address1"
                                        ),
                                    ]);
                                }
                            }}
                            value={address.line1}
                        />
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            maxLength={100}
                            name="Address2"
                            label="Address 2"
                            placeholder="Address 2"
                            onChange={(value) =>
                                FormUtils.handleValueChange(
                                    address,
                                    { line2: value },
                                    setAddress
                                )
                            }
                            value={address.line2}
                        />
                    </div>
                    <div
                        className={`${COMPONENT_CLASS}__section__wrapper triple`}>
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            isRequired={true}
                            maxLength={100}
                            name="City"
                            label="City"
                            placeholder="City"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    address,
                                    { city: value },
                                    setAddress
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "City"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "City"
                                        ),
                                    ]);
                                }
                            }}
                            value={address.city}
                        />
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            isRequired={true}
                            maxLength={15}
                            name="ZipCode"
                            label="Zip"
                            placeholder="00000"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    address,
                                    { zipCode: value },
                                    setAddress
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "ZipCode"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "ZipCode"
                                        ),
                                    ]);
                                }
                            }}
                            value={address.zipCode}
                        />
                        <Dropdown
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            isRequired={true}
                            name="State"
                            isSearchable={true}
                            label="State"
                            placeholder="Select"
                            options={StateOptions}
                            onChange={(value: string) => {
                                FormUtils.handleValueChange(
                                    address,
                                    { state: value },
                                    setAddress
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "State"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "State"
                                        ),
                                    ]);
                                }
                            }}
                            value={address.state.toString()}
                        />
                    </div>
                    <div
                        className={`${COMPONENT_CLASS}__section__wrapper double`}>
                        {/*{isEditing && isSelf && (*/}
                        {/*    <ReadonlyMemberEmail email={member.email} />*/}
                        {/*)}*/}
                        {isEditing && (
                            <TextInput
                                fieldValidation={fieldValidation}
                                isLocked={!isEditing}
                                isRequired={true}
                                maxLength={320}
                                name="Email"
                                label={
                                    isSelf ? "Preferred Contact Email" : "Email"
                                }
                                placeholder="Email"
                                onChange={(value) => {
                                    FormUtils.handleValueChange(
                                        member,
                                        { email: value },
                                        setMember
                                    );
                                    if (
                                        fieldValidation?.find(
                                            (v) => v.field === "Email"
                                        )
                                    ) {
                                        setFieldValidation([
                                            ...fieldValidation.filter(
                                                (v) => v.field !== "Email"
                                            ),
                                        ]);
                                    }
                                }}
                                value={member.email}
                            />
                        )}
                        <TextInput
                            fieldValidation={fieldValidation}
                            isLocked={!isEditing}
                            displayFunction={(args) => {
                                let result = "";
                                if (args.length > 0) {
                                    result += `(${args[0]}`;
                                }
                                if (args.length > 1) {
                                    result += `) ${args[1]}`;
                                }
                                if (args.length === 3) {
                                    result += `-${args[2]}`;
                                }
                                return result;
                            }}
                            inputFormat={
                                /^\(?(\d{0,3})\)?\s?(\d{0,3})-?(\d{0,4})$/
                            }
                            isRequired={true}
                            maxLength={14}
                            name="Phone"
                            label="Phone"
                            placeholder="(000) 000-0000"
                            onChange={(value) => {
                                FormUtils.handleValueChange(
                                    member,
                                    { phone: value },
                                    setMember
                                );
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "Phone"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "Phone"
                                        ),
                                    ]);
                                }
                            }}
                            value={member.phone}
                        />
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__section`}>
                    <Heading size="h3">
                        {isSelf
                            ? "Your Insurance"
                            : "Insurance for Household Member"}
                    </Heading>
                    {(isEditing || !hasInsurance) && (
                        <div
                            className={`${COMPONENT_CLASS}__section__wrapper double`}>
                            <Checkbox
                                label="I am not using insurance"
                                isChecked={!hasInsurance}
                                isLocked={!isEditing}
                                onChange={(isChecked) =>
                                    setHasInsurance(!isChecked)
                                }
                            />
                        </div>
                    )}
                    {hasInsurance && (
                        <div
                            className={`${COMPONENT_CLASS}__section__wrapper double`}>
                            <TextInput
                                fieldValidation={fieldValidation}
                                isLocked={!isEditing}
                                isRequired={true}
                                maxLength={50}
                                name="PrimaryPolicyFirstName"
                                label="Primary Policy Holder First Name"
                                placeholder="Primary Policy Holder First Name"
                                onChange={(value) => {
                                    FormUtils.handleValueChange(
                                        primaryInsurance,
                                        { firstName: value },
                                        setPrimaryInsurance
                                    );
                                    if (
                                        fieldValidation?.find(
                                            (v) =>
                                                v.field ===
                                                "PrimaryPolicyFirstName"
                                        )
                                    ) {
                                        setFieldValidation([
                                            ...fieldValidation.filter(
                                                (v) =>
                                                    v.field !==
                                                    "PrimaryPolicyFirstName"
                                            ),
                                        ]);
                                    }
                                }}
                                value={primaryInsurance.firstName}
                            />
                            <TextInput
                                fieldValidation={fieldValidation}
                                isLocked={!isEditing}
                                isRequired={true}
                                maxLength={75}
                                name="PrimaryPolicyLastName"
                                label="Primary Policy Holder Last Name"
                                placeholder="Primary Policy Holder Last Name"
                                onChange={(value) => {
                                    FormUtils.handleValueChange(
                                        primaryInsurance,
                                        { lastName: value },
                                        setPrimaryInsurance
                                    );
                                    if (
                                        fieldValidation?.find(
                                            (v) =>
                                                v.field ===
                                                "PrimaryPolicyLastName"
                                        )
                                    ) {
                                        setFieldValidation([
                                            ...fieldValidation.filter(
                                                (v) =>
                                                    v.field !==
                                                    "PrimaryPolicyLastName"
                                            ),
                                        ]);
                                    }
                                }}
                                value={primaryInsurance.lastName}
                            />
                            <Dropdown
                                fieldValidation={fieldValidation}
                                isLocked={!isEditing}
                                isRequired={true}
                                name="PrimaryRelationship"
                                isSearchable={false}
                                placeholder="Select"
                                label="Relationship"
                                options={InsuranceRelationshipOptions}
                                onChange={(value: string) => {
                                    FormUtils.handleValueChange(
                                        primaryInsurance,
                                        { relationship: value },
                                        setPrimaryInsurance
                                    );
                                    if (
                                        fieldValidation?.find(
                                            (v) =>
                                                v.field ===
                                                "PrimaryRelationship"
                                        )
                                    ) {
                                        setFieldValidation([
                                            ...fieldValidation.filter(
                                                (v) =>
                                                    v.field !==
                                                    "PrimaryRelationship"
                                            ),
                                        ]);
                                    }
                                }}
                                value={primaryInsurance.relationship?.toString()}
                            />
                            <div></div>
                            <Dropdown
                                fieldValidation={fieldValidation}
                                isLocked={!isEditing}
                                isRequired={true}
                                name="PrimaryInsurancePlanKey"
                                isSearchable={true}
                                placeholder="Start Typing to Find Insurance Plan"
                                label="Insurance Plan"
                                loadOptions={(inputValue) => {
                                    if (settings == null) {
                                        return [] as any;
                                    }
                                    const index = SearchUtils.indexByType(
                                        settings,
                                        "insurance"
                                    );
                                    return index
                                        .search(inputValue)
                                        .then(({ hits }) => {
                                            const options = hits.map(
                                                (hit: any) => ({
                                                    value: hit.objectID,
                                                    label: hit.name,
                                                })
                                            );
                                            setPrimaryInsuranceOptions(options);
                                            return options;
                                        });
                                }}
                                options={primaryInsuranceOptions}
                                onChange={(value: string) => {
                                    FormUtils.handleValueChange(
                                        primaryInsurance,
                                        { insurancePlanKey: value },
                                        setPrimaryInsurance
                                    );
                                    if (
                                        fieldValidation?.find(
                                            (v) =>
                                                v.field ===
                                                "PrimaryInsurancePlanKey"
                                        )
                                    ) {
                                        setFieldValidation([
                                            ...fieldValidation.filter(
                                                (v) =>
                                                    v.field !==
                                                    "PrimaryInsurancePlanKey"
                                            ),
                                        ]);
                                    }
                                }}
                                value={primaryInsurance.insurancePlanKey}
                            />
                            <div
                                className={`${COMPONENT_CLASS}__section__wrapper double`}>
                                <TextInput
                                    fieldValidation={fieldValidation}
                                    isLocked={!isEditing}
                                    isRequired={true}
                                    maxLength={50}
                                    name="PrimaryInsuranceMemberId"
                                    label="Member ID"
                                    placeholder="Member ID"
                                    onChange={(value) => {
                                        FormUtils.handleValueChange(
                                            primaryInsurance,
                                            { insuranceMemberId: value },
                                            setPrimaryInsurance
                                        );
                                        if (
                                            fieldValidation?.find(
                                                (v) =>
                                                    v.field ===
                                                    "PrimaryInsuranceMemberId"
                                            )
                                        ) {
                                            setFieldValidation([
                                                ...fieldValidation.filter(
                                                    (v) =>
                                                        v.field !==
                                                        "PrimaryInsuranceMemberId"
                                                ),
                                            ]);
                                        }
                                    }}
                                    value={primaryInsurance.insuranceMemberId}
                                />
                                <TextInput
                                    fieldValidation={fieldValidation}
                                    isLocked={!isEditing}
                                    isRequired={true}
                                    maxLength={50}
                                    name="PrimaryGroupNum"
                                    label="Group No."
                                    placeholder="Group No."
                                    onChange={(value) => {
                                        FormUtils.handleValueChange(
                                            primaryInsurance,
                                            { groupNum: value },
                                            setPrimaryInsurance
                                        );
                                        if (
                                            fieldValidation?.find(
                                                (v) =>
                                                    v.field ===
                                                    "PrimaryGroupNum"
                                            )
                                        ) {
                                            setFieldValidation([
                                                ...fieldValidation.filter(
                                                    (v) =>
                                                        v.field !==
                                                        "PrimaryGroupNum"
                                                ),
                                            ]);
                                        }
                                    }}
                                    value={primaryInsurance.groupNum}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {showAuthorizedCheck && (
                    <div className={`${COMPONENT_CLASS}__section authorized`}>
                        <Checkbox
                            fieldValidation={fieldValidation}
                            label="I certify I am authorized to make health care decisions for this person"
                            isChecked={isAuthorized}
                            isLocked={!isEditing}
                            name="IsAuthorized"
                            onChange={(value) => {
                                setIsAuthorized(value);
                                if (
                                    fieldValidation?.find(
                                        (v) => v.field === "IsAuthorized"
                                    )
                                ) {
                                    setFieldValidation([
                                        ...fieldValidation.filter(
                                            (v) => v.field !== "IsAuthorized"
                                        ),
                                    ]);
                                }
                            }}
                            type="white"
                        />
                    </div>
                )}
                {error && (
                    <div className={`${COMPONENT_CLASS}__section`}>
                        <Error message={error} />
                    </div>
                )}
                {isEditing && (
                    <div
                        className={`${COMPONENT_CLASS}__section ${COMPONENT_CLASS}__actions center`}>
                        {setIsEditing && (
                            <Button
                                onClick={() => setIsEditing(false)}
                                type="plain">
                                Cancel
                            </Button>
                        )}
                        <Button
                            disabled={showAuthorizedCheck && !isAuthorized}
                            isSubmit={true}
                            size="large">
                            {saveButtonText}
                        </Button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default MemberForm;
