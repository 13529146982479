const handleValueChange = (object: any, newObject: any, stateChangeFunc: any) =>
    stateChangeFunc(Object.assign({}, object, newObject));

const displayFilter = (
    textbox: HTMLInputElement,
    matchFormat: RegExp,
    displayFunction: (args: string[]) => string
): void => {
    //displaySeparator: string): void => {
    // Old way of handling formatting using display separator arg
    // if (textbox.value) {
    //     const args = textbox.value.match(matchFormat);
    //     if (args && args.length > 1) {
    //         args.shift();
    //         let value = args.filter((val: string) => val && val.length > 0).join(displaySeparator);
    //         if (textbox.value.endsWith(displaySeparator)) {
    //             value += displaySeparator;
    //         }
    //         textbox.value = value;
    //     }
    // }
    if (textbox.value) {
        const args = textbox.value.match(matchFormat);
        if (args && args.length > 1) {
            args.shift();
            textbox.value = displayFunction(
                args.filter((val: string) => val && val.length > 0)
            );
        }
    }
};

const validateInput = (
    textbox: HTMLInputElement,
    oldValue: string,
    inputFilter: (value: string) => boolean,
    matchFormat?: RegExp,
    displayFunction?: (args: string[]) => string
): boolean => {
    if (inputFilter(textbox.value)) {
        if (displayFunction && matchFormat) {
            displayFilter(textbox, matchFormat, displayFunction);
        }
        return true;
    } else {
        return false;
    }
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const FormUtils = {
    handleValueChange,
    validateInput,
};

export default FormUtils;

// #endregion Exports
