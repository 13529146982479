import React, { useEffect } from "react";
import { useAuth } from "utilities/contexts/auth-state-context";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SignOutPage: React.FC = () => {
    const { signOut } = useAuth();

    useEffect(() => {
        signOut();
    }, [signOut]);

    return null;
};

export default SignOutPage;
