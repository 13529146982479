import React from "react";
import "./heading.scss";

interface HeadingProps {
    children: React.ReactNode;
    size?: "h1" | "h2" | "h3" | "h4" | "h5";
    type?: "primary" | "light" | "white";
    className?: string;
}

/**
 * Primary UI component
 */
export const Heading = ({
    children,
    size = "h1",
    type = "primary",
    className = "",
}: HeadingProps) => {
    switch (size) {
        case "h2":
            return <h2 className={`${type} ${className}`}>{children}</h2>;
        case "h3":
            return <h3 className={`${type} ${className}`}>{children}</h3>;
        case "h4":
            return <h4 className={`${type} ${className}`}>{children}</h4>;
        case "h5":
            return <h5 className={`${type} ${className}`}>{children}</h5>;
        default:
            return <h1 className={`${type} ${className}`}>{children}</h1>;
    }
};
