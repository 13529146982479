import { SearchIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Error } from "atoms/forms/error";
import React from "react";
import StringUtils from "utilities/string-utils";
import "./search.scss";

const BASE_CLASS_NAME = "c-search";

interface CheckboxProps {
    errorMessage?: string;
    isLocked: boolean;
    type?: "primary" | "white";
    name?: string;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    onSearch?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Search = ({
    errorMessage,
    isLocked = false,
    type = "primary",
    name,
    value,
    placeholder,
    ...props
}: CheckboxProps) => {
    const errorClass = StringUtils.hasValue(errorMessage) ? "errored" : "";
    const lockedClass = isLocked ? "locked" : "";

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (props.onSearch) {
            props.onSearch();
        }
    };

    return (
        <div
            className={`${BASE_CLASS_NAME} ${[
                errorClass,
                lockedClass,
                type,
            ].join(" ")}`}>
            <div className={`${BASE_CLASS_NAME}__wrapper`}>
                <form onSubmit={onSubmit}>
                    <SearchIcon />
                    <input
                        type="search"
                        disabled={isLocked}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onKeyDown={(e) => {
                            if (e.charCode === 13 && props.onSearch) {
                                props.onSearch();
                            }
                        }}
                        onChange={(e) => {
                            if (props.onChange) {
                                props.onChange(e.target.value);
                            }
                        }}
                    />
                    <Button isSubmit={true}>Search</Button>
                </form>
            </div>
            {StringUtils.hasValue(errorMessage) && (
                <Error message={errorMessage} />
            )}
        </div>
    );
};
