import { Auth, Amplify } from "aws-amplify";
import { CognitoConfig } from "services/cognitoConfig";

const initializeCognito = (
    clientID: string,
    identityProvider: string
): boolean => {
    let clientConfig: any = null;
    if (clientID !== undefined && clientID != null && clientID.length > 0) {
        clientConfig = CognitoConfig.find(
            (configItem) => configItem.appClientID === clientID
        );
    } else {
        clientConfig = CognitoConfig[0];
    }
    if (clientConfig !== null) {
        if (identityProvider !== "")
            clientConfig.config.oauth.identityProvider = identityProvider;
        Amplify.configure({
            Auth: clientConfig?.config,
        });
    }
    return clientConfig !== null;
};

const getIdToken = async (): Promise<string> => {
    var currentSession = await Auth.currentSession();
    var idToken = "";
    if (currentSession.isValid()) {
        idToken = currentSession.getIdToken().getJwtToken();
    }
    return idToken;
};
const getAccessToken = async (): Promise<string> => {
    var currentSession = await Auth.currentSession();
    var accesstoken = "";
    if (currentSession.isValid()) {
        accesstoken = currentSession.getAccessToken().getJwtToken();
    }
    return accesstoken;
};

const getUserIdFromToken = (idToken: string): string => {
    const decodedToken = JSON.parse(atob(idToken.split(".")[1]));
    return decodedToken.sub as string;
};

const CognitoUtils = {
    initializeCognito,
    getAccessToken,
    getIdToken,
    getUserIdFromToken,
};

export default CognitoUtils;
