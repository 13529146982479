import HcnLogo from "assets/images/hcn-logo-grey-2@2x.png";
import { Button } from "atoms/buttons/button";
import { Error } from "atoms/forms/error";
import { PasswordInput } from "atoms/forms/password-input";
import { TextInput } from "atoms/forms/text-input";
import { PasswordStrength } from "atoms/password-strength/password-strength";
import { Anchor } from "atoms/typography/anchor";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { UserRegister } from "interfaces/user-register";
import React, { useEffect, useState } from "react";
import { AuthError, useAuth } from "utilities/contexts/auth-state-context";
import StringUtils from "utilities/string-utils";
import "./create-account.scss";

const COMPONENT_CLASS = "c-create-account";

const CreateAccount: React.FC = () => {
    const [error, setError] = useState<AuthError>();
    const [emailError, setEmailError] = useState<string>();
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
    const [isPasswordError, setIsPasswordError] = useState<boolean>();
    const [user, setUser] = useState<UserRegister>({
        email: "",
        password: "",
        confirmPassword: "",
    });
    const {
        isLoading: isAuthLoading,
        error: registerError,
        registerUser,
    } = useAuth();

    useEffect(() => {
        setError(registerError);
    }, [registerError]);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (isAuthLoading) {
            return;
        }

        let isErrored = false;

        setEmailError(undefined);
        setIsPasswordError(undefined);
        setConfirmPasswordError(undefined);
        setError(undefined);

        if (StringUtils.isEmpty(user.email)) {
            setEmailError("Email is required");
            isErrored = true;
        }
        if (!StringUtils.isValidPassword(user.password)) {
            setIsPasswordError(true);
            isErrored = true;
        }
        if (user.password !== user.confirmPassword) {
            setConfirmPasswordError("Confirm Password does not match");
            isErrored = true;
        }

        if (isErrored) {
            return;
        }

        registerUser(user);
    };

    return (
        <div>
            <Heading size="h2">Create Account</Heading>
            <form onSubmit={handleSubmit}>
                <TextInput
                    errorMessage={emailError}
                    label="Email"
                    placeholder="Email"
                    value={user.email}
                    onChange={(email) => {
                        setEmailError(undefined);
                        setUser(Object.assign({}, user, { email: email }));
                    }}
                />
                <PasswordInput
                    label="Password"
                    isErrored={isPasswordError}
                    placeholder="Password"
                    value={user.password}
                    onChange={(password) => {
                        setIsPasswordError(undefined);
                        setUser(
                            Object.assign({}, user, {
                                password: password,
                            })
                        );
                    }}
                />
                <PasswordStrength password={user.password || ""} />
                <PasswordInput
                    errorMessage={confirmPasswordError}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    value={user.confirmPassword}
                    onChange={(password) => {
                        setConfirmPasswordError(undefined);
                        setUser(
                            Object.assign({}, user, {
                                confirmPassword: password,
                            })
                        );
                    }}
                />
                {error && <Error message={error?.message} />}
                <div className={`${COMPONENT_CLASS}__form__terms`}>
                    <Paragraph size="xsmall">
                        By creating an account, you agree to our
                        <br />
                        <Anchor to="/terms-of-service" size="xsmall">
                            Terms of Service
                        </Anchor>{" "}
                        and{" "}
                        <Anchor to="/privacy-policy" size="xsmall">
                            Privacy Policy
                        </Anchor>
                        .
                    </Paragraph>
                </div>
                <div className={`${COMPONENT_CLASS}__form__actions`}>
                    <Button isSubmit={true} size="large">
                        Create Account
                    </Button>
                    <img alt="HCN Logo" src={HcnLogo} />
                </div>
            </form>
        </div>
    );
};

export default CreateAccount;
