import { PaymentType } from "models/interfaces/purchase";
import { ListOptions } from "./interfaces/list-options";

export const PaymentTypeOptions: ListOptions[] = [
    {
        value: PaymentType.ACH.toString(),
        label: "Bank Account",
    },
    {
        value: PaymentType.CreditCard.toString(),
        label: "Credit/Debit",
    },
];
