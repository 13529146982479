import {
    MinusIcon,
    OfficeBuildingIcon,
    ShoppingCartIcon,
} from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { CartButton } from "atoms/buttons/cart-button";
import { IconButton } from "atoms/buttons/icon-button";
import { Tag } from "atoms/tag/tag";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import Bundle from "models/interfaces/bundle";
import Facility from "models/interfaces/facility";
import Item from "models/interfaces/item";
import ProviderBundleItem from "models/interfaces/provider-bundle-item";
import MemberCartBundle from "models/interfaces/member-cart-bundle";
import ProviderBundle from "models/interfaces/provider-bundle";
import { CustomScrollbar } from "molecules/custom-scrollbar/custom-scrollbar";
import { Location } from "molecules/locations/location";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import BundleService from "services/bundle-service";
import CartService from "services/cart-service";
import { useCartState } from "utilities/contexts/cart-state-context";
import StringUtils from "utilities/string-utils";
import "./procedure-detail-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-procedure-detail";

function ProcedureDetailPage() {
    const { id } = useParams();
    const [bundle, setBundle] = useState<Bundle>();
    const [expandedLocationIds, setExpandedLocationIds] = useState<string[]>(
        []
    );
    const [providerBundle, setProviderBundle] = useState<ProviderBundle>();
    const [itemInCart, setItemInCart] = useState<
        MemberCartBundle | undefined
    >();
    const [isLoading, setIsLoading] = useState(true);
    const { state: cartState, setState: setCartState } = useCartState();
    const history = useHistory();
    const windowRef = useRef(null);

    if (StringUtils.isEmpty(id)) {
        history.push("/procedures");
    }

    useEffect(() => {
        const getBundle = async () => {
            let insurancePlanKey = undefined;
            if (
                cartState?.member?.memberInsurances &&
                cartState?.member?.memberInsurances.length > 0
            ) {
                insurancePlanKey =
                    cartState?.member?.memberInsurances[0].insurancePlanKey;
            }

            const idToken = await CognitoUtils.getIdToken();

            try {
                BundleService.getBundle(id!, idToken, insurancePlanKey).then(
                    (result) => {
                        if (result?.data) {
                            setBundle(result.data);
                            const items =
                                cartState.cart?.memberCartBundles || [];
                            const selectedItem = items.find(
                                (i) =>
                                    i.bundle?.bundleKey ===
                                    result.data?.bundleKey
                            );
                            let providerBundle: ProviderBundle | undefined;
                            if (selectedItem) {
                                providerBundle =
                                    result.data.providerBundles.find(
                                        (i) =>
                                            i.providerBundleKey ===
                                            selectedItem.providerBundleKey
                                    );
                            }

                            if (providerBundle) {
                                setProviderBundle(providerBundle);
                                setExpandedLocationIds([
                                    providerBundle.provider.facilityKey,
                                ]);
                            } else {
                                setProviderBundle(
                                    result.data.providerBundles[0]
                                );
                                setExpandedLocationIds([
                                    result.data.providerBundles[0].provider
                                        .facilityKey,
                                ]);
                            }
                            if (windowRef?.current) {
                                (windowRef.current as any).scrollTo(0, 0);
                            }
                        }
                        setIsLoading(false);
                    }
                );
            } catch (error) {
                console.error("Error fetching bundle:", error);
                setIsLoading(false);
            }
        };
        getBundle();
    }, [
        cartState.cart?.memberCartBundles,
        cartState?.member?.memberInsurances,
        id,
    ]);

    useEffect(() => {
        const items = cartState.cart?.memberCartBundles || [];
        setItemInCart(
            items.find((i) => i.bundle?.bundleKey === bundle?.bundleKey)
        );
    }, [bundle?.bundleKey, cartState.cart?.memberCartBundles]);

    const handleExpandClick = (facility: Facility) => {
        let ids = Array.from(expandedLocationIds);
        if (ids.indexOf(facility.facilityKey) > -1) {
            ids = ids.filter((id) => id !== facility.facilityKey);
        } else {
            ids.push(facility.facilityKey);
        }
        setExpandedLocationIds(ids);
    };

    const handleCartButtonClick = async (providerBundleKey?: string) => {
        const memberId = cartState.member?.id;

        if (StringUtils.isEmpty(memberId)) {
            return;
        }

        try {
            setIsLoading(true);

            if (itemInCart != null) {
                await CartService.deleteItem(
                    cartState.member!,
                    cartState.cart!,
                    itemInCart!
                );
            }
            if (itemInCart?.providerBundleKey !== providerBundleKey) {
                await CartService.addItem(cartState.member!, cartState.cart!, {
                    providerBundleKey: providerBundleKey!,
                });
            }
            const idToken = await CognitoUtils.getIdToken();
            const response = await CartService.getCart(memberId, idToken);

            if (response?.status === 200 && response?.data) {
                setCartState({
                    ...cartState,
                    ...{
                        cart: response.data,
                        cartIsLoading: false,
                    },
                });
            }

            setIsLoading(false);
        } catch (ex) {
            // TODO: Improve error handling
            console.error(ex);
            setIsLoading(false);
        }
    };

    const facility = providerBundle?.provider?.facility;
    const hasInsurance =
        (cartState.member?.memberInsurances &&
            cartState.member?.memberInsurances.length > 0) ||
        false;
    const insuredCostRange = bundle?.providerBundles.map(
        (providerBundle) => providerBundle.totalContractedCost
    ) || [0];
    const uninsuredCostRange = bundle?.providerBundles.map(
        (providerBundle) => providerBundle.totalGrossCost
    ) || [0];
    const includedProcedures =
        providerBundle?.providerBundleItems?.filter(
            (providerItem) =>
                providerItem?.utilization && providerItem.utilization === 100
        ) || [];
    const potentialProcedures =
        providerBundle?.providerBundleItems?.filter(
            (providerItem) =>
                providerItem?.utilization && providerItem.utilization < 100
        ) || [];

    const insuredCostMin = Math.min(...insuredCostRange);
    const insuredCostMax = Math.max(...insuredCostRange);
    const uninsuredCostMin = Math.min(...uninsuredCostRange);
    const uninsuredCostMax = Math.max(...uninsuredCostRange);

    let insuredCost = "$0";
    let uninsuredCost = "$0";

    if (insuredCostMin && insuredCostMax) {
        if (insuredCostMin === insuredCostMax) {
            insuredCost = StringUtils.formatCurrency(insuredCostMin);
        } else {
            insuredCost = `${StringUtils.formatCurrency(
                insuredCostMin
            )}-${StringUtils.formatCurrency(insuredCostMax)}`;
        }
    }

    if (uninsuredCostMin && uninsuredCostMax) {
        if (uninsuredCostMin === uninsuredCostMax) {
            uninsuredCost = StringUtils.formatCurrency(uninsuredCostMin);
        } else {
            uninsuredCost = `${StringUtils.formatCurrency(
                uninsuredCostMin
            )}-${StringUtils.formatCurrency(uninsuredCostMax)}`;
        }
    }

    const currentDetailSteps = history?.location?.state?.detailSteps || 0;

    return (
        <div>
            <CartLayout
                header={
                    <Button
                        onClick={() => history.go(-(currentDetailSteps + 1))}
                        size="small"
                        type="light">
                        &lt; Back to Search Results
                    </Button>
                }
                isLocked={false}
                showLoader={isLoading}
                windowRef={windowRef}>
                {bundle && (
                    <div className={COMPONENT_CLASS}>
                        {itemInCart && (
                            <div className={`${COMPONENT_CLASS}__cart`}>
                                <ShoppingCartIcon />
                                <div
                                    className={`${COMPONENT_CLASS}__cart__content`}>
                                    <Paragraph>{bundle.bundleName}</Paragraph>
                                    <Paragraph size="small">
                                        at{" "}
                                        {
                                            itemInCart!.providerBundle?.provider
                                                ?.facility?.facilityName
                                        }
                                    </Paragraph>
                                </div>
                                <CartButton
                                    isSelected={itemInCart != null}
                                    type="white"
                                    onClick={() =>
                                        handleCartButtonClick(
                                            itemInCart?.providerBundleKey
                                        )
                                    }
                                />
                            </div>
                        )}
                        <Heading size="h2">{bundle.bundleName}</Heading>
                        <div className={`${COMPONENT_CLASS}__info`}>
                            <Paragraph>
                                {bundle.bundleDescription}&nbsp;
                                {!StringUtils.isEmpty(bundle.resourceLink) && (
                                    <a
                                        href={bundle.resourceLink}
                                        target="_blank"
                                        rel="noreferrer">
                                        Learn More
                                    </a>
                                )}
                            </Paragraph>
                            <div className={`${COMPONENT_CLASS}__info__code`}>
                                <Label>Code</Label>
                                <Paragraph size="small">
                                    {bundle.primaryMedicalCodeset}-
                                    {bundle.primaryMedicalCode}
                                </Paragraph>
                                {hasInsurance ? (
                                    <>
                                        <Label>Est. Insured Cost</Label>
                                        <Paragraph size="small">
                                            {insuredCost}
                                        </Paragraph>
                                        <Label>Est. Uninsured Cost</Label>
                                        <Paragraph size="small">
                                            {uninsuredCost}
                                        </Paragraph>
                                    </>
                                ) : (
                                    <>
                                        <Label>Est. Uninsured Cost</Label>
                                        <Paragraph size="small">
                                            {uninsuredCost}
                                        </Paragraph>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={`${COMPONENT_CLASS}__locations`}>
                            <div
                                className={`${COMPONENT_CLASS}__locations__header`}>
                                <OfficeBuildingIcon />
                                <Heading size="h3">Available Locations</Heading>
                            </div>
                            <Paragraph size="small">
                                Browse the available locations for this
                                procedure to find your preferred facility. Each
                                facility will include a list of Included and
                                Potential procedures.
                            </Paragraph>
                            <div
                                className={`${COMPONENT_CLASS}__locations__wrapper`}>
                                <CustomScrollbar>
                                    <div
                                        className={`${COMPONENT_CLASS}__locations__list`}>
                                        {bundle.providerBundles.map(
                                            (providerBundle, index) => (
                                                <div
                                                    className={`${COMPONENT_CLASS}__locations__list__item`}
                                                    key={index}
                                                    onClick={() =>
                                                        setProviderBundle(
                                                            providerBundle
                                                        )
                                                    }>
                                                    <Location
                                                        facility={
                                                            providerBundle
                                                                .provider
                                                                .facility
                                                        }
                                                        isSelected={
                                                            facility?.facilityKey ===
                                                            providerBundle
                                                                .provider
                                                                .facilityKey
                                                        }
                                                        isInCart={
                                                            providerBundle?.providerBundleKey ===
                                                            itemInCart?.providerBundleKey
                                                        }
                                                        isInNetwork={
                                                            providerBundle
                                                                .provider
                                                                .insuranceStatus ===
                                                            "In-Network"
                                                        }
                                                        isInsurance={
                                                            hasInsurance
                                                        }
                                                        price={
                                                            hasInsurance
                                                                ? providerBundle.totalContractedCost
                                                                : providerBundle.totalGrossCost
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </CustomScrollbar>
                                <CustomScrollbar>
                                    {facility && (
                                        <div
                                            className={`${COMPONENT_CLASS}__locations__selected`}>
                                            <div
                                                className={`${COMPONENT_CLASS}__locations__selected__header`}>
                                                <Heading size="h3">
                                                    {facility?.facilityName}
                                                </Heading>
                                                <CartButton
                                                    isSelected={
                                                        providerBundle?.providerBundleKey ===
                                                        itemInCart?.providerBundleKey
                                                    }
                                                    type={
                                                        providerBundle?.providerBundleKey ===
                                                        itemInCart?.providerBundleKey
                                                            ? "clear"
                                                            : "primary"
                                                    }
                                                    onClick={() =>
                                                        handleCartButtonClick(
                                                            providerBundle?.providerBundleKey
                                                        )
                                                    }
                                                />
                                            </div>
                                            <Heading size="h4">
                                                Included Procedures
                                            </Heading>
                                            {providerBundle?.totalContractedCost !=
                                                null &&
                                                hasInsurance && (
                                                    <Tag>
                                                        {StringUtils.formatCurrency(
                                                            providerBundle.totalContractedCost
                                                        )}
                                                    </Tag>
                                                )}
                                            {providerBundle?.totalGrossCost !=
                                                null &&
                                                !hasInsurance && (
                                                    <Tag>
                                                        {StringUtils.formatCurrency(
                                                            providerBundle.totalGrossCost
                                                        )}
                                                    </Tag>
                                                )}
                                            <Paragraph size="xsmall">
                                                Included procedures will be
                                                performed by your healthcare
                                                provider at the time of service.
                                                Your personalized out-of-pocket
                                                estimate will be based on
                                                included procedures.
                                            </Paragraph>
                                            <div
                                                className={`${COMPONENT_CLASS}__procedure-list`}>
                                                <div
                                                    className={`${COMPONENT_CLASS}__procedure-list__header`}>
                                                    <div>
                                                        <Label>Procedure</Label>
                                                    </div>
                                                    <div>
                                                        <Label>
                                                            Patient Utilization
                                                        </Label>
                                                    </div>
                                                    <div>
                                                        <Label>
                                                            Est. Uninsured Cost
                                                        </Label>
                                                    </div>
                                                </div>
                                                {includedProcedures
                                                    .sort((a, b) =>
                                                        a.item.itemName.localeCompare(
                                                            b.item.itemName
                                                        )
                                                    )
                                                    .map(
                                                        (
                                                            providerItem: ProviderBundleItem,
                                                            index: number
                                                        ) => (
                                                            <div
                                                                className={`${COMPONENT_CLASS}__procedure-list__item included`}
                                                                key={index}>
                                                                <div>
                                                                    {providerItem.item && (
                                                                        <Paragraph size="xsmall">
                                                                            {
                                                                                providerItem
                                                                                    .item
                                                                                    .itemName
                                                                            }
                                                                        </Paragraph>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <Paragraph size="xsmall">
                                                                        {
                                                                            providerItem.utilization
                                                                        }
                                                                        %
                                                                    </Paragraph>
                                                                </div>
                                                                <div>
                                                                    <Paragraph size="xsmall">
                                                                        {StringUtils.formatCurrency(
                                                                            providerItem.grossCharge ||
                                                                                0
                                                                        )}
                                                                    </Paragraph>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            {potentialProcedures.length > 0 && (
                                                <div
                                                    className={`${COMPONENT_CLASS}__potential-procedures`}>
                                                    <Heading size="h4">
                                                        Potential Procedures
                                                    </Heading>
                                                    <Paragraph size="xsmall">
                                                        Potential procedures may
                                                        be performed by your
                                                        healthcare provider at
                                                        the time of service but
                                                        will not be factored
                                                        into your personalized
                                                        out-of-pocket estimate.
                                                        Please refer to the
                                                        Patient Utilization rate
                                                        to determine the
                                                        possibility of
                                                        additional costs.
                                                    </Paragraph>
                                                    <div
                                                        className={`${COMPONENT_CLASS}__procedure-list`}>
                                                        <div
                                                            className={`${COMPONENT_CLASS}__procedure-list__header`}>
                                                            <div>
                                                                <Label>
                                                                    Procedure
                                                                </Label>
                                                            </div>
                                                            <div>
                                                                <Label>
                                                                    Patient
                                                                    Utilization
                                                                </Label>
                                                            </div>
                                                            <div>
                                                                <Label>
                                                                    Est.
                                                                    Uninsured
                                                                    Cost
                                                                </Label>
                                                            </div>
                                                        </div>
                                                        {potentialProcedures
                                                            .sort((a, b) =>
                                                                a.item.itemName.localeCompare(
                                                                    b.item
                                                                        .itemName
                                                                )
                                                            )
                                                            .map(
                                                                (
                                                                    providerItem: ProviderBundleItem,
                                                                    index: number
                                                                ) => (
                                                                    <div
                                                                        className={`${COMPONENT_CLASS}__procedure-list__item potential`}
                                                                        key={
                                                                            index
                                                                        }>
                                                                        <div>
                                                                            {providerItem.item && (
                                                                                <Paragraph size="xsmall">
                                                                                    {
                                                                                        providerItem
                                                                                            .item
                                                                                            .itemName
                                                                                    }
                                                                                </Paragraph>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            <Paragraph size="xsmall">
                                                                                {
                                                                                    providerItem.utilization
                                                                                }

                                                                                %
                                                                            </Paragraph>
                                                                        </div>
                                                                        <div>
                                                                            <Paragraph size="xsmall">
                                                                                {StringUtils.formatCurrency(
                                                                                    providerItem.grossCharge ||
                                                                                        0
                                                                                )}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </CustomScrollbar>
                            </div>
                            <div
                                className={`${COMPONENT_CLASS}__locations__cards`}>
                                {bundle.providerBundles.map(
                                    (providerBundle, index) => (
                                        <div
                                            className={`${COMPONENT_CLASS}__locations__list__item`}
                                            key={index}
                                            onClick={() =>
                                                setProviderBundle(
                                                    providerBundle
                                                )
                                            }>
                                            <Location
                                                facility={
                                                    providerBundle.provider
                                                        .facility
                                                }
                                                isExpanded={
                                                    expandedLocationIds.indexOf(
                                                        providerBundle.provider
                                                            .facilityKey
                                                    ) > -1
                                                }
                                                isSelected={false}
                                                isInCart={
                                                    providerBundle?.providerBundleKey ===
                                                    itemInCart?.providerBundleKey
                                                }
                                                isInNetwork={
                                                    providerBundle.provider
                                                        .insuranceStatus ===
                                                    "In-Network"
                                                }
                                                isInsurance={hasInsurance}
                                                price={
                                                    providerBundle.totalContractedCost
                                                }
                                                onExpandClick={() =>
                                                    handleExpandClick(
                                                        providerBundle.provider
                                                            .facility
                                                    )
                                                }>
                                                <div
                                                    className={`${COMPONENT_CLASS}__locations__selected`}>
                                                    <Heading size="h4">
                                                        Included Procedures
                                                    </Heading>
                                                    <Paragraph size="small">
                                                        Included procedures will
                                                        be performed by your
                                                        healthcare provider at
                                                        the time of service.
                                                        Your personalized
                                                        out-of-pocket estimate
                                                        will be based on
                                                        included procedures.
                                                    </Paragraph>
                                                    <div
                                                        className={`${COMPONENT_CLASS}__procedure-list`}>
                                                        {includedProcedures.map(
                                                            (
                                                                providerItem: ProviderBundleItem,
                                                                index: number
                                                            ) => (
                                                                <div
                                                                    className={`${COMPONENT_CLASS}__procedure-list__item included`}
                                                                    key={index}>
                                                                    <div>
                                                                        {providerItem.item && (
                                                                            <Paragraph size="xsmall">
                                                                                {
                                                                                    providerItem
                                                                                        .item
                                                                                        .itemName
                                                                                }
                                                                            </Paragraph>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <Label>
                                                                            Utilization
                                                                        </Label>
                                                                        <Paragraph size="small">
                                                                            {
                                                                                providerItem.utilization
                                                                            }
                                                                            %
                                                                        </Paragraph>
                                                                    </div>
                                                                    <div>
                                                                        <Label>
                                                                            Est.
                                                                            Cost
                                                                        </Label>
                                                                        <Paragraph size="small">
                                                                            {StringUtils.formatCurrency(
                                                                                providerItem.grossCharge ||
                                                                                    0
                                                                            )}
                                                                        </Paragraph>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    {potentialProcedures.length >
                                                        0 && (
                                                        <div>
                                                            <Heading size="h4">
                                                                Potential
                                                                Procedures
                                                            </Heading>
                                                            <Paragraph size="small">
                                                                Potential
                                                                procedures may
                                                                be performed by
                                                                your healthcare
                                                                provider at the
                                                                time of service
                                                                but will not be
                                                                factored into
                                                                your
                                                                personalized
                                                                out-of-pocket
                                                                estimate. Please
                                                                refer to the
                                                                Patient
                                                                Utilization rate
                                                                to determine the
                                                                possibility of
                                                                additional
                                                                costs.
                                                            </Paragraph>
                                                            <div
                                                                className={`${COMPONENT_CLASS}__procedure-list`}>
                                                                {potentialProcedures.map(
                                                                    (
                                                                        providerItem: ProviderBundleItem,
                                                                        index: number
                                                                    ) => (
                                                                        <div
                                                                            className={`${COMPONENT_CLASS}__procedure-list__item potential`}
                                                                            key={
                                                                                index
                                                                            }>
                                                                            <div>
                                                                                {providerItem.item && (
                                                                                    <Paragraph size="xsmall">
                                                                                        {
                                                                                            providerItem
                                                                                                .item
                                                                                                .itemName
                                                                                        }
                                                                                    </Paragraph>
                                                                                )}
                                                                            </div>
                                                                            <div>
                                                                                <Label>
                                                                                    Utilization
                                                                                </Label>
                                                                                <Paragraph size="small">
                                                                                    {
                                                                                        providerItem.utilization
                                                                                    }

                                                                                    %
                                                                                </Paragraph>
                                                                            </div>
                                                                            <div>
                                                                                <Label>
                                                                                    Est.
                                                                                    Cost
                                                                                </Label>
                                                                                <Paragraph size="small">
                                                                                    {StringUtils.formatCurrency(
                                                                                        providerItem.grossCharge ||
                                                                                            0
                                                                                    )}
                                                                                </Paragraph>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={`${COMPONENT_CLASS}__locations__cards__action`}>
                                                        {providerBundle?.providerBundleKey ===
                                                            itemInCart?.providerBundleKey && (
                                                            <IconButton
                                                                icon={
                                                                    <MinusIcon />
                                                                }
                                                                type="clear"
                                                                onClick={() =>
                                                                    handleCartButtonClick(
                                                                        providerBundle?.providerBundleKey
                                                                    )
                                                                }>
                                                                Remove From Cart
                                                            </IconButton>
                                                        )}
                                                        {providerBundle?.providerBundleKey !==
                                                            itemInCart?.providerBundleKey && (
                                                            <IconButton
                                                                icon={
                                                                    <ShoppingCartIcon />
                                                                }
                                                                onClick={() =>
                                                                    handleCartButtonClick(
                                                                        providerBundle?.providerBundleKey
                                                                    )
                                                                }>
                                                                Add To Cart
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                </div>
                                            </Location>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </CartLayout>
        </div>
    );
}

export default ProcedureDetailPage;
