import React from "react";
import { Button, ButtonProps } from "./button";
import "./button.scss";

interface IconButtonProps extends ButtonProps {
    icon: React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const IconButton = ({ icon, ...props }: IconButtonProps) => {
    return (
        <Button cssClassName="icon" {...props}>
            {icon}
            <span>{props.children}</span>
        </Button>
    );
};
