import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
import { Paragraph } from "atoms/typography/paragraph";
import React from "react";
import StringUtils from "utilities/string-utils";
import "./password-strength.scss";

const COMPONENT_CLASS = "c-password-strength";

interface PasswordStrengthProps {
    password: string;
}

/**
 * Primary UI component for user interaction
 */
export const PasswordStrength = ({ password }: PasswordStrengthProps) => {
    const lengthValid = !StringUtils.isEmpty(password) && password.length >= 8;
    const validMatches = [];

    // Validate lowercase characters
    validMatches.push(!StringUtils.isEmpty(password) && /[a-z]/.test(password));

    // Validate uppercase characters
    validMatches.push(!StringUtils.isEmpty(password) && /[A-Z]/.test(password));

    // Validate numbers
    validMatches.push(!StringUtils.isEmpty(password) && /[0-9]/.test(password));

    // Validate special characters
    validMatches.push(
        !StringUtils.isEmpty(password) && /[^\w\s]/.test(password)
    );

    const containValid = validMatches.filter((m) => m).length >= 4;

    return (
        <div className={COMPONENT_CLASS}>
            <ul className={`${COMPONENT_CLASS}__list`}>
                <li className={`${COMPONENT_CLASS}__list__item`}>
                    {lengthValid && (
                        <CheckCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon valid`}
                        />
                    )}
                    {!lengthValid && (
                        <XCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon invalid`}
                        />
                    )}
                    <Paragraph size="small">
                        At least 8 characters in length
                    </Paragraph>
                </li>
                <li className={`${COMPONENT_CLASS}__list__item`}>
                    {containValid && (
                        <CheckCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon valid`}
                        />
                    )}
                    {!containValid && (
                        <XCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon invalid`}
                        />
                    )}
                    <Paragraph size="small">Should contain:</Paragraph>
                </li>
                <li className={`${COMPONENT_CLASS}__list__item indent`}>
                    {validMatches[0] && (
                        <CheckCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon valid`}
                        />
                    )}
                    {!validMatches[0] && (
                        <div
                            className={`${COMPONENT_CLASS}__list__item__icon empty`}></div>
                    )}
                    <Paragraph size="small">Lowercase letters (a-z)</Paragraph>
                </li>
                <li className={`${COMPONENT_CLASS}__list__item indent`}>
                    {validMatches[1] && (
                        <CheckCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon valid`}
                        />
                    )}
                    {!validMatches[1] && (
                        <div
                            className={`${COMPONENT_CLASS}__list__item__icon empty`}></div>
                    )}
                    <Paragraph size="small">Uppercase letters (A-Z)</Paragraph>
                </li>
                <li className={`${COMPONENT_CLASS}__list__item indent`}>
                    {validMatches[2] && (
                        <CheckCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon valid`}
                        />
                    )}
                    {!validMatches[2] && (
                        <div
                            className={`${COMPONENT_CLASS}__list__item__icon empty`}></div>
                    )}
                    <Paragraph size="small">Numbers (0-9)</Paragraph>
                </li>
                <li className={`${COMPONENT_CLASS}__list__item indent`}>
                    {validMatches[3] && (
                        <CheckCircleIcon
                            className={`${COMPONENT_CLASS}__list__item__icon valid`}
                        />
                    )}
                    {!validMatches[3] && (
                        <div
                            className={`${COMPONENT_CLASS}__list__item__icon empty`}></div>
                    )}
                    <Paragraph size="small">
                        Special Characters (!, $, #, etc)
                    </Paragraph>
                </li>
            </ul>
        </div>
    );
};
