import { Error } from "atoms/forms/error";
import { Label } from "atoms/typography/label";
import { FormValidation } from "models/interfaces/form-validation";
import React, { useMemo } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import StringUtils from "utilities/string-utils";
import "./dropdown.scss";

const BASE_CLASS_NAME = "c-dropdown";

export interface DropdownOption<TValue = string> {
    label: string;
    value: TValue;
}

interface DropdownProps<TValue> {
    fieldValidation?: FormValidation[];
    errorMessage?: string;
    isLocked?: boolean;
    isRequired?: boolean;
    isSearchable?: boolean;
    name?: string;
    label?: string;
    loadOptions?: (inputValue: string) => Promise<DropdownOption<TValue>[]>;
    options?: DropdownOption<TValue>[];
    value?: TValue;
    placeholder?: string;
    onChange?: (value: TValue) => void;
    simpleSearch?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export function Dropdown<TValue>({
    fieldValidation,
    errorMessage,
    isLocked = false,
    isRequired = false,
    isSearchable = false,
    name,
    label,
    loadOptions,
    options = [],
    value,
    placeholder,
    simpleSearch = true,
    ...props
}: DropdownProps<TValue>) {
    if (errorMessage == null && fieldValidation != null) {
        errorMessage = fieldValidation?.find((v) => v?.field === name)?.error;
    }

    const errorClass = StringUtils.hasValue(errorMessage) ? "errored" : "";
    const lockedClass = isLocked ? "locked" : "";
    const searchClass = isSearchable ? "search" : "";

    const customLoadOptions = useMemo(() => {
        if (loadOptions != null || !simpleSearch) {
            return loadOptions;
        }

        return async (search: string): Promise<DropdownOption<TValue>[]> => {
            const listOptions = options.filter((option) => {
                if (!search) {
                    return true;
                }

                return option.label
                    .toLowerCase()
                    .startsWith(search.toLowerCase());
            });

            return await Promise.resolve(listOptions);
        };
    }, [loadOptions, options, simpleSearch]);

    return (
        <div
            className={`${BASE_CLASS_NAME} ${[
                errorClass,
                lockedClass,
                searchClass,
            ].join(" ")}`}>
            {StringUtils.hasValue(label) && (
                <Label isRequired={isRequired}>{label}</Label>
            )}
            {customLoadOptions && (
                <AsyncSelect
                    cacheOptions
                    className={`${BASE_CLASS_NAME}__container`}
                    classNamePrefix={BASE_CLASS_NAME}
                    isDisabled={isLocked}
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    id={name}
                    name={name}
                    defaultOptions={options}
                    loadOptions={customLoadOptions}
                    onChange={(newValue: any, actionMeta: any) => {
                        if (props.onChange) {
                            props.onChange(newValue.value);
                        }
                    }}
                    value={options.find((o) => o.value === value)}
                />
            )}
            {!customLoadOptions && (
                <Select
                    className={`${BASE_CLASS_NAME}__container`}
                    classNamePrefix={BASE_CLASS_NAME}
                    isDisabled={isLocked}
                    isSearchable={isSearchable}
                    placeholder={placeholder}
                    id={name}
                    name={name}
                    options={options}
                    onChange={(newValue: any, actionMeta: any) => {
                        if (props.onChange) {
                            props.onChange(newValue.value);
                        }
                    }}
                    value={options.find((o) => o.value === value)}
                />
            )}
            {StringUtils.hasValue(errorMessage) && (
                <Error message={errorMessage} />
            )}
        </div>
    );
}
