import React from "react";
import "./paragraph.scss";

interface ParagraphProps {
    cssClassName?: string;
    children: React.ReactNode;
    size?: "xsmall" | "small" | "medium" | "large" | "xlarge";
    type?: "primary" | "white" | "light" | "color";
    weight?: "normal" | "semibold";
}

/**
 * Primary UI component
 */
export const Paragraph = ({
    cssClassName,
    children,
    size = "medium",
    type = "primary",
    weight = "normal",
    ...props
}: ParagraphProps) => {
    return (
        <p
            className={`c-paragraph ${[
                size,
                type,
                weight,
                cssClassName || "",
            ].join(" ")}`}
            {...props}>
            {children}
        </p>
    );
};
