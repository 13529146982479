import { UserCircleIcon } from "@heroicons/react/solid";
import { Button } from "atoms/buttons/button";
import React from "react";
import "./card.scss";

const BASE_CLASS_NAME = "c-card";

interface NewMemberCardProps {
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const NewMemberCard = ({ ...props }: NewMemberCardProps) => {
    return (
        <div
            className={`${BASE_CLASS_NAME} primary new-member expanded stacked`}>
            <UserCircleIcon />
            <Button
                type="primary"
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}>
                Add Member
            </Button>
        </div>
    );
};
