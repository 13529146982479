import { useState } from "react";
import AccountService from "services/account-service";
import React from "react";
import VerifyEmail from "interfaces/verify-email";
import { useSearchParams } from "utilities/hooks/use-search-params";
import OnboardingLayout, {
    OnboardingStateAtom,
} from "layouts/onboarding-layout/onboarding-layout";
import { MailIcon, XCircleIcon } from "@heroicons/react/outline";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { Anchor } from "atoms/typography/anchor";
import { Loader } from "molecules/loader/loader";
import { Button } from "atoms/buttons/button";
import { useHistory } from "react-router-dom";
import useOneTimeExecution from "utilities/hooks/use-one-time-execution";
import "./verify-email-page.scss";
import { useAtom } from "jotai";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-verify-email";

const VerfiyEmailPage = () => {
    const history = useHistory();
    const { userid, token } = useSearchParams();
    const [error, setError] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>();

    const [, updateOnboardingState] = useAtom(OnboardingStateAtom);

    const verifyEmail = async (payload: VerifyEmail) => {
        try {
            // Call the verifyEmail method from AccountService
            await AccountService.verifyEmail(payload.userId, payload.token);
            setIsEmailVerified(true); // Set to true upon successful verification
        } catch (error) {
            setError("An error occurred during verification.");
        } finally {
            setIsLoading(false);
        }
    };

    const onResendEmail = async () => {
        try {
            const idToken = await CognitoUtils.getIdToken();
            setIsLoading(true);
            const email = await AccountService.sendVerificationEmail({
                userId: userid,
                token,
            });

            if (!email) {
                setIsLoading(false);
                setError("And error occurred");
                return;
            }

            updateOnboardingState({ email, userId: userid });
        } catch (error) {
            setError("And error occurred");
        }

        setIsLoading(false);
        history.push("/create-account/confirmation");
    };

    useOneTimeExecution(() => {
        verifyEmail({ userId: userid, token });
    });

    return (
        <OnboardingLayout>
            {isLoading && <Loader isVisible={true} />}
            <div className={COMPONENT_CLASS}>
                <div
                    className={`${COMPONENT_CLASS}__content ${
                        isLoading ? "--loading" : ""
                    }`}>
                    {!isLoading && (
                        <>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                {isEmailVerified === true ? (
                                    <>
                                        <MailIcon />
                                        <Heading size="h2">
                                            Your Email is Verified
                                        </Heading>
                                        <Paragraph size="medium">
                                            You can now log in to your HCN
                                            account with your email.
                                        </Paragraph>
                                    </>
                                ) : (
                                    <>
                                        <XCircleIcon />
                                        <Heading size="h2">We're Sorry</Heading>
                                        <Paragraph size="medium">
                                            This verification link has expired.
                                            Please click Resend, and we'll send
                                            a new verification link to your
                                            email address.
                                        </Paragraph>
                                    </>
                                )}
                            </div>
                            <div className={`${COMPONENT_CLASS}__actions`}>
                                {isEmailVerified === true ? (
                                    <Anchor
                                        cssClassName="create-account-confirmation"
                                        to={`/sign-in?emailVerified=${isEmailVerified}`}
                                        size="large"
                                        type="primary-button">
                                        Continue to Sign-in
                                    </Anchor>
                                ) : (
                                    <Button
                                        cssClassName="create-account-confirmation"
                                        onClick={onResendEmail}
                                        size="large">
                                        Resend
                                    </Button>
                                )}
                            </div>
                            {error && (
                                <div className={`${COMPONENT_CLASS}__error`}>
                                    {error}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </OnboardingLayout>
    );
};

export default VerfiyEmailPage;
