import { PaymentStatus } from "models/enumerations/payment-status";
import { Payment } from "models/interfaces/payment";
import { useMemo } from "react";
import { DateTime } from "luxon";
import ArrayUtils from "utilities/array-utils";

interface UsePaymentTimelineOptions {
    payments: Payment[];
    total: number;
    monthlyAmount: number;
    numberOfPayments: number;
    numberOfRemainingPayments: number;
    startDate: string;
}

interface PaymentTimelineItem {
    purchaseDate: Date;
    total: number;
    amountRemaining: number;
    status?: PaymentStatus;
}

const usePaymentTimeline = ({
    payments,
    total,
    monthlyAmount,
    numberOfPayments,
    numberOfRemainingPayments,
    startDate,
}: UsePaymentTimelineOptions) => {
    const paymentTimeline: PaymentTimelineItem[] = useMemo(() => {
        const pastPayments = aggregatePastPayments(
            payments,
            total,
            monthlyAmount
        );

        return generatePaymentTimeline(
            numberOfPayments,
            numberOfRemainingPayments,
            calculateStartDate(
                startDate,
                numberOfRemainingPayments + payments.length - numberOfPayments
            ),
            monthlyAmount,
            total,
            pastPayments
        );
    }, [
        monthlyAmount,
        numberOfPayments,
        numberOfRemainingPayments,
        payments,
        startDate,
        total,
    ]);

    return {
        paymentTimeline,
    };
};

// Private Functions

function calculateStartDate(
    startDate: string,
    failedPaymentsCount: number
): string {
    if (failedPaymentsCount === 0) {
        return startDate;
    }

    return DateTime.fromISO(startDate)
        .plus({ month: failedPaymentsCount })
        .toISO();
}

function aggregatePastPayments(
    payments: Payment[],
    total: number,
    monthlyAmount: number
) {
    return payments.reduce(
        (prev: PaymentTimelineItem[], current: Payment, index: number) => {
            return [
                ...prev,
                buildPastPaymentTimelineItem(
                    prev,
                    total,
                    monthlyAmount,
                    current,
                    index - 1
                ),
            ];
        },
        []
    );
}

function buildPastPaymentTimelineItem(
    prev: PaymentTimelineItem[],
    total: number,
    monthlyAmount: number,
    current: Payment,
    prevIndex: number
): PaymentTimelineItem {
    const prevAmountRemaining = prev[prevIndex]?.amountRemaining ?? total;
    const amountRemaining = prevAmountRemaining - monthlyAmount;

    return {
        purchaseDate: new Date(current.purchaseDate),
        total: monthlyAmount,
        amountRemaining: Number(
            current.status !== PaymentStatus.Failed
                ? amountRemaining.toFixed(2)
                : prevAmountRemaining
        ),
        status: current.status,
    };
}

function generatePaymentTimeline(
    numberOfPayments: number,
    numberOfRemainingPayments: number,
    startDate: string,
    monthlyAmount: number,
    total: number,
    pastPayments: PaymentTimelineItem[]
): PaymentTimelineItem[] {
    const start = numberOfPayments - numberOfRemainingPayments + 1;
    const end = numberOfPayments;
    const totalRemaining =
        pastPayments.length === 0
            ? total
            : pastPayments[pastPayments.length - 1].amountRemaining;

    const calc = ArrayUtils.range(end, start).reduce(
        (
            prev: { totalRemaining: number; payments: PaymentTimelineItem[] },
            paymentNumber: number
        ) => {
            if (prev.totalRemaining <= 0) {
                return prev;
            }

            const isLastPayment = numberOfPayments <= paymentNumber;
            const nextTimelineItem = {
                purchaseDate: DateTime.fromISO(startDate)
                    .plus({ month: paymentNumber - 1 })
                    .toJSDate(),
                total: isLastPayment ? prev.totalRemaining : monthlyAmount,
                amountRemaining: isLastPayment
                    ? 0
                    : Number((prev.totalRemaining - monthlyAmount).toFixed(2)),
            };

            return {
                totalRemaining: nextTimelineItem.amountRemaining,
                payments: [...prev.payments, nextTimelineItem],
            };
        },
        { totalRemaining, payments: pastPayments }
    );

    return calc.payments;
}

export default usePaymentTimeline;
