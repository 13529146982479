import { BankAccountType } from "models/interfaces/purchase";
import { ListOptions } from "./interfaces/list-options";

export const BankAccountTypeOptions: ListOptions[] = [
    {
        value: BankAccountType.Checking.toString(),
        label: "Checking",
    },
    {
        value: BankAccountType.Savings.toString(),
        label: "Savings",
    },
];
