import { Anchor } from "atoms/typography/anchor";
import HcnLogoGrey from "assets/images/hcn-logo-grey-2@2x.png";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { DateTime } from "luxon";
import React from "react";
import "./contact-us-page.scss";
import { Button } from "atoms/buttons/button";
import { useHistory } from "react-router-dom";

const COMPONENT_CLASS = "c-contact-us-page";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ContactUsPage: React.FC<any> = () => {
    const history = useHistory();

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__window`}>
                <div className={`${COMPONENT_CLASS}__container__header`}>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        <div
                            className={`${COMPONENT_CLASS}__container__header__content`}>
                            <div className="space-y-7 pt-7">
                                <Button
                                    onClick={() => history.goBack()}
                                    size="small"
                                    type="light">
                                    &lt; Back
                                </Button>
                                <Heading size="h1" type="white">
                                    Contact Us
                                </Heading>
                            </div>
                        </div>
                    </div>
                </div>
                <main className={`${COMPONENT_CLASS}__content`}>
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <div>
                            <div
                                className="article-container"
                                id="article-container">
                                <article className="article">
                                    <section className="article-info">
                                        <div className="article-content">
                                            <div className="article-body">
                                                <Paragraph>
                                                    <strong>
                                                        <em>
                                                            Contact us at
                                                            1-800-237-5044
                                                        </em>
                                                    </strong>
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </section>
                                </article>
                            </div>
                        </div>
                        <div
                            className={`${COMPONENT_CLASS}__container__footer`}>
                            <div className={`${COMPONENT_CLASS}__footer__logo`}>
                                <img
                                    alt="Healthcare Commerce Network Logo"
                                    src={HcnLogoGrey}
                                />
                            </div>
                            <div
                                className={`${COMPONENT_CLASS}__footer__links`}>
                                <Anchor to="/terms-of-service" size="xsmall">
                                    Terms of Service
                                </Anchor>{" "}
                                |{" "}
                                <Anchor to="/privacy-policy" size="xsmall">
                                    Privacy Policy
                                </Anchor>
                                |{" "}
                                <Anchor to="/contact-us" size="xsmall">
                                    Contact Us
                                </Anchor>
                            </div>
                            <Paragraph size="xsmall" type="white">
                                &copy; {DateTime.now().toFormat("yyyy")}
                            </Paragraph>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default ContactUsPage;
