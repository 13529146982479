import { useCallback, useEffect, useMemo, useState } from "react";
import PriceJob from "models/interfaces/price-job";
import PriceService from "services/price-service";
import { PriceJobStatus } from "models/enumerations/price-job-status";

// Interfaces

export interface UsePriceJobServiceHookOptions {
    refreshRateMs?: number;
    memberId?: string;
    cartId?: string;
    priceJobId?: string;
    isTimedOut?: boolean;
}

export interface UsePriceJobServiceHook {
    isProcessing: boolean;
    isProcessed: boolean;
    data?: PriceJob;
    status: PriceJobStatus;
    statusDescription: string;
    personalizedPriceTotal: number;
}

// Constants

const REFRESH_INTERVAL = 1000 * 1; //1 seconds

/**
 * Hook to get the price job for a cart.
 */
export default function usePriceJob({
    refreshRateMs = REFRESH_INTERVAL,
    cartId,
    memberId,
    priceJobId,
    isTimedOut,
}: UsePriceJobServiceHookOptions = {}): UsePriceJobServiceHook {
    const [priceJob, setPriceJob] = useState<PriceJob>();
    const [priceJobLoaded, setPriceJobLoaded] = useState(false);
    const [priceJobLoading, setPriceJobLoading] = useState(true);
    const { status } = priceJob ?? {};

    const statusDescription = useMemo(
        () => getStatusDescription(status),
        [status]
    );

    const personalizedPriceTotal = useMemo(() => {
        return calculatePersonalizedPriceTotal(priceJob);
    }, [priceJob]);

    const load = useCallback(
        (abortSignal?: AbortSignal) => {
            if (!cartId || !memberId || !priceJobId || isTimedOut) {
                return;
            }

            setPriceJobLoading(true);

            const callApi = async () => {
                if (isTimedOut) {
                    return; // Stop further API calls if timed out
                }
                try {
                    const response = await PriceService.getJob(
                        memberId,
                        cartId,
                        priceJobId,
                        abortSignal
                    );

                    const isLoaded =
                        response?.data?.status === PriceJobStatus.Complete;

                    setPriceJob(response?.data);
                    setPriceJobLoaded(isLoaded);
                    setPriceJobLoading(!isLoaded);

                    if (isLoaded && timer) {
                        clearInterval(timer);
                        return;
                    }
                } catch (e) {
                    console.error("Error loading price job api", e);
                }
            };

            const timer = setInterval(callApi, refreshRateMs);

            if (abortSignal != null) {
                abortSignal.onabort = () => {
                    if (timer) {
                        clearInterval(timer);
                    }
                };
            }

            callApi();
        },
        [cartId, memberId, priceJobId, refreshRateMs, isTimedOut]
    );

    useEffect(() => {
        const abortController = new AbortController();

        load(abortController.signal);

        return () => abortController.abort();
    }, [load]);

    return {
        isProcessed: priceJobLoaded,
        isProcessing: priceJobLoading,
        data: priceJob,
        status: priceJob?.status || PriceJobStatus.Scheduled,
        statusDescription,
        personalizedPriceTotal,
    };
}

// Local Functions

const calculatePersonalizedPriceTotal = (priceJob?: PriceJob) => {
    if (priceJob == null) {
        return 0;
    }

    const { discountResponses = [], status } = priceJob;

    if (status !== PriceJobStatus.Complete || discountResponses.length < 1) {
        return 0;
    }

    return discountResponses.reduce((prev, curr) => {
        return (prev += curr.personalPrice);
    }, 0);
};

const getStatusDescription = (status?: PriceJobStatus): string => {
    switch (status) {
        case PriceJobStatus.Scheduled:
        case PriceJobStatus.PatientSearch:
        case PriceJobStatus.PatientNew:
        case PriceJobStatus.PatientPendingCreate:
            return "Step 1 - Finding & Updating Patient Record";
        case PriceJobStatus.OrderCreate:
            return "Step 2 - Validating Patient's Insurance Coverage";
        case PriceJobStatus.EstimateSubmit:
            return "Step 3 - Checking for Financial Aid Eligibility";
        case PriceJobStatus.DiscountScoring:
            return "Step 4 - Personalizing Out-of-Pocket Price";
        case PriceJobStatus.ManualWorkQueue:
            return "Your request is being reviewed. We will notify you once the price estimation is ready.";
        case PriceJobStatus.Error:
            return "Error - There was an error processing your request. Please try again";
        case PriceJobStatus.Complete:
        default:
            return "";
    }
};
