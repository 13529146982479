import HcnLogo from "assets/images/hcn-logo-grey-2@2x.png";
import { Button } from "atoms/buttons/button";
import { Error } from "atoms/forms/error";
import { PasswordInput } from "atoms/forms/password-input";
import { TextInput } from "atoms/forms/text-input";
import { Anchor } from "atoms/typography/anchor";
import { Heading } from "atoms/typography/heading";
import { UserSignIn } from "interfaces/user-sign-in";
import { useAtomValue } from "jotai";
import { OnboardingStateAtom } from "layouts/onboarding-layout/onboarding-layout";
import { Loader } from "molecules/loader/loader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountService from "services/account-service";
import {
    AuthError,
    AuthErrorCodes,
    useAuth,
} from "utilities/contexts/auth-state-context";
import StringUtils from "utilities/string-utils";
import "./sign-in.scss";
import { CognitoConfig, ICognitoConfig } from "services/cognitoConfig";
import env from "react-dotenv";
import Auth from "@aws-amplify/auth";
const COMPONENT_CLASS = "c-sign-in";

const SignIn: React.FC = () => {
    const [error, setError] = useState<AuthError>();
    const [isVerifying, setIsVerifying] = useState(false);
    const [emailError, setEmailError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [signInUser, setSignInUser] = useState<UserSignIn>({
        email: "",
        password: "",
    });
    const allowedProviders = env.HCN_SIGN_IN_PROVIDERS?.split(",");
    const { error: authError, isLoading, signIn, ssoSignIn } = useAuth();

    useEffect(() => {
        setError(authError);
    }, [authError]);

    const resendVerificationCode = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.resendSignUp(user.username);
        } catch (error) {
            console.error("Error resending code:", error);
        }
    };

    const SignInError: React.FC<{
        error?: AuthError;
        onVerifying: () => void;
    }> = ({ error, onVerifying }): JSX.Element | null => {
        const history = useHistory();
        const { userId = "" } = useAtomValue(OnboardingStateAtom);

        const resendVerificationEmail = async () => {
            onVerifying();

            try {
                await AccountService.sendVerificationEmail({
                    userId,
                    token: "",
                });
            } catch {}

            history.push("/create-account/confirmation");
        };

        if (!error) {
            return null;
        }

        if (error.code === AuthErrorCodes.EmailVerificationRequired) {
            return (
                <Error
                    message={
                        <span className={`${COMPONENT_CLASS}__verify-email`}>
                            To sign in, you must verify your email.{" "}
                            <Button onClick={resendVerificationEmail}>
                                Resend Verification Email.
                            </Button>
                        </span>
                    }
                />
            );
        }

        return <Error message={error.message} />;
    };

    const shouldShowResendVerification =
        error?.code === AuthErrorCodes.EmailVerificationRequired;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (isLoading) {
            return;
        }

        let isErrored = false;

        setEmailError(undefined);
        setPasswordError(undefined);
        setError(undefined);

        if (StringUtils.isEmpty(signInUser.email)) {
            setEmailError("Email is required");
            isErrored = true;
        }
        if (StringUtils.isEmpty(signInUser.password)) {
            setPasswordError("Password is required");
            isErrored = true;
        }

        if (isErrored) {
            return;
        }
        signIn(signInUser);
    };

    return (
        <div>
            <div>
                <Heading size="h2">Sign In</Heading>
                <form onSubmit={handleSubmit}>
                    {/* Render email and password fields if CognitoConfig is empty */}
                    {/*{CognitoConfig.length === 0 && (*/}
                    <>
                        <TextInput
                            errorMessage={emailError}
                            label="Email"
                            placeholder="Email"
                            value={signInUser.email}
                            onChange={(email) => {
                                setEmailError(undefined);
                                setSignInUser(
                                    Object.assign({}, signInUser, {
                                        email: email,
                                    })
                                );
                            }}
                        />
                        <PasswordInput
                            errorMessage={passwordError}
                            label="Password"
                            placeholder="Password"
                            value={signInUser.password}
                            onChange={(password) => {
                                setPasswordError(undefined);
                                setSignInUser(
                                    Object.assign({}, signInUser, {
                                        password: password,
                                    })
                                );
                            }}
                        />
                        <div
                            className={`${COMPONENT_CLASS}__forgot-password-container`}>
                            <Anchor
                                cssClassName="forgot-password"
                                to="/forgot-password"
                                size="xsmall">
                                Forgot Password
                            </Anchor>
                        </div>
                        <SignInError
                            error={error}
                            onVerifying={() => setIsVerifying(true)}
                        />
                        <Loader isVisible={isVerifying} />
                        <Button
                            isSubmit={true}
                            size="large"
                            cssClassName="primary"
                            onClick={() => {
                                signIn(signInUser);
                            }}>
                            Sign In
                        </Button>
                    </>
                    {/*)}*/}
                    {/* Render buttons for each identity provider with display name */}
                    <div className={`${COMPONENT_CLASS}__form__actions`}>
                        {CognitoConfig.filter((config) =>
                            allowedProviders?.includes(config.identityProvider)
                        ).map((config: ICognitoConfig) => (
                            <div key={config.identityProvider}>
                                <Button
                                    isSubmit={false}
                                    size="medium"
                                    cssClassName="sso-button"
                                    onClick={() => {
                                        ssoSignIn("", config.identityProvider);
                                    }}>
                                    Sign in with {config.displayName}
                                </Button>
                            </div>
                        ))}
                    </div>
                    {/* Render forgot password, error, loader, and actions */}
                    {/*CognitoConfig.length === 0 && (
                        <>
                            <Anchor
                                cssClassName="forgot-password"
                                to="/forgot-password"
                                size="xsmall">
                                Forgot Password
                            </Anchor>
                            <SignInError
                                error={error}
                                onVerifying={() => setIsVerifying(true)}
                            />
                            <Loader isVisible={isVerifying} />
                            {shouldShowResendVerification && (
                                <Button onClick={resendVerificationCode}>
                                    Resend Verification Code
                                </Button>
                            )}
                            <div
                                className={`${COMPONENT_CLASS}__form__actions`}>
                                <Button isSubmit={true} size="medium">
                                    Sign In
                                </Button>
                            </div>*
                        </>
                    )*/}
                </form>
            </div>

            <img alt="HCN Logo" src={HcnLogo} />
        </div>
    );
};

export default SignIn;
