import { useEffect, useRef } from "react";

export default function useOneTimeExecution(
    execute: () => void,
    condition: boolean = true
): void {
    const executedFlag = useRef(false);

    useEffect(() => {
        if (executedFlag.current || !condition) {
            return;
        }

        executedFlag.current = true;
        execute();
    }, [condition, execute]);
}
