import { CheckIcon } from "@heroicons/react/outline";
import { Error } from "atoms/forms/error";
import { FormValidation } from "models/interfaces/form-validation";
import React from "react";
import StringUtils from "utilities/string-utils";
import "./checkbox.scss";

const BASE_CLASS_NAME = "c-checkbox";

interface CheckboxProps {
    fieldValidation?: FormValidation[];
    errorMessage?: string;
    label?: string;
    isChecked: boolean;
    isLocked?: boolean;
    name?: string;
    onChange?: (isChecked: boolean) => void;
    type?: "primary" | "white";
}

/**
 * Primary UI component for user interaction
 */
export const Checkbox = ({
    fieldValidation,
    errorMessage,
    label,
    isChecked = false,
    isLocked = false,
    name,
    type = "primary",
    ...props
}: CheckboxProps) => {
    if (errorMessage == null && fieldValidation != null) {
        errorMessage = fieldValidation?.find((v) => v?.field === name)?.error;
    }

    const checkedClass = isChecked ? "active" : "";
    const errorClass = StringUtils.hasValue(errorMessage) ? "errored" : "";
    const lockedClass = isLocked ? "locked" : "";

    return (
        <span>
            <label
                className={`${BASE_CLASS_NAME} ${[
                    errorClass,
                    checkedClass,
                    lockedClass,
                    type,
                ].join(" ")}`}>
                <input
                    type="checkbox"
                    onChange={() => {
                        if (props.onChange && !isLocked) {
                            props.onChange(!isChecked);
                        }
                    }}
                />
                <span
                    className={`${BASE_CLASS_NAME}__checkmark ${checkedClass} ${
                        isLocked ? "locked" : ""
                    }`}
                    aria-hidden="true">
                    <CheckIcon />
                </span>
                {!StringUtils.isEmpty(label) && (
                    <span className={`${BASE_CLASS_NAME}__label`}>{label}</span>
                )}
            </label>
            {StringUtils.hasValue(errorMessage) && (
                <Error message={errorMessage} />
            )}
        </span>
    );
};
