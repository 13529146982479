import React, { useCallback, useMemo, useState } from "react";
import { Heading } from "atoms/typography/heading";
import PaymentMethod from "models/payment-method";
import { PaymentType as PaymentTypeInterface } from "models/interfaces/purchase";
import { Button } from "atoms/buttons/button";
import ManagePaymentModal from "organisms/manage-payment-modal/manage-payment-modal";

interface PaymentTypeProps {
    isRecurringPayment: boolean;
    memberOrderId: string;
    paymentMethod: PaymentMethod;
}

const PaymentType = ({
    isRecurringPayment,
    memberOrderId,
    paymentMethod: paymentMethodProp,
}: PaymentTypeProps) => {
    const [updatedPaymentMethod, setUpdatedPaymentMethod] =
        useState<PaymentMethod>();

    const paymentMethod = useMemo<PaymentMethod>(() => {
        return updatedPaymentMethod ?? paymentMethodProp;
    }, [updatedPaymentMethod, paymentMethodProp]);

    const showAddress = paymentMethod.address != null;
    const showAddress2 = paymentMethod.address2 != null;

    const [isManagePaymentModalOpen, setIsManagePaymentModalOpen] =
        useState<boolean>(false);

    const handleManagePaymentModalClose = useCallback(
        (paymentMethod?: PaymentMethod) => {
            if (paymentMethod != null) {
                setUpdatedPaymentMethod(paymentMethod);
            }

            setIsManagePaymentModalOpen(false);
        },
        []
    );

    return (
        <div className="w-full space-y-6 text-left">
            <Heading size="h3" className="text-xl">
                Payment Type
            </Heading>
            {paymentMethod != null && (
                <div>
                    <p>
                        {paymentMethod.paymentType ===
                        PaymentTypeInterface.CreditCard
                            ? paymentMethod.cardType
                            : paymentMethod.achBankName}
                        &nbsp;*
                        {paymentMethod.lastFour}
                    </p>
                    <p>{paymentMethod.name}</p>
                    {showAddress && (
                        <>
                            <p>{paymentMethod.address}</p>
                            {showAddress2 && <p>{paymentMethod.address2}</p>}
                            <p>
                                {paymentMethod.city}
                                ,&nbsp;
                                {paymentMethod.state}
                                &nbsp;{paymentMethod.zip}
                            </p>
                        </>
                    )}
                </div>
            )}

            {isRecurringPayment && (
                <>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => setIsManagePaymentModalOpen(true)}>
                        Manage Payment
                    </Button>

                    <ManagePaymentModal
                        isOpen={isManagePaymentModalOpen}
                        onClose={handleManagePaymentModalClose}
                        memberOrderId={memberOrderId}
                        paymentMethod={paymentMethod}
                    />
                </>
            )}
        </div>
    );
};

export default PaymentType;
