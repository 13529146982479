import {
    ChevronDownIcon,
    CreditCardIcon,
    LockClosedIcon,
    OfficeBuildingIcon,
    SearchIcon,
} from "@heroicons/react/outline";
import ArxLogo from "assets/images/arx-logo@2x.png";
import HcnLogoGrey from "assets/images/hcn-logo-grey-2@2x.png";
import HcnLogo from "assets/images/hcn-logo@2x.png";
import McLogo from "assets/images/mc-logo@2x.png";
import { Toggle } from "atoms/toggle/toggle";
import { Anchor } from "atoms/typography/anchor";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import { DateTime } from "luxon";
import { Loader } from "molecules/loader/loader";
import CreateAccount from "organisms/create-account/create-account";
import SignIn from "organisms/sign-in/sign-in";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "utilities/contexts/auth-state-context";
import "./sign-in-page.scss";

const BASE_CLASS_NAME = "c-sign-in";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const SignInPage: React.FC = () => {
    const { isLoading: isAuthLoading, settings } = useAuth();
    const [isLoading, setIsLoading] = useState(isAuthLoading);
    const history = useHistory();

    useEffect(() => {
        setIsLoading(isAuthLoading);
    }, [isAuthLoading]);

    const page =
        window.location.pathname === "/create-account"
            ? "create-account"
            : "sign-in";
    const height = page === "create-account" ? "tall" : "";

    return (
        <div className={`${BASE_CLASS_NAME}`}>
            <div className={`${BASE_CLASS_NAME}__hero ${height}`}>
                <Loader isVisible={isLoading} />
                <div className={`${BASE_CLASS_NAME}__hero__background`}></div>
                <div className={`${BASE_CLASS_NAME}__hero__wrapper`}>
                    <div className={`${BASE_CLASS_NAME}__hero__intro desktop`}>
                        <div className={`${BASE_CLASS_NAME}__hero__logo`}>
                            {settings?.siteLogo && (
                                <img
                                    alt={`${settings?.siteName} Logo`}
                                    src={settings?.siteLogo}
                                />
                            )}
                        </div>
                        <div className={`${BASE_CLASS_NAME}__hero__title`}>
                            <Label size="large">Welcome</Label>
                            <Heading size="h1" type="white">
                                Your Health, Your Choice, Your Price
                            </Heading>
                            <Paragraph size="large" type="white">
                                Family-Centered, Consumer-Inspired Healthcare
                                Marketplace
                            </Paragraph>
                            <div
                                className={`${BASE_CLASS_NAME}__hero__title__more`}>
                                <ChevronDownIcon />
                                <Label size="large">Learn More</Label>
                            </div>
                        </div>
                    </div>
                    <div className={`${BASE_CLASS_NAME}__form`}>
                        <Toggle
                            options={[
                                {
                                    label: "Sign In",
                                    isSelected: page === "sign-in",
                                    url: "/sign-in",
                                },
                                {
                                    label: "Create Account",
                                    isSelected: page === "create-account",
                                    url: "/create-account",
                                },
                            ]}
                            onClick={(option) => {
                                history.replace({
                                    pathname: option.url,
                                    search: window.location.search,
                                });
                            }}
                        />
                        {page === "sign-in" && <SignIn />}
                        {page === "create-account" && <CreateAccount />}
                    </div>
                    <div className={`${BASE_CLASS_NAME}__hero__intro mobile`}>
                        <div className={`${BASE_CLASS_NAME}__hero__title`}>
                            <Label size="large">Welcome</Label>
                            <Heading size="h1" type="white">
                                Your Health, Your Choice, Your Price
                            </Heading>
                            <Paragraph size="large" type="white">
                                Family-Centered, Consumer-Inspired Healthcare
                                Marketplace
                            </Paragraph>
                            <div
                                className={`${BASE_CLASS_NAME}__hero__title__more`}>
                                <ChevronDownIcon />
                                <Label size="large">Learn More</Label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${BASE_CLASS_NAME}__how`}>
                <div className={`${BASE_CLASS_NAME}__wrapper`}>
                    <Heading size="h2">How it Works</Heading>
                    <div className={`${BASE_CLASS_NAME}__how__wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__how__item`}>
                            <SearchIcon />
                            <Label size="large">Find A Procedure</Label>
                            <Paragraph>
                                Search for a non-urgent procedure for you or a
                                family member based on a Physician Order.
                            </Paragraph>
                        </div>
                        <div className={`${BASE_CLASS_NAME}__how__item`}>
                            <OfficeBuildingIcon />
                            <Label size="large">Select Your Provider</Label>
                            <Paragraph>
                                Select the location where you'd like the
                                procedure to be performed.
                            </Paragraph>
                        </div>
                        <div className={`${BASE_CLASS_NAME}__how__item`}>
                            <CreditCardIcon />
                            <Label size="large">
                                Get Your Price &amp; Checkout
                            </Label>
                            <Paragraph>
                                Get your personalized out-of-pocket estimate
                                based on your insurance coverage and financial
                                aid eligibility, and pay with flexible payment
                                options.
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${BASE_CLASS_NAME}__who`}>
                <div className={`${BASE_CLASS_NAME}__wrapper`}>
                    <div className={`${BASE_CLASS_NAME}__who__wrapper`}>
                        <Heading size="h3">Who is HCN</Heading>
                        <div className={`${BASE_CLASS_NAME}__who__item`}>
                            <img
                                alt="Healthcare Commerce Network Logo"
                                src={HcnLogo}
                            />
                            <div className="md:hidden">
                                <Label>HCN</Label>
                                <Paragraph size="small">
                                    Healthcare Commerce Network (HCN) is an
                                    ARxChange and Mastercard eCommerce platform
                                    that individualizes, simplifies, and
                                    transforms the consumer shopping experience.
                                </Paragraph>
                            </div>
                        </div>
                        <div className={`${BASE_CLASS_NAME}__who__item`}>
                            <img
                                className={`${BASE_CLASS_NAME}__who__item__image arx`}
                                alt="ARXChange Logo"
                                src={ArxLogo}
                            />
                            <div className="md:hidden">
                                <Label>ARXChange</Label>
                                <Paragraph size="small">
                                    ARxChange provides the user interface,
                                    procedural-bundling logic, and financial
                                    precision that personalizes out-of-pocket
                                    economics for HCN consumers.
                                </Paragraph>
                            </div>
                        </div>
                        <div className={`${BASE_CLASS_NAME}__who__item`}>
                            <img alt="Mastercard Logo" src={McLogo} />
                            <div className="md:hidden">
                                <Label>Mastercard</Label>
                                <Paragraph size="small">
                                    Mastercard delivers its global pioneering
                                    and payment innovation to HCN
                                    consumers—simpler, smarter, and safer.
                                </Paragraph>
                            </div>
                        </div>
                        <div
                            className={`${BASE_CLASS_NAME}__who__item hidden md:block`}>
                            <Label>HCN</Label>
                            <Paragraph size="small">
                                Healthcare Commerce Network (HCN) is an
                                ARxChange and Mastercard eCommerce platform that
                                individualizes, simplifies, and transforms the
                                consumer shopping experience.
                            </Paragraph>
                        </div>
                        <div
                            className={`${BASE_CLASS_NAME}__who__item hidden md:block`}>
                            <Label>ARXChange</Label>
                            <Paragraph size="small">
                                ARxChange provides the user interface,
                                procedural-bundling logic, and financial
                                precision that personalizes out-of-pocket costs
                                for HCN consumers.
                            </Paragraph>
                        </div>
                        <div
                            className={`${BASE_CLASS_NAME}__who__item hidden md:block`}>
                            <Label>Mastercard</Label>
                            <Paragraph size="small">
                                Mastercard delivers its global pioneering and
                                payment innovation to HCN consumers—simpler,
                                smarter, and safer.
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${BASE_CLASS_NAME}__secure`}>
                <div className={`${BASE_CLASS_NAME}__wrapper`}>
                    <div className={`${BASE_CLASS_NAME}__secure__wrapper`}>
                        <LockClosedIcon />
                        <div className={`${BASE_CLASS_NAME}__secure__content`}>
                            <Label>Shop Confidently And Safely</Label>
                            <Paragraph size="small">
                                Your information is protected and secure.
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${BASE_CLASS_NAME}__footer`}>
                <div className={`${BASE_CLASS_NAME}__wrapper`}>
                    <div className={`${BASE_CLASS_NAME}__footer__wrapper`}>
                        <div className={`${BASE_CLASS_NAME}__footer__logo`}>
                            <img
                                alt="Healthcare Commerce Network Logo"
                                src={HcnLogoGrey}
                            />
                        </div>
                        <div className={`${BASE_CLASS_NAME}__footer__links`}>
                            <Anchor to="/terms-of-service" size="xsmall">
                                Terms of Service
                            </Anchor>{" "}
                            |{" "}
                            <Anchor to="/privacy-policy" size="xsmall">
                                Privacy Policy
                            </Anchor>
                            |{" "}
                            <Anchor to="/contact-us" size="xsmall">
                                Contact Us
                            </Anchor>
                        </div>
                        <Paragraph size="xsmall">
                            &copy; {DateTime.now().toFormat("yyyy")}
                        </Paragraph>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignInPage;
