import { InsuranceLevel } from "models/enumerations/insurance-level";
import { InsuranceRelationship } from "models/enumerations/insurance-relationship";

export default interface MemberInsurance {
    insuranceLevel?: InsuranceLevel;
    firstName: string;
    lastName: string;
    relationship?: InsuranceRelationship;
    insuranceMemberId: string;
    groupNum: string;
    insurancePlanKey: string;
    insurancePlanName?: string;
}

export const emptyMemberInsurance = (): MemberInsurance => ({
    insuranceLevel: undefined,
    firstName: "",
    lastName: "",
    relationship: undefined,
    insuranceMemberId: "",
    groupNum: "",
    insurancePlanKey: "",
});
