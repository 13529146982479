import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import useOrder from "utilities/hooks/use-order";
import BasicInformation from "./basic-information";
import PaymentDetails from "./payment-details";
import PaymentTimeline from "./payment-timeline";
import Procedures from "./procedures";

type PurchaseDetailsParams = {
    memberOrderId: string;
};

const PurchaseDetailsPage = () => {
    const { memberOrderId } = useParams<PurchaseDetailsParams>();
    const {
        order,
        orderItem,
        loading: isLoadingOrder,
        isOneTimePayment,
        isRecurringPayment,
    } = useOrder({
        memberOrderId: memberOrderId!,
    });

    const {
        monthlyAmount = 0,
        numberOfPayments = 0,
        numberOfRemainingPayments = 0,
        payments = [],
        startDate = "",
    } = order?.recurringPayment ?? {};

    const history = useHistory();

    const handleDashboardBtnClick = () => {
        history.push("/dashboard");
    };
    const handleAllPurchasesBtnClick = () => {
        history.push("/all-purchases");
    };

    return (
        <div>
            <DashboardLayout
                showPageLoader={isLoadingOrder}
                header={
                    <div className="space-y-7 pt-7">
                        <div className="space-x-2">
                            <Button
                                onClick={handleAllPurchasesBtnClick}
                                size="small"
                                type="light">
                                &lt; Back to All Purchases
                            </Button>
                            <Button
                                onClick={handleDashboardBtnClick}
                                size="small"
                                type="light">
                                &lt; Back to Dashboard
                            </Button>
                        </div>
                        <Heading size="h1" type="white">
                            Purchase Details
                        </Heading>
                    </div>
                }>
                {order != null && (
                    <div className="w-full space-y-12 pt-8">
                        {orderItem && <BasicInformation {...orderItem} />}
                        <Procedures procedures={order.procedures} />
                        <PaymentDetails
                            {...order}
                            isOneTimePayment={isOneTimePayment}
                            isRecurringPayment={isRecurringPayment}
                            paymentMethod={order.paymentMethod}
                            memberOrderId={order.id}
                            termInMonths={
                                isRecurringPayment
                                    ? order.recurringPayment!.numberOfPayments
                                    : null
                            }
                        />
                        {isRecurringPayment && (
                            <PaymentTimeline
                                payments={payments}
                                total={orderItem?.total ?? order.total}
                                monthlyAmount={monthlyAmount}
                                numberOfPayments={numberOfPayments}
                                numberOfRemainingPayments={
                                    numberOfRemainingPayments
                                }
                                startDate={startDate}
                            />
                        )}
                    </div>
                )}
            </DashboardLayout>
        </div>
    );
};

export default PurchaseDetailsPage;
