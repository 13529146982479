import { TagIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Paragraph } from "atoms/typography/paragraph";
import { DateTime } from "luxon";
import { CartStatus } from "models/enumerations/cart-status";
import Member from "models/interfaces/member";
import { Modal } from "molecules/modal/modal";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CartService from "services/cart-service";
import useCart from "utilities/hooks/use-cart";
import "./procedure-banner.scss";
import CognitoUtils from "utilities/cognito-utils";

const BASE_CLASS = "c-procedure-banner";

export enum ProcedureBannerType {
    Decline,
    Expired,
    ManualWorkQueue,
    DashboardExpired,
    ReadyForReview,
}

export interface ProcedureBannerProps {
    completedOn?: string;
    expiresOn?: string;
    cartId: string;
    member: Member;
    displayType: ProcedureBannerType;
}

const ProcedureBanner = ({
    completedOn,
    displayType,
    expiresOn,
    cartId,
    member,
}: ProcedureBannerProps) => {
    const [showDeclineModal, setDeclineShowModal] = useState(false);
    const [isDeclining, setIsDeclining] = useState(false);
    const history = useHistory();
    const { update } = useCart();

    const isExpired =
        displayType === ProcedureBannerType.Expired ||
        displayType === ProcedureBannerType.DashboardExpired;

    const declineCart = async () => {
        setIsDeclining(true);
        try {
            const idToken = await CognitoUtils.getIdToken();
            await CartService.declineEstimate(member.id, cartId, idToken);
            history.push("/dashboard");
        } catch {
            // TODO: Displaying a message here would be nice
        } finally {
            setIsDeclining(false);
        }
    };

    const handleDeclineModalClose = () => {
        setDeclineShowModal(false);
    };
    const handleDeclineEstimateBtnClick = () => {
        setDeclineShowModal(true);
    };
    const handleReviewEstimateBtnClick = () => {
        const m: Member = {
            cartStatus: member.cartStatus,
            id: member.id,
            firstName: member.firstName,
            lastName: member.lastName,
            email: member.email,
            phone: member.phone,
            relationship: member.relationship,
            memberInsurances: member.memberInsurances,
            userId: member.userId,
        };

        // Don't provide a cartId when loading if cart has expired, so users can start a new cart
        update({
            member: m,
            cartId:
                member.cartStatus !== CartStatus.Expired ? member.cartId : "",
        });

        history.push(`/personalize/price/${cartId}`);
    };
    const handleConfirmDeclineEstimateBtnClick = async () => {
        await declineCart();
    };
    const handleDeclineCancelBtnClick = () => {
        setDeclineShowModal(false);
    };

    const completedOnDate = completedOn
        ? DateTime.fromISO(completedOn)
        : DateTime.now();
    const expiresOnDate = expiresOn
        ? DateTime.fromISO(expiresOn)
        : DateTime.now().plus({ days: 14 });
    const expiresDays = Math.ceil(
        expiresOnDate.diff(completedOnDate, "days").toObject().days || 14
    );

    return (
        <div className={BASE_CLASS}>
            <div
                className={`${BASE_CLASS}__wrapper ${
                    isExpired ? "--expired" : ""
                }`}>
                <div className={`${BASE_CLASS}__content`}>
                    <TagIcon className={`${BASE_CLASS}__tag-icon`} />
                    <p>
                        {procedureBannerText({
                            displayType,
                            expiresOnDate,
                            expiresDays,
                            memberName: member.firstName,
                        })}
                    </p>
                </div>
                {displayType === ProcedureBannerType.Decline && (
                    <Button onClick={handleDeclineEstimateBtnClick}>
                        Decline Estimate
                    </Button>
                )}
                {displayType === ProcedureBannerType.ReadyForReview && (
                    <Button onClick={handleReviewEstimateBtnClick}>
                        Review Price
                    </Button>
                )}
            </div>
            <Modal
                isOpen={showDeclineModal}
                isLoading={false}
                onClose={handleDeclineModalClose}
                wrapperClassName="w-[36rem]"
                title={"Are you sure?"}>
                <div className="w-full space-y-8">
                    <Paragraph>
                        If you decline, this estimate will no longer be
                        available to you, and you'll need to go through the
                        steps again to complete your purchase.
                    </Paragraph>
                    <div className="w-full space-y-4">
                        <Button
                            onClick={handleDeclineCancelBtnClick}
                            disabled={isDeclining}
                            cssClassName="w-full">
                            No, take me back to my estimate &gt;
                        </Button>
                        <Button
                            cssClassName="w-full"
                            disabled={isDeclining}
                            onClick={handleConfirmDeclineEstimateBtnClick}
                            type="white">
                            Yes, I'd like to decline this estimate & start again
                            &gt;
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

// Private Functions

const procedureBannerText = ({
    displayType,
    expiresDays,
    expiresOnDate,
    memberName,
}: {
    displayType: ProcedureBannerType;
    expiresOnDate: DateTime;
    expiresDays: number;
    memberName: string;
}) => {
    switch (displayType) {
        case ProcedureBannerType.Expired:
            return `This estimate expired on ${expiresOnDate.toFormat(
                "MM/dd/yyyy"
            )}`;
        case ProcedureBannerType.DashboardExpired:
            return `Your Price for ${memberName}'s Procedure Expired on ${expiresOnDate.toFormat(
                "MM/dd/yyyy"
            )}`;
        case ProcedureBannerType.ReadyForReview:
            return `Your Price for ${memberName}'s Procedure is Ready for Review`;
        case ProcedureBannerType.ManualWorkQueue:
            return `Your estimate for ${memberName} is being reviewed. You will be notified when it's complete.`;
        default:
            return `This estimate is valid for ${expiresDays} days and will expire on ${expiresOnDate.toFormat(
                "MM/dd/yyyy"
            )}.`;
    }
};

export default ProcedureBanner;
