import { ChevronRightIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import Category from "models/interfaces/category";
import { Loader } from "molecules/loader/loader";
import React, { useEffect, useState } from "react";
import CategoriesService from "services/categories-service";
import StringUtils from "utilities/string-utils";
import "./category-search.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-category-search";

interface CategorySearchProps {
    handleCategorySearch: (categoryKey: string) => void;
    selectedCategory: Category | undefined;
    setSelectedCategory: (category: Category | undefined) => void;
    type: "primary" | "white";
}

const CategorySearch: React.FC<CategorySearchProps> = (
    props: CategorySearchProps
) => {
    const searchParams = StringUtils.parseQueryString(window.location.search);
    const [categories, setCategories] = useState<Category[]>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getCategories = async () => {
            const idToken = await CognitoUtils.getIdToken();
            const categoriesResponse = await CategoriesService.get(idToken);
            if (categoriesResponse?.data) {
                setCategories(categoriesResponse.data);
            }

            if (!StringUtils.isEmpty(searchParams?.categoryKey)) {
                const response = await CategoriesService.getParent(
                    searchParams?.categoryKey,
                    idToken
                );
                if (response?.data) {
                    props.setSelectedCategory(response.data);
                }
            }

            setIsLoading(false);
        };

        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCategoryClick = (category?: Category) => {
        if (!category) {
            props.setSelectedCategory(undefined);
            return;
        }
        if (category?.subCategories && category.subCategories.length > 0) {
            props.setSelectedCategory(category);
            return;
        }
        props.handleCategorySearch(category?.categoryKey);
    };

    const displayCategories = (
        props.selectedCategory
            ? props.selectedCategory.subCategories
            : categories
    )?.sort(
        (a, b) =>
            (a.sortOrder ?? 0) - (b.sortOrder ?? 0) ||
            a.categoryName.localeCompare(b.categoryName)
    );

    return (
        <div className={`${COMPONENT_CLASS} ${props.type}`}>
            <Loader isVisible={isLoading} />
            <div className={`${COMPONENT_CLASS}__wrapper`}>
                {props.selectedCategory && (
                    <Heading size="h3">
                        {props.selectedCategory.categoryName}
                    </Heading>
                )}
                {!props.selectedCategory && (
                    <Heading size="h3">Not sure what to search for?</Heading>
                )}
                <Paragraph size="small">
                    Here are some common{" "}
                    {props.selectedCategory ? "procedures" : "starting points"}.
                </Paragraph>
                {props.selectedCategory && (
                    <Button
                        onClick={() => handleCategoryClick(undefined)}
                        size="small"
                        type={props.type}>
                        &lt; Back
                    </Button>
                )}
                {displayCategories && (
                    <div className={`${COMPONENT_CLASS}__list`}>
                        {displayCategories?.map((category, index) => (
                            <div
                                className={`${COMPONENT_CLASS}__item`}
                                onClick={() => handleCategoryClick(category)}
                                key={index}>
                                {!StringUtils.isEmpty(
                                    category?.categoryIcon
                                ) && (
                                    <img
                                        alt={`${category.categoryName} icon`}
                                        src={category.categoryIcon}
                                    />
                                )}
                                <Paragraph
                                    size="small"
                                    type="white"
                                    weight="semibold">
                                    {category.categoryName}
                                </Paragraph>
                                {category.subCategories &&
                                    category.subCategories.length > 0 && (
                                        <ChevronRightIcon />
                                    )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CategorySearch;
