import { Heading } from "atoms/typography/heading";
import { Procedure as ProcedureInterface } from "models/interfaces/procedure";
import React from "react";

interface ProcedureProps {
    procedure: ProcedureInterface;
}

const Procedure = ({ procedure }: ProcedureProps) => {
    return (
        <div className="flex w-full flex-col items-center justify-between rounded-md border border-[1.5px] border-grey-200 p-4 md:flex-row">
            <span className="w-full text-left font-semibold">
                {procedure.name}
            </span>
            <span className="w-full text-left">{procedure.location}</span>
        </div>
    );
};

interface ProceduresProps {
    procedures: ProcedureInterface[];
}

const Procedures = ({ procedures }: ProceduresProps) => {
    return (
        <div className="w-full space-y-6">
            <Heading size="h3" className="text-xl">
                Procedures
            </Heading>
            <div className="space-y-2">
                {procedures.map((procedure, index) => {
                    return (
                        <Procedure
                            key={procedure.memberOrderBundleId + index}
                            procedure={procedure}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Procedures;
