import { ChevronDownIcon, ShoppingCartIcon } from "@heroicons/react/outline";
import { Tag } from "atoms/tag/tag";
import { Paragraph } from "atoms/typography/paragraph";
import Facility from "models/interfaces/facility";
import React from "react";
import StringUtils from "utilities/string-utils";
import "./location.scss";

const BASE_CLASS_NAME = "c-location";

interface LocationProps {
    children?: React.ReactChild;
    facility: Facility;
    isExpanded?: boolean;
    isSelected: boolean;
    isInCart: boolean;
    isInNetwork: boolean;
    isInsurance: boolean;
    onExpandClick?: () => void;
    onClick?: () => void;
    price?: number;
}

/**
 * Primary UI component for user interaction
 */
export const Location = ({
    facility,
    isExpanded = false,
    isSelected = false,
    isInCart = false,
    isInNetwork = true,
    isInsurance = true,
    price,
    ...props
}: LocationProps) => {
    let address = facility.address.line1;
    if (StringUtils.hasValue(facility.address.line2)) {
        address += `\n${facility.address.line2}`;
    }
    address += `\n${facility.address.city}, ${facility.address.state} ${facility.address.zipCode}`;
    const selectedClass = isSelected ? "active" : "";
    const inNetworkClass = isInNetwork || !isInsurance ? "" : "white";
    const cardClass = props.onExpandClick != null ? "card" : "";
    const expandedClass =
        props.onExpandClick != null && isExpanded ? "expanded" : "";

    return (
        <div
            className={`${BASE_CLASS_NAME} ${[
                selectedClass,
                inNetworkClass,
                cardClass,
                expandedClass,
            ].join(" ")}`}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}>
            <div className={`${BASE_CLASS_NAME}__content__wrapper`}>
                <div className={`${BASE_CLASS_NAME}__content`}>
                    <Paragraph size="small" weight="semibold">
                        {facility.facilityName}
                    </Paragraph>
                    <Paragraph size="xsmall">{address}</Paragraph>
                    <div className={`${BASE_CLASS_NAME}__tags`}>
                        {isInsurance && (
                            <Tag>
                                {isInNetwork ? "In Network" : "Out Of Network"}
                            </Tag>
                        )}
                        {price != null && (
                            <Tag>
                                {isInCart && <ShoppingCartIcon />}
                                {StringUtils.formatCurrency(price)}
                            </Tag>
                        )}
                    </div>
                </div>
                {props.onExpandClick != null && (
                    <button
                        type="button"
                        className="c-button primary"
                        onClick={() => props.onExpandClick!()}>
                        <ChevronDownIcon />
                    </button>
                )}
            </div>
            {props.children && (
                <div className={`${BASE_CLASS_NAME}__children`}>
                    {props.children}
                </div>
            )}
        </div>
    );
};
