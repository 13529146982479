import {
    ChevronDownIcon,
    LogoutIcon,
    ShoppingCartIcon,
} from "@heroicons/react/outline";
import HcnLogoGrey from "assets/images/hcn-logo-grey@2x.png";
import HcnLogoWhite from "assets/images/hcn-logo-white@2x.png";
import { Button } from "atoms/buttons/button";
import { CartButton } from "atoms/buttons/cart-button";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import Member from "models/interfaces/member";
import MemberCartBundle from "models/interfaces/member-cart-bundle";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "utilities/contexts/auth-state-context";
import useCart from "utilities/hooks/use-cart";
import usePriceDetails from "utilities/hooks/use-price-details";
import StringUtils from "utilities/string-utils";
import "./sidebar.scss";

const COMPONENT_CLASS = "c-sidebar";

interface SidebarProps {
    logo?: string;
    member: Member;
    isExpanded: boolean;
    isLocked: boolean;
    onClick?: () => void;
    setIsExpanded: (expanded: boolean) => void;
}

/**
 * Primary UI component for user interaction
 */
export const Sidebar = ({
    logo,
    member,
    isExpanded = false,
    isLocked = false,
    ...props
}: SidebarProps) => {
    const history = useHistory();
    const { isLoading: isAuthLoading, signOut: signOutUser } = useAuth();
    const { cart, hasInsurance, subtotal } = useCart();

    const items = cart?.memberCartBundles || [];

    const priceDetails = usePriceDetails({
        cart,
        hasInsurance,
        grossCharges: subtotal,
    });

    const handleBackClick = (item: MemberCartBundle) => {
        if (isLocked) {
            return;
        }
        props.setIsExpanded(false);
        let currentDetailSteps = history?.location?.state?.detailSteps || 0;
        if (history.location.pathname === "/procedures") {
            currentDetailSteps = -1;
        }
        history.push(`/procedures/${item.bundle?.bundleKey}`, {
            detailSteps: currentDetailSteps + 1,
        });
    };

    const handleSignOut = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        signOutUser();
    };

    return (
        <div className={`${COMPONENT_CLASS} ${isExpanded ? "expanded" : ""}`}>
            <div className={`${COMPONENT_CLASS}__wrapper`}>
                <div className={`${COMPONENT_CLASS}__header`}>
                    <div className={`${COMPONENT_CLASS}__logo`}>
                        <img alt="Health System Logo" src={logo} />
                    </div>
                    <div className={`${COMPONENT_CLASS}__signout header`}>
                        <Link
                            className={`${COMPONENT_CLASS}__signout__link`}
                            to="/sign-out"
                            onClick={handleSignOut}>
                            <LogoutIcon />
                            <Label>Sign Out</Label>
                        </Link>
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__dashboard`}>
                    <div onClick={() => history.push("/dashboard")}>
                        <Button type="light">&lt; Back To Dashboard</Button>
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__intro`}>
                    <div className={`${COMPONENT_CLASS}__intro__content`}>
                        <ShoppingCartIcon />
                        <Label size="medium">
                            {`${member.firstName}'s Procedures`}
                        </Label>
                    </div>
                    <div className={`${COMPONENT_CLASS}__intro__buttons`}>
                        <CartButton
                            cartItemCount={items.length || 0}
                            isSelected={false}
                            size="small"
                            type="dark"
                        />
                        <div
                            className={`${COMPONENT_CLASS}__expand`}
                            onClick={() => props.setIsExpanded(!isExpanded)}>
                            <ChevronDownIcon />
                        </div>
                    </div>
                </div>
                <div className={`${COMPONENT_CLASS}__item__wrapper`}>
                    {items &&
                        !isAuthLoading &&
                        items.length > 0 &&
                        items.map((item, index) => (
                            <div
                                className={`${COMPONENT_CLASS}__item ${
                                    isLocked ? "locked" : ""
                                }`}
                                key={index}
                                onClick={() => handleBackClick(item)}>
                                <Paragraph size="small">
                                    {item.bundle?.bundleName || "N/A"}
                                </Paragraph>
                                <Paragraph cssClassName="facility" size="small">
                                    at{" "}
                                    {item.providerBundle?.provider?.facility
                                        ?.facilityName || "N/A"}
                                </Paragraph>
                                <div
                                    className={`${COMPONENT_CLASS}__item__footer`}>
                                    {!isLocked && (
                                        <CartButton
                                            size="small"
                                            type="white"
                                            isSelected={true}
                                        />
                                    )}
                                    <Paragraph
                                        cssClassName="price"
                                        size="small">
                                        {StringUtils.formatCurrency(
                                            item.cost || 0
                                        )}
                                    </Paragraph>
                                </div>
                            </div>
                        ))}
                    {(!items || items.length === 0) && (
                        <div className={`${COMPONENT_CLASS}__item none`}>
                            <Paragraph size="xsmall" weight="semibold">
                                Search to find procedures. Any you add to your
                                cart will appear here.
                            </Paragraph>
                        </div>
                    )}
                </div>
                {(!items || items.length === 0) && (
                    <div className={`${COMPONENT_CLASS}__powered desktop`}>
                        <img alt="HCN Logo" src={HcnLogoGrey} />
                    </div>
                )}
            </div>
            {items && items.length > 0 && (
                <div className={`${COMPONENT_CLASS}__subtotal`}>
                    {cart?.completedOn ? (
                        <Label type="light">Est. Total Fees</Label>
                    ) : (
                        <Label type="light">Est. Subtotal</Label>
                    )}
                    <Paragraph type="white">
                        {StringUtils.formatCurrency(
                            cart?.completedOn ? priceDetails.youPay : subtotal
                        )}
                    </Paragraph>
                    <div className={`${COMPONENT_CLASS}__powered  desktop`}>
                        <img alt="HCN Logo" src={HcnLogoGrey} />
                    </div>
                </div>
            )}
            {!isLocked && items.length > 0 && (
                <div className={`${COMPONENT_CLASS}__personalized-price`}>
                    <Button
                        onClick={() => history.push("/personalize")}
                        size="large"
                        type="white">
                        Get Your Personalized Price {isExpanded ? ">" : ""}
                    </Button>
                </div>
            )}
            <div className={`${COMPONENT_CLASS}__signout footer`}>
                <Link
                    className={`${COMPONENT_CLASS}__signout__link`}
                    to="/sign-out"
                    onClick={handleSignOut}>
                    <LogoutIcon />
                    <Label>Sign Out</Label>
                </Link>
            </div>
            <div className={`${COMPONENT_CLASS}__powered mobile`}>
                <img alt="HCN Logo" src={HcnLogoWhite} />
            </div>
        </div>
    );
};
