import { Heading } from "atoms/typography/heading";
import { FormatDateType } from "models/enumerations/format-date-type";
import { PaymentStatus } from "models/enumerations/payment-status";
import { Payment } from "models/interfaces/payment";
import React from "react";
import StringUtils from "utilities/string-utils";
import usePaymentTimeline from "./use-payment-timeline";

interface PaymentTimelineProps {
    payments: Payment[];
    total: number;
    monthlyAmount: number;
    numberOfPayments: number;
    numberOfRemainingPayments: number;
    startDate: string;
}

const PaymentTimeline = ({
    payments,
    total,
    monthlyAmount,
    numberOfPayments,
    numberOfRemainingPayments,
    startDate,
}: PaymentTimelineProps) => {
    const { paymentTimeline } = usePaymentTimeline({
        payments,
        total,
        monthlyAmount,
        numberOfPayments,
        numberOfRemainingPayments,
        startDate,
    });

    return (
        <div className="w-full space-y-6 text-left">
            <Heading size="h3" className="text-xl">
                Payment Timeline
            </Heading>
            <div className="space-y-2">
                <div className="flex w-full flex-col items-center justify-between rounded-md px-4 md:flex-row">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Date
                    </span>
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Amount
                    </span>
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Status
                    </span>
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Remaining
                    </span>
                </div>
                {paymentTimeline.map((payment, index) => {
                    const paymentStatus = getPaymentStatus(
                        index,
                        payment.status
                    );

                    return (
                        <div
                            key={index}
                            className="flex w-full flex-col items-center justify-between rounded-md border-[1.5px] border-grey-200 p-4 md:flex-row">
                            <span className="w-full text-left font-semibold">
                                {StringUtils.formatDate(
                                    payment.purchaseDate,
                                    FormatDateType.DateOnly
                                )}
                            </span>
                            <span className="w-full text-left">
                                {StringUtils.formatCurrency(payment.total)}
                            </span>
                            <span
                                className={`${
                                    payment.status != null
                                        ? "text-primary-800"
                                        : "text-grey-200"
                                } w-full text-left`}>
                                {getPaymentStatusText(paymentStatus)}
                            </span>
                            <span className="w-full text-left">
                                {StringUtils.formatCurrency(
                                    payment.amountRemaining
                                )}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const getPaymentStatus = (index: number, paymentStatus?: PaymentStatus) => {
    if (paymentStatus != null) {
        return paymentStatus;
    }

    if (index === 0) {
        return PaymentStatus.Pending;
    }

    return PaymentStatus.Upcoming;
};

const getPaymentStatusText = (paymentStatus: PaymentStatus) => {
    switch (paymentStatus) {
        case PaymentStatus.Upcoming:
            return "Upcoming";
        case PaymentStatus.Succeeded:
            return "Paid";
        case PaymentStatus.Failed:
            return "Failed";
        case PaymentStatus.Pending:
            return "Pending";
        default:
            throw new Error("Payment status not listed.");
    }
};

export default PaymentTimeline;
