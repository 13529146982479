import { UserRegister } from "interfaces/user-register";
import { UserSignIn } from "interfaces/user-sign-in";
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import AccountService from "services/account-service";
import SettingsService from "services/settings-service";
import { parseErrorResponse } from "utilities/error-utils";
import StringUtils from "utilities/string-utils";
import AuthState from "../../models/interfaces/global-state";
import { OnboardingStateAtom } from "layouts/onboarding-layout/onboarding-layout";
import { useSetAtom } from "jotai";
import { Auth } from "aws-amplify";
import { ICognitoConfig, CognitoConfig } from "services/cognitoConfig";
import CognitoUtils from "utilities/cognito-utils";

interface AuthStateContextProps {
    state: AuthState;
    setState: Dispatch<SetStateAction<AuthState>>;
}

export const AuthStateContext = createContext<
    AuthStateContextProps | undefined
>(undefined);

export const AuthStateProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const initialState: AuthState =
        localStorage.getItem("state") &&
        JSON.parse(localStorage.getItem("state")!)
            ? JSON.parse(localStorage.getItem("state")!)
            : {};

    const [state, setState] = useState({ ...initialState, isLoading: false });

    useEffect(() => {
        const getSettings = async () => {
            SettingsService.getSettings().then((result: any) => {
                if (result.data) {
                    setState((prevState) => ({
                        ...prevState,
                        ...{
                            settings: result.data,
                        },
                    }));
                }
            });
        };

        if (!state.settings || !state.settings?.clientKey) {
            getSettings();
        }
    }, [state.settings]);

    useEffect(() => {
        const { isLoading, ...saveState } = state;
        localStorage.setItem("state", JSON.stringify(saveState));
    }, [state]);

    const { sitePrimaryColors } = state.settings ?? {};
    const css = useMemo(() => {
        if (sitePrimaryColors) {
            const colors = JSON.parse(sitePrimaryColors);
            return `
                :root {
                    --veryLight: ${colors.veryLight};
                    --light: ${colors.light};
                    --primaryLight: ${colors.primaryLight};
                    --primary: ${colors.primary};
                    --primaryDark: ${colors.primaryDark};
                    --dark: ${colors.dark};
                    --darkest: ${colors.darkest};
                }`;
        }

        return undefined;
    }, [sitePrimaryColors]);

    return (
        <AuthStateContext.Provider value={{ state, setState }}>
            {css && <style>{css}</style>}
            {children}
        </AuthStateContext.Provider>
    );
};

interface UseAuthHookOptions {
    runVerify?: boolean;
}

export enum AuthErrorCodes {
    General,
    InvalidAuth,
    EmailVerificationRequired,
    UserNotConfirmedException,
    EmailUpdate,
}

export interface AuthError {
    code: AuthErrorCodes;
    message?: string;
}

export const useAuth = ({ runVerify = false }: UseAuthHookOptions = {}) => {
    const context = useContext(AuthStateContext);
    if (!context) {
        throw new Error(
            "useGlobalState must be used within a AuthStateContext"
        );
    }
    var accessToken = "";
    var idToken = "";

    const updateOnboardingState = useSetAtom(OnboardingStateAtom);

    const [error, setError] = useState<AuthError>();
    const [verified, setVerified] = useState(false);
    const { state, setState } = context;
    const { user, isLoading, authenticated, settings } = state;

    const history = useHistory();

    const setIsLoading = useCallback(
        (isLoading: boolean) => {
            setState((prevState) => ({
                ...prevState,
                isLoading,
            }));
        },
        [setState]
    );

    const registerUser = useCallback(
        async (registerUser: UserRegister) => {
            setIsLoading(true);
            try {
                await AccountService.registerUser(registerUser);
                setIsLoading(false);
                history.push("/create-account/confirmation");
            } catch (error) {
                setIsLoading(false);
                setError({
                    code: AuthErrorCodes.General,
                    message: "Error Registering User",
                });
            }
        },
        [history, setIsLoading]
    );

    const ssoSignIn = useCallback(
        async (clientID, identityProvider) => {
            setIsLoading(true);
            setState({
                clientID: clientID,
                isAuthorizing: true,
                isLoading: true,
                authenticated: false,
                user: null,
            });

            const result = CognitoUtils.initializeCognito(
                clientID,
                identityProvider
            );

            const cognitoConfig = CognitoConfig[0] as ICognitoConfig;

            if (!result) {
            } else {
                const useHostedUI = false;

                if (useHostedUI) {
                    //NOTE  This call goes to Hosted UI, see above to build a URL to redirect to registered provider
                    await Auth.federatedSignIn()
                        .then((result) => {
                            setState({
                                clientID: clientID,
                                isAuthorizing: false,
                                isLoading: false,
                                authenticated: true,
                                user: null,
                            });
                        })
                        .catch((error) => {
                            setError({
                                code: AuthErrorCodes.General,
                                message:
                                    error.data?.exceptionMessage ||
                                    error.data?.message ||
                                    error.statusText,
                            });
                            setIsLoading(false);
                        });
                } else {
                    var authorizeEndPoint: string = "".concat(
                        CognitoConfig[0].config.oauth.domain,
                        "/oauth2/authorize"
                    );

                    var redirectURI: string =
                        cognitoConfig.config.oauth.redirectSignIn;
                    var responseType: string =
                        cognitoConfig.config.oauth.responseType;
                    var scope = "";
                    if (cognitoConfig.config.oauth.scope) {
                        cognitoConfig.config.oauth.scope?.forEach(
                            (scopeItem: string) => {
                                if (scope === "") {
                                    scope = scopeItem;
                                } else {
                                    scope = scope + "+" + scopeItem;
                                }
                            }
                        );
                    }
                    var clientId: string =
                        cognitoConfig.config.userPoolWebClientId;
                    window.location.href = `https://${authorizeEndPoint}?identity_provider=${identityProvider}&redirect_uri=${redirectURI}&response_type=${responseType}&client_id=${clientId}&scope=${scope}`;
                    return Promise.resolve(true);
                }
            }
        },
        [setIsLoading, setState]
    );

    const signIn = useCallback(
        async (signInUser: UserSignIn) => {
            setIsLoading(true);
            try {
                // Ensure email and password are defined
                if (!signInUser.email || !signInUser.password) {
                    throw new Error("Email and password are required.");
                }

                const user = await AccountService.signInUser(
                    signInUser.email,
                    signInUser.password
                );

                setState({ ...state, user, authenticated: true });
                setIsLoading(false);
                history.push("/dashboard");
            } catch (error) {
                setIsLoading(false);
                console.error("Sign-in error:", error);
                setError({
                    code: (error as any).code || AuthErrorCodes.General,
                    message: (error as any).message || "Sign-in failed: ",
                });
            }
        },
        [history, setIsLoading, state]
    );
    const signOut = useCallback(async () => {
        setIsLoading(true);
        try {
            await AccountService.signOutUser();
            setState({ ...state, authenticated: false, user: null });
            setIsLoading(false);
            history.push("/sign-in");
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }, [history, setIsLoading]);

    const updateEmailAsync = useCallback(
        async (email: string) => {
            setIsLoading(true);

            const idToken = await CognitoUtils.getIdToken();

            const result = await AccountService.updateEmail(email, idToken);

            setIsLoading(false);

            setState((prev) => ({
                ...prev,
                user: result,
            }));
        },
        [setIsLoading, setState]
    );

    const verifyAsync = useCallback(async () => {
        if (!state.isLoading) {
            try {
                if (Auth.Credentials.Auth !== undefined) {
                    var idToken = "";
                    Auth.currentSession()
                        .then(async (result: any) => {
                            var userLoggedIn = Auth.currentUserInfo() !== null;
                            var sessionIsValid = (
                                await Auth.currentSession()
                            ).isValid();
                            idToken = await CognitoUtils.getIdToken();
                            var verified = await AccountService.verify(idToken);
                            var authed =
                                sessionIsValid &&
                                userLoggedIn &&
                                verified; /*TODO - enable the verify() call when api is working */
                            setState((prev) => {
                                return {
                                    ...prev,
                                    authenticated: authed,
                                };
                            });
                        })
                        .catch((error: any) => {
                            setState((prev) => {
                                return {
                                    ...prev,
                                    authenticated: false,
                                };
                            });
                        });

                    if (!authenticated) {
                        console.log("TODO - Enable backend verification ...");
                        /*
                        TODO -- THIS will cause SSO to break until backend is connected
                        setIsLoading(false);
                        history.push(
                            `/sign-in?redirectUrl=${window.location.href.replace(
                                window.location.origin,
                                ""
                            )}`
                        );
                        return;*/
                    } else {
                        idToken = await CognitoUtils.getIdToken();
                        const { data, status } = await AccountService.getUser(
                            idToken
                        );

                        setState((prev) => {
                            return {
                                ...prev,
                                user: status === 200 ? data : null,
                            };
                        });

                        if (
                            (status !== 200 || !data?.isOnboarded) &&
                            window.location.pathname !== "/onboarding"
                        ) {
                            setIsLoading(false);
                            history.push("/onboarding");
                            return;
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }
    }, [authenticated, history, setIsLoading, setState, state.isLoading]);

    useEffect(() => {
        CognitoUtils.initializeCognito("", "");
        if (runVerify && !verified) {
            verifyAsync();
        }
    }, [runVerify, verified, verifyAsync, state.clientID]);
    return {
        error,
        user,
        isAuthenticated: state.authenticated,
        isLoading,
        isAuthorizing: state.isAuthorizing,
        isOnboarded: user?.isOnboarded ?? false,
        settings,
        accessToken,
        idToken,
        registerUser,
        signIn,
        signOut,
        ssoSignIn,
        updateEmail: useCallback(
            (email: string) => {
                updateEmailAsync(email);
            },
            [updateEmailAsync]
        ),
        verify: useCallback(() => {
            verifyAsync();
        }, [verifyAsync]),
    };
};

//async function updateEmail(email: string, idToken: string) {
//    try {
//        const result = await AccountService.updateEmail({ email }, idToken);

//        if (result.status >= 400) {
//            throw result;
//        }

//        return { result };
//    } catch (err) {
//        const { errorMessage } = parseErrorResponse(err);
//        return { errorMessage };
//    }
//}
