import { CheckIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { CartButton } from "atoms/buttons/cart-button";
import { IconButton } from "atoms/buttons/icon-button";
import { Checkbox } from "atoms/forms/checkbox";
import { Dropdown } from "atoms/forms/dropdown";
import { Error } from "atoms/forms/error";
import { Search } from "atoms/forms/search";
import { TextArea } from "atoms/forms/text-area";
import { TextInput } from "atoms/forms/text-input";
import { PasswordStrength } from "atoms/password-strength/password-strength";
import { Spinner } from "atoms/spinner/spinner";
import { Toggle } from "atoms/toggle/toggle";
import { Anchor } from "atoms/typography/anchor";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import React from "react";
import { useAuth } from "utilities/contexts/auth-state-context";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const KopPage: React.FC = () => {
    useAuth({ runVerify: true });

    return (
        <div style={{ margin: "0 2rem" }}>
            <Heading size="h1">KOP</Heading>
            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Buttons</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="small">Button Small Primary</Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium">Button Medium Primary</Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="large">Button Large Primary</Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="dark">
                        Button Medium Dark
                    </Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="clear">
                        Button Medium Clear
                    </Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="dark-gray">
                        Button Medium Dark Gray
                    </Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="gray">
                        Button Medium Gray
                    </Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="light">
                        Button Medium Light
                    </Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="white">
                        Button Medium White
                    </Button>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Button size="medium" type="plain">
                        Button Medium Plain
                    </Button>
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Cart Button</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Cart Button Small Primary</Heading>
                    <CartButton size="small" isSelected={false} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Cart Button Medium Primary</Heading>
                    <CartButton size="medium" isSelected={false} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Cart Button Medium Dark</Heading>
                    <CartButton size="medium" type="dark" isSelected={false} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Cart Button Medium Clear</Heading>
                    <CartButton size="medium" type="clear" isSelected={false} />
                </div>
                <div
                    style={{
                        margin: "1rem 0",
                        padding: "1rem",
                        backgroundColor: "#D6CBF3",
                    }}>
                    <Heading size="h4">Cart Button Medium White</Heading>
                    <CartButton size="medium" type="white" isSelected={false} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">
                        Cart Button Medium Primary Selected
                    </Heading>
                    <CartButton size="medium" isSelected={true} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">
                        Cart Button Medium Primary With Items
                    </Heading>
                    <CartButton
                        size="medium"
                        isSelected={false}
                        cartItemCount={2}
                    />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Icon Button</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <IconButton size="medium" icon={<CheckIcon />}>
                        Icon Button Medium Primary
                    </IconButton>
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Anchors</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Anchor size="xsmall" to="/kop">
                        Anchor XSmall Primary
                    </Anchor>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Anchor size="small" to="/kop">
                        Anchor Small Primary
                    </Anchor>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Anchor size="medium" to="/kop">
                        Anchor Medium Primary
                    </Anchor>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Anchor size="large" to="/kop">
                        Anchor Large Primary
                    </Anchor>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Anchor size="medium" type="color" to="/kop">
                        Anchor Medium Purple
                    </Anchor>
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Heading</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h1">Heading H1 Primary</Heading>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h2">Heading H2 Primary</Heading>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h3">Heading H3 Primary</Heading>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Heading H4 Primary</Heading>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h1" type="light">
                        Heading H1 Light
                    </Heading>
                </div>
                <div
                    style={{
                        margin: "1rem 0",
                        padding: "1rem",
                        backgroundColor: "#D6CBF3",
                    }}>
                    <Heading size="h1" type="white">
                        Heading H1 White
                    </Heading>
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Label</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Label size="medium">Label Medium</Label>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Label size="large">Label Large</Label>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Label size="medium" isRequired={true}>
                        Label Medium Required
                    </Label>
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Paragraph</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Paragraph size="xsmall">
                        Paragraph XSmall Primary
                    </Paragraph>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Paragraph size="small">Paragraph Small Primary</Paragraph>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Paragraph size="medium">
                        Paragraph Medium Primary
                    </Paragraph>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Paragraph size="large">Paragraph Large Primary</Paragraph>
                </div>
                <div
                    style={{
                        margin: "1rem 0",
                        padding: "1rem",
                        backgroundColor: "#D6CBF3",
                    }}>
                    <Paragraph size="medium" type="white">
                        Paragraph Medium White
                    </Paragraph>
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Paragraph size="medium" type="color">
                        Paragraph Medium Purple
                    </Paragraph>
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Password Strength</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">
                        Password Strength Invalid Password (123abc)
                    </Heading>
                    <PasswordStrength password="123abc" />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">
                        Password Strength Valid Password (123abc@R)
                    </Heading>
                    <PasswordStrength password="123abc@R" />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Spinner</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Spinner />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Toggle</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Toggle (First Selected)</Heading>
                    <Toggle
                        options={[
                            { label: "Item 1", isSelected: true, url: "" },
                            { label: "Item 2", isSelected: false, url: "" },
                        ]}
                        onClick={() => console.log("onClick")}
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Toggle (Second Selected)</Heading>
                    <Toggle
                        options={[
                            { label: "Item 1", isSelected: false, url: "" },
                            { label: "Item 2", isSelected: true, url: "" },
                        ]}
                        onClick={() => console.log("onClick")}
                    />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Forms - Checkbox</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Checkbox label="Checkbox Unchecked" isChecked={false} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Checkbox label="Checkbox Checked" isChecked={true} />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Checkbox
                        label="Checkbox Unchecked Locked"
                        isLocked={true}
                        isChecked={false}
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Checkbox
                        label="Checkbox Checked Locked"
                        isLocked={true}
                        isChecked={true}
                    />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Forms - Dropdown</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Dropdown
                        label="Dropdown"
                        options={[
                            { label: "Option A", value: "Option A" },
                            { label: "Option B", value: "Option B" },
                        ]}
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Dropdown
                        label="Dropdown Selected"
                        options={[
                            { label: "Option A", value: "Option A" },
                            { label: "Option B", value: "Option B" },
                        ]}
                        value="Option A"
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Dropdown
                        label="Dropdown With Error"
                        errorMessage="Error Message"
                        options={[
                            { label: "Option A", value: "Option A" },
                            { label: "Option B", value: "Option B" },
                        ]}
                        value="Option A"
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Dropdown
                        label="Dropdown Locked"
                        isLocked={true}
                        options={[
                            { label: "Option A", value: "Option A" },
                            { label: "Option B", value: "Option B" },
                        ]}
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <Dropdown
                        label="Dropdown Selected Locked"
                        isLocked={true}
                        options={[
                            { label: "Option A", value: "Option A" },
                            { label: "Option B", value: "Option B" },
                        ]}
                        value="Option A"
                    />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Forms - Error</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Error message="Default Error" />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Forms - Search</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <Heading size="h4">Search Primary</Heading>
                    <Search type="primary" isLocked={false} />
                </div>
                <div
                    style={{
                        margin: "1rem 0",
                        padding: "1rem",
                        backgroundColor: "#D6CBF3",
                    }}>
                    <Heading size="h4">Search White</Heading>
                    <Search type="white" isLocked={false} />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Forms - Text Area</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <TextArea label="Text Area" />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <TextArea
                        label="Text Area With Value"
                        value="Text Area Input"
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <TextArea
                        label="Text Area Errored"
                        errorMessage="Error Message"
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <TextArea
                        label="Text Area Locked"
                        isLocked={true}
                        value="Text Area Input"
                    />
                </div>
            </div>

            <div style={{ margin: "2rem 0" }}>
                <Heading size="h2">Forms - Text Input</Heading>
                <div style={{ margin: "1rem 0" }}>
                    <TextInput label="Text Input" />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <TextInput label="Text Input With Value" value="Value" />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <TextInput
                        label="Text Input Errored"
                        errorMessage="Error Message"
                    />
                </div>
                <div style={{ margin: "1rem 0" }}>
                    <TextInput
                        isLocked={true}
                        label="Text Input Locked"
                        value="Value"
                    />
                </div>
            </div>
        </div>
    );
};

export default KopPage;
