import {
    ChevronDownIcon,
    ShoppingCartIcon,
    TagIcon,
    UserCircleIcon,
} from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { CartStatus } from "models/enumerations/cart-status";
import { MemberRelationship } from "models/enumerations/member-relationship";
import { ButtonOption } from "models/interfaces/button-option";
import Member from "models/interfaces/member";
import React from "react";
import "./card.scss";

const COMPONENT_CLASS = "c-card";

interface CardProps {
    buttons?: ButtonOption[];
    cartItems?: number;
    cartStatus?: CartStatus;
    hideExpand?: boolean;
    isExpanded?: boolean;
    member: Member;
    type?: "primary" | "white";
    onCartClick?: () => void;
    onExpandClick?: () => void;
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Card = ({
    buttons,
    cartItems,
    cartStatus,
    hideExpand = false,
    isExpanded = false,
    member,
    type = "primary",
    ...props
}: CardProps) => {
    let memberRelationshipText =
        MemberRelationship[
            member.relationship || MemberRelationship.Self
        ].toString();
    if (member.relationship === MemberRelationship.Self) {
        memberRelationshipText = "You";
    }
    if (member.relationship === MemberRelationship.LegalGuardian) {
        memberRelationshipText = "Legal Guardian";
    }

    return (
        <div
            className={`${COMPONENT_CLASS} ${type} ${
                props.onClick ? "clickable" : ""
            } ${isExpanded ? "expanded" : ""}`}
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}>
            <div className={`${COMPONENT_CLASS}__icon desktop`}>
                <UserCircleIcon />
                {(cartStatus === CartStatus.Personalized ||
                    cartStatus === CartStatus.Locked) && (
                    <div
                        className={`${COMPONENT_CLASS}__cart icon-only ${
                            cartStatus === CartStatus.Locked ? "locked" : ""
                        }`}>
                        <TagIcon />
                    </div>
                )}
                {cartStatus === CartStatus.Open &&
                    cartItems != null &&
                    cartItems > 0 && (
                        <div
                            className={`${COMPONENT_CLASS}__cart ${
                                props.onCartClick ? "cursor-pointer" : ""
                            }`}
                            onClick={props.onCartClick}>
                            <ShoppingCartIcon />
                            <span>{cartItems}</span>
                        </div>
                    )}
            </div>
            <div className={`${COMPONENT_CLASS}__content`}>
                <Heading size="h4">
                    {member.firstName}
                    <br />
                    {member.lastName}
                </Heading>
                <Paragraph size="small">{memberRelationshipText}</Paragraph>
                {buttons &&
                    buttons.length > 0 &&
                    buttons.map((button, index) => (
                        <Button
                            key={index}
                            type={button.type}
                            onClick={() => {
                                if (button.onClick) {
                                    button.onClick();
                                }
                            }}>
                            {button.label}
                        </Button>
                    ))}
            </div>
            <div className={`${COMPONENT_CLASS}__icon mobile`}>
                {cartStatus === CartStatus.Personalized && (
                    <div
                        className={`${COMPONENT_CLASS}__cart icon-only`}
                        onClick={props.onCartClick}>
                        <TagIcon />
                    </div>
                )}
                {cartStatus === CartStatus.Open &&
                    cartItems != null &&
                    cartItems > 0 && (
                        <div
                            className={`${COMPONENT_CLASS}__cart ${
                                props.onCartClick ? "cursor-pointer" : ""
                            }`}
                            onClick={props.onCartClick}>
                            <ShoppingCartIcon className="icon-cart" />
                            <span>{cartItems}</span>
                        </div>
                    )}
            </div>
            <div
                className={`${COMPONENT_CLASS}__expand`}
                onClick={() => {
                    if (props.onExpandClick) {
                        props.onExpandClick();
                    }
                }}>
                {!hideExpand && <ChevronDownIcon />}
            </div>
        </div>
    );
};
