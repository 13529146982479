import axios, { AxiosResponse } from "axios";
import Member from "models/interfaces/member";
import MemberCart from "models/interfaces/member-cart";
import MemberCartBundle from "models/interfaces/member-cart-bundle";
import { Purchase } from "models/interfaces/purchase";
import { RecurringPaymentPurchase } from "models/recurring-payment-purchase";

const addItem = async (
    member: Member,
    cart: MemberCart,
    bundle: MemberCartBundle
): Promise<AxiosResponse> => {
    try {
        const response = await axios.put(
            `/api/members/${member.id}/cart/${cart.id}/bundles`,
            bundle
        );
        console.log(response);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("Error adding item to cart:", error);
            throw error.response;
        } else {
            console.error("Unexpected error type:", error);
            throw error;
        }
    }
};

const deleteItem = async (
    member: Member,
    cart: MemberCart,
    bundle: MemberCartBundle
): Promise<AxiosResponse> => {
    return axios
        .delete(
            `/api/members/${member.id}/cart/${cart.id}/bundles/${bundle.id}`
        )
        .then(
            (response) => {
                return response;
            },
            (error) => {
                throw error.response;
            }
        );
};

const getCart = async (
    memberId: string,
    idToken: string
): Promise<AxiosResponse<MemberCart>> => {
    try {
        axios.defaults.headers.common["Authorization"] = `${idToken}`;
        const response = await axios.get<MemberCart>(
            `/api/members/${memberId}/cart`
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("Error getting cart:", error);
            throw error.response;
        } else {
            console.error("Unexpected error type:", error);
            throw error;
        }
    }
};

const getCartById = async (
    memberId: string,
    cartId: string
): Promise<AxiosResponse<MemberCart>> => {
    try {
        const response = await axios.get<MemberCart>(
            `/api/members/${memberId}/cart/${cartId}`
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("Error getting cart by ID:", error);
            throw error.response;
        } else {
            console.error("Unexpected error type:", error);
            throw error;
        }
    }
};

const personalizeCart = async (
    member: Member,
    cart: MemberCart,
    idToken: string
): Promise<AxiosResponse<MemberCart>> => {
    try {
        axios.defaults.headers.common["Authorization"] = `${idToken}`;
        const response = await axios.post<MemberCart>(
            `/api/members/${member.id}/cart/${cart.id}/personalize`
        );
        console.log(response);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("Error personalizing cart:", error);
            throw error.response;
        } else {
            console.error("Unexpected error type:", error);
            throw error;
        }
    }
};

const purchaseCart = async (
    member: Member,
    cart: MemberCart,
    purchase: Purchase
): Promise<AxiosResponse> => {
    return axios
        .post(`/api/members/${member.id}/cart/${cart.id}/purchase`, purchase)
        .then(
            (response) => {
                return response;
            },
            (error) => {
                throw error.response;
            }
        );
};

const purchaseCartRecurring = async (
    member: Member,
    cart: MemberCart,
    recurringPaymentPurchase: RecurringPaymentPurchase
): Promise<AxiosResponse> => {
    return axios
        .post(
            `/api/members/${member.id}/cart/${cart.id}/purchase/recurring`,
            recurringPaymentPurchase
        )
        .then(
            (response) => {
                return response;
            },
            (error) => {
                throw error.response;
            }
        );
};

const declineEstimate = async (
    memberId: string,
    cartId: string,
    idToken: string
): Promise<AxiosResponse<MemberCart>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.put(`/api/members/${memberId}/cart/${cartId}/decline`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const updateMemberCartOnError = async (
    memberId: string,
    cartId: string,
    idToken: string
): Promise<AxiosResponse> => {
    try {
        axios.defaults.headers.common["Authorization"] = `${idToken}`;
        const response = await axios.put(
            `/api/members/${memberId}/cart/${cartId}/error`
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error(
                "Error updating member cart on error:",
                error.message
            );
            if (error.response) {
                console.error(
                    "Backend responded with status:",
                    error.response.status
                );
                console.error("Response data:", error.response.data);
                console.error("Response headers:", error.response.headers);
            } else {
                console.error("Error message:", error.message);
            }
            throw error.response;
        } else {
            console.error("Unexpected error type:", error);
            throw error;
        }
    }
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const CartService = {
    addItem,
    deleteItem,
    getCart,
    getCartById,
    personalizeCart,
    purchaseCart,
    purchaseCartRecurring,
    declineEstimate,
    updateMemberCartOnError,
};

export default CartService;

// #endregion Exports
