import { useLocation } from "react-router-dom";

/**
 * Hook to get the search params from the url
 */
export function useSearchParams() {
    const { search } = useLocation();

    if (search == null) {
        return {};
    }

    let result: Record<string, string> = {};

    const params = new URLSearchParams(search);
    params.forEach((value, key) => {
        result = {
            ...result,
            [key]: value,
        };
    });

    return result;
}
