import { PriceJobStatus } from "models/enumerations/price-job-status";
import { LoadingState } from "models/interfaces/cart-state";
import MemberCart from "models/interfaces/member-cart";
import PriceJob from "models/interfaces/price-job";
import { useCallback, useEffect, useState } from "react";
import CartService from "services/cart-service";
import { useCartState } from "utilities/contexts/cart-state-context";

interface UseMemberCartServiceOptions {
    autoLoadCart?: boolean;
}

interface UseMemberCartServiceHook extends LoadingState<MemberCart> {
    load(): void;
}

export default function useMemberCartService({
    autoLoadCart,
}: UseMemberCartServiceOptions = {}): UseMemberCartServiceHook {
    const {
        state: { cart, cartId, member, cartIsLoading = false },
        setState: setCartState,
    } = useCartState();

    const [cartLoaded, setCartLoaded] = useState(false);

    const { completedOn } = cart ?? {};
    const { id: memberId } = member ?? {};

    const loadMemberCart = useCallback(() => {
        if (memberId == null || cartId == null) {
            return;
        }

        setCartState((prev) => ({
            ...prev,
            cartIsLoading: true,
        }));

        CartService.getCartById(memberId, cartId).then((response) => {
            const priceJobLoading =
                response.data.priceJob?.status !== PriceJobStatus.Complete;

            const priceJob: LoadingState<PriceJob> = {
                isLoaded: !priceJobLoading,
                isLoading: priceJobLoading,
                data: response.data.priceJob,
            };

            setCartLoaded(true);
            setCartState((prevCartState) => ({
                ...prevCartState,
                cart: response.data,
                cartIsLoading: false,
                priceJob,
            }));
        });
    }, [cartId, memberId, setCartState]);

    useEffect(() => {
        if (
            !autoLoadCart ||
            completedOn != null ||
            cartIsLoading ||
            cartLoaded
        ) {
            return;
        }

        loadMemberCart();
    }, [autoLoadCart, cartIsLoading, cartLoaded, completedOn, loadMemberCart]);

    return {
        isLoaded: cartLoaded,
        isLoading: cartIsLoading,
        data: cart,
        load: loadMemberCart,
    };
}
