import { ExclamationCircleIcon } from "@heroicons/react/outline";
import React, { ReactElement } from "react";
import StringUtils from "utilities/string-utils";
import "./error.scss";

const BASE_CLASS_NAME = "c-error";

interface ErrorProps {
    message?: ReactElement | string;
    type?: "field" | "form";
}

/**
 * Primary UI component for user interaction
 */
export const Error = ({
    message,
    type = "field",
}: ErrorProps): ReactElement | null => {
    if (typeof message === "string" && StringUtils.isEmpty(message)) {
        return null;
    }

    return (
        <div className={`${BASE_CLASS_NAME} ${BASE_CLASS_NAME}__${type}`}>
            <ExclamationCircleIcon />
            <span>{message}</span>
        </div>
    );
};
