import PaymentMethod from "models/payment-method";
import React from "react";
import PaymentTerms from "./payment-terms";
import PaymentType from "./payment-type";
import PricingRecap from "./pricing-recap";

interface PaymentDetailsProps {
    subtotal: number;
    discount: number;
    cashDiscount: number;
    total: number;
    memberOrderId: string;
    paymentMethod: PaymentMethod;
    isOneTimePayment: boolean;
    isRecurringPayment: boolean;
    termInMonths: number | null;
}

const PaymentDetails = ({
    subtotal,
    discount,
    cashDiscount,
    total,
    memberOrderId,
    paymentMethod,
    isOneTimePayment,
    isRecurringPayment,
    termInMonths,
}: PaymentDetailsProps) => {
    return (
        <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            <PricingRecap
                subtotal={subtotal}
                discount={discount}
                cashDiscount={cashDiscount}
                total={total}
            />
            <PaymentTerms
                isOneTimePayment={isOneTimePayment}
                isRecurringPayment={isRecurringPayment}
                termInMonths={termInMonths}
            />
            <PaymentType
                isRecurringPayment={isRecurringPayment}
                memberOrderId={memberOrderId}
                paymentMethod={paymentMethod}
            />
        </div>
    );
};

export default PaymentDetails;
