import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import { MemberRelationship } from "models/enumerations/member-relationship";
import Member from "models/interfaces/member";
import { Card } from "molecules/cards/card";
import { NewMemberCard } from "molecules/cards/new-member-card";
import { Modal } from "molecules/modal/modal";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MemberService from "services/member-service";
import "./onboarding-household-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-onboarding-household-page";

const OnboardingHouseholdPage: React.FC<any> = (props: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [members, setMembers] = useState<Member[]>();
    const [memberToRemove, setMemberToRemove] = useState<Member>();
    const history = useHistory();

    useEffect(() => {
        let isMounted = true;

        const loadData = async () => {
            try {
                var idToken = await CognitoUtils.getIdToken();
                const memberResponse = await MemberService.getMembers(idToken);

                if (isMounted && memberResponse.status === 200) {
                    setMembers(
                        memberResponse.data.filter(
                            (m: any) =>
                                m.relationship !== MemberRelationship.Self
                        )
                    );
                }
            } catch (error) {
                console.error("Error loading data:", error);
                // add code to display an error message to the user
            } finally {
                setIsLoading(false);
            }
        };

        loadData();

        return () => {
            isMounted = false;
        };
    }, []);

    const removeMember = async () => {
        if (!memberToRemove?.id) {
            return;
        }

        setIsLoading(true);

        try {
            const idToken = await CognitoUtils.getIdToken();
            const removeResponse = await MemberService.deleteMember(
                memberToRemove,
                idToken
            );

            if (removeResponse.status === 200) {
                setMembers(members?.filter((m) => m.id !== memberToRemove.id));
            }
        } catch (error) {
            console.error("Error removing member:", error);
            // add code to display an error message to the user
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <DashboardLayout
                coloredBackground={true}
                showPageLoader={isLoading}>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <Heading size="h2">
                            Would You Like to Add a Family Member?
                        </Heading>
                        <Paragraph size="medium">
                            Add a family member to shop for their care. You must
                            be authorized to make health care decisions for this
                            person.
                        </Paragraph>
                    </div>
                    <div className={`${COMPONENT_CLASS}__household`}>
                        <div
                            className={`${COMPONENT_CLASS}__household__wrapper`}>
                            {members?.map((member, index) => (
                                <Card
                                    buttons={[
                                        {
                                            label: "Remove",
                                            onClick: () => {
                                                setMemberToRemove(member);
                                                setIsOpen(true);
                                            },
                                            type: "clear",
                                        },
                                    ]}
                                    key={`household-${index}`}
                                    hideExpand={true}
                                    isExpanded={true}
                                    member={member}
                                />
                            ))}
                            <NewMemberCard
                                onClick={() =>
                                    history.push("/dashboard/add-member", {
                                        from: "onboarding-household-page",
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button onClick={() => history.push("/")} size="large">
                            Continue To Dashboard &gt;
                        </Button>
                    </div>
                </div>
            </DashboardLayout>
            <Modal
                buttons={[
                    {
                        label: "No, Don't Remove",
                        onClick: () => setIsOpen(false),
                        type: "primary",
                    },
                    {
                        label: "Yes, Remove Member",
                        onClick: () => removeMember(),
                        type: "white",
                    },
                ]}
                isOpen={isOpen}
                isLoading={isLoading}
                onClose={setIsOpen}
                title="Are you sure?">
                <Paragraph size="small">
                    Please confirm that you want to delete this member.
                </Paragraph>
            </Modal>
        </div>
    );
};

export default OnboardingHouseholdPage;
