import { KeyIcon, MailIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Error } from "atoms/forms/error";
import { TextInput } from "atoms/forms/text-input";
import { Anchor } from "atoms/typography/anchor";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { ForgotPassword } from "interfaces/forgot-password";
import OnboardingLayout from "layouts/onboarding-layout/onboarding-layout";
import { Loader } from "molecules/loader/loader";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountService from "services/account-service";
import StringUtils from "utilities/string-utils";
import "./forgot-password-page.scss";

const COMPONENT_CLASS = "c-forgot-password";

const ForgotPasswordPage: React.FC<any> = () => {
    const [emailError, setEmailError] = useState<string>();
    const [error, setError] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [forgotPassword, setForgotPassword] = useState<ForgotPassword>({
        email: "",
    });
    const history = useHistory();
    const formRef = useRef<any>();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        let isErrored = false;

        setEmailError(undefined);
        setError(undefined);

        if (StringUtils.isEmpty(forgotPassword.email)) {
            setEmailError("Email is required");
            isErrored = true;
        }

        if (isErrored) {
            return;
        }

        setIsLoading(true);

        try {
            // Call the forgotPassword method
            await AccountService.forgotPassword(forgotPassword.email || "");
            setIsSubmitted(true);
        } catch {
            setError(
                "Invalid Email:Setup Error Handling for ForgotPassword Page"
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <Loader isVisible={isLoading} />
            <OnboardingLayout>
                <div className={COMPONENT_CLASS}>
                    {!isSubmitted && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <KeyIcon />
                                <Heading size="h2">
                                    Forgot your password?
                                </Heading>
                                <Paragraph size="medium">
                                    Enter the email associated with your
                                    account, and we will email you instructions
                                    to reset it.
                                </Paragraph>
                            </div>
                            <form
                                className={`${COMPONENT_CLASS}__form`}
                                onSubmit={handleSubmit}
                                ref={formRef}>
                                <TextInput
                                    errorMessage={emailError}
                                    label="Email"
                                    placeholder="Email"
                                    value={forgotPassword.email}
                                    onChange={(email) => {
                                        setEmailError(undefined);
                                        setForgotPassword(
                                            Object.assign({}, forgotPassword, {
                                                email: email,
                                            })
                                        );
                                    }}
                                />
                                {error && <Error message={error} />}
                                <div
                                    className={`${COMPONENT_CLASS}__form__actions`}>
                                    <Button isSubmit={true} size="large">
                                        Get Instructions
                                    </Button>
                                    <Anchor
                                        cssClassName="forgot-password"
                                        to="/sign-in"
                                        size="xsmall">
                                        Back to Sign In
                                    </Anchor>
                                </div>
                            </form>
                        </div>
                    )}
                    {isSubmitted && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <MailIcon />
                                <Heading size="h2">Check your email.</Heading>
                                <Paragraph size="medium">
                                    We sent a password reset link to <br />
                                    <strong>{forgotPassword.email}</strong>
                                </Paragraph>
                            </div>
                            <div className={`${COMPONENT_CLASS}__form`}>
                                <div
                                    className={`${COMPONENT_CLASS}__form__actions`}>
                                    <Button
                                        isSubmit={true}
                                        size="large"
                                        onClick={() =>
                                            history.push("/sign-in")
                                        }>
                                        Back to Sign In
                                    </Button>
                                    <Paragraph>
                                        Didn't receive the email?{" "}
                                        <span onClick={handleSubmit}>
                                            Resend
                                        </span>
                                    </Paragraph>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </OnboardingLayout>
        </div>
    );
};

export default ForgotPasswordPage;
