import { Error } from "atoms/forms/error";
import { Label } from "atoms/typography/label";
import { FormValidation } from "models/interfaces/form-validation";
import React from "react";
import FormUtils from "utilities/form-utils";
import usePrevious from "utilities/hooks/use-previous";
import StringUtils from "utilities/string-utils";
import "./text-input.scss";

const BASE_CLASS_NAME = "c-text-input";

interface TextInputProps {
    displaySeparator?: string;
    displayFunction?: (args: string[]) => string;
    fieldValidation?: FormValidation[];
    inputFormat?: RegExp;
    parsingFormat?: RegExp;
    errorMessage?: string;
    isLocked?: boolean;
    isRequired?: boolean;
    maxLength?: number;
    name?: string;
    label?: string;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
}

/**
 * Primary UI component for user interaction
 */
export const TextInput = ({
    displaySeparator,
    displayFunction,
    fieldValidation,
    inputFormat,
    parsingFormat,
    errorMessage,
    isLocked = false,
    isRequired = false,
    maxLength,
    name,
    label,
    value,
    placeholder,
    ...props
}: TextInputProps) => {
    if (errorMessage == null && fieldValidation != null) {
        errorMessage = fieldValidation?.find((v) => v?.field === name)?.error;
    }

    const oldValue = usePrevious(value);
    const errorClass = StringUtils.hasValue(errorMessage) ? "errored" : "";
    const lockedClass = isLocked ? "locked" : "";
    const ref: any = React.createRef();

    return (
        <div
            className={`${BASE_CLASS_NAME} ${[errorClass, lockedClass].join(
                " "
            )}`}>
            {StringUtils.hasValue(label) && (
                <Label isRequired={isRequired}>{label}</Label>
            )}
            <input
                type="text"
                disabled={isLocked}
                id={name}
                name={name}
                maxLength={maxLength}
                ref={ref}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    if (
                        inputFormat &&
                        !FormUtils.validateInput(
                            ref.current,
                            oldValue || "",
                            (value: string) => inputFormat.test(value),
                            inputFormat,
                            displayFunction
                        )
                    ) {
                        return;
                    }
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
            />
            {StringUtils.hasValue(errorMessage) && (
                <Error message={errorMessage} />
            )}
        </div>
    );
};
