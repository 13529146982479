import { ShoppingCartIcon } from "@heroicons/react/outline";
import React from "react";
import "./button.scss";

interface CartButtonProps {
    cartItemCount?: number;
    isSelected: boolean;
    size?: "small" | "medium";
    type?: "primary" | "dark" | "clear" | "white";
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const CartButton = ({
    cartItemCount,
    isSelected = false,
    size = "medium",
    type = "primary",
    ...props
}: CartButtonProps) => {
    const itemClass = cartItemCount != null ? "has-count" : "";
    const selectedClass = isSelected ? "selected" : "";
    return (
        <button
            type="button"
            className={[
                "c-button",
                size,
                type,
                "shopping-cart",
                itemClass,
                selectedClass,
            ].join(" ")}
            {...props}>
            <ShoppingCartIcon className="icon-cart" />
            {cartItemCount != null && <span>{cartItemCount}</span>}
            {cartItemCount == null && (
                <span>{isSelected ? (size === "small" ? "-" : "–") : "+"}</span>
            )}
        </button>
    );
};
