import PriceJob from "models/interfaces/price-job";
import Member from "./member";
import MemberCart from "./member-cart";

export type LoadingState<T> = {
    data?: T;
    isLoaded: boolean;
    isLoading: boolean;
    error?: any;
};

export function defaultLoadingState<T>(): LoadingState<T> {
    return {
        isLoaded: false,
        isLoading: false,
    };
}

export default interface CartState {
    cart?: MemberCart;
    cartId?: string;
    cartIsLoading?: boolean;
    hasInsurance?: boolean;
    member?: Member;
    paymentTerms?: number;
    priceJob?: LoadingState<PriceJob>;
}
