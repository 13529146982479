import { Error } from "atoms/forms/error";
import { Label } from "atoms/typography/label";
import React from "react";
import StringUtils from "utilities/string-utils";
import "./text-area.scss";

const BASE_CLASS_NAME = "c-text-area";

interface CheckboxProps {
    errorMessage?: string;
    isLocked?: boolean;
    maxLength?: number;
    name?: string;
    label?: string;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
}

/**
 * Primary UI component for user interaction
 */
export const TextArea = ({
    errorMessage,
    isLocked = false,
    maxLength,
    name,
    label,
    value,
    placeholder,
    ...props
}: CheckboxProps) => {
    const errorClass = StringUtils.hasValue(errorMessage) ? "errored" : "";
    const lockedClass = isLocked ? "locked" : "";
    return (
        <div
            className={`${BASE_CLASS_NAME} ${[errorClass, lockedClass].join(
                " "
            )}`}>
            {StringUtils.hasValue(label) && <Label>label</Label>}
            <textarea
                disabled={isLocked}
                id={name}
                name={name}
                maxLength={maxLength}
                rows={4}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
            />
            {StringUtils.hasValue(errorMessage) && (
                <Error message={errorMessage} />
            )}
        </div>
    );
};
