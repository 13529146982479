import React from "react";
import "./button.scss";

export interface ButtonProps {
    cssClassName?: string;
    disabled?: boolean;
    size?: "small" | "medium" | "large";
    type?:
        | "primary"
        | "dark"
        | "clear"
        | "dark-gray"
        | "gray"
        | "light"
        | "white"
        | "plain";
    children: React.ReactNode;
    isSubmit?: boolean;
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
    cssClassName,
    size = "medium",
    type = "primary",
    isSubmit = false,
    ...props
}: ButtonProps) => {
    return (
        <button
            type={isSubmit ? "submit" : "button"}
            className={`c-button ${[cssClassName || "", size, type].join(" ")}`}
            {...props}>
            {props.children}
        </button>
    );
};
