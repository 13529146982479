import { MailIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Anchor } from "atoms/typography/anchor";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { useAtom } from "jotai";
import OnboardingLayout, {
    OnboardingStateAtom,
} from "layouts/onboarding-layout/onboarding-layout";
import { Loader } from "molecules/loader/loader";
import React, { useState } from "react";
import AccountService from "services/account-service";
import "./create-account-confirmation-page.scss";

const COMPONENT_CLASS = "c-create-account-confirmation";

const CreateAccountConfirmationPage: React.FC = () => {
    const [{ email, userId = "" }] = useAtom(OnboardingStateAtom);
    const [isVerifying, setIsVerifying] = useState(false);

    const resendVerificationEmail = async () => {
        setIsVerifying(true);

        try {
            await AccountService.sendVerificationEmail({
                userId,
                token: "",
            });
        } catch {}

        setIsVerifying(false);
    };

    return (
        <OnboardingLayout>
            <Loader isVisible={isVerifying} />
            <div className={COMPONENT_CLASS}>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <MailIcon />
                        <Heading size="h2">Check Your Email</Heading>
                        <Paragraph size="medium">
                            We sent an email verification link to{" "}
                            <strong>{email}</strong>
                        </Paragraph>
                    </div>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Anchor
                            to="/sign-in"
                            size="large"
                            type="primary-button">
                            Continue to Sign-in
                        </Anchor>
                        <span className="resend">
                            <span>Didn't receive the email?</span>
                            <Button
                                type="plain"
                                onClick={resendVerificationEmail}>
                                resend
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
        </OnboardingLayout>
    );
};

export default CreateAccountConfirmationPage;
