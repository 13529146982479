import { Button } from "atoms/buttons/button";
import { Confirm } from "atoms/confirm/confirm";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import { DateTime } from "luxon";
import { MemberRelationship } from "models/enumerations/member-relationship";
import Member, { emptyMember } from "models/interfaces/member";
import ConfirmationModal from "organisms/confirmation-modal/confirmation-modal";
import MemberForm from "organisms/member-form/member-form";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import MemberService from "services/member-service";
import StringUtils from "utilities/string-utils";
import "./profile-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-profile";

const ProfilePage: React.FC = () => {
    const { id } = useParams();
    const [error, setError] = useState<string>();
    const [member, setMember] = useState<Member>(emptyMember());
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
        useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const history = useHistory();
    const windowRef = useRef<Window>(null);

    useEffect(() => {
        const loadMember = async () => {
            if (StringUtils.isEmpty(id)) {
                setIsLoading(false);
                return;
            }
            const idToken = await CognitoUtils.getIdToken();
            MemberService.getMember(id, idToken).then((memberResult) => {
                if (memberResult?.data) {
                    const member = memberResult.data;
                    if (StringUtils.hasValue(member.dob)) {
                        member.dob = DateTime.fromISO(member.dob).toFormat(
                            "MM/dd/yyyy"
                        );
                    }

                    setMember(member);
                }

                setIsLoading(false);
            });
        };

        loadMember();
    }, [id]);

    useEffect(() => {
        if (isEditing) {
            return;
        }

        windowRef.current?.scrollTo(0, 0);
    }, [isEditing]);

    const handleSubmit = async (updatedMember: Member) => {
        if (!isEditing) {
            history.push("/");
            return;
        }

        setIsLoading(true);

        const idToken = await CognitoUtils.getIdToken();

        MemberService.updateMember(member.id, updatedMember, idToken).then(
            (memberResult) => {
                if (memberResult.status !== 200) {
                    setError(
                        "There was an error saving the profile. Please try again."
                    );
                    setIsLoading(false);
                    return;
                }
                if (memberResult?.data) {
                    const member = memberResult.data;
                    if (!StringUtils.isEmpty(member.dob)) {
                        member.dob = DateTime.fromISO(member.dob!).toFormat(
                            "MM/dd/yyyy"
                        );
                    }
                    setMember(member);
                }
                setIsEditing(false);
                setIsLoading(false);
                setIsSaved(true);
                (windowRef.current as any).scrollTo(0, 0);
            },
            (error: any) => {
                if (error.status === 401) {
                    setError("Invalid request. Please try again.");
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    const handleConfirmationModalClose = () => {
        setIsDeleteConfirmationOpen(false);
    };

    const handleConfirmationModalConfirm = async () => {
        setIsLoading(true);
        setIsDeleteConfirmationOpen(false);

        const idToken = await CognitoUtils.getIdToken();

        MemberService.deleteMember(member, idToken).then(
            (memberResult) => {
                if (memberResult.status !== 200) {
                    setError(
                        "There was an error deleting the profile. Please try again."
                    );
                    setIsLoading(false);
                    return;
                }
                setIsLoading(false);
                history.goBack();
            },
            (error) => {
                if (error.status === 401) {
                    setError(
                        "There was an error deleting the profile. Please try again."
                    );
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    const isFromSearch =
        history?.location?.state?.from === "procedure-search-page";
    const isSelf = member?.relationship === MemberRelationship.Self;

    return (
        <div>
            <DashboardLayout
                showPageLoader={isLoading}
                header={
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <Button
                            onClick={() => history.goBack()}
                            size="small"
                            type="light">
                            &lt; Back to {isFromSearch ? "Search" : "Dashboard"}
                        </Button>
                        <Heading size="h1" type="white">
                            {isSelf ? "Your Profile" : "Profile"}
                        </Heading>
                    </div>
                }
                windowRef={windowRef}>
                <div className={COMPONENT_CLASS}>
                    {isSaved && (
                        <Confirm text=" Your changes were successfully saved" />
                    )}
                    <div className={`${COMPONENT_CLASS}__intro`}>
                        {isSelf && (
                            <Paragraph>
                                We'll use the information below to verify your
                                insurance coverage, verify financial aid
                                eligibility, and customize your payment terms
                                for future purchases.
                            </Paragraph>
                        )}
                        {!isSelf && (
                            <Paragraph>
                                We'll use this information as the patient record
                                when you purchase on behalf of your family
                                member. You have certified that you are
                                authorized to make health care decisions for
                                this person.
                            </Paragraph>
                        )}
                        <div className="flex flex-shrink-0 flex-col gap-2">
                            {!isEditing && (
                                <Button onClick={() => setIsEditing(true)}>
                                    Edit Profile
                                </Button>
                            )}
                            {!isEditing && !isLoading && !isSelf && (
                                <>
                                    <Button
                                        type="clear"
                                        onClick={() =>
                                            setIsDeleteConfirmationOpen(true)
                                        }>
                                        Delete Profile
                                    </Button>
                                    <ConfirmationModal
                                        cancelButtonText="No, take me back to profile"
                                        confirmButtonText="Yes, I'd like to delete this family member"
                                        isOpen={isDeleteConfirmationOpen}
                                        title="Are you sure?"
                                        onCancel={handleConfirmationModalClose}
                                        onConfirm={
                                            handleConfirmationModalConfirm
                                        }>
                                        <Paragraph>
                                            This action cannot be undone. By
                                            deleting this family member, we'll
                                            remove the member from your
                                            dashboard, and you'll need to re-add
                                            them to continue shopping for their
                                            care.
                                        </Paragraph>
                                        <Paragraph cssClassName="py-6">
                                            If you have any active payment plans
                                            for care purchased on behalf of this
                                            family member, you are still
                                            responsible for all remaining
                                            payments.
                                        </Paragraph>
                                    </ConfirmationModal>
                                </>
                            )}
                        </div>
                    </div>
                    <MemberForm
                        error={error}
                        isEditing={isEditing}
                        isSelf={isSelf}
                        member={member}
                        onSubmit={handleSubmit}
                        saveButtonText="Save Changes"
                        setIsEditing={setIsEditing}
                        showAuthorizedCheck={false}
                    />
                </div>
            </DashboardLayout>
        </div>
    );
};

export default ProfilePage;
