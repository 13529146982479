import { UserCircleIcon } from "@heroicons/react/solid";
import { Heading } from "atoms/typography/heading";
import { FormatDateType } from "models/enumerations/format-date-type";
import React from "react";
import { OrderItem } from "utilities/hooks/use-orders";
import StringUtils from "utilities/string-utils";

const BasicInformation = ({
    firstName,
    lastName,
    purchaseDate,
    referenceNumber,
    total,
}: OrderItem) => {
    return (
        <div className="w-full space-y-6">
            <Heading size="h3" className="text-xl">
                Basic Information
            </Heading>
            <div className="grid w-full grid-cols-2 gap-y-4 md:grid-cols-4">
                <div className="flex w-full flex-col space-y-2">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Purchased For
                    </span>
                    <span className="flex w-full items-center space-x-1 text-left font-semibold">
                        <UserCircleIcon className="h-6 w-6 text-primary-700" />
                        <span>
                            {firstName} {lastName.charAt(0)}.
                        </span>
                    </span>
                </div>
                <div className="flex w-full flex-col space-y-2">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Purchase Date
                    </span>
                    <span>
                        {StringUtils.formatDate(
                            purchaseDate,
                            FormatDateType.DateOnly
                        )}
                    </span>
                </div>
                <div className="flex w-full flex-col space-y-2">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Account #
                    </span>
                    <span>#{referenceNumber}</span>
                </div>
                <div className="flex w-full flex-col space-y-2">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Total
                    </span>
                    <span>{StringUtils.formatCurrency(total)}</span>
                </div>
            </div>
        </div>
    );
};

export default BasicInformation;
