import React from "react";
import LockIcon from "assets/icons/lock_icon.png";

interface ReadonlyMemberEmailProps {
    email?: string;
}

const ReadonlyMemberEmail: React.FC<ReadonlyMemberEmailProps> = ({ email }) => {
    return (
        <div>
            <div className="mb-1 text-xs font-semibold text-grey-500">
                Email
            </div>
            <div className="flex flex-row items-center gap-[10px]">
                <img
                    className="my-auto h-auto flex-shrink-0"
                    src={LockIcon}
                    width={18}
                    height={16}
                    alt="lock icon"
                />
                <div className="text-grey-200">
                    {!!email && (
                        <div className="-mb-1 -mt-1 text-base">{email}</div>
                    )}
                    <div className="text-xs font-semibold uppercase">
                        Editable through account settings
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReadonlyMemberEmail;
