import { ChevronRightIcon } from "@heroicons/react/outline";
import { UserCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import { FormatDateType } from "models/enumerations/format-date-type";
import { PaymentStatus } from "models/enumerations/payment-status";
import React from "react";
import { Link } from "react-router-dom";
import { OrderItem } from "utilities/hooks/use-orders";
import StringUtils from "utilities/string-utils";
import "./order-list-item.scss";

const COMPONENT_CLASS = "c-order-list-item";

const OrderListItem = ({
    id,
    firstName,
    lastName,
    purchaseDate,
    referenceNumber,
    total,
    remaining,
    status,
}: OrderItem) => {
    return (
        <Link to={`/purchase-details/${id}`} className={COMPONENT_CLASS}>
            <div className={`${COMPONENT_CLASS}__header`}>
                <span className="flex w-full">
                    <span className="flex items-center space-x-1 font-semibold sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                        <UserCircleIcon className="h-6 w-6 flex-shrink-0 text-primary-700" />
                        <span>
                            {firstName} {lastName.charAt(0)}.
                        </span>
                    </span>
                    <span className="hidden sm:basis-1/2 md:basis-1/3 lg:block lg:basis-1/4">
                        {StringUtils.formatDate(
                            purchaseDate,
                            FormatDateType.DateOnly
                        )}
                    </span>
                    <span className="hidden sm:basis-1/2 md:block md:basis-1/3 lg:basis-1/4">
                        #{referenceNumber}
                    </span>
                    <span className="hidden pr-24 text-right sm:block sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                        {StringUtils.formatCurrency(total)}
                    </span>
                </span>
                <span className="flex w-[120px] flex-none items-center justify-end space-x-2">
                    <span>{StringUtils.formatCurrency(remaining)}</span>
                    {status === PaymentStatus.Failed && (
                        <span>
                            <ExclamationCircleIcon className="h-6 w-6 text-red-900" />
                        </span>
                    )}
                    <ChevronRightIcon className="w-6 flex-shrink-0 text-primary-700" />
                </span>
            </div>
        </Link>
    );
};

export default OrderListItem;
