import React from "react";
import { Redirect } from "react-router-dom";
import useCart from "utilities/hooks/use-cart";

function PersonalizePricePage() {
    const { cartId } = useCart();
    const redirectUrl = cartId ? `/personalize/price/${cartId}` : `/dashboard`;
    return <Redirect to={redirectUrl} />;
}

export default PersonalizePricePage;
