import { Error } from "atoms/forms/error";
import { Label } from "atoms/typography/label";
import React, { useState } from "react";
import StringUtils from "utilities/string-utils";
import "./text-input.scss";

const BASE_CLASS_NAME = "c-text-input";

interface PasswordInputProps {
    errorMessage?: string;
    name?: string;
    label?: string;
    isErrored?: boolean;
    value?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
}

/**
 * Primary UI component for user interaction
 */
export const PasswordInput = ({
    errorMessage,
    name,
    label,
    isErrored,
    value,
    placeholder,
    ...props
}: PasswordInputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const errorClass =
        StringUtils.hasValue(errorMessage) || isErrored ? "errored" : "";
    return (
        <div
            className={`${BASE_CLASS_NAME} ${["password", errorClass].join(
                " "
            )}`}>
            <div className={`${BASE_CLASS_NAME}__label__wrapper`}>
                {StringUtils.hasValue(label) && <Label>{label}</Label>}
                <p
                    className="c-paragraph xsmall"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? "Hide" : "Show"}
                </p>
            </div>
            <input
                type={showPassword ? "text" : "password"}
                id={name}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
            />
            {StringUtils.hasValue(errorMessage) && (
                <Error message={errorMessage} />
            )}
        </div>
    );
};
