import { Button } from "atoms/buttons/button";
import { Spinner } from "atoms/spinner/spinner";
import { Heading } from "atoms/typography/heading";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "utilities/contexts/auth-state-context";
import useOrders from "utilities/hooks/use-orders";
import NoActivePaymentPlans from "./no-active-payment-plans";
import PaymentPlan from "./payment-plan";

interface PaymentPlansProps {
    showLoader?: boolean;
}

const PaymentPlans: React.FC<PaymentPlansProps> = ({ showLoader = true }) => {
    const { paymentPlanOrders, loading: loadingOrders } = useOrders();
    const history = useHistory();
    const { isLoading: isAuthLoading } = useAuth();

    const handleAllPurchasesBtnClick = () => {
        history.push("/all-purchases");
    };

    return (
        <div className="mt-2">
            <Heading size="h2" type="light">
                <span className="flex items-center justify-between">
                    <span>Payment Plans</span>
                    <Button
                        onClick={handleAllPurchasesBtnClick}
                        size="small"
                        type="light">
                        All purchases
                    </Button>
                </span>
            </Heading>
            <div className="mt-4 flex flex-col space-y-2">
                {loadingOrders ? (
                    <div className="flex w-full items-center justify-center">
                        {!isAuthLoading && (
                            <Spinner size="small" variant="secondary" />
                        )}
                    </div>
                ) : paymentPlanOrders.length > 0 ? (
                    paymentPlanOrders.map((order) => {
                        return <PaymentPlan key={order.id} order={order} />;
                    })
                ) : (
                    <NoActivePaymentPlans />
                )}
            </div>
        </div>
    );
};

export default PaymentPlans;
