import { Dialog, Transition } from "@headlessui/react";
import { Button } from "atoms/buttons/button";
import React, { Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import {
    PersonalizedPriceDetails,
    PersonalizedPriceDetailsProps,
} from "organisms/personalized-price-details/personalized-price-details";
import "./payment-details-modal.scss";

const COMPONENT_CLASS = "c-payment-details-modal";

interface PaymentDetailsModalProps extends PersonalizedPriceDetailsProps {
    isOpen: boolean;
    onClose: () => void;
}

const PaymentDetailsModal: React.FC<PaymentDetailsModalProps> = ({
    isOpen,
    onClose,
    cart,
    hasInsurance,
    grossCharges,
}) => {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={COMPONENT_CLASS} onClose={onClose}>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay
                            className={`${COMPONENT_CLASS}__overlay`}
                        />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className={`${COMPONENT_CLASS}__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <Button type="plain" onClick={onClose}>
                                    <XIcon /> Close
                                </Button>
                            </div>
                            <div className="flex flex-col gap-6">
                                <PersonalizedPriceDetails
                                    cart={cart}
                                    hasInsurance={hasInsurance}
                                    grossCharges={grossCharges}
                                />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default PaymentDetailsModal;
