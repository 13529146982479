export interface Address {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    zipCode: string;
}

export const emptyAddress = (): Address => ({
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
});
