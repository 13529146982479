import { DateTime } from "luxon";
import CartState, { LoadingState } from "models/interfaces/cart-state";
import Member from "models/interfaces/member";
import MemberCart from "models/interfaces/member-cart";
import PriceJob from "models/interfaces/price-job";
import { useCallback, useEffect, useMemo, useState } from "react";
import MemberService from "services/member-service";
import CognitoUtils from "utilities/cognito-utils";
import { useCartState } from "utilities/contexts/cart-state-context";
import useMemberCartService from "utilities/hooks/use-member-cart-service";
import usePriceJobService from "utilities/hooks/use-price-job-service";

interface UseCartHookOptions {
    autoLoadCart?: boolean;
    autoLoadInsurance?: boolean;
    autoLoadPriceJob?: boolean;
}

interface UpdateParams {
    cartId?: string;
    cart?: MemberCart;
    member?: Member;
    priceJob?: PriceJob;
}

interface UseCartHookResult extends CartState {
    isExpired: boolean;
    isLoading: boolean;
    isLoadingInsuranceInformation: boolean;
    subtotal: number;
    isPaymentPlanPurchase: boolean;
    priceJobStatusDescription: string;
    clearPaymentTerms: () => void;
    update: (params: UpdateParams) => void;
    updatePriceJob: (priceJob?: LoadingState<PriceJob>) => void;
    updatePaymentTerms: (paymentTerms: number) => void;
}

export default function useCart({
    autoLoadCart = false,
    autoLoadInsurance = false,
    autoLoadPriceJob = false,
}: UseCartHookOptions = {}): UseCartHookResult {
    const [isLoadingInsuranceInformation, setIsLoadingInsuranceInformation] =
        useState<boolean>(false);

    const { state: cartState, setState: setCartState } = useCartState();

    const { isLoading: isCartLoading, data: cart } = useMemberCartService({
        autoLoadCart,
    });

    const {
        isProcessed: isPriceJobProcessed,
        isProcessing: isPriceJobProcessing,
        data: priceJob,
        statusDescription: priceJobStatusDescription,
    } = usePriceJobService({
        autoLoadPriceJob,
    });

    const { expiresOn, memberCartBundles = [] } = cart ?? {};
    const { member, paymentTerms, cartId, hasInsurance = false } = cartState;
    const memberId = member?.id;
    const isPaymentPlanPurchase = paymentTerms != null;

    const isExpired = useMemo(() => {
        if (expiresOn != null) {
            return (
                Math.ceil(
                    DateTime.now()
                        .diff(DateTime.fromISO(expiresOn), "days")
                        .toObject().days || 0
                ) > 0
            );
        }

        return false;
    }, [expiresOn]);

    const subtotal = useMemo(
        () =>
            memberCartBundles.reduce((prev, item) => {
                const cost = hasInsurance ? item.contractedCost : item.cost;
                return prev + (cost || 0);
            }, 0),
        [memberCartBundles, hasInsurance]
    );

    const update = useCallback(
        ({ cart, cartId, member, priceJob }: UpdateParams) => {
            setCartState((prev): CartState => {
                const nextCart =
                    cart == null
                        ? undefined
                        : {
                              ...cart,
                              priceJob,
                              priceJobId: priceJob?.id,
                          };

                cartId = !cartId ? cart?.id : cartId;
                cart = cartId === cart?.id ? nextCart : undefined;

                return {
                    ...prev,
                    cartId,
                    cart,
                    cartIsLoading: false,
                    member,
                    priceJob: {
                        data: priceJob,
                        isLoaded: priceJob != null,
                        isLoading: false,
                    },
                };
            });
        },
        [setCartState]
    );

    const updatePriceJob = useCallback(
        (priceJob?: LoadingState<PriceJob>) => {
            setCartState((prev): CartState => {
                const nextCart =
                    prev.cart == null
                        ? undefined
                        : {
                              ...prev.cart,
                              priceJob: priceJob?.data,
                              priceJobId: priceJob?.data?.id,
                          };

                return {
                    ...prev,
                    cart: nextCart,
                    priceJob,
                };
            });
        },
        [setCartState]
    );

    const [loadedInsuranceInformation, setInsuranceInformationLoaded] =
        useState(false);

    const loadInsuranceInformationAsync = useCallback(
        async (memberId: string) => {
            const idToken = await CognitoUtils.getIdToken();
            const memberResponse = await MemberService.getMember(
                memberId,
                idToken
            );

            if (memberResponse?.data) {
                let hasInsurance = false;
                if (memberResponse.data.memberInsurances) {
                    hasInsurance =
                        memberResponse.data.memberInsurances.length > 0;
                }

                setCartState((prev) => ({ ...prev, hasInsurance }));
            }

            setIsLoadingInsuranceInformation(false);
            setInsuranceInformationLoaded(true);
        },
        [setCartState]
    );

    useEffect(() => {
        if (
            !autoLoadInsurance ||
            loadedInsuranceInformation ||
            isLoadingInsuranceInformation ||
            memberId == null
        ) {
            return;
        }

        setIsLoadingInsuranceInformation(true);
        loadInsuranceInformationAsync(memberId);
    }, [
        autoLoadInsurance,
        isLoadingInsuranceInformation,
        loadInsuranceInformationAsync,
        loadedInsuranceInformation,
        memberId,
    ]);

    return {
        cart,
        paymentTerms,
        isPaymentPlanPurchase,
        cartId,
        cartIsLoading: isCartLoading,
        hasInsurance,
        isExpired,
        isLoading:
            isLoadingInsuranceInformation ||
            isCartLoading ||
            isPriceJobProcessing,
        isLoadingInsuranceInformation,
        member,
        priceJob: {
            isLoading: isPriceJobProcessing,
            isLoaded: isPriceJobProcessed,
            data: priceJob,
        },
        priceJobStatusDescription,
        subtotal,
        clearPaymentTerms: useCallback(() => {
            setCartState((prev) => ({
                ...prev,
                paymentTerms: undefined,
            }));
        }, [setCartState]),
        update,
        updatePriceJob,
        updatePaymentTerms: useCallback(
            (paymentTerms: number) => {
                setCartState((prev) => ({
                    ...prev,
                    paymentTerms,
                }));
            },
            [setCartState]
        ),
    };
}
