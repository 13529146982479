import { ShoppingCartIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CartService from "services/cart-service";
import { useCartState } from "utilities/contexts/cart-state-context";
import useRedirectExpiredCart from "utilities/hooks/use-redirect-expired-cart";
import StringUtils from "utilities/string-utils";
import "./personalize-start-page.scss";
import CognitoUtils from "utilities/cognito-utils";

/*******************************************************
  THIS PAGE HAS BEEN REPLACED WITH MODAL IN CART LAYOUT
 ********************************************************/

const COMPONENT_CLASS = "c-personalize-start";
function PersonalizeStartPage() {
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const { state: cartState, setState: setCartState } = useCartState();
    useRedirectExpiredCart();

    useEffect(() => {
        const fetchCartData = async () => {
            const memberId = cartState.member?.id;

            if (StringUtils.isEmpty(memberId)) {
                return;
            }

            try {
                const idToken = await CognitoUtils.getIdToken();

                const response = await CartService.getCart(memberId, idToken);

                if (response?.data) {
                    setCartState((prevState) => ({
                        ...prevState,
                        cart: response.data,
                        cartIsLoading: false,
                    }));
                }

                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching cart data:", error);
                setIsLoading(false);
            }
        };

        const fetchDataAndCleanup = async () => {
            await fetchCartData();
        };

        fetchDataAndCleanup();

        return () => {};
    }, [cartState.member?.id, setCartState]);

    return (
        <div>
            <CartLayout
                header={
                    <Button
                        onClick={() => history.push("/procedures")}
                        size="small"
                        type="light">
                        &lt; Back to Search
                    </Button>
                }
                fullscreen={true}
                isLocked={false}
                hideFooter={true}
                showLoader={isLoading}>
                <div className={COMPONENT_CLASS}>
                    <ShoppingCartIcon />
                    <Heading size="h2">Before we get started...</Heading>
                    <Paragraph>
                        Carefully review the items in your cart to ensure you
                        have the correct procedures and locations selected. Once
                        you select the continue button, you can no longer edit
                        your cart without starting the process over.
                    </Paragraph>
                    <div className={`${COMPONENT_CLASS}__actions`}>
                        <Button
                            onClick={() => history.push(`/procedures`)}
                            type="white">
                            Add More Procedures
                        </Button>
                        <Button
                            onClick={() => history.push("/personalize/summary")}
                            type="primary">
                            Continue &gt;
                        </Button>
                    </div>
                </div>
            </CartLayout>
        </div>
    );
}

export default PersonalizeStartPage;
