import { Button } from "atoms/buttons/button";
import { Sidebar } from "molecules/sidebar/sidebar";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCartState } from "utilities/contexts/cart-state-context";
import { useAuth } from "utilities/contexts/auth-state-context";
import { Loader } from "molecules/loader/loader";
import "./cart-layout.scss";
import Footer from "molecules/footer/footer";
import { Modal } from "molecules/modal/modal";

const COMPONENT_CLASS = "c-cart-layout";

interface CartLayoutProps {
    children: React.ReactNode;
    fullscreen?: boolean;
    fullwidth?: boolean;
    header?: React.ReactNode;
    isLocked: boolean;
    hideFooter?: boolean;
    showLoader?: boolean;
    windowRef?: any;
    onLoaderChange?: (isVisible: boolean) => void;
}

const CartLayout: React.FC<CartLayoutProps> = (props: CartLayoutProps) => {
    const { showLoader = false, onLoaderChange } = props;

    const [hasFooter, setHasFooter] = useState(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
    const [showPersonalizeModal, setShowPersonalizeModal] = useState(false);
    const history = useHistory();
    const {
        user,
        isAuthenticated,
        isLoading: isAuthLoading,
        settings,
    } = useAuth({ runVerify: true });
    const { state: cartState } = useCartState();

    const isLoading = useMemo(
        () =>
            cartState.cart == null ? true : cartState.cartIsLoading ?? false,
        [cartState.cart, cartState.cartIsLoading]
    );

    useEffect(() => {
        if (props.isLocked === true || props.hideFooter === true) {
            setHasFooter(false);
            return;
        }
        setHasFooter((cartState.cart?.memberCartBundles?.length ?? 0) > 0);
    }, [cartState.cart?.memberCartBundles, props.hideFooter, props.isLocked]);

    const loaderVisible = isLoading || isAuthLoading || showLoader;

    useEffect(() => {
        onLoaderChange?.(loaderVisible);
    }, [loaderVisible, onLoaderChange]);

    if (!isAuthenticated) {
        history.push(
            `/sign-in?redirectUrl=${window.location.href.replace(
                window.location.origin,
                ""
            )}`
        );
        return null;
    }

    if (
        user != null &&
        !user?.isOnboarded &&
        window.location.pathname !== "/onboarding"
    ) {
        history.push("/onboarding");
        return null;
    }

    if (!cartState.member) {
        history.push("/dashboard");
        return null;
    }

    const handleShowPersonalizeModal = () => setShowPersonalizeModal(true);

    const handleClosePersonalizeModal = () => setShowPersonalizeModal(false);

    return (
        <div
            id="cart-layout"
            className={`${COMPONENT_CLASS} ${
                isSidebarExpanded ? "no-scroll" : ""
            }`}>
            <Loader isVisible={loaderVisible} />
            <div className={`${COMPONENT_CLASS}__window`}>
                <Sidebar
                    logo={settings?.siteLogo}
                    member={cartState.member}
                    isExpanded={isSidebarExpanded}
                    isLocked={props.isLocked}
                    setIsExpanded={setIsSidebarExpanded}
                />
                <div className={`${COMPONENT_CLASS}__section`}>
                    {props.header && (
                        <div
                            className={`${COMPONENT_CLASS}__header ${
                                props.isLocked ? "locked" : ""
                            }`}>
                            <div
                                className={`${COMPONENT_CLASS}__header__content`}>
                                {props.header}
                            </div>
                        </div>
                    )}
                    <div
                        id="layout-wrapper"
                        className={`${COMPONENT_CLASS}__wrapper ${
                            props.header ? "short" : ""
                        } ${hasFooter ? "footer" : ""}`}
                        ref={props.windowRef}>
                        <main
                            className={`${COMPONENT_CLASS}__content ${
                                props.fullscreen ? "fullscreen" : ""
                            } ${props.fullwidth ? "fullwidth" : ""}`}>
                            <div className={`${COMPONENT_CLASS}__container`}>
                                {props.children}
                                <Footer
                                    coloredBackground={false}
                                    hideOnDesktop={true}
                                />
                            </div>
                        </main>
                    </div>
                    {hasFooter && (
                        <div className={`${COMPONENT_CLASS}__footer`}>
                            <div className={`${COMPONENT_CLASS}__content`}>
                                <div
                                    className={`${COMPONENT_CLASS}__footer__content`}>
                                    <Button
                                        onClick={handleShowPersonalizeModal}
                                        size="large"
                                        type="white">
                                        Get Your Price &gt;
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={showPersonalizeModal}
                isLoading={false}
                onClose={handleClosePersonalizeModal}
                wrapperClassName="w-[36rem]"
                title={"Before we get started..."}>
                <div className="w-full space-y-8">
                    <p>
                        Carefully review the items in your cart to ensure you
                        have the correct <strong>procedures</strong> and{" "}
                        <strong>locations</strong> selected.
                        <br></br>
                        <br></br>
                        Once you select the continue button, you can no longer
                        edit your cart without starting the process over.
                    </p>
                    <div className="flex w-full justify-between space-x-4">
                        <Button
                            onClick={handleClosePersonalizeModal}
                            disabled={false}
                            size="large"
                            cssClassName="flex-1">
                            ADD MORE PROCEDURES
                        </Button>
                        <Button
                            disabled={false}
                            onClick={() => history.push("/personalize/summary")}
                            type="primary"
                            size="large"
                            cssClassName="flex-1">
                            CONTINUE TO PRICE
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CartLayout;
