import { Button } from "atoms/buttons/button";
import { Confirm } from "atoms/confirm/confirm";
import { Checkbox } from "atoms/forms/checkbox";
import { Dropdown, DropdownOption } from "atoms/forms/dropdown";
import { Error } from "atoms/forms/error";
import { TextInput } from "atoms/forms/text-input";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { InsuranceLevel } from "models/enumerations/insurance-level";
import { MemberRelationship } from "models/enumerations/member-relationship";
import { FormValidation } from "models/interfaces/form-validation";
import Member, { emptyMember } from "models/interfaces/member";
import MemberInsurance, {
    emptyMemberInsurance,
} from "models/interfaces/member-insurance";
import { MemberRelationshipOptions } from "models/member-relationship-options";
import { PersonalizeProgress } from "molecules/personalize-progress/personalize-progress";
import React, {
    Dispatch,
    FormEvent,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import MemberService from "services/member-service";
import { useAuth } from "utilities/contexts/auth-state-context";
import { useCartState } from "utilities/contexts/cart-state-context";
import useRedirectExpiredCart from "utilities/hooks/use-redirect-expired-cart";
import SearchUtils from "utilities/search-utils";
import StringUtils from "utilities/string-utils";
import "../personalize-summary-page/personalize-summary-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-personalize-summary";
const FORM_CLASS = "c-member-form";

function PersonalizeSummaryInsurancePage() {
    const [error, setError] = useState<string>();
    const [member, setMember] = useState<Member>(emptyMember());
    const [originalMember, setOriginalMember] = useState<Member>(member);

    const { hasInsurance, primaryInsurance, primaryInsuranceOptions } =
        useMemo(() => {
            const hasInsurance = (member.memberInsurances ?? []).length > 0;

            return {
                hasInsurance,
                primaryInsurance: hasInsurance
                    ? member.memberInsurances[0]
                    : emptyMemberInsurance(),
                primaryInsuranceOptions: hasInsurance
                    ? [
                          {
                              value: member.memberInsurances[0],
                              label: member.memberInsurances[0]
                                  .insurancePlanName,
                          } as DropdownOption<MemberInsurance>,
                      ]
                    : [],
            };
        }, [member.memberInsurances]);

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [fieldValidation, setFieldValidation] = useState<FormValidation[]>();
    const history = useHistory();
    const { settings } = useAuth();
    const { state: cartState } = useCartState();
    const windowRef = useRef(null);
    useRedirectExpiredCart();

    const setPrimaryInsurance: Dispatch<
        SetStateAction<MemberInsurance | undefined>
    > = (action) => {
        setMember((prev) => {
            const hasInsurance = (prev.memberInsurances?.length ?? 0) > 0;
            const primaryInsurance =
                typeof action !== "function"
                    ? action
                    : action(
                          hasInsurance ? prev.memberInsurances[0] : undefined
                      );

            return {
                ...prev,
                memberInsurances: primaryInsurance ? [primaryInsurance] : [],
            };
        });
    };

    useEffect(() => {
        const loadMember = async (memberId: string) => {
            const idToken = await CognitoUtils.getIdToken();

            MemberService.getMember(memberId, idToken).then((response) => {
                if (response?.data) {
                    setMember(response.data);
                    setOriginalMember(response.data);
                }

                setIsLoading(false);
            });
        };

        if (cartState.member?.id) {
            loadMember(cartState.member.id);
        }
    }, [cartState.member]);

    const handleContinueClick = () => {
        history.push("/personalize/consent");
    };

    const handleEditClick = () => {
        setIsSaved(false);
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsSaved(false);
        setIsEditing(false);
        setMember(originalMember);
    };

    const handleSave = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isEditing) {
            return;
        }

        const validationErrors = [];

        // Insurance field validations
        if (hasInsurance) {
            if (StringUtils.isEmpty(primaryInsurance.firstName)) {
                validationErrors.push({
                    field: "PrimaryPolicyFirstName",
                    error: "First Name is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.lastName)) {
                validationErrors.push({
                    field: "PrimaryPolicyLastName",
                    error: "Last Name is required",
                });
            }
            if (primaryInsurance.relationship == null) {
                validationErrors.push({
                    field: "PrimaryRelationship",
                    error: "Relationship is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.insurancePlanKey)) {
                validationErrors.push({
                    field: "PrimaryInsurancePlanKey",
                    error: "Insurance Plan is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.insuranceMemberId)) {
                validationErrors.push({
                    field: "PrimaryInsuranceMemberId",
                    error: "Member ID is required",
                });
            }
            if (StringUtils.isEmpty(primaryInsurance.groupNum)) {
                validationErrors.push({
                    field: "PrimaryGroupNum",
                    error: "Group Number is required",
                });
            }
        }

        setFieldValidation(validationErrors);
        if (validationErrors.length > 0) {
            return;
        }

        setIsLoading(true);

        const idToken = await CognitoUtils.getIdToken();
        MemberService.updateMember(member.id, member, idToken).then(
            (memberResult) => {
                if (memberResult.status !== 200) {
                    setError(
                        "There was an saving the profile. Please try again."
                    );
                    setIsLoading(false);
                    return;
                }
                setMember(memberResult.data);
                setOriginalMember(memberResult.data);
                setIsSaved(true);
                setIsLoading(false);
                setIsEditing(false);
                if (windowRef.current) {
                    (windowRef.current as any).scrollTo(0, 0);
                }
            },
            (error: any) => {
                if (error.status === 401) {
                    setError("Invalid request. Please try again.");
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    const isSelf = cartState.member?.relationship === MemberRelationship.Self;

    return (
        <div>
            <CartLayout
                isLocked={true}
                header={
                    <PersonalizeProgress
                        title="Getting Your Price"
                        currentStep={isSelf ? 2 : 3}
                        showProgress={true}
                    />
                }
                showLoader={isLoading}
                windowRef={windowRef}>
                <div className={COMPONENT_CLASS}>
                    {isSaved && (
                        <Confirm text="Your changes were successfully saved" />
                    )}
                    <Heading size="h2">Insurance Information</Heading>
                    <Paragraph>
                        We'll use the information below to verify the patient's
                        insurance coverage. Please review your information
                        carefully to ensure it is accurate. If you make any
                        changes to this information, we'll update the patient's
                        profile for future purchases.
                    </Paragraph>
                    {!isLoading && (
                        <div className={FORM_CLASS}>
                            <form onSubmit={handleSave}>
                                <div className={`${FORM_CLASS}__section`}>
                                    {(isEditing || !hasInsurance) && (
                                        <div
                                            className={`${FORM_CLASS}__section__wrapper double`}>
                                            <Checkbox
                                                label="I am not using insurance"
                                                isChecked={!hasInsurance}
                                                isLocked={!isEditing}
                                                onChange={(isChecked) =>
                                                    setMember((prev) => {
                                                        return {
                                                            ...prev,
                                                            memberInsurances:
                                                                !isChecked
                                                                    ? [
                                                                          {
                                                                              ...emptyMemberInsurance(),
                                                                              insuranceLevel:
                                                                                  InsuranceLevel.Primary,
                                                                          },
                                                                      ]
                                                                    : [],
                                                        };
                                                    })
                                                }
                                            />
                                        </div>
                                    )}
                                    {hasInsurance && (
                                        <div
                                            className={`${FORM_CLASS}__section__wrapper double`}>
                                            <TextInput
                                                fieldValidation={
                                                    fieldValidation
                                                }
                                                isLocked={!isEditing}
                                                isRequired={true}
                                                maxLength={50}
                                                name="PrimaryPolicyFirstName"
                                                label="Primary Policy Holder First Name"
                                                placeholder="Primary Policy Holder First Name"
                                                onChange={(value) => {
                                                    setPrimaryInsurance(
                                                        (prev) => ({
                                                            ...(prev ??
                                                                emptyMemberInsurance()),
                                                            firstName: value,
                                                        })
                                                    );

                                                    if (
                                                        fieldValidation?.find(
                                                            (v) =>
                                                                v.field ===
                                                                "PrimaryPolicyFirstName"
                                                        )
                                                    ) {
                                                        setFieldValidation([
                                                            ...fieldValidation.filter(
                                                                (v) =>
                                                                    v.field !==
                                                                    "PrimaryPolicyFirstName"
                                                            ),
                                                        ]);
                                                    }
                                                }}
                                                value={
                                                    primaryInsurance.firstName
                                                }
                                            />
                                            <TextInput
                                                fieldValidation={
                                                    fieldValidation
                                                }
                                                isLocked={!isEditing}
                                                isRequired={true}
                                                maxLength={75}
                                                name="PrimaryPolicyLastName"
                                                label="Primary Policy Holder Last Name"
                                                placeholder="Primary Policy Holder Last Name"
                                                onChange={(value) => {
                                                    setPrimaryInsurance(
                                                        (prev) => ({
                                                            ...(prev ??
                                                                emptyMemberInsurance()),
                                                            lastName: value,
                                                        })
                                                    );

                                                    if (
                                                        fieldValidation?.find(
                                                            (v) =>
                                                                v.field ===
                                                                "PrimaryPolicyLastName"
                                                        )
                                                    ) {
                                                        setFieldValidation([
                                                            ...fieldValidation.filter(
                                                                (v) =>
                                                                    v.field !==
                                                                    "PrimaryPolicyLastName"
                                                            ),
                                                        ]);
                                                    }
                                                }}
                                                value={
                                                    primaryInsurance.lastName
                                                }
                                            />
                                            <Dropdown
                                                fieldValidation={
                                                    fieldValidation
                                                }
                                                isLocked={!isEditing}
                                                isRequired={true}
                                                name="PrimaryRelationship"
                                                isSearchable={false}
                                                placeholder="Select"
                                                label="Relationship"
                                                options={
                                                    MemberRelationshipOptions
                                                }
                                                onChange={(value: string) => {
                                                    setPrimaryInsurance(
                                                        (prev) => ({
                                                            ...(prev ??
                                                                emptyMemberInsurance()),
                                                            relationship:
                                                                Number(value),
                                                        })
                                                    );

                                                    if (
                                                        fieldValidation?.find(
                                                            (v) =>
                                                                v.field ===
                                                                "PrimaryRelationship"
                                                        )
                                                    ) {
                                                        setFieldValidation([
                                                            ...fieldValidation.filter(
                                                                (v) =>
                                                                    v.field !==
                                                                    "PrimaryRelationship"
                                                            ),
                                                        ]);
                                                    }
                                                }}
                                                value={primaryInsurance.relationship?.toString()}
                                            />
                                            <div></div>
                                            <Dropdown
                                                fieldValidation={
                                                    fieldValidation
                                                }
                                                isLocked={!isEditing}
                                                isRequired={true}
                                                name="PrimaryInsurancePlanKey"
                                                isSearchable={true}
                                                placeholder="Start Typing to Find Insurance Plan"
                                                label="Insurance Plan"
                                                loadOptions={async (
                                                    inputValue
                                                ) => {
                                                    if (settings == null) {
                                                        return [] as any;
                                                    }

                                                    const index =
                                                        SearchUtils.indexByType(
                                                            settings,
                                                            "insurance"
                                                        );

                                                    const results = await index
                                                        .search(inputValue)
                                                        .then(({ hits }) =>
                                                            hits.map(
                                                                (
                                                                    hit: any
                                                                ): DropdownOption<MemberInsurance> => ({
                                                                    value: {
                                                                        insurancePlanKey:
                                                                            hit.objectID,
                                                                        insurancePlanName:
                                                                            hit.name,
                                                                    } as MemberInsurance,
                                                                    label: hit.name,
                                                                })
                                                            )
                                                        );

                                                    return results;
                                                }}
                                                options={
                                                    primaryInsuranceOptions
                                                }
                                                onChange={({
                                                    insurancePlanKey,
                                                    insurancePlanName,
                                                }) => {
                                                    setPrimaryInsurance(
                                                        (prev) => {
                                                            return {
                                                                ...(prev ??
                                                                    emptyMemberInsurance()),
                                                                insurancePlanKey,
                                                                insurancePlanName,
                                                            };
                                                        }
                                                    );

                                                    if (
                                                        fieldValidation?.find(
                                                            (v) =>
                                                                v.field ===
                                                                "PrimaryInsurancePlanKey"
                                                        )
                                                    ) {
                                                        setFieldValidation([
                                                            ...fieldValidation.filter(
                                                                (v) =>
                                                                    v.field !==
                                                                    "PrimaryInsurancePlanKey"
                                                            ),
                                                        ]);
                                                    }
                                                }}
                                                value={primaryInsurance}
                                            />
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper double`}>
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isLocked={!isEditing}
                                                    isRequired={true}
                                                    maxLength={50}
                                                    name="PrimaryInsuranceMemberId"
                                                    label="Member ID"
                                                    placeholder="Member ID"
                                                    onChange={(value) => {
                                                        setPrimaryInsurance(
                                                            (prev) => {
                                                                return {
                                                                    ...(prev ??
                                                                        emptyMemberInsurance()),
                                                                    insuranceMemberId:
                                                                        value,
                                                                };
                                                            }
                                                        );

                                                        if (
                                                            fieldValidation?.find(
                                                                (v) =>
                                                                    v.field ===
                                                                    "PrimaryInsuranceMemberId"
                                                            )
                                                        ) {
                                                            setFieldValidation([
                                                                ...fieldValidation.filter(
                                                                    (v) =>
                                                                        v.field !==
                                                                        "PrimaryInsuranceMemberId"
                                                                ),
                                                            ]);
                                                        }
                                                    }}
                                                    value={
                                                        primaryInsurance.insuranceMemberId
                                                    }
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isLocked={!isEditing}
                                                    isRequired={true}
                                                    maxLength={50}
                                                    name="PrimaryGroupNum"
                                                    label="Group No."
                                                    placeholder="Group No."
                                                    onChange={(value) => {
                                                        setPrimaryInsurance(
                                                            (prev) => {
                                                                return {
                                                                    ...(prev ??
                                                                        emptyMemberInsurance()),
                                                                    groupNum:
                                                                        value,
                                                                };
                                                            }
                                                        );

                                                        if (
                                                            fieldValidation?.find(
                                                                (v) =>
                                                                    v.field ===
                                                                    "PrimaryGroupNum"
                                                            )
                                                        ) {
                                                            setFieldValidation([
                                                                ...fieldValidation.filter(
                                                                    (v) =>
                                                                        v.field !==
                                                                        "PrimaryGroupNum"
                                                                ),
                                                            ]);
                                                        }
                                                    }}
                                                    value={
                                                        primaryInsurance.groupNum
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {error && (
                                    <div className={`${FORM_CLASS}__section`}>
                                        <Error message={error} />
                                    </div>
                                )}
                                {!isEditing && (
                                    <div
                                        className={`${FORM_CLASS}__section ${FORM_CLASS}__actions right`}>
                                        <Button
                                            onClick={handleEditClick}
                                            type="white">
                                            Edit Information
                                        </Button>
                                        <Button onClick={handleContinueClick}>
                                            Continue &gt;
                                        </Button>
                                    </div>
                                )}
                                {isEditing && (
                                    <div
                                        className={`${FORM_CLASS}__section ${FORM_CLASS}__actions right`}>
                                        <Button
                                            onClick={handleCancel}
                                            type="plain">
                                            Cancel
                                        </Button>
                                        <Button isSubmit={true}>Save</Button>
                                    </div>
                                )}
                            </form>
                        </div>
                    )}
                </div>
            </CartLayout>
        </div>
    );
}

export default PersonalizeSummaryInsurancePage;
