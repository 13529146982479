import { Dialog, Transition } from "@headlessui/react";
import { Button } from "atoms/buttons/button";
import React, { Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Heading } from "atoms/typography/heading";
import "./confirmation-modal.scss";

const COMPONENT_CLASS = "c-confirmation-modal";

interface ConfirmationModalProps {
    cancelButtonText: string;
    confirmButtonText: string;
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    cancelButtonText,
    confirmButtonText,
    children,
    isOpen,
    onCancel,
    onConfirm,
    title,
}) => {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={COMPONENT_CLASS} onClose={onCancel}>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay
                            className={`${COMPONENT_CLASS}__overlay`}
                        />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className={`${COMPONENT_CLASS}__wrapper`}>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <Button type="plain" onClick={onCancel}>
                                    <XIcon /> Close
                                </Button>
                            </div>
                            <div>
                                <Heading size="h3" className="pb-6">
                                    {title}
                                </Heading>
                                {children}
                            </div>
                            <div className="flex flex-row justify-between gap-6">
                                <Button size="large" onClick={onCancel}>
                                    {cancelButtonText}
                                </Button>
                                <Button
                                    type="clear"
                                    size="large"
                                    onClick={onConfirm}>
                                    {confirmButtonText}
                                </Button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ConfirmationModal;
