import { Heading } from "atoms/typography/heading";
import React from "react";

interface PaymentTermsProps {
    isOneTimePayment: boolean;
    isRecurringPayment: boolean;
    termInMonths: number | null;
}

const PaymentTerms = ({
    isOneTimePayment,
    isRecurringPayment,
    termInMonths,
}: PaymentTermsProps) => {
    return (
        <div className="w-full space-y-6 text-left">
            <Heading size="h3" className="text-xl">
                Payment Terms
            </Heading>
            {isOneTimePayment ? (
                <div>Pay Now</div>
            ) : (
                isRecurringPayment && (
                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                                Plan Term
                            </span>
                            <span className="whitespace-nowrap">
                                {termInMonths} mo.
                            </span>
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                                Recurring
                            </span>
                            <span>Monthly</span>
                        </div>
                        <div className="flex items-center justify-between">
                            <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                                Interest
                            </span>
                            <span>0%</span>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default PaymentTerms;
