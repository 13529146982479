import React from "react";
import { OrderItem } from "utilities/hooks/use-orders";
import OrderListItem from "./order-list-item";
import "./order-list.scss";

const COMPONENT_CLASS = "c-order-list";

interface OrderListProps {
    orders: OrderItem[];
}

const OrderList = ({ orders }: OrderListProps) => {
    return (
        <div className={COMPONENT_CLASS}>
            <div className={`${COMPONENT_CLASS}__header`}>
                <div className="ml-4 flex w-full">
                    <span
                        className={`${COMPONENT_CLASS}__header-item flex items-center space-x-1 font-semibold sm:basis-1/2 md:basis-1/3 lg:basis-1/4`}>
                        Purchased For
                    </span>
                    <span
                        className={`${COMPONENT_CLASS}__header-item hidden sm:basis-1/2 md:basis-1/3 lg:block lg:basis-1/4`}>
                        Purchase Date
                    </span>
                    <span
                        className={`${COMPONENT_CLASS}__header-item hidden sm:basis-1/2 md:block md:basis-1/3 lg:basis-1/4`}>
                        Account #
                    </span>
                    <span
                        className={`${COMPONENT_CLASS}__header-item hidden pr-[90px] text-right sm:block sm:basis-1/2 md:basis-1/3 lg:basis-1/4`}>
                        Total
                    </span>
                </div>
                <span
                    className={`${COMPONENT_CLASS}__header-item flex w-[120px] flex-none items-center justify-end space-x-2 pr-6`}>
                    Remaining
                </span>
            </div>
            <div className="flex flex-col space-y-2">
                {orders.map((e) => (
                    <OrderListItem key={e.id} {...e} />
                ))}
            </div>
        </div>
    );
};

export default OrderList;
