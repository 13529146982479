import ErrorResponse from "models/interfaces/error";
import { FormValidation } from "models/interfaces/form-validation";
import { hasProperties } from "utilities/object-utils";

function hasMessage(error: unknown): error is { data: { message: string } } {
    return (
        hasProperties(error, "data") &&
        hasProperties(error.data, "message") &&
        typeof error.data.message === "string"
    );
}

function isErrorResponse(error: unknown): error is ErrorResponse {
    return (
        hasProperties(error, "data") &&
        hasProperties(
            error.data,
            "field",
            "fieldError",
            "error",
            "exceptionMessage"
        ) &&
        typeof error.data.field === "string" &&
        typeof error.data.fieldError === "string" &&
        typeof error.data.error === "string" &&
        typeof error.data.exceptionMessage === "string"
    );
}

export function parseErrorResponse(error: unknown): {
    errorMessage: string;
    fieldValidation: FormValidation[];
} {
    if (isErrorResponse(error)) {
        return {
            errorMessage: error.data.error,
            fieldValidation: [
                {
                    error: error.data.fieldError,
                    field: error.data.field,
                },
            ],
        };
    }

    return {
        errorMessage: hasMessage(error)
            ? error.data.message
            : "An error occured",
        fieldValidation: [],
    };
}
