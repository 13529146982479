import { Button } from "atoms/buttons/button";
import { Checkbox } from "atoms/forms/checkbox";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { PersonalizeProgress } from "molecules/personalize-progress/personalize-progress";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CartService from "services/cart-service";
import { useCartState } from "utilities/contexts/cart-state-context";
import useRedirectExpiredCart from "utilities/hooks/use-redirect-expired-cart";
import "../personalize-summary-page/personalize-summary-page.scss";
import "./personalize-consent-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-personalize-summary";
const FORM_CLASS = "c-member-form";

function PersonalizeConsentPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [hasAgreed, setHasAgreed] = useState(false);
    const { state: cartState, setState: setCartState } = useCartState();
    const history = useHistory();
    useRedirectExpiredCart();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (hasAgreed) {
            setIsLoading(true);

            try {
                var idToken = await CognitoUtils.getIdToken();
                const response = await CartService.personalizeCart(
                    cartState.member!,
                    cartState.cart!,
                    idToken
                );

                if (response?.status === 200 && response?.data?.priceJobId) {
                    const cart = Object.assign({}, cartState.cart);
                    cart.priceJobId = response?.data?.priceJobId;
                    setCartState({
                        ...cartState,
                        ...{
                            cart: cart,
                            cartIsLoading: false,
                        },
                    });
                    history.push(`/personalize/price/${cart.id}`);
                    return;
                }
            } catch (error) {
                console.error("Error submitting form:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div>
            <CartLayout
                isLocked={true}
                header={
                    <PersonalizeProgress
                        title="Getting Your Price"
                        currentStep={4}
                        showProgress={true}
                    />
                }
                showLoader={isLoading}>
                <div className={COMPONENT_CLASS}>
                    <Heading size="h2">
                        Personal Price Disclaimers & Authorization
                    </Heading>
                    <Paragraph>
                        Please review and agree to the terms below before
                        continuing.
                    </Paragraph>
                    <div className={FORM_CLASS}>
                        <form onSubmit={handleSubmit}>
                            <div
                                className={`${FORM_CLASS}__section c-personalize-consent`}>
                                <Heading size="h3">Personalized Price</Heading>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Paragraph size="small">
                                        Your Personalized Price is based on a
                                        good faith estimate of charges for the
                                        items and services reasonably expected
                                        to be included with the procedures that
                                        you have selected in your cart, benefit
                                        information obtained from your health
                                        insurance company, and any discounts or
                                        payment plan terms available from the
                                        Health Care Provider for which you
                                        qualify.
                                    </Paragraph>
                                </div>
                                <Heading size="h3">
                                    Good Faith Estimate Disclaimer
                                </Heading>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Paragraph size="small">
                                        If noted, this Good Faith Estimate
                                        contains items or services that are
                                        anticipated to require separate
                                        scheduling and are expected to occur
                                        before or following the expected period
                                        of care for the primary item of service.
                                        Separate good faith estimates will be
                                        issued to an individual upon scheduling
                                        or upon request of items or services so
                                        noted. Information such as diagnosis
                                        codes, service codes, expected charges,
                                        and provider or facility identifiers may
                                        not be included for the noted items or
                                        services included in your Good Faith
                                        Estimate because that information will
                                        be provided in a separate Good Faith
                                        Estimate upon scheduling of such items
                                        or services upon request.
                                    </Paragraph>
                                </div>
                                <Heading size="h3">
                                    Required Disclaimers
                                </Heading>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Paragraph size="small">
                                        This Good Faith Estimate shows the
                                        estimated cost of items and services
                                        that are reasonably expected for your
                                        health care needs for the items or
                                        services that you selected in your cart.
                                        The estimate is based on information
                                        known at the time the estimate is
                                        created. Actual items, services, or
                                        charges may differ from those listed in
                                        this Good Faith Estimate. The Good Faith
                                        Estimate does not include any unknown or
                                        unexpected costs that may arise during
                                        treatment. There could be additional
                                        charges if complications or special
                                        circumstances occur. If applicable,
                                        these additional charges will be billed
                                        in addition to the amounts paid through
                                        HCN. If this happens, federal law allows
                                        you to dispute (appeal) the bill. If
                                        your bill is $400.00 or more than this
                                        Good Faith Estimate, you may have the
                                        right to dispute the bill. You may
                                        contact the Health Care Provider or
                                        facility listed to let them know the
                                        billed charges are higher than the Good
                                        Faith Estimate. You can ask them to
                                        update the bill to match the Good Faith
                                        Estimate, ask to negotiate the bill, or
                                        ask if there is additional financial
                                        assistance available. You may also start
                                        a dispute resolution process with the
                                        U.S Department of Health and Human
                                        Services (HHS). If you choose to use the
                                        dispute resolution process, you must
                                        start the dispute process within 120
                                        calendar days (about 4 Months) of the
                                        date on the original bill. There is a
                                        $25 fee to use the dispute process. If
                                        the agency reviewing your dispute agrees
                                        with you, you will have to pay the price
                                        on this Good Faith Estimate. If the
                                        agency disagrees with you and agrees
                                        with the Health Care Provider or
                                        facility, you will have to pay the
                                        higher amount. To learn more and get a
                                        form to start the process, go to
                                        http://www.cms.gov/nosurprises or call
                                        1-800-985-3059. For questions or more
                                        information about your right to a Good
                                        Faith Estimate or dispute process, visit
                                        http://www.cms.gov/nosurprises or call
                                        1-800-985-3059. Keep a copy of your Good
                                        Faith Estimate in a safe place or take
                                        pictures of it. You may need it if you
                                        are billed a higher amount. The Good
                                        Faith Estimate is not a contract and
                                        does not require you to obtain the items
                                        or services from any of the providers or
                                        facilities identified in this Good Faith
                                        Estimate. The Initiation of the
                                        patient-provider dispute resolution
                                        process will not adversely affect the
                                        quality of health care services
                                        furnished to an uninsured (or Selfpay)
                                        individual by a provider or facility.
                                    </Paragraph>
                                </div>
                                <Heading size="h3">
                                    Disclaimer for Anesthesia Services Only
                                </Heading>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Paragraph size="small">
                                        Anesthesia Services are billed based on
                                        1.) The type of surgical procedure, 2.)
                                        The amount of time the procedure takes,
                                        and 3.) The health condition of the
                                        individual patient including any special
                                        monitoring or services they may require.
                                        The anesthesia average charge you have
                                        been quoted is based on the average
                                        patient and average length of procedure.
                                        If your individual care requires
                                        additional time, advanced monitoring
                                        and/or post-operative management such as
                                        pain management, those additional
                                        services will Increase the charge
                                        amount. Items not represented in the
                                        average charge include but are not
                                        limited to: arterial line placements,
                                        central/pulmonary catheter placements,
                                        echocardiogram, and post-operative pain
                                        management procedures.
                                    </Paragraph>
                                </div>
                                <Heading size="h3">Authorizations</Heading>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Paragraph size="small">
                                        I hereby authorize HCN and the Health
                                        Care Provider to obtain benefit
                                        information about me from my health
                                        insurance company and to obtain soft
                                        credit reports concerning me from one or
                                        more credit bureaus in order to provide
                                        me with a Personalized Price for the
                                        items or services that I have selected
                                        in my cart. I also authorize HCN and the
                                        Health Care Provider to provide
                                        information about me (whether received
                                        from me, my health insurance company, or
                                        a credit bureau) to third parties for
                                        business-related purposes including, but
                                        not limited to: billing, collection,
                                        instituting payment arrangements, and
                                        determining eligibility for available
                                        programs. I understand that a soft
                                        credit inquiry has no impact on my
                                        credit score and is not visible on my
                                        credit report to any parties other than
                                        me. I also understand that Health Care
                                        Provider may obtain credit reports
                                        concerning me without my written
                                        authorization under some circumstances,
                                        as permitted by law.
                                    </Paragraph>
                                </div>
                                <Heading size="h3">Consent</Heading>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Paragraph size="small">
                                        By checking the box below, I acknowledge
                                        that the information I have provided
                                        including insurance benefits and any
                                        information I have presented to verify
                                        my own identity, and if applicable any
                                        information used to verify the identity
                                        of a minor beneficiary is current,
                                        correct, and complete to the best of my
                                        knowledge. I also acknowledge that I
                                        have read, understand, and agree to the
                                        disclaimers and authorizations stated
                                        above.
                                    </Paragraph>
                                </div>
                                <div
                                    className={`${FORM_CLASS}__section__wrapper`}>
                                    <Checkbox
                                        label="I authorize HCN to procure a consumer report on me"
                                        isChecked={hasAgreed}
                                        onChange={(isChecked) =>
                                            setHasAgreed(isChecked)
                                        }
                                    />
                                </div>
                            </div>
                            <div
                                className={`${FORM_CLASS}__section ${FORM_CLASS}__actions right`}>
                                <Button disabled={!hasAgreed} isSubmit={true}>
                                    Get Your Price &gt;
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </CartLayout>
        </div>
    );
}

export default PersonalizeConsentPage;
