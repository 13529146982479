import "assets/scss/app.scss";
import AccountSettingsPage from "pages/account-settings-page/account-settings-page";
import AddMemberPage from "pages/add-member-page/add-member-page";
import AllPurchagesPage from "pages/all-purchases-page/all-purchases-page";
import CompletePage from "pages/complete-page/complete-page";
import ContactUsPage from "pages/contact-us-page/contact-us-page";
import CreateAccountConfirmationPage from "pages/create-account-confirmation-page/create-account-confirmation-page";
import DashboardPage from "pages/dashboard-page/dashboard-page";
import ForgotPasswordPage from "pages/forgot-password-page/forgot-password-page";
import KopPage from "pages/kop-page/kop-page";
import OnboardingHouseholdPage from "pages/onboarding-household-page/onboarding-household-page";
import OnboardingPage from "pages/onboarding-page/onboarding-page";
import PaymentPage from "pages/pay-page/pay-page";
import PersonalizeConsentPage from "pages/personalize-consent-page/personalize-consent-page";
import PersonalizePayPage from "pages/personalize-pay-page/personalize-pay-page";
import PersonalizePriceDetailsPage from "pages/personalize-price-page/personalize-price-details-page";
import PersonalizePricePage from "pages/personalize-price-page/personalize-price-page";
import PersonalizeStartPage from "pages/personalize-start-page/personalize-start-page";
import PersonalizeSummaryInsurancePage from "pages/personalize-summary-insurance-page/personalize-summary-insurance-page";
import PersonalizeSummaryMemberPage from "pages/personalize-summary-member-page/personalize-summary-member-page";
import PersonalizeSummaryPage from "pages/personalize-summary-page/personalize-summary-page";
import PrivacyPolicyPage from "pages/privacy-policy-page/privacy-policy-page";
import ProcedureDetailPage from "pages/procedure-detail-page/procedure-detail-page";
import ProcedureResultPage from "pages/procedure-result-page/procedure-result-page";
import ProcedureSearchPage from "pages/procedure-search-page/procedure-search-page";
import ProfilePage from "pages/profile-page/profile-page";
import PurchaseDetailsPage from "pages/purchase-details-page/purchase-details-page";
import ResetPasswordPage from "pages/reset-password-page/reset-password-page";
import SignInPage from "pages/sign-in-page/sign-in-page";
import SignOutPage from "pages/sign-out-page/sign-out-page";
import TermsOfServicePage from "pages/terms-of-service-page/terms-of-service-page";
import VerfiyEmailPage from "pages/verify-email-page/verify-email-page";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthStateProvider } from "utilities/contexts/auth-state-context";
import { CartStateProvider } from "utilities/contexts/cart-state-context";

// -----------------------------------------------------------------------------------------
// #region Constants
// -----------------------------------------------------------------------------------------

// #endregion Constants

// -----------------------------------------------------------------------------------------
// #region Application Component
// -----------------------------------------------------------------------------------------

const App: React.FC = () => {
    return (
        <Router>
            <AuthStateProvider>
                <CartStateProvider>
                    <Switch>
                        <Route path="/account-settings">
                            <AccountSettingsPage />
                        </Route>
                        <Route path="/all-purchases">
                            <AllPurchagesPage />
                        </Route>
                        <Route path="/kop">
                            <KopPage />
                        </Route>
                        <Route path="/sign-in">
                            <SignInPage />
                        </Route>
                        <Route path="/create-account/confirmation">
                            <CreateAccountConfirmationPage />
                        </Route>
                        <Route path="/create-account">
                            <SignInPage />
                        </Route>
                        <Route path="/sign-out">
                            <SignOutPage />
                        </Route>
                        <Route path="/forgot-password">
                            <ForgotPasswordPage />
                        </Route>
                        <Route path="/reset-password">
                            <ResetPasswordPage />
                        </Route>
                        <Route path="/terms-of-service">
                            <TermsOfServicePage />
                        </Route>
                        <Route path="/contact-us">
                            <ContactUsPage />
                        </Route>
                        <Route path="/privacy-policy">
                            <PrivacyPolicyPage />
                        </Route>
                        <Route path="/onboarding/household">
                            <OnboardingHouseholdPage />
                        </Route>
                        <Route path="/onboarding">
                            <OnboardingPage />
                        </Route>
                        <Route path="/dashboard/add-member">
                            <AddMemberPage />
                        </Route>
                        <Route path="/dashboard/profile/:id">
                            <ProfilePage />
                        </Route>
                        <Route path="/procedures/result">
                            <ProcedureResultPage />
                        </Route>
                        <Route path="/procedures/:id">
                            <ProcedureDetailPage />
                        </Route>
                        <Route path="/procedures">
                            <ProcedureSearchPage />
                        </Route>
                        <Route path="/personalize/summary">
                            <PersonalizeSummaryPage />
                        </Route>
                        <Route path="/personalize/member">
                            <PersonalizeSummaryMemberPage />
                        </Route>
                        <Route path="/personalize/insurance">
                            <PersonalizeSummaryInsurancePage />
                        </Route>
                        <Route path="/personalize/consent">
                            <PersonalizeConsentPage />
                        </Route>
                        <Route path="/personalize/price/:cartId">
                            <PersonalizePriceDetailsPage />
                        </Route>
                        <Route path="/personalize/price">
                            <PersonalizePricePage />
                        </Route>
                        <Route path="/personalize/pay">
                            <PersonalizePayPage />
                        </Route>
                        <Route path="/personalize">
                            <PersonalizeStartPage />
                        </Route>
                        <Route path="/payment">
                            <PaymentPage />
                        </Route>
                        <Route path="/complete">
                            <CompletePage />
                        </Route>
                        <Route path="/purchase-details/:memberOrderId">
                            <PurchaseDetailsPage />
                        </Route>
                        <Route path="/email/verify">
                            <VerfiyEmailPage />
                        </Route>
                        <Route path="/">
                            <DashboardPage />
                        </Route>
                    </Switch>
                </CartStateProvider>
            </AuthStateProvider>
        </Router>
    );
};

// #endregion Application Component

// -----------------------------------------------------------------------------------------
// #region Export
// -----------------------------------------------------------------------------------------

export default App;

// #endregion Export
