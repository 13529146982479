import axios, { AxiosResponse } from "axios";
import { ForgotPassword } from "interfaces/forgot-password";
import { ResetPassword } from "interfaces/reset-password";
import UpdateEmail from "interfaces/update-email";
import { UserRegister } from "interfaces/user-register";
import { UserSignIn } from "interfaces/user-sign-in";
import VerifyEmail from "interfaces/verify-email";
import Member from "models/interfaces/member";
import User from "models/interfaces/user";
import env from "react-dotenv";
import { Auth } from "aws-amplify";

axios.defaults.baseURL = env.API_URL;

const completeOnboarding = async (idToken: string): Promise<AxiosResponse> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.put("/api/account/onboarding", { isOnboarded: true }).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const forgotPassword = async (email: string): Promise<void> => {
    // Call the Auth.forgotPassword method
    const result = await Auth.forgotPassword(email);

    if (result) {
        // Handle success if necessary
    }

    return;
};

export const registerUser = async (
    userRegister: UserRegister
): Promise<void> => {
    try {
        await Auth.signUp({
            username: userRegister.email, // Ensure this matches your Cognito configuration
            password: userRegister.password,
            attributes: {
                email: userRegister.email,
            },
        });
    } catch (error) {
        console.error("Cognito sign up error:", error); // Log the error
        throw error; // Propagate the error
    }
};
// No return statement needed as the return type is void
const resetPassword = async (
    email: string,
    code: string,
    password: string
): Promise<{ status: number }> => {
    await Auth.forgotPasswordSubmit(email, code, password);
    return { status: 200 }; // Return a status object
};

const signInUser = async (email: string, password: string): Promise<any> => {
    return Auth.signIn(email, password); // Use Cognito for sign-in
};

const signOutUser = async (): Promise<void> => {
    return Auth.signOut(); // Use Cognito for sign-out
};

const getMember = async (idToken: string): Promise<AxiosResponse<Member>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<Member>("/api/account/member").then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const getUser = async (idToken: string): Promise<AxiosResponse<User>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<User>("/api/account").then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const verify = async (idToken: string): Promise<boolean> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return true;
    /*
        TODO - This will always break until backend is working
    return axios.get("/api/account/verify").then(
        (response) => {
            return response.status === 200;
        },
        () => {
            return false;
        }
    );
    */
};

const verifyEmail = async (userId: string, code: string): Promise<void> => {
    try {
        // Use Auth to confirm the email verification code
        await Auth.verifyCurrentUserAttributeSubmit("email", code);
    } catch (error) {
        throw error; // Propagate the error to be handled by the caller
    }
};

const getUserByEmail = async (email: string): Promise<AxiosResponse<User>> => {
    return axios.get<User>(`/api/account/by-email/${email}`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const sendVerificationEmail = async (
    payload: VerifyEmail
): Promise<string | undefined> => {
    return axios.post<string>("/api/account/email-confirmation", payload).then(
        (response) => response.data,
        () => undefined
    );
};

const updateEmail = async (
    email: string,
    idToken: string
): Promise<User | null> => {
    try {
        // Get the currently authenticated user
        const user = await Auth.currentAuthenticatedUser();

        // Update the user's email
        await Auth.updateUserAttributes(user, { email });

        // If successful, return the updated user object
        return user; // Make sure this returns the correct User type
    } catch (error) {
        console.error("Error updating email:", error);
        return null; // Return null if there's an error
    }
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const AccountService = {
    completeOnboarding,
    forgotPassword,
    /*    registerVerifyUser,*/
    registerUser,
    resetPassword,
    signInUser,
    signOutUser,
    getMember,
    getUser,
    sendVerificationEmail,
    verify,
    getUserByEmail,
    updateEmail,
    verifyEmail,
};

export default AccountService;

// #endregion Exports
