export enum PriceJobStatus {
    Scheduled,
    PatientSearch,
    PatientNew,
    PatientPendingCreate,
    OrderCreate,
    EstimateSubmit,
    DiscountScoring,
    ManualWorkQueue,
    Complete,
    Error,
}
