import HcnLogo from "assets/images/hcn-logo-white@2x.png";
import { Anchor } from "atoms/typography/anchor";
import { Paragraph } from "atoms/typography/paragraph";
import { DateTime } from "luxon";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "utilities/contexts/auth-state-context";
import "./onboarding-layout.scss";
import { atom } from "jotai";

const COMPONENT_CLASS = "c-onboarding-layout";
export const OnboardingStateAtom = atom<OnboardingState>({});

interface OnboardingState {
    email?: string;
    userId?: string;
}

interface OnboardingLayoutProps {
    children: React.ReactNode;
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = (
    props: OnboardingLayoutProps
) => {
    const history = useHistory();
    const { settings } = useAuth();

    return (
        <div className={`${COMPONENT_CLASS} colored`}>
            <div className={`${COMPONENT_CLASS}__window`}>
                <div
                    className={`${COMPONENT_CLASS}__container__header`}
                    onClick={() => history.push("/sign-in")}>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        <div
                            className={`${COMPONENT_CLASS}__container__header__content`}>
                            {settings?.siteLogo && (
                                <img
                                    alt={`${settings?.siteName} Logo`}
                                    src={settings?.siteLogo}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <main className={`${COMPONENT_CLASS}__content`}>
                    <div className={`${COMPONENT_CLASS}__container`}>
                        {props.children}
                        <div
                            className={`${COMPONENT_CLASS}__container__footer`}>
                            <div className={`${COMPONENT_CLASS}__footer__logo`}>
                                <img
                                    alt="Healthcare Commerce Network Logo"
                                    src={HcnLogo}
                                />
                            </div>
                            <div
                                className={`${COMPONENT_CLASS}__footer__links`}>
                                <Anchor to="/terms-of-service" size="xsmall">
                                    Terms of Service
                                </Anchor>{" "}
                                |{" "}
                                <Anchor to="/privacy-policy" size="xsmall">
                                    Privacy Policy
                                </Anchor>
                                |{" "}
                                <Anchor to="/contact-us" size="xsmall">
                                    Contact Us
                                </Anchor>
                            </div>
                            <Paragraph size="xsmall" type="white">
                                &copy; {DateTime.now().toFormat("yyyy")}
                            </Paragraph>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default OnboardingLayout;
