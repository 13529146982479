// import "why-did-you-render"; // Must be first import per docs. https://github.com/welldone-software/why-did-you-render

import "babel-polyfill";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import App from "./app";

/*
---------------------------------------------------------------------------------------------
Application setup
---------------------------------------------------------------------------------------------
*/

ReactDOM.render(<App />, document.getElementById("root"));
