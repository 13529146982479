import { CheckCircleIcon, KeyIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Error } from "atoms/forms/error";
import { PasswordInput } from "atoms/forms/password-input";
import { PasswordStrength } from "atoms/password-strength/password-strength";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { ResetPassword } from "interfaces/reset-password";
import OnboardingLayout from "layouts/onboarding-layout/onboarding-layout";
import { Loader } from "molecules/loader/loader";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AccountService from "services/account-service";
import StringUtils from "utilities/string-utils";
import "./reset-password-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-reset-password";

const ResetPasswordPage: React.FC<any> = (props: any) => {
    const searchParams = StringUtils.parseQueryString(window.location.search);
    const [error, setError] = useState<string>();
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>();
    const [isPasswordError, setIsPasswordError] = useState<boolean>();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [resetPassword, setResetPassword] = useState<ResetPassword>({
        code: searchParams?.code || "",
        email: searchParams?.email || "",
        confirmPassword: "",
        password: "",
    });
    const history = useHistory();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            resetPassword.code.length === 0 ||
            resetPassword.email?.length === 0
        ) {
            return;
        }

        let isErrored = false;

        setIsPasswordError(undefined);
        setConfirmPasswordError(undefined);
        setError(undefined);

        if (!StringUtils.isValidPassword(resetPassword.password)) {
            setIsPasswordError(true);
            isErrored = true;
        }
        if (resetPassword.password !== resetPassword.confirmPassword) {
            setConfirmPasswordError("Confirm Password does not match");
            isErrored = true;
        }
        if (!resetPassword.email || !resetPassword.password) {
            setError("All fields are required");
            setIsLoading(false);
            return;
        }

        if (isErrored) {
            return;
        }

        setIsLoading(true);

        AccountService.resetPassword(
            resetPassword.email,
            resetPassword.code,
            resetPassword.password
        ).then(
            (result) => {
                if (result && result.status === 200) {
                    setIsSubmitted(true);
                    setIsLoading(false);
                }
            },
            (error: any) => {
                if (error.status === 400) {
                    setError(
                        "Invalid reset token. Please go to request reset and enter your email again"
                    );
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    return (
        <div>
            <Loader isVisible={isLoading} />
            <OnboardingLayout>
                <div className={COMPONENT_CLASS}>
                    {!isSubmitted && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <KeyIcon />
                                <Heading size="h2">
                                    Set your new password.
                                </Heading>
                                <Paragraph size="medium">
                                    Your new password must be different than
                                    previously used passwords.
                                </Paragraph>
                            </div>
                            {(resetPassword.code.length === 0 ||
                                resetPassword.email?.length === 0) && (
                                <Error message="Invalid reset URL. Please check the URL and try again. If the problem persists, please request a new password reset email." />
                            )}
                            <form
                                className={`${COMPONENT_CLASS}__form ${
                                    isSubmitted ? "submitted" : ""
                                }`}
                                onSubmit={handleSubmit}>
                                <PasswordInput
                                    label="Password"
                                    isErrored={isPasswordError}
                                    placeholder="Password"
                                    value={resetPassword.password}
                                    onChange={(password) => {
                                        setIsPasswordError(undefined);
                                        setResetPassword(
                                            Object.assign({}, resetPassword, {
                                                password: password,
                                            })
                                        );
                                    }}
                                />
                                <PasswordStrength
                                    password={resetPassword.password || ""}
                                />
                                <PasswordInput
                                    errorMessage={confirmPasswordError}
                                    label="Confirm Password"
                                    placeholder="Confirm Password"
                                    value={resetPassword.confirmPassword}
                                    onChange={(password) => {
                                        setConfirmPasswordError(undefined);
                                        setResetPassword(
                                            Object.assign({}, resetPassword, {
                                                confirmPassword: password,
                                            })
                                        );
                                    }}
                                />
                                {error && <Error message={error} />}
                                <div
                                    className={`${COMPONENT_CLASS}__form__actions`}>
                                    <Button isSubmit={true} size="large">
                                        Reset Password
                                    </Button>
                                </div>
                                <div
                                    className={`${COMPONENT_CLASS}__form__actions submitted`}>
                                    <Button
                                        isSubmit={true}
                                        size="large"
                                        onClick={() =>
                                            history.push("/sign-in")
                                        }>
                                        Continue to Sign In
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                    {isSubmitted && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <CheckCircleIcon />
                                <Heading size="h2">Password reset.</Heading>
                                <Paragraph size="medium">
                                    Your password has been successfully reset.
                                </Paragraph>
                            </div>
                            <div className={`${COMPONENT_CLASS}__form`}>
                                <div
                                    className={`${COMPONENT_CLASS}__form__actions`}>
                                    <Button
                                        isSubmit={true}
                                        size="large"
                                        onClick={() =>
                                            history.push("/sign-in")
                                        }>
                                        Continue To Sign In
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </OnboardingLayout>
        </div>
    );
};

export default ResetPasswordPage;
