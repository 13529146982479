import { Dialog, Transition } from "@headlessui/react";
import { Button } from "atoms/buttons/button";
import React, { Fragment, useState } from "react";
import { Error } from "atoms/forms/error";
import { XIcon } from "@heroicons/react/outline";
import {
    BankAccountType,
    PaymentType,
    Purchase,
} from "models/interfaces/purchase";
import { Address, emptyAddress } from "models/interfaces/address";
import "./manage-payment-modal.scss";
import usePayment from "utilities/hooks/use-payment";
import { FormValidation } from "models/interfaces/form-validation";
import { Dropdown } from "atoms/forms/dropdown";
import { PaymentTypeOptions } from "models/payment-type-options";
import { Heading } from "atoms/typography/heading";
import { BankAccountTypeOptions } from "models/bank-account-type-options";
import { TextInput } from "atoms/forms/text-input";
import { StateOptions } from "models/state-options";
import PaymentMethod from "models/payment-method";
import { Loader } from "molecules/loader/loader";

const COMPONENT_CLASS = "c-manage-payment-modal";
const FORM_CLASS = "c-member-form";

interface ManagePaymentModalProps {
    isOpen: boolean;
    onClose: (purchase?: PaymentMethod) => void;
    memberOrderId: string;
    paymentMethod: PaymentMethod;
}

const getPurchase = (paymentMethod: PaymentMethod): Purchase => {
    const { name, paymentType } = paymentMethod;

    return {
        accountNumber: "",
        accountNumberConfirmation: "",
        bankAccountType: BankAccountType.Checking,
        cardNumber: "",
        code: "",
        expirationDate: "",
        firstName: name.split(" ")[0],
        lastName: name.split(" ")[1],
        name,
        routingNumber: "",
        paymentType,
    };
};

const getAddress = (paymentMethod: PaymentMethod): Address => {
    if (paymentMethod.paymentType === PaymentType.CreditCard) {
        return {
            line1: paymentMethod.address,
            line2: paymentMethod.address2 ?? "",
            city: paymentMethod.city,
            state: paymentMethod.state,
            zipCode: paymentMethod.zip,
        };
    }

    return emptyAddress();
};

const ManagePaymentModal: React.FC<ManagePaymentModalProps> = ({
    isOpen,
    onClose,
    memberOrderId,
    paymentMethod,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [address, setAddress] = useState<Address>(getAddress(paymentMethod));
    const [purchase, setPurchase] = useState<Purchase>(
        getPurchase(paymentMethod)
    );
    const [fieldValidation, setFieldValidation] = useState<FormValidation[]>();
    const { updatePayment, validatePurchase } = usePayment();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        setIsSaving(true);

        const validationErrors = validatePurchase(purchase, address);
        setFieldValidation(validationErrors);
        setErrorMessage("");

        if (validationErrors.length > 0) {
            setIsSaving(false);
            return;
        }

        const { response, errorMessage, fieldValidation } = await updatePayment(
            memberOrderId,
            purchase,
            address
        );

        setIsSaving(false);

        if (response != null) {
            handleClose(response.data.paymentMethod);
            return;
        }

        setErrorMessage(errorMessage ?? "");
        setFieldValidation(fieldValidation ?? []);
    };

    const isACH = purchase.paymentType === PaymentType.ACH;
    const isCreditCard = purchase.paymentType === PaymentType.CreditCard;

    const handleClose = (purchase?: PaymentMethod) => {
        onClose(purchase);
        setFieldValidation([]);
        setIsSaving(false);
        setErrorMessage(undefined);
        setPurchase(getPurchase(paymentMethod));
        setAddress(getAddress(paymentMethod));
    };

    const handleFieldChange = (
        field: string,
        updateAction: (value: string) => void
    ) => {
        return (value: string) => {
            updateAction(value);
            if (fieldValidation?.find((v) => v.field === field)) {
                setFieldValidation([
                    ...fieldValidation.filter((v) => v.field !== field),
                ]);
            }
        };
    };

    const handlePurchaseFieldChange = (
        field: string,
        update: (value: string) => Partial<Purchase>
    ) => {
        return handleFieldChange(field, (value: string) => {
            setPurchase((prev) => ({
                ...prev,
                ...update(value),
            }));
        });
    };

    const handleAddressFieldChange = (
        field: string,
        update: (value: string) => Partial<Address>
    ) => {
        return handleFieldChange(field, (value: string) => {
            setAddress((prev) => ({
                ...prev,
                ...update(value),
            }));
        });
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={COMPONENT_CLASS}
                onClose={() => handleClose()}>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay
                            className={`${COMPONENT_CLASS}__overlay`}
                        />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div className={`${COMPONENT_CLASS}__wrapper`}>
                            <Loader isVisible={isSaving} />
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <Button
                                    type="plain"
                                    onClick={() => handleClose()}>
                                    <XIcon /> Close
                                </Button>
                            </div>
                            <div className="flex flex-col gap-6">
                                <div className={FORM_CLASS}>
                                    <form onSubmit={handleSubmit}>
                                        <div
                                            className={`${FORM_CLASS}__section`}>
                                            <Heading size="h3">
                                                Manage Payment
                                            </Heading>
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper double border-b border-grey-200 pt-6 pb-10`}>
                                                <Dropdown
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={true}
                                                    name="PaymentType"
                                                    label="Payment Type"
                                                    options={PaymentTypeOptions}
                                                    onChange={handlePurchaseFieldChange(
                                                        "PaymentType",
                                                        (value: string) => ({
                                                            paymentType:
                                                                Number(value),
                                                        })
                                                    )}
                                                    value={purchase.paymentType.toString()}
                                                />
                                            </div>
                                            <Heading size="h3">
                                                Basic Information
                                            </Heading>

                                            {purchase.paymentType ===
                                                PaymentType.ACH && (
                                                <>
                                                    <div
                                                        className={`${FORM_CLASS}__section__wrapper double`}>
                                                        <Dropdown
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={isACH}
                                                            isSearchable={true}
                                                            name="AccountType"
                                                            label="Account Type"
                                                            placeholder="Select"
                                                            options={
                                                                BankAccountTypeOptions
                                                            }
                                                            onChange={handlePurchaseFieldChange(
                                                                "AccountType",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    bankAccountType:
                                                                        Number(
                                                                            value
                                                                        ),
                                                                })
                                                            )}
                                                            value={
                                                                purchase.bankAccountType?.toString() ??
                                                                ""
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`${FORM_CLASS}__section__wrapper double mb-4`}>
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={isACH}
                                                            maxLength={50}
                                                            name="FirstName"
                                                            label="First Name"
                                                            placeholder="First Name"
                                                            onChange={handlePurchaseFieldChange(
                                                                "FirstName",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    firstName:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.firstName
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={isACH}
                                                            maxLength={50}
                                                            name="LastName"
                                                            label="Last Name"
                                                            placeholder="Last Name"
                                                            onChange={handlePurchaseFieldChange(
                                                                "LastName",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    lastName:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.lastName
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={isACH}
                                                            maxLength={50}
                                                            name="BankAccountNumber"
                                                            label="Bank Account Number"
                                                            placeholder=" Account Number"
                                                            onChange={handlePurchaseFieldChange(
                                                                "BankAccountNumber",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    accountNumber:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.accountNumber
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={isACH}
                                                            maxLength={50}
                                                            name="BankAccountNumberConfirmation"
                                                            label="Confirm Account Number"
                                                            placeholder=" Account Number"
                                                            onChange={handlePurchaseFieldChange(
                                                                "BankAccountNumberConfirmation",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    accountNumberConfirmation:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.accountNumberConfirmation
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={isACH}
                                                            maxLength={50}
                                                            name="BankRoutingNumber"
                                                            label="Bank Routing Number"
                                                            placeholder="Routing Number"
                                                            onChange={handlePurchaseFieldChange(
                                                                "BankRoutingNumber",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    routingNumber:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.routingNumber
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}

                                            {purchase.paymentType ===
                                                PaymentType.CreditCard && (
                                                <>
                                                    <div
                                                        className={`${FORM_CLASS}__section__wrapper double mb-4`}>
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={50}
                                                            name="NameOnCard"
                                                            label="Name On Card"
                                                            placeholder="Name"
                                                            onChange={handlePurchaseFieldChange(
                                                                "NameOnCard",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    name: value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.name
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={75}
                                                            name="CardNumber"
                                                            label="Card Number"
                                                            placeholder="Card Number"
                                                            onChange={handlePurchaseFieldChange(
                                                                "CardNumber",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    cardNumber:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.cardNumber
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            displayFunction={(
                                                                args
                                                            ) => {
                                                                let result = "";
                                                                if (
                                                                    args.length >
                                                                    0
                                                                ) {
                                                                    result += `${args[0]}`;
                                                                }
                                                                if (
                                                                    args.length >
                                                                    1
                                                                ) {
                                                                    result += `/${args[1]}`;
                                                                }
                                                                return result;
                                                            }}
                                                            inputFormat={
                                                                /^(\d{0,2})\/?(\d{0,2})$/
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={5}
                                                            name="ExpirationDate"
                                                            label="Exp. Date"
                                                            placeholder="00/00"
                                                            onChange={handlePurchaseFieldChange(
                                                                "ExpirationDate",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    expirationDate:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.expirationDate
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={4}
                                                            name="CVV"
                                                            label="Security Code"
                                                            placeholder="Code"
                                                            onChange={handlePurchaseFieldChange(
                                                                "CVV",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    code: value,
                                                                })
                                                            )}
                                                            value={
                                                                purchase.code
                                                            }
                                                        />
                                                    </div>
                                                    <Heading size="h3">
                                                        Billing Address
                                                    </Heading>
                                                    <div
                                                        className={`${FORM_CLASS}__section__wrapper double`}>
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={100}
                                                            name="Address1"
                                                            label="Address 1"
                                                            placeholder="Address 1"
                                                            onChange={handleAddressFieldChange(
                                                                "Address1",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    line1: value,
                                                                })
                                                            )}
                                                            value={
                                                                address.line1
                                                            }
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            maxLength={100}
                                                            name="Address2"
                                                            label="Address 2"
                                                            placeholder="Address 2"
                                                            onChange={(value) =>
                                                                setAddress(
                                                                    (prev) => ({
                                                                        ...prev,
                                                                        line2: value,
                                                                    })
                                                                )
                                                            }
                                                            value={
                                                                address.line2
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`${FORM_CLASS}__section__wrapper triple`}>
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={100}
                                                            name="City"
                                                            label="City"
                                                            placeholder="City"
                                                            onChange={handleAddressFieldChange(
                                                                "City",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    city: value,
                                                                })
                                                            )}
                                                            value={address.city}
                                                        />
                                                        <TextInput
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            maxLength={15}
                                                            name="ZipCode"
                                                            label="Zip"
                                                            placeholder="00000"
                                                            onChange={handleAddressFieldChange(
                                                                "ZipCode",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    zipCode:
                                                                        value,
                                                                })
                                                            )}
                                                            value={
                                                                address.zipCode
                                                            }
                                                        />
                                                        <Dropdown
                                                            fieldValidation={
                                                                fieldValidation
                                                            }
                                                            isRequired={
                                                                isCreditCard
                                                            }
                                                            name="State"
                                                            isSearchable={true}
                                                            label="State"
                                                            placeholder="Select"
                                                            options={
                                                                StateOptions
                                                            }
                                                            onChange={handleAddressFieldChange(
                                                                "State",
                                                                (
                                                                    value: string
                                                                ) => ({
                                                                    state: value,
                                                                })
                                                            )}
                                                            value={address.state.toString()}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {errorMessage && (
                                            <div
                                                className={`${FORM_CLASS}__section`}>
                                                <Error
                                                    message={errorMessage}
                                                    type="form"
                                                />
                                            </div>
                                        )}
                                        <div
                                            className={`${FORM_CLASS}__section ${FORM_CLASS}__actions left ${COMPONENT_CLASS}__actions`}>
                                            <Button
                                                disabled={isSaving}
                                                type="primary"
                                                isSubmit={true}>
                                                Save Changes
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default ManagePaymentModal;
