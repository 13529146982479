import { LogoutIcon, CogIcon } from "@heroicons/react/outline";
import { Label } from "atoms/typography/label";
import Footer from "molecules/footer/footer";
import { Loader } from "molecules/loader/loader";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "utilities/contexts/auth-state-context";
import "./dashboard-layout.scss";

const COMPONENT_CLASS = "c-dashboard-layout";

interface DashboardLayoutProps {
    coloredBackground?: boolean;
    header?: React.ReactNode;
    children: React.ReactNode;
    windowRef?: any;
    showSettingsBtn?: boolean;
    showPageLoader?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = (
    props: DashboardLayoutProps
) => {
    const history = useHistory();

    const { showSettingsBtn = true, showPageLoader = false } = props;

    const { settings, signOut, isLoading, isAuthenticated, isAuthorizing } =
        useAuth({ runVerify: true });

    const handleSignOut = (e: any) => {
        //e.preventDefault();
        // e.stopPropagation();
        signOut();
    };
    if (!isAuthorizing && !isAuthenticated) {
        history.push("/sign-in");
    }

    return (
        <div
            className={`${COMPONENT_CLASS} ${
                props.coloredBackground ? "colored" : ""
            }`}>
            <div className={`${COMPONENT_CLASS}__window`} ref={props.windowRef}>
                <Loader isVisible={isLoading || showPageLoader} />
                <div className={`${COMPONENT_CLASS}__container__header`}>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        <div
                            className={`${COMPONENT_CLASS}__container__header__content`}>
                            {settings?.siteLogo && (
                                <img
                                    alt={`${settings?.siteName} Logo`}
                                    src={settings?.siteLogo}
                                />
                            )}
                            <div
                                className={`${COMPONENT_CLASS}__container__actions`}>
                                {showSettingsBtn && (
                                    <Link
                                        className={`${COMPONENT_CLASS}__container__actions__logout`}
                                        to="/account-settings">
                                        <CogIcon />
                                        <Label>Settings</Label>
                                    </Link>
                                )}
                                <Link
                                    className={`${COMPONENT_CLASS}__container__actions__logout`}
                                    to="/sign-out"
                                    onClick={handleSignOut}>
                                    <LogoutIcon />
                                    <Label>Sign Out</Label>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {props.header && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <div
                                className={`${COMPONENT_CLASS}__container__header__content`}>
                                {props.header}
                            </div>
                        </div>
                    )}
                </div>
                <main className={`${COMPONENT_CLASS}__content`}>
                    <div className={`${COMPONENT_CLASS}__container`}>
                        {props.children}
                        <Footer coloredBackground={props.coloredBackground} />
                    </div>
                </main>
            </div>
        </div>
    );
};

export default DashboardLayout;
