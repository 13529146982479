import React from "react";
import "./label.scss";

const BASE_CLASS_NAME = "c-label";

interface LabelProps {
    children: React.ReactNode;
    isRequired?: boolean;
    size?: "medium" | "large";
    type?: "primary" | "light";
}

/**
 * Primary UI component
 */
export const Label = ({
    children,
    isRequired = false,
    size = "medium",
    type = "primary",
    ...props
}: LabelProps) => {
    return (
        <label
            className={`${BASE_CLASS_NAME} ${[size, type].join(" ")}`}
            {...props}>
            {children}
            {isRequired && (
                <span className={`${BASE_CLASS_NAME}__required`}>*</span>
            )}
        </label>
    );
};
