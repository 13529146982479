import { DateTime } from "luxon";
import { FormatDateType } from "models/enumerations/format-date-type";

const formatCurrency = (value: number): string =>
    value.toLocaleString("en-US", { style: "currency", currency: "USD" });

const formatText = (format: string, args: string[]) => {
    return format.replace(/{(\d+)}/g, (match, number) =>
        typeof args[number] != "undefined" ? args[number] : ""
    );
};

const formatDate = (date: Date | string, type?: FormatDateType) => {
    const d =
        date instanceof Date
            ? date
            : DateTime.fromISO(
                  type === FormatDateType.DateOnly
                      ? date.substring(0, 10)
                      : date
              ).toJSDate();

    const year = d.getFullYear();
    const month = (1 + d.getMonth()).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
};

/**
 * Formats an array of strings to a list format
 *
 * @param array of strings
 */

const formatToList = (array: Array<string>): string => {
    if (array.length === 0) {
        return "";
    }

    if (array.length === 1) {
        return array[0];
    }

    if (array.length === 2) {
        return array.join(" and ");
    }

    const copy = [...array];

    const last = copy.pop();

    const list = copy.join(", ");

    return `${list}, and ${last}`;
};

const isEmpty = (value?: string): value is undefined => {
    if (value == null || value.length === 0) {
        return true;
    }
    return false;
};

const hasValue = (value?: string): value is string => !isEmpty(value);

/**
 * Compare equality of two strings, with the option of case-insensitivity.
 *
 * @param {string} left
 * @param {string} right
 * @param {boolean} [caseInsensitive=true] If set to true, casing is ignored, ie: "STRING" would equal "sTrInG"
 * @reference https://stackoverflow.com/a/2140723
 */
const isEqual = (
    left: string,
    right: string,
    caseInsensitive: boolean = true
): boolean => {
    if (!caseInsensitive) {
        return left === right;
    }

    // Compare the two strings without taking into account casing. Variants of the same base character
    // are the same unless they have different accents, ie: isEqual("a", "á") returns false
    return (
        left.localeCompare(right, undefined, { sensitivity: "accent" }) === 0
    );
};

/**
 * Validates a given string is at least 6 characters long and meets 3 of the following:
 * 1 uppercase letter
 * 1 lowercase letter
 * 1 number
 * 1 special character
 * @param value
 */
const isValidPassword = (value?: string): boolean => {
    if (isEmpty(value)) {
        return false;
    }

    const lengthValid = value!.length >= 8;
    const validMatches = [];

    // Validate lowercase characters
    validMatches.push(/[a-z]/.test(value!));

    // Validate uppercase characters
    validMatches.push(/[A-Z]/.test(value!));

    // Validate numbers
    validMatches.push(/[0-9]/.test(value!));

    // Validate special characters
    validMatches.push(/[^\w\s]/.test(value!));

    const containValid = validMatches.filter((m) => m).length >= 4;

    return lengthValid && containValid;
};

/**
 * Validates that a string is a valid URL.
 * Validates that the URL contains a scheme/protocol (i.e. "https://" or "http://")
 * and that it does not contain any whitespace characters.
 * @param value
 * @param allowedProtocols
 */
const isValidUrl = (
    value?: string,
    allowedProtocols: Array<string> = ["http", "https"]
): boolean => {
    if (isEmpty(value)) {
        return false;
    }

    const pattern = new RegExp(`^(${allowedProtocols.join("|")})://[^ "]+$`);
    return pattern.test(value!);
};

const parseQueryString = (querystring: string) => {
    // parse query string
    const params = new URLSearchParams(querystring);
    const obj: any = {};

    params.forEach((value, key) => {
        obj[key] = value;
    });

    return obj;
};

const isEmail = (email: string) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// #endregion Functions

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const StringUtils = {
    formatCurrency,
    formatText,
    formatDate,
    formatToList,
    hasValue,
    isEmpty,
    isEqual,
    isValidPassword,
    isValidUrl,
    parseQueryString,
    isEmail,
};

export default StringUtils;

// #endregion Exports
