import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import { DateTime } from "luxon";
import { MemberRelationship } from "models/enumerations/member-relationship";
import Member from "models/interfaces/member";
import MemberForm from "organisms/member-form/member-form";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountService from "services/account-service";
import MemberService from "services/member-service";
import { useAuth } from "utilities/contexts/auth-state-context";
import StringUtils from "utilities/string-utils";
import "./onboarding-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-onboarding-page";

const OnboardingPage: React.FC = () => {
    const [error, setError] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);
    const { isOnboarded } = useAuth();
    const history = useHistory();

    useEffect(() => {
        const checkMembers = async () => {
            try {
                const idToken = await CognitoUtils.getIdToken();
                const allMembers = await MemberService.getMembers(idToken);

                if (
                    allMembers?.data != null &&
                    allMembers.data.filter(
                        (m) => m.relationship === MemberRelationship.Self
                    ).length > 0
                ) {
                    if (!isOnboarded) {
                        await AccountService.completeOnboarding(idToken);
                    }
                    history.push("/");
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Error checking members:", error);
            }
        };

        checkMembers();
    }, [history, isOnboarded]);

    const handleSubmit = async (member: Member) => {
        if (StringUtils.hasValue(member.dob)) {
            member.dob = DateTime.fromFormat(
                member.dob!,
                "MM/dd/yyyy"
            ).toISODate();
        }
        const idToken = await CognitoUtils.getIdToken();

        MemberService.createMember(member, idToken).then(
            (memberResult: any) => {
                if (memberResult.status !== 200) {
                    setError(
                        "There was an error setting up your account. Please try again."
                    );
                    return;
                }

                AccountService.completeOnboarding(idToken).then(
                    (accountResult) => {
                        setIsLoading(false);
                        history.push("/onboarding/household");
                    },
                    (error: any) => {
                        if (error.status === 401) {
                            setError("Invalid request. Please try again.");
                        } else {
                            setError(
                                error.data?.exceptionMessage ||
                                    error.data?.message ||
                                    error.statusText
                            );
                        }
                        setIsLoading(false);
                    }
                );
            },
            (error: any) => {
                if (error.status === 401) {
                    setError("Invalid request. Please try again.");
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    return (
        <div>
            <DashboardLayout
                coloredBackground={true}
                showPageLoader={isLoading}>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <Heading size="h2">Welcome to HCN!</Heading>
                        <Paragraph size="medium">
                            Your account was successfully created. To get
                            started, let's build out your profile.
                        </Paragraph>
                    </div>
                    <MemberForm
                        error={error}
                        isEditing={true}
                        isSelf={true}
                        onSubmit={handleSubmit}
                        showAuthorizedCheck={false}
                    />
                </div>
            </DashboardLayout>
        </div>
    );
};

export default OnboardingPage;
