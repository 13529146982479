import { Button } from "atoms/buttons/button";
import { Dropdown } from "atoms/forms/dropdown";
import { Error } from "atoms/forms/error";
import { TextInput } from "atoms/forms/text-input";
import PaymentDetailsModal from "organisms/payment-details-modal/payment-details-modal";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { DateTime } from "luxon";
import { Address, emptyAddress } from "models/interfaces/address";
import { FormValidation } from "models/interfaces/form-validation";
import {
    emptyPurchase,
    PaymentType,
    Purchase,
} from "models/interfaces/purchase";
import { StateOptions } from "models/state-options";
import { PersonalizeProgress } from "molecules/personalize-progress/personalize-progress";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StringUtils from "utilities/string-utils";
import "./pay-page.scss";
import usePayment from "../../utilities/hooks/use-payment";
import useCart from "utilities/hooks/use-cart";
import { PaymentTypeOptions } from "models/payment-type-options";
import { BankAccountTypeOptions } from "models/bank-account-type-options";
import ProcedureBanner, {
    ProcedureBannerType,
} from "molecules/procedure-banner/procedure-banner";
import usePriceDetails from "utilities/hooks/use-price-details";

const COMPONENT_CLASS = "c-payment";
const FORM_CLASS = "c-member-form";

function PaymentPage() {
    const [address, setAddress] = useState<Address>(emptyAddress());
    const [error, setError] = useState<string>();
    const [fieldValidation, setFieldValidation] = useState<FormValidation[]>();
    const [isExpired, setIsExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [purchase, setPurchase] = useState<Purchase>(emptyPurchase());
    const [personalPrice, setPersonalPrice] = useState<number>();
    const history = useHistory();
    const {
        cart,
        clearPaymentTerms,
        hasInsurance,
        member,
        paymentTerms: terms,
        subtotal,
    } = useCart();

    const priceDetails = usePriceDetails({
        cart,
        hasInsurance,
        grossCharges: subtotal,
    });

    const [isPaymentDetailsModalOpen, setIsPaymentDetailsModalOpen] =
        useState(false);
    const {
        loading: isPurchaseLoading,
        validatePurchase,
        completePurchase,
    } = usePayment();

    useEffect(() => {
        if (!cart) {
            return;
        }

        setPersonalPrice(cart?.personalizedPriceTotal);
        if (cart?.expiresOn) {
            setIsExpired(
                Math.ceil(
                    DateTime.now()
                        .diff(DateTime.fromISO(cart.expiresOn), "days")
                        .toObject().days || 0
                ) > 0
            );
        }

        setIsLoading(false);
    }, [cart]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const validationErrors = validatePurchase(purchase, address);
        setFieldValidation(validationErrors);
        if (validationErrors.length > 0) {
            return;
        }

        const { response, errorMessage, fieldValidation } =
            await completePurchase(purchase, address);

        if (errorMessage != null) {
            setError(errorMessage);
            setFieldValidation(fieldValidation);
        } else if (response?.status === 200 && cart != null) {
            clearPaymentTerms();
            history.push(`/complete?referenceNumber=${cart.referenceNumber}`);
        } else {
            setError("An unknown error has occurred. Please try again.");
        }
    };

    const formattedPrice = StringUtils.formatCurrency(personalPrice ?? 0);

    const totalDueToday = !terms
        ? StringUtils.formatCurrency(priceDetails.payInFullAmount)
        : StringUtils.formatCurrency((personalPrice || 0) / terms);

    const isACH = purchase.paymentType === PaymentType.ACH;
    const isCreditCard = purchase.paymentType === PaymentType.CreditCard;

    const onFieldChange = (
        field: string,
        updateAction: (value: string) => void
    ) => {
        return (value: string) => {
            updateAction(value);
            if (fieldValidation?.find((v) => v.field === field)) {
                setFieldValidation([
                    ...fieldValidation.filter((v) => v.field !== field),
                ]);
            }
        };
    };

    const onPurchaseFieldChange = (
        field: string,
        update: (value: string) => Partial<Purchase>
    ) => {
        return onFieldChange(field, (value: string) => {
            setPurchase((prev) => ({
                ...prev,
                ...update(value),
            }));
        });
    };

    const onAddressFieldChange = (
        field: string,
        update: (value: string) => Partial<Address>
    ) => {
        return onFieldChange(field, (value: string) => {
            setAddress((prev) => ({
                ...prev,
                ...update(value),
            }));
        });
    };

    return (
        <div>
            <CartLayout
                isLocked={true}
                header={
                    <PersonalizeProgress
                        title="Your Price"
                        currentStep={-1}
                        showProgress={false}
                    />
                }
                showLoader={isLoading}>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        {cart != null && member != null && (
                            <ProcedureBanner
                                completedOn={cart.completedOn}
                                expiresOn={cart.expiresOn}
                                cartId={cart.id}
                                member={member}
                                displayType={
                                    isExpired
                                        ? ProcedureBannerType.Expired
                                        : ProcedureBannerType.Decline
                                }
                            />
                        )}
                        <Heading size="h2">Your Price &amp; Payment</Heading>
                        <Paragraph>
                            Please enter your payment details. All information
                            is stored safely and securely.
                        </Paragraph>
                        <div className={`${COMPONENT_CLASS}__section primary`}>
                            <div
                                className={`${COMPONENT_CLASS}__section__summary`}>
                                <div
                                    className={`${COMPONENT_CLASS}__section__summary__payment-details`}>
                                    <div>
                                        <Heading size="h4">You Pay</Heading>
                                        <Paragraph
                                            cssClassName={`${COMPONENT_CLASS}__amount`}
                                            size="xlarge"
                                            type="white">
                                            {formattedPrice}
                                        </Paragraph>
                                    </div>
                                    <Button
                                        size="small"
                                        type="plain"
                                        onClick={() =>
                                            setIsPaymentDetailsModalOpen(true)
                                        }>
                                        See Details
                                    </Button>
                                </div>
                                {!terms ? (
                                    <PaymentTypeCard
                                        payInFullDiscount={
                                            priceDetails.payInFullDiscount
                                        }
                                        onChangeSelected={history.goBack}
                                        title="Pay Now">
                                        Pay online with your credit card or bank
                                        account and receive a pre-appointment
                                        discount.
                                    </PaymentTypeCard>
                                ) : (
                                    <PaymentTypeCard
                                        onChangeSelected={history.goBack}
                                        title="Care Now, Pay Later">
                                        <Paragraph size="small">
                                            Term Length: {terms} Months
                                        </Paragraph>
                                        <Paragraph size="small">
                                            Est. Per Month:
                                            {StringUtils.formatCurrency(
                                                (personalPrice || 0) / terms
                                            )}
                                        </Paragraph>
                                        <Paragraph size="small">
                                            Interest: 0%
                                        </Paragraph>
                                        <Paragraph size="small">
                                            Start Date: Today (
                                            {DateTime.now().toLocaleString()})
                                        </Paragraph>
                                    </PaymentTypeCard>
                                )}
                            </div>
                        </div>
                        <div className={FORM_CLASS}>
                            <form onSubmit={handleSubmit}>
                                <div className={`${FORM_CLASS}__section`}>
                                    <div
                                        className={`${FORM_CLASS}__section__wrapper double border-t border-b border-grey-200 pt-6 pb-10`}>
                                        <Dropdown
                                            fieldValidation={fieldValidation}
                                            isRequired={true}
                                            name="PaymentType"
                                            label="Payment Type"
                                            options={PaymentTypeOptions}
                                            onChange={onPurchaseFieldChange(
                                                "PaymentType",
                                                (value: string) => ({
                                                    paymentType: Number(value),
                                                })
                                            )}
                                            value={purchase.paymentType.toString()}
                                        />
                                    </div>
                                    <Heading size="h3">
                                        Basic Information
                                    </Heading>

                                    {purchase.paymentType ===
                                        PaymentType.ACH && (
                                        <>
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper double`}>
                                                <Dropdown
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isACH}
                                                    isSearchable={true}
                                                    name="AccountType"
                                                    label="Account Type"
                                                    placeholder="Select"
                                                    options={
                                                        BankAccountTypeOptions
                                                    }
                                                    onChange={onPurchaseFieldChange(
                                                        "AccountType",
                                                        (value: string) => ({
                                                            bankAccountType:
                                                                Number(value),
                                                        })
                                                    )}
                                                    value={
                                                        purchase.bankAccountType?.toString() ??
                                                        ""
                                                    }
                                                />
                                            </div>
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper double mb-4`}>
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isACH}
                                                    maxLength={50}
                                                    name="FirstName"
                                                    label="First Name"
                                                    placeholder="First Name"
                                                    onChange={onPurchaseFieldChange(
                                                        "FirstName",
                                                        (value: string) => ({
                                                            firstName: value,
                                                        })
                                                    )}
                                                    value={purchase.firstName}
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isACH}
                                                    maxLength={50}
                                                    name="LastName"
                                                    label="Last Name"
                                                    placeholder="Last Name"
                                                    onChange={onPurchaseFieldChange(
                                                        "LastName",
                                                        (value: string) => ({
                                                            lastName: value,
                                                        })
                                                    )}
                                                    value={purchase.lastName}
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isACH}
                                                    maxLength={50}
                                                    name="BankAccountNumber"
                                                    label="Bank Account Number"
                                                    placeholder=" Account Number"
                                                    onChange={onPurchaseFieldChange(
                                                        "BankAccountNumber",
                                                        (value: string) => ({
                                                            accountNumber:
                                                                value,
                                                        })
                                                    )}
                                                    value={
                                                        purchase.accountNumber
                                                    }
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isACH}
                                                    maxLength={50}
                                                    name="BankAccountNumberConfirmation"
                                                    label="Confirm Account Number"
                                                    placeholder=" Account Number"
                                                    onChange={onPurchaseFieldChange(
                                                        "BankAccountNumberConfirmation",
                                                        (value: string) => ({
                                                            accountNumberConfirmation:
                                                                value,
                                                        })
                                                    )}
                                                    value={
                                                        purchase.accountNumberConfirmation
                                                    }
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isACH}
                                                    maxLength={50}
                                                    name="BankRoutingNumber"
                                                    label="Bank Routing Number"
                                                    placeholder="Routing Number"
                                                    onChange={onPurchaseFieldChange(
                                                        "BankRoutingNumber",
                                                        (value: string) => ({
                                                            routingNumber:
                                                                value,
                                                        })
                                                    )}
                                                    value={
                                                        purchase.routingNumber
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}

                                    {purchase.paymentType ===
                                        PaymentType.CreditCard && (
                                        <>
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper double mb-4`}>
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={50}
                                                    name="NameOnCard"
                                                    label="Name On Card"
                                                    placeholder="Name"
                                                    onChange={onPurchaseFieldChange(
                                                        "NameOnCard",
                                                        (value: string) => ({
                                                            name: value,
                                                        })
                                                    )}
                                                    value={purchase.name}
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={75}
                                                    name="CardNumber"
                                                    label="Card Number"
                                                    placeholder="Card Number"
                                                    onChange={onPurchaseFieldChange(
                                                        "CardNumber",
                                                        (value: string) => ({
                                                            cardNumber: value,
                                                        })
                                                    )}
                                                    value={purchase.cardNumber}
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    displayFunction={(args) => {
                                                        let result = "";
                                                        if (args.length > 0) {
                                                            result += `${args[0]}`;
                                                        }
                                                        if (args.length > 1) {
                                                            result += `/${args[1]}`;
                                                        }
                                                        return result;
                                                    }}
                                                    inputFormat={
                                                        /^(\d{0,2})\/?(\d{0,2})$/
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={5}
                                                    name="ExpirationDate"
                                                    label="Exp. Date"
                                                    placeholder="00/00"
                                                    onChange={onPurchaseFieldChange(
                                                        "ExpirationDate",
                                                        (value: string) => ({
                                                            expirationDate:
                                                                value,
                                                        })
                                                    )}
                                                    value={
                                                        purchase.expirationDate
                                                    }
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={4}
                                                    name="CVV"
                                                    label="Security Code"
                                                    placeholder="Code"
                                                    onChange={onPurchaseFieldChange(
                                                        "CVV",
                                                        (value: string) => ({
                                                            code: value,
                                                        })
                                                    )}
                                                    value={purchase.code}
                                                />
                                            </div>
                                            <Heading size="h3">
                                                Billing Address
                                            </Heading>
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper double`}>
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={100}
                                                    name="Address1"
                                                    label="Address 1"
                                                    placeholder="Address 1"
                                                    onChange={onAddressFieldChange(
                                                        "Address1",
                                                        (value: string) => ({
                                                            line1: value,
                                                        })
                                                    )}
                                                    value={address.line1}
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    maxLength={100}
                                                    name="Address2"
                                                    label="Address 2"
                                                    placeholder="Address 2"
                                                    onChange={(value) =>
                                                        setAddress((prev) => ({
                                                            ...prev,
                                                            line2: value,
                                                        }))
                                                    }
                                                    value={address.line2}
                                                />
                                            </div>
                                            <div
                                                className={`${FORM_CLASS}__section__wrapper triple`}>
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={100}
                                                    name="City"
                                                    label="City"
                                                    placeholder="City"
                                                    onChange={onAddressFieldChange(
                                                        "City",
                                                        (value: string) => ({
                                                            city: value,
                                                        })
                                                    )}
                                                    value={address.city}
                                                />
                                                <TextInput
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    maxLength={15}
                                                    name="ZipCode"
                                                    label="Zip"
                                                    placeholder="00000"
                                                    onChange={onAddressFieldChange(
                                                        "ZipCode",
                                                        (value: string) => ({
                                                            zipCode: value,
                                                        })
                                                    )}
                                                    value={address.zipCode}
                                                />
                                                <Dropdown
                                                    fieldValidation={
                                                        fieldValidation
                                                    }
                                                    isRequired={isCreditCard}
                                                    name="State"
                                                    isSearchable={true}
                                                    label="State"
                                                    placeholder="Select"
                                                    options={StateOptions}
                                                    onChange={onAddressFieldChange(
                                                        "State",
                                                        (value: string) => ({
                                                            state: value,
                                                        })
                                                    )}
                                                    value={address.state.toString()}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                {error && (
                                    <div className={`${FORM_CLASS}__section`}>
                                        <Error message={error} type="form" />
                                    </div>
                                )}
                                <div
                                    className={`${FORM_CLASS}__section ${FORM_CLASS}__actions right`}>
                                    <Button
                                        disabled={isPurchaseLoading}
                                        isSubmit={true}
                                        cssClassName={`${FORM_CLASS}__actions__total`}>
                                        <div className="flex items-center justify-between space-x-4">
                                            <span>Total due today</span>
                                            <span className="font-serif">
                                                {totalDueToday}
                                            </span>
                                            <span className="rounded-full bg-white py-[3px] px-3 text-primary-700">
                                                Pay
                                            </span>
                                        </div>
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </CartLayout>
            <PaymentDetailsModal
                isOpen={isPaymentDetailsModalOpen}
                onClose={() => setIsPaymentDetailsModalOpen(false)}
                cart={cart}
                hasInsurance={hasInsurance}
                grossCharges={subtotal}
            />
        </div>
    );
}

interface PaymentTypeCardProps {
    payInFullDiscount?: number;
    onChangeSelected: () => void;
    title: string;
}

const PaymentTypeCard: React.FC<PaymentTypeCardProps> = ({
    children,
    payInFullDiscount,
    onChangeSelected,
    title,
}) => {
    const hasPayInFullDiscount =
        payInFullDiscount != null && payInFullDiscount > 0;

    return (
        <div className={`${COMPONENT_CLASS}__section__summary__item between`}>
            <div className="flex h-full flex-row gap-x-10">
                <div className="flex h-full flex-grow flex-col gap-y-2">
                    <div className="flex-grow justify-self-start">
                        <Heading size="h4">{title}</Heading>
                        <Paragraph size="small">{children}</Paragraph>
                    </div>

                    {hasPayInFullDiscount && (
                        <div className="justify-self-end">
                            <Paragraph size="xsmall" weight="semibold">
                                SAVE{" "}
                                {StringUtils.formatCurrency(
                                    payInFullDiscount || 0
                                )}
                            </Paragraph>
                        </div>
                    )}
                </div>
                <div className="none flex flex-col">
                    <Button onClick={onChangeSelected}>Selected</Button>
                    <Button
                        type="plain"
                        cssClassName="underline"
                        onClick={onChangeSelected}>
                        Change
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;
