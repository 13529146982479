import MemberCart from "models/interfaces/member-cart";
import NumberUtils from "utilities/number-utils";

interface UsePriceDetailsOptions {
    cart?: MemberCart;
    hasInsurance?: boolean;
    grossCharges?: number;
}

const usePriceDetails = ({
    cart,
    hasInsurance,
    grossCharges = 0,
}: UsePriceDetailsOptions) => {
    const personalizedPriceTotal = cart?.personalizedPriceTotal ?? 0;
    const amountCharged = grossCharges ?? 0;
    const fee = cart?.priceJob?.priceJobEstimate?.fee ?? 0;
    const copay = cart?.priceJob?.priceJobEstimate?.copay ?? 0;
    const oop = cart?.priceJob?.priceJobEstimate?.oop ?? 0;
    const coinsurance = cart?.priceJob?.priceJobEstimate?.coinsurance ?? 0;
    const deductible1 = fee;
    const deductible2 = cart?.priceJob?.priceJobEstimate?.deductible ?? 0;
    const deductibleAmount =
        deductible1 >= deductible2 ? deductible2 : deductible1;
    const afaDiscount =
        cart?.priceJob?.discountResponses?.reduce((a, b) => {
            if (a == null || a > b.afaDiscount) {
                return b.afaDiscount;
            }

            return a;
        }, undefined as number | undefined) ?? 0;
    const coinsuranceAmount1 = (fee - deductible2) * (coinsurance / 100);
    const coinsuranceAmount2 = oop - deductible2;
    const coinsuranceAmount =
        fee <= deductible2
            ? 0
            : coinsuranceAmount1 <= coinsuranceAmount2
            ? coinsuranceAmount1
            : coinsuranceAmount2;
    const copayAmount =
        deductibleAmount + coinsuranceAmount + copay >= oop
            ? oop - (deductibleAmount + coinsuranceAmount)
            : copay;
    const subtotal = hasInsurance
        ? copayAmount + deductibleAmount + coinsuranceAmount
        : amountCharged;
    const yourPersonalizedDiscount = hasInsurance
        ? afaDiscount
        : grossCharges - personalizedPriceTotal;

    const payInFullDiscount =
        cart?.priceJob?.discountResponses?.reduce((prev, curr) => {
            if (prev == null && curr.payInFullDiscount != null) {
                return curr.payInFullDiscount;
            }

            return prev;
        }, undefined as number | undefined) ?? 0;

    return {
        amountCharged: amountCharged,
        networkSavings: amountCharged - fee,
        amountAllowed: fee,
        healthPlanResponsibility: NumberUtils.toCurrency(
            fee + copayAmount - (subtotal ?? 0)
        ),
        amountNotCovered: 0,
        copayAmount: hasInsurance ? copayAmount : null,
        deductibleAmount: hasInsurance ? deductibleAmount : null,
        coinsuranceAmount: hasInsurance
            ? NumberUtils.toCurrency(coinsuranceAmount)
            : null,
        yourSubTotal: subtotal,
        yourPersonalizedDiscount: yourPersonalizedDiscount,
        youPay: personalizedPriceTotal,
        payInFullDiscount,
        payInFullAmount: personalizedPriceTotal - payInFullDiscount,
    };
};

export default usePriceDetails;
