import { Button } from "atoms/buttons/button";
import { Spinner } from "atoms/spinner/spinner";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { DateTime } from "luxon";
import PriceJob from "models/interfaces/price-job";
import { PersonalizeProgress } from "molecules/personalize-progress/personalize-progress";
import ProcedureBanner, {
    ProcedureBannerType,
} from "molecules/procedure-banner/procedure-banner";
import { PersonalizedPriceDetails } from "organisms/personalized-price-details/personalized-price-details";
import { useMembers } from "utilities/hooks/use-members";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import useMemberCart from "utilities/hooks/use-member-cart";
import usePriceJob from "utilities/hooks/use-price-job";
import "./personalize-price-page.scss";
import { useMember } from "utilities/hooks/use-member";
import useCart from "utilities/hooks/use-cart";
import usePrevious from "utilities/hooks/use-previous";
import { PriceJobStatus } from "models/enumerations/price-job-status";
import CartService from "services/cart-service";
import CognitoUtils from "utilities/cognito-utils";
import { Modal } from "molecules/modal/modal";

const COMPONENT_CLASS = "c-personalize-price";

function PersonalizePriceDetailsPage() {
    const history = useHistory();
    const { cartId } = useParams();
    const { clearPaymentTerms, update, updatePriceJob } = useCart();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const [showManualReviewModal, setShowManualReviewModal] = useState(false);
    const [showJobSpinner, setShowJobSpinner] = useState(false);
    const [isTimedOut, setIsTimedOut] = useState(false);
    const { data: members, isLoading: isMembersLoading } = useMembers();
    const member = useMemo(
        () => members?.find((member) => member.cartId === cartId),
        [members, cartId]
    );
    const {
        data: cart,
        isLoading: isCartLoading,
        isExpired,
        refresh: refreshMemberCart,
        subtotal,
    } = useMemberCart({
        cartId,
        memberId: member?.id,
    });

    const {
        data: priceJob,
        isProcessing: isPriceJobProcessing,
        statusDescription: priceJobStatusDescription,
    } = usePriceJob({
        cartId,
        memberId: member?.id,
        priceJobId: cart?.priceJobId,
        isTimedOut,
    });

    useEffect(() => {
        const isPriceJobLoaded = !!cart?.priceJob && !isPriceJobProcessing;

        updatePriceJob({
            data: cart?.priceJob,
            isLoaded: isPriceJobLoaded,
            isLoading: isPriceJobProcessing,
        });

        if (!cart?.completedOn && isPriceJobLoaded) {
            refreshMemberCart();
        }
    }, [
        cart?.completedOn,
        cart?.priceJob,
        isPriceJobProcessing,
        refreshMemberCart,
        updatePriceJob,
    ]);

    useEffect(() => {
        handlePriceJobStatus(priceJob?.status);
    }, [priceJob?.status]);

    useEffect(() => {
        if (
            priceJob &&
            isPriceJobTimedOut(priceJob) &&
            !showErrorModal &&
            !showManualReviewModal
        ) {
            setShowTimeoutModal(true);
            setShowJobSpinner(false);
            setIsTimedOut(true);
        }
    }, [priceJob, showErrorModal, showManualReviewModal]);

    const handlePriceJobStatus = (status?: PriceJobStatus) => {
        switch (status) {
            case PriceJobStatus.Error:
                setShowJobSpinner(false);
                setShowErrorModal(true);
                setIsTimedOut(true);
                break;
            case PriceJobStatus.ManualWorkQueue:
                setShowJobSpinner(false);
                setShowManualReviewModal(true);
                setIsTimedOut(true);
                break;
        }
    };

    const isPriceJobTimedOut = (
        priceJob?: PriceJob,
        isLoaded: boolean = false
    ): boolean => {
        if (!priceJob || isLoaded) return false;

        const priceJobCreatedOn = DateTime.fromISO(
            priceJob.createdOn || DateTime.now().toISO()
        );
        return DateTime.now().diff(priceJobCreatedOn, "seconds").seconds >= 25;
    };

    const handleNotificationClick = useCallback(async () => {
        var idToken = await CognitoUtils.getIdToken();
        if (!member || !cartId) {
            console.error("Member or cartId is undefined");
            return;
        }
        await CartService.updateMemberCartOnError(member.id, cartId, idToken);
        refreshMemberCart();
        history.push("/dashboard");
    }, [member, cartId, history, refreshMemberCart]);

    const handleDashboardRedirect = useCallback(() => {
        history.push("/dashboard");
    }, [history]);

    const { hasInsurance, isLoading: isMemberLoading } = useMember(member?.id);

    const prevIsPriceJobProcessing = usePrevious(isPriceJobProcessing);

    const completedOn = cart?.completedOn
        ? DateTime.fromISO(cart?.completedOn)
        : DateTime.now();

    const [cartUpdated, setCartUpdated] = useState(false);
    const [needsFinalReload, setNeedsFinalReload] =
        useState(isPriceJobProcessing);
    const [finalReloaded, setFinalReloaded] = useState(false);

    const isProcessing =
        isPriceJobProcessing ||
        (needsFinalReload && (isCartLoading || !finalReloaded));

    const isLoading =
        isProcessing || isCartLoading || isMembersLoading || isMemberLoading;

    useEffect(() => {
        if (priceJob == null) {
            return;
        }

        setNeedsFinalReload(
            (prev) => prev || priceJob.status !== PriceJobStatus.Complete
        );
    }, [priceJob]);

    useEffect(() => {
        if (cartUpdated || !cart || !priceJob || !member) {
            return;
        }

        update({
            cart,
            priceJob,
            member,
        });

        setCartUpdated(true);
    }, [cart, priceJob, member, update, cartUpdated]);

    useEffect(() => {
        if (
            prevIsPriceJobProcessing &&
            !isPriceJobProcessing &&
            needsFinalReload
        ) {
            refreshMemberCart();
            setFinalReloaded(true);
        }
    }, [
        isPriceJobProcessing,
        needsFinalReload,
        prevIsPriceJobProcessing,
        refreshMemberCart,
    ]);

    const handleLoaderChange = useCallback((isVisible: boolean) => {
        setShowJobSpinner(!isVisible);
    }, []);

    return (
        <div>
            <CartLayout
                fullscreen={isProcessing}
                isLocked={true}
                header={
                    <PersonalizeProgress
                        title="Your Price"
                        currentStep={-1}
                        showProgress={false}
                    />
                }
                showLoader={!cartUpdated}
                onLoaderChange={handleLoaderChange}>
                <Modal
                    isOpen={showErrorModal}
                    isLoading={false}
                    onClose={handleNotificationClick}
                    title="Error Processing Request"
                    buttons={[
                        {
                            label: "Go to Dashboard",
                            type: "primary",
                            onClick: handleNotificationClick,
                        },
                    ]}>
                    <p>
                        An error occurred while processing your request. Please
                        try again later.
                    </p>
                </Modal>

                <Modal
                    isOpen={showManualReviewModal}
                    isLoading={false}
                    onClose={handleDashboardRedirect}
                    title="Pricing in Review"
                    buttons={[
                        {
                            label: "Go to Dashboard",
                            type: "primary",
                            onClick: handleDashboardRedirect,
                        },
                    ]}>
                    <p>
                        Your request is being reviewed. We will notify you once
                        the price estimation is ready.
                    </p>
                </Modal>

                <Modal
                    isOpen={showTimeoutModal}
                    isLoading={false}
                    onClose={handleNotificationClick}
                    title="Timeout Error"
                    buttons={[
                        {
                            label: "Go to Dashboard",
                            type: "primary",
                            onClick: handleNotificationClick,
                        },
                    ]}>
                    <p>
                        We're sorry, this process is taking longer than
                        expected. Please try again later.
                    </p>
                </Modal>

                <div
                    className={`${COMPONENT_CLASS} ${
                        isProcessing ? "processing" : ""
                    }`}>
                    {isProcessing && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            {showJobSpinner && <Spinner size="small" />}
                            <Heading size="h2">
                                Hang tight, we're retrieving your price.
                            </Heading>
                            <Label>{priceJobStatusDescription}</Label>
                        </div>
                    )}
                    {!isLoading && (
                        <div className={`${COMPONENT_CLASS}__content`}>
                            {cart != null && member != null && (
                                <ProcedureBanner
                                    completedOn={cart.completedOn}
                                    expiresOn={cart.expiresOn}
                                    cartId={cart.id}
                                    member={member}
                                    displayType={
                                        isExpired
                                            ? ProcedureBannerType.Expired
                                            : ProcedureBannerType.Decline
                                    }
                                />
                            )}
                            <Heading size="h2">Your Price</Heading>
                            <Paragraph>
                                Your out-of-pocket estimate was calculated based
                                on your insurance coverage and financial aid
                                eligibility as of{" "}
                                {completedOn.toFormat("MM/dd/yyyy")}. If
                                something about your situation changes at the
                                time of service, you may receive a follow-up
                                payment or refund issued by the healthcare
                                system.
                            </Paragraph>
                            <PersonalizedPriceDetails
                                cart={cart}
                                hasInsurance={hasInsurance}
                                grossCharges={subtotal}
                            />
                            <div className={`${COMPONENT_CLASS}__actions`}>
                                {!isExpired && (
                                    <Button
                                        onClick={() => {
                                            clearPaymentTerms();
                                            history.push("/personalize/pay");
                                        }}>
                                        See Payment Options &gt;
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </CartLayout>
        </div>
    );
}

export default PersonalizePriceDetailsPage;
