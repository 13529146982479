import { Dialog, Transition } from "@headlessui/react";
import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { ButtonOption } from "models/interfaces/button-option";
import React, { Fragment } from "react";
import { Loader } from "../loader/loader";
import "./modal.scss";

const COMPONENT_CLASS = "c-modal";

type ModalProps = {
    buttons?: ButtonOption[];
    wrapperClassName?: string | string[];
    children: React.ReactNode;
    isFullWidth?: boolean;
    isOpen: boolean;
    isLoading: boolean;
    onClose: (open: boolean) => void;
    title: string;
};

export const Modal = ({
    buttons,
    isFullWidth = false,
    wrapperClassName = "",
    isOpen,
    isLoading,
    onClose,
    title,
    ...props
}: ModalProps) => {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className={COMPONENT_CLASS} onClose={onClose}>
                <div className={`${COMPONENT_CLASS}__content`}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <Dialog.Overlay
                            className={`${COMPONENT_CLASS}__overlay`}
                        />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                        aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <div
                            className={`${COMPONENT_CLASS}__wrapper ${
                                isFullWidth ? "full" : ""
                            } ${wrapperClassName}`}>
                            <Loader isVisible={isLoading} />
                            <div className={`${COMPONENT_CLASS}__header`}>
                                <div
                                    className={`${COMPONENT_CLASS}__header__title`}>
                                    <Heading size="h2">{title}</Heading>
                                </div>
                            </div>
                            {props.children}
                            {buttons && buttons.length > 0 && (
                                <div className={`${COMPONENT_CLASS}__actions`}>
                                    {buttons.map((button, index) => (
                                        <Button
                                            key={index}
                                            type={button.type}
                                            onClick={() => {
                                                if (button.onClick) {
                                                    button.onClick();
                                                }
                                            }}>
                                            {button.label}
                                        </Button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
