/**
 * Type guard to check for existing properties
 */
export function hasProperties<T>(
    obj: unknown,
    ...properties: (keyof T)[]
): obj is Record<keyof T, unknown> {
    return !properties.some((property) => {
        return !Object.prototype.hasOwnProperty.call(obj, property);
    });
}
