import HcnLogoGrey from "assets/images/hcn-logo-grey-2@2x.png";
import HcnLogo from "assets/images/hcn-logo-white@2x.png";
import { Anchor } from "atoms/typography/anchor";
import { Paragraph } from "atoms/typography/paragraph";
import { DateTime } from "luxon";
import React from "react";
import "./footer.scss";

const COMPONENT_CLASS = "c-footer";

interface FooterProps {
    coloredBackground?: boolean;
    hideOnDesktop?: boolean;
}

const Footer: React.FC<FooterProps> = ({
    coloredBackground,
    hideOnDesktop,
}) => {
    const stylingClasses = [
        COMPONENT_CLASS,
        coloredBackground ? "colored" : "",
        hideOnDesktop ? "hide-desktop" : "",
    ].join(" ");

    return (
        <div className={stylingClasses}>
            <div className={`${COMPONENT_CLASS}__logo`}>
                <img
                    alt="Healthcare Commerce Network Logo"
                    src={coloredBackground ? HcnLogo : HcnLogoGrey}
                />
            </div>
            <div className={`${COMPONENT_CLASS}__links`}>
                <Anchor to="/terms-of-service" size="xsmall">
                    Terms of Service
                </Anchor>{" "}
                |{" "}
                <Anchor to="/privacy-policy" size="xsmall">
                    Privacy Policy
                </Anchor>
                |{" "}
                <Anchor to="/contact-us" size="xsmall">
                    Contact Us
                </Anchor>
            </div>
            <Paragraph size="xsmall" type="white">
                &copy; {DateTime.now().toFormat("yyyy")}
            </Paragraph>
        </div>
    );
};

export default Footer;
