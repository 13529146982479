import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MemberRelationship } from "models/enumerations/member-relationship";
import Member from "models/interfaces/member";
import MemberService from "services/member-service";
import { LoadingState } from "models/interfaces/cart-state";
import CognitoUtils from "utilities/cognito-utils";

/**
 * Hook that handles members and member carts
 */
export function useMembers(): LoadingState<Member[]> {
    const [error, setError] = useState<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [members, setMembers] = useState<Member[]>([]);
    const history = useHistory();

    const loadData = useCallback(async () => {
        const idToken = await CognitoUtils.getIdToken();
        const memberResponse = await MemberService.getMembers(idToken);

        if (
            memberResponse?.data == null ||
            memberResponse.data.filter(
                (m) => m.relationship === MemberRelationship.Self
            ).length === 0
        ) {
            history.push("/onboarding");
            return;
        }

        setIsLoading(false);

        if (memberResponse.status !== 200) {
            setError("An error occurred getting the member list.");
            return;
        }

        setMembers(memberResponse.data);
        setIsLoaded(true);
        setIsLoading(false);
    }, [history]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        error,
        isLoading,
        isLoaded,
        data: members,
    };
}
