import React from "react";
import Scrollbars from "react-custom-scrollbars";
import "./custom-scrollbar.scss";

const COMPONENT_CLASS = "c-custom-scrollbar";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface CustomScrollbarProps {
    children: React.ReactNode;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const CustomScrollbar: React.FC<CustomScrollbarProps> = (
    props: CustomScrollbarProps
) => {
    return (
        <div className={COMPONENT_CLASS}>
            <Scrollbars
                renderTrackVertical={(props) => (
                    <div
                        {...props}
                        className={`${COMPONENT_CLASS}__track-vertical`}
                    />
                )}
                renderThumbVertical={(props) => (
                    <div
                        {...props}
                        className={`${COMPONENT_CLASS}__thumb-vertical`}
                    />
                )}
                renderView={(props) => (
                    <div {...props} className={`${COMPONENT_CLASS}__view`} />
                )}>
                {props.children}
            </Scrollbars>
        </div>
    );
};

export { CustomScrollbar };
