import { PaymentStatus } from "models/enumerations/payment-status";
import MemberOrder from "models/interfaces/member-order";
import { Payment } from "models/interfaces/payment";
import { useEffect, useRef, useState } from "react";
import OrdersService from "services/orders-service";

export interface OrderItem {
    id: string;
    firstName: string;
    lastName: string;
    purchaseDate: string;
    referenceNumber: string;
    total: number;
    remaining: number;
    status: PaymentStatus;
}

const useOrders = () => {
    const executedRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<MemberOrder[]>([]);
    const paymentPlanOrders = orders
        .filter((e) => e.recurringPayment != null)
        .map(orderToRecurringPayment)
        .sort(comparePurchaseDate);
    const oneTimePaymentOrders = orders
        .filter((e) => e.payment != null)
        .map(orderToPayment)
        .sort(comparePurchaseDate);
    const hasFailedPayment =
        paymentPlanOrders.some((e) => e.status === PaymentStatus.Failed) ||
        oneTimePaymentOrders.some((e) => e.status === PaymentStatus.Failed);

    useEffect(() => {
        if (executedRef.current === true) return;

        executedRef.current = true;

        const loadOrders = async () => {
            setLoading(true);

            try {
                const response = await OrdersService.getOrders();

                if (response.data == null || response.status !== 200) {
                    throw new Error("Failed to fetch orders: Invalid response");
                }

                setOrders(response.data);
            } catch (error) {
                console.error("Error fetching orders:", error);
            } finally {
                setLoading(false);
            }
        };

        loadOrders();
    }, []);

    return {
        loading,
        orders,
        paymentPlanOrders,
        oneTimePaymentOrders,
        hasFailedPayment,
    };
};

const orderToRecurringPayment = (e: MemberOrder) => {
    const { monthlyAmount = 0, payments = [] } = e?.recurringPayment ?? {};

    const totalPaymentSum =
        payments.length === 0
            ? monthlyAmount
            : payments
                  .filter(
                      (p) =>
                          p.status === PaymentStatus.Succeeded ||
                          p.status === PaymentStatus.Pending
                  )
                  .map((p) => p.total)
                  .reduce((prev, curr) => {
                      return (prev += curr);
                  }, 0) ?? 0;

    const orderItem: OrderItem = {
        id: e.id,
        purchaseDate: e.recurringPayment!.startDate,
        referenceNumber: e.recurringPayment!.referenceNumber,
        total: e.total,
        remaining: e?.total ? e?.total - totalPaymentSum : 0,
        firstName: e.firstName,
        lastName: e.lastName,
        status:
            getMostRecentPayment(e.recurringPayment!.payments)?.status ??
            PaymentStatus.Pending,
    };

    return orderItem;
};

const orderToPayment = (e: MemberOrder) => {
    const orderItem: OrderItem = {
        id: e.id,
        purchaseDate: e.payment!.purchaseDate,
        referenceNumber: e.payment!.referenceNumber,
        total: e.payment!.total,
        remaining: 0,
        firstName: e.firstName,
        lastName: e.lastName,
        status: e.payment!.status,
    };

    return orderItem;
};

const comparePurchaseDate = (a: Payment, b: Payment) =>
    new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime();

const getMostRecentPayment = (payments: Payment[]) => {
    return [...payments].sort(comparePurchaseDate)[0];
};

export default useOrders;
