import axios, { AxiosResponse } from "axios";
import MemberOrder from "models/interfaces/member-order";
import { Purchase } from "models/interfaces/purchase";
import { useAuth } from "utilities/contexts/auth-state-context";
const getOrders = async (): Promise<AxiosResponse<MemberOrder[]>> => {
    const { idToken } = useAuth({ runVerify: false });

    axios.defaults.headers.common["Authorization"] = `${idToken}`;

    return axios.get("/api/orders").then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};
const getOrder = async (
    memberOrderId: string,
    idToken: string
): Promise<AxiosResponse<MemberOrder>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get(`/api/orders/${memberOrderId}`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};
const updateOrder = async (
    memberOrderId: string,
    payment: Purchase,
    idToken: string
): Promise<AxiosResponse<MemberOrder>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.post(`/api/orders/${memberOrderId}/payment`, payment).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const OrdersService = {
    getOrders,
    getOrder,
    updateOrder,
};

export default OrdersService;
