import PaymentOptions from "models/interfaces/payment-options";
import React, { FormEventHandler } from "react";
import { useRef } from "react";

interface PaymentPlanSliderProps {
    paymentPlans: PaymentOptions[];
    selectedPlanIndex: number;
    selectedPlan: PaymentOptions;
    handleSliderInput: FormEventHandler<HTMLInputElement>;
}

const COMPONENT_CLASS = "c-personalize-pay";
const SLIDER_THUMB_WIDTH = 15;

const PaymentPlanSlider = ({
    paymentPlans,
    selectedPlanIndex,
    selectedPlan,
    handleSliderInput,
}: PaymentPlanSliderProps) => {
    const sliderRef = useRef<HTMLDivElement>(null);

    const hasManyPaymentPlans = paymentPlans.length > 1;
    const sliderLabelStyle = {
        left: `calc(${
            (hasManyPaymentPlans
                ? selectedPlanIndex / (paymentPlans.length - 1)
                : 0.5) *
                ((sliderRef.current as HTMLDivElement)?.getBoundingClientRect()
                    .width -
                    SLIDER_THUMB_WIDTH) +
            SLIDER_THUMB_WIDTH / 2
        }px - 3.5rem)`,
    };

    return (
        <div
            ref={sliderRef}
            className={`${COMPONENT_CLASS}__pay-later__term-selection__slider`}>
            <span
                style={sliderLabelStyle}
                className={`${COMPONENT_CLASS}__pay-later__term-selection__label`}>
                <span>{selectedPlan.termInMonths} Months</span>
                <span
                    className={`${COMPONENT_CLASS}__pay-later__term-selection__label__arrow-down`}></span>
            </span>
            <input
                type="range"
                min="0"
                max={hasManyPaymentPlans ? paymentPlans.length - 1 : 1}
                step={hasManyPaymentPlans ? 1 : 0.5}
                value={hasManyPaymentPlans ? selectedPlanIndex : 0.5}
                onInput={handleSliderInput}
                disabled={!hasManyPaymentPlans}
            />
        </div>
    );
};

export default PaymentPlanSlider;
