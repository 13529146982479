import { InsuranceRelationship } from "./enumerations/insurance-relationship";
import { ListOptions } from "./interfaces/list-options";

export const InsuranceRelationshipOptions: ListOptions[] = [
    {
        value: InsuranceRelationship.Self.toString(),
        label: InsuranceRelationship[InsuranceRelationship.Self],
    },
    {
        value: InsuranceRelationship.Spouse.toString(),
        label: InsuranceRelationship[InsuranceRelationship.Spouse],
    },
    {
        value: InsuranceRelationship.Child.toString(),
        label: InsuranceRelationship[InsuranceRelationship.Child],
    },
];
