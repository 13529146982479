import { Spinner } from "atoms/spinner/spinner";
import React from "react";
import "./loader.scss";

const COMPONENT_CLASS = "c-loader";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface LoaderProps {
    isVisible: boolean;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Loader: React.FC<LoaderProps> = ({ isVisible }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <div className={`${COMPONENT_CLASS} -active`}>
            <div className={`${COMPONENT_CLASS}__overlay`}></div>
            <Spinner />
        </div>
    );
};

export { Loader };
