import { Paragraph } from "atoms/typography/paragraph";
import React from "react";
import "./tag.scss";

const COMPONENT_CLASS = "c-tag";

interface ToggleProps {
    children: React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const Tag = ({ children }: ToggleProps) => {
    return (
        <div className={COMPONENT_CLASS}>
            <Paragraph>{children}</Paragraph>
        </div>
    );
};
