import { Button } from "atoms/buttons/button";
import CartService from "services/cart-service";
import { Checkbox } from "atoms/forms/checkbox";
import { Confirm } from "atoms/confirm/confirm";
import { Dropdown } from "atoms/forms/dropdown";
import { Error } from "atoms/forms/error";
import { TextInput } from "atoms/forms/text-input";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { DateTime } from "luxon";
import { MemberRelationship } from "models/enumerations/member-relationship";
import { Address, emptyAddress } from "models/interfaces/address";
import { FormValidation } from "models/interfaces/form-validation";
import Member, { emptyMember } from "models/interfaces/member";
import { StateOptions } from "models/state-options";
import { PersonalizeProgress } from "molecules/personalize-progress/personalize-progress";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import MemberService from "services/member-service";
import { useCartState } from "utilities/contexts/cart-state-context";
import FormUtils from "utilities/form-utils";
import useRedirectExpiredCart from "utilities/hooks/use-redirect-expired-cart";
import StringUtils from "utilities/string-utils";
import "../personalize-summary-page/personalize-summary-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-personalize-summary";
const FORM_CLASS = "c-member-form";

function PersonalizeSummaryMemberPage() {
    const [error, setError] = useState<string>();
    const [member, setMember] = useState<Member>(emptyMember());
    const [originalMember, setOriginalMember] = useState<Member>(emptyMember());
    const [address, setAddress] = useState<Address>(emptyAddress());
    const [hasAgreed, setHasAgreed] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const [fieldValidation, setFieldValidation] = useState<FormValidation[]>();
    const history = useHistory();
    const { state: cartState, setState: setCartState } = useCartState();
    const windowRef = useRef(null);
    useRedirectExpiredCart();

    useEffect(() => {
        const loadMember = async () => {
            const idToken = await CognitoUtils.getIdToken();
            MemberService.getMember(cartState.member!.id!, idToken).then(
                (response) => {
                    if (response?.data) {
                        if (!StringUtils.isEmpty(response.data.dob)) {
                            response.data.dob = DateTime.fromISO(
                                response.data.dob!
                            ).toFormat("MM/dd/yyyy");
                        }
                        setMember(response.data);
                        setOriginalMember(response.data);
                        setAddress(response.data.address || emptyAddress());
                    }
                    setIsLoading(false);
                }
            );
        };

        if (cartState.member?.id) {
            loadMember();
        }
    }, [cartState.member]);

    useEffect(() => {
        if (!isEditing) {
            const m = Object.assign({}, originalMember);
            setMember(m);
            setAddress(m.address || emptyAddress());
        }
    }, [isEditing, originalMember]);

    const handleContinueClick = async () => {
        if (hasAgreed) {
            setIsLoading(true);

            try {
                var idToken = await CognitoUtils.getIdToken();
                const response = await CartService.personalizeCart(
                    cartState.member!,
                    cartState.cart!,
                    idToken
                );

                if (response?.status === 200 && response?.data?.priceJobId) {
                    const cart = Object.assign({}, cartState.cart);
                    cart.priceJobId = response?.data?.priceJobId;
                    setCartState({
                        ...cartState,
                        ...{
                            cart: cart,
                            cartIsLoading: false,
                        },
                    });
                    history.push(`/personalize/price/${cart.id}`);
                    return;
                }
            } catch (error) {
                console.error("Error submitting form:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleEditClick = () => {
        setIsSaved(false);
        setIsEditing(true);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isEditing) {
            return;
        }

        const validationErrors = [];

        // Member field validations
        if (StringUtils.isEmpty(member.firstName)) {
            validationErrors.push({
                field: "FirstName",
                error: "First name is required",
            });
        }
        if (StringUtils.isEmpty(member.lastName)) {
            validationErrors.push({
                field: "LastName",
                error: "Last name is required",
            });
        }
        if (StringUtils.isEmpty(member.dob)) {
            validationErrors.push({
                field: "DOB",
                error: "Date of Birth is required",
            });
        }
        if (StringUtils.isEmpty(member.ssn)) {
            validationErrors.push({ field: "SSN", error: "SSN is required" });
        } else if (member.ssn && member.ssn.length < 11) {
            validationErrors.push({ field: "SSN", error: "SSN invalid" });
        }
        if (StringUtils.isEmpty(member.email)) {
            validationErrors.push({
                field: "Email",
                error: "Email is required",
            });
        }
        if (StringUtils.isEmpty(member.phone)) {
            validationErrors.push({
                field: "Phone",
                error: "Phone number is required",
            });
        }

        // Address field validations
        if (StringUtils.isEmpty(address.line1)) {
            validationErrors.push({
                field: "Address1",
                error: "Address is required",
            });
        }
        if (StringUtils.isEmpty(address.city)) {
            validationErrors.push({ field: "City", error: "City is required" });
        }
        if (StringUtils.isEmpty(address.zipCode)) {
            validationErrors.push({
                field: "ZipCode",
                error: "Zip is required",
            });
        }
        if (StringUtils.isEmpty(address.state)) {
            validationErrors.push({
                field: "State",
                error: "State is required",
            });
        }

        setFieldValidation(validationErrors);
        if (validationErrors.length > 0) {
            return;
        }

        setIsLoading(true);

        const updatedMember = Object.assign({}, member);
        updatedMember.address = address;
        if (StringUtils.isEmpty(updatedMember.middleInitial)) {
            updatedMember.middleInitial = undefined;
        }
        if (StringUtils.isEmpty(address.line2)) {
            address.line2 = undefined;
        }

        updatedMember.address = address;

        setIsLoading(true);

        const idToken = await CognitoUtils.getIdToken();

        MemberService.updateMember(member.id, updatedMember, idToken).then(
            (memberResult) => {
                if (memberResult.status !== 200) {
                    setError(
                        "There was an saving the profile. Please try again."
                    );
                    setIsLoading(false);
                    return;
                }
                setOriginalMember(memberResult.data);
                setIsSaved(true);
                setIsLoading(false);
                setIsEditing(false);
                if (windowRef.current) {
                    (windowRef.current as any).scrollTo(0, 0);
                }
            },
            (error: any) => {
                if (error.status === 401) {
                    setError("Invalid request. Please try again.");
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    return (
        <div>
            <CartLayout
                isLocked={true}
                header={
                    <PersonalizeProgress
                        title="Getting Your Price"
                        currentStep={3}
                        showProgress={true}
                    />
                }
                showLoader={isLoading}
                windowRef={windowRef}>
                <div className={COMPONENT_CLASS}>
                    {isSaved && (
                        <Confirm text="Your changes were successfully saved" />
                    )}
                    <Heading size="h2">Patient Information</Heading>
                    <Paragraph size="small">
                        The patient information below is for the individual
                        receiving the procedure. Please review the information
                        carefully to ensure it is accurate. If you make any
                        changes to this information, we'll update the family
                        member's profile for future purchases.
                    </Paragraph>
                    {!isLoading && (
                        <div className={FORM_CLASS}>
                            <form onSubmit={handleSubmit}>
                                <div className={`${FORM_CLASS}__section`}>
                                    <div
                                        className={`${FORM_CLASS}__section__wrapper triple`}>
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            maxLength={50}
                                            name="FirstName"
                                            label="First Name"
                                            placeholder="First Name"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { firstName: value },
                                                    setMember
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field ===
                                                            "FirstName"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "FirstName"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={member.firstName}
                                        />
                                        <TextInput
                                            isLocked={!isEditing}
                                            maxLength={10}
                                            name="MiddleInitial"
                                            label="MI"
                                            placeholder="MI"
                                            onChange={(value) =>
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { middleInitial: value },
                                                    setMember
                                                )
                                            }
                                            value={member.middleInitial}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            maxLength={75}
                                            name="LastName"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { lastName: value },
                                                    setMember
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field ===
                                                            "LastName"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "LastName"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={member.lastName}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            displayFunction={(args) => {
                                                let result = "";
                                                if (args.length > 0) {
                                                    result += `${args[0]}`;
                                                }
                                                if (args.length > 1) {
                                                    result += `/${args[1]}`;
                                                }
                                                if (args.length === 3) {
                                                    result += `/${args[2]}`;
                                                }
                                                return result;
                                            }}
                                            inputFormat={
                                                /^(\d{0,2})\/?(\d{0,2})\/?(\d{0,4})$/
                                            }
                                            isRequired={true}
                                            maxLength={10}
                                            name="DOB"
                                            label="Date Of Birth"
                                            placeholder="00/00/0000"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { dob: value },
                                                    setMember
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) => v.field === "DOB"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "DOB"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={member.dob}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            displayFunction={(args) => {
                                                let result = "";
                                                if (args.length > 0) {
                                                    result += `${args[0]}`;
                                                }
                                                if (args.length > 1) {
                                                    result += `-${args[1]}`;
                                                }
                                                if (args.length === 3) {
                                                    result += `-${args[2]}`;
                                                }
                                                return result;
                                            }}
                                            inputFormat={
                                                /^(\d{0,3})-?(\d{0,2})-?(\d{0,4})$/
                                            }
                                            isRequired={true}
                                            maxLength={11}
                                            name="SSN"
                                            label="Social Security #"
                                            placeholder="000-00-0000"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { ssn: value },
                                                    setMember
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) => v.field === "SSN"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "SSN"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={member.ssn}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            maxLength={320}
                                            name="Email"
                                            label="Email"
                                            placeholder="Email"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { email: value },
                                                    setMember
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field === "Email"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "Email"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={member.email}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            displayFunction={(args) => {
                                                let result = "";
                                                if (args.length > 0) {
                                                    result += `(${args[0]}`;
                                                }
                                                if (args.length > 1) {
                                                    result += `) ${args[1]}`;
                                                }
                                                if (args.length === 3) {
                                                    result += `-${args[2]}`;
                                                }
                                                return result;
                                            }}
                                            inputFormat={
                                                /^\(?(\d{0,3})\)?\s?(\d{0,3})-?(\d{0,4})$/
                                            }
                                            isRequired={true}
                                            maxLength={14}
                                            name="Phone"
                                            label="Phone"
                                            placeholder="(000) 000-0000"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    member,
                                                    { phone: value },
                                                    setMember
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field === "Phone"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "Phone"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={member.phone}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            maxLength={100}
                                            name="Address1"
                                            label="Address 1"
                                            placeholder="Address 1"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    address,
                                                    { line1: value },
                                                    setAddress
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field ===
                                                            "Address1"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "Address1"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={address.line1}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            maxLength={100}
                                            name="Address2"
                                            label="Address 2"
                                            placeholder="Address 2"
                                            onChange={(value) =>
                                                FormUtils.handleValueChange(
                                                    address,
                                                    { line2: value },
                                                    setAddress
                                                )
                                            }
                                            value={address.line2}
                                        />
                                    </div>
                                    <div
                                        className={`${FORM_CLASS}__section__wrapper triple`}>
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            maxLength={100}
                                            name="City"
                                            label="City"
                                            placeholder="City"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    address,
                                                    { city: value },
                                                    setAddress
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field === "City"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "City"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={address.city}
                                        />
                                        <TextInput
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            maxLength={15}
                                            name="ZipCode"
                                            label="Zip"
                                            placeholder="00000"
                                            onChange={(value) => {
                                                FormUtils.handleValueChange(
                                                    address,
                                                    { zipCode: value },
                                                    setAddress
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field ===
                                                            "ZipCode"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "ZipCode"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={address.zipCode}
                                        />
                                        <Dropdown
                                            fieldValidation={fieldValidation}
                                            isLocked={!isEditing}
                                            isRequired={true}
                                            name="State"
                                            isSearchable={true}
                                            label="State"
                                            placeholder="Select"
                                            options={StateOptions}
                                            onChange={(value: string) => {
                                                FormUtils.handleValueChange(
                                                    address,
                                                    { state: value },
                                                    setAddress
                                                );
                                                if (
                                                    fieldValidation?.find(
                                                        (v) =>
                                                            v.field === "State"
                                                    )
                                                ) {
                                                    setFieldValidation([
                                                        ...fieldValidation.filter(
                                                            (v) =>
                                                                v.field !==
                                                                "State"
                                                        ),
                                                    ]);
                                                }
                                            }}
                                            value={address.state.toString()}
                                        />
                                    </div>
                                </div>
                                {error && (
                                    <div className={`${FORM_CLASS}__section`}>
                                        <Error message={error} />
                                    </div>
                                )}
                                {!isEditing && (
                                    <>
                                        <Heading size="h2">Consent</Heading>
                                        <div
                                            className={`${FORM_CLASS}__section__wrapper`}>
                                            <Paragraph size="small">
                                                By checking the box below, I
                                                acknowledge that the information
                                                I have provided including
                                                insurance benefits and any
                                                information I have presented to
                                                verify my own identity, and if
                                                applicable any information used
                                                to verify the identity of a
                                                minor beneficiary is current,
                                                correct, and complete to the
                                                best of my knowledge. I also
                                                acknowledge that I have read,
                                                understand, and agree to the
                                                disclaimers and authorizations
                                                stated here.
                                            </Paragraph>
                                        </div>
                                        <div
                                            className={`${FORM_CLASS}__section__wrapper`}>
                                            <Checkbox
                                                label="I authorize HCN to procure a consumer report on me"
                                                isChecked={hasAgreed}
                                                onChange={(isChecked) =>
                                                    setHasAgreed(isChecked)
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                                {!isEditing && (
                                    <div
                                        className={`${FORM_CLASS}__section ${FORM_CLASS}__actions right`}>
                                        <Button
                                            onClick={handleEditClick}
                                            type="white">
                                            Edit Information
                                        </Button>
                                        <Button
                                            onClick={handleContinueClick}
                                            disabled={!hasAgreed}>
                                            Get Your Price &gt;
                                        </Button>
                                    </div>
                                )}
                                {isEditing && (
                                    <div
                                        className={`${FORM_CLASS}__section ${FORM_CLASS}__actions right`}>
                                        <Button
                                            onClick={() => setIsEditing(false)}
                                            type="plain">
                                            Cancel
                                        </Button>
                                        <Button isSubmit={true}>Save</Button>
                                    </div>
                                )}
                            </form>
                        </div>
                    )}
                </div>
            </CartLayout>
        </div>
    );
}

export default PersonalizeSummaryMemberPage;
