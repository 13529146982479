import { Heading } from "atoms/typography/heading";
import React from "react";
import StringUtils from "../../utilities/string-utils";

interface PricingRecapProps {
    subtotal: number;
    discount: number;
    cashDiscount: number;
    total: number;
}

const PricingRecap = ({
    subtotal,
    discount,
    cashDiscount,
    total,
}: PricingRecapProps) => {
    return (
        <div className="w-full space-y-6 text-left">
            <Heading size="h3" className="text-xl">
                Pricing Recap
            </Heading>
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Subtotal
                    </span>
                    <span>{StringUtils.formatCurrency(subtotal)}</span>
                </div>
                <div className="flex items-center justify-between">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Discount
                    </span>
                    <span>{StringUtils.formatCurrency(discount)}</span>
                </div>
                <div className="flex items-center justify-between">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Cash Discount
                    </span>
                    <span>{StringUtils.formatCurrency(cashDiscount)}</span>
                </div>
                <div className="flex items-center justify-between">
                    <span className="w-full text-left text-3xs font-bold uppercase tracking-[1.5px] text-grey-200">
                        Total
                    </span>
                    <span>{StringUtils.formatCurrency(total)}</span>
                </div>
            </div>
        </div>
    );
};

export default PricingRecap;
