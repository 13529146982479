import { ChevronRightIcon, SearchIcon } from "@heroicons/react/outline";
import algoliasearch, { SearchIndex } from "algoliasearch";
import { Button } from "atoms/buttons/button";
import { Search } from "atoms/forms/search";
import { Spinner } from "atoms/spinner/spinner";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { AlgoliaBundle } from "models/interfaces/algolia-bundle";
import Category from "models/interfaces/category";
import CategorySearch from "organisms/category-search/category-search";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import CategoriesService from "services/categories-service";
import { useAuth } from "utilities/contexts/auth-state-context";
import StringUtils from "utilities/string-utils";
import "../../atoms/buttons/button.scss";
import "./procedure-result-page.scss";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-procedure-result";

function ProcedureResultPage() {
    const searchParams = StringUtils.parseQueryString(window.location.search);
    const [categoryKey, setCategoryKey] = useState(
        searchParams?.categoryKey || ""
    );
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [hits, setHits] = useState<any[]>([]);
    const [searchIndex, setSearchIndex] = useState<SearchIndex>();
    const [searchText, setSearchText] = useState(searchParams?.q || "");
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [totalResults, setTotalResults] = useState(0);
    const { settings } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (totalResults === 0) {
            document
                .getElementById("cart-layout")
                ?.classList.add("procedure-results-none");
        }

        return () => {
            document
                .getElementById("cart-layout")
                ?.classList.remove("procedure-results-none");
        };
    }, [totalResults, isLoading]);

    useEffect(() => {
        if (StringUtils.isEmpty(categoryKey)) {
            return;
        }
        const getCategories = async () => {
            const idToken = await CognitoUtils.getIdToken();

            CategoriesService.getByKey(categoryKey, idToken).then(
                (response) => {
                    if (response?.data) {
                        setSelectedCategory(response.data);
                    }
                }
            );
        };

        getCategories();
    }, [categoryKey]);

    useEffect(() => {
        const client = algoliasearch(
            settings?.algoliaAppId || "",
            settings?.algoliaApiKey || ""
        );

        setSearchIndex(
            client.initIndex(settings?.algoliaBundleIndex || "bundle")
        );
    }, [
        settings?.algoliaApiKey,
        settings?.algoliaAppId,
        settings?.algoliaBundleIndex,
    ]);

    useEffect(() => {
        if (!searchIndex) {
            return;
        }
        handleSearch(0);
    }, [categoryKey, searchIndex]);

    const handleBack = () => {
        const historyPath: any = {
            pathname: "/procedures",
        };
        if (!StringUtils.isEmpty(categoryKey)) {
            historyPath.search = `?categoryKey=${categoryKey}`;
        }
        history.push(historyPath);
    };

    const handleSearchClick = () => {
        history.replace({
            pathname: "/procedures/result",
            search: `?q=${searchText}&categoryKey=${categoryKey}`,
        });
        handleSearch(0);
    };

    const handleSearch = async (page: number) => {
        if (!searchIndex) {
            setHits([]);
            setTotalResults(0);
            setIsLoading(false);
            return;
        }

        if (page === 0) {
            setIsLoading(true);
        }

        const facetFilters = [];
        //const facetFilters = [`clientKey:${settings?.clientKey}`];

        if (!StringUtils.isEmpty(categoryKey)) {
            facetFilters.push(`categoryKey:${categoryKey}`);
        }

        const searchResult = await searchIndex?.search(searchText, {
            hitsPerPage: 25,
            page: page,
            facetFilters: facetFilters,
        });
        setCurrentPage(page);
        if (page === 0) {
            setHits(searchResult.hits);
            setTotalResults(searchResult.nbHits);
        } else {
            setHits(hits.concat(searchResult.hits));
        }
        setHasMoreItems(searchResult.nbPages > page + 1);
        setIsLoading(false);
    };

    return (
        <div>
            <CartLayout
                header={
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <Button
                            cssClassName="whitespace-nowrap"
                            onClick={() => handleBack()}
                            size="small"
                            type="light">
                            &lt; Back
                        </Button>
                        <Search
                            placeholder="Name, CPT Code, or Keyword"
                            isLocked={false}
                            onChange={(text) => setSearchText(text)}
                            onSearch={handleSearchClick}
                            value={searchText}
                        />
                    </div>
                }
                isLocked={false}
                fullwidth={true}
                fullscreen={!isLoading && totalResults === 0}
                showLoader={isLoading}>
                {!isLoading && totalResults === 0 && (
                    <div className={`${COMPONENT_CLASS} empty`}>
                        <div className={`${COMPONENT_CLASS}__none`}>
                            <SearchIcon />
                            <Heading size="h2">No Results Found</Heading>
                            <Paragraph>
                                We're sorry! We could not find a match. Please
                                try another search or try one of the below
                                categories.
                            </Paragraph>
                        </div>
                        <CategorySearch
                            handleCategorySearch={(categoryKey: string) => {
                                setCategoryKey(categoryKey);
                                setSearchText("");
                                history.replace({
                                    pathname: "/procedures/result",
                                    search: `?q=&categoryKey=${categoryKey}`,
                                });
                            }}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            type="primary"
                        />
                    </div>
                )}
                {!isLoading && totalResults > 0 && (
                    <div className={COMPONENT_CLASS}>
                        <Heading size="h3">
                            Showing {totalResults} Result(s)
                            {selectedCategory
                                ? ` for ${selectedCategory.categoryName}`
                                : ""}
                        </Heading>
                        <InfiniteScroll
                            next={() => handleSearch(currentPage + 1)}
                            dataLength={hits.length}
                            hasMore={hasMoreItems}
                            loader={<Spinner key="spinner-0" />}
                            scrollableTarget="layout-wrapper">
                            <div className={`${COMPONENT_CLASS}__list`}>
                                <div
                                    className={`${COMPONENT_CLASS}__list__header`}>
                                    <div>
                                        <Label>Procedure</Label>
                                    </div>
                                    <div>
                                        <Label>Code</Label>
                                    </div>
                                    <div></div>
                                </div>
                                {hits?.map(
                                    (hit: AlgoliaBundle, index: number) => {
                                        return (
                                            <div
                                                className={`${COMPONENT_CLASS}__list__item`}
                                                key={index}
                                                onClick={() =>
                                                    history.push(
                                                        `/procedures/${hit.objectID}`
                                                    )
                                                }>
                                                <div>
                                                    <Paragraph
                                                        size="small"
                                                        weight="semibold">
                                                        {hit.name}
                                                    </Paragraph>
                                                </div>
                                                <div
                                                    className={`${COMPONENT_CLASS}__list__item__section desktop`}>
                                                    <Paragraph size="small">
                                                        {
                                                            hit.primaryMedicalCodeset
                                                        }{" "}
                                                        -{" "}
                                                        {hit.primaryMedicalCode}
                                                    </Paragraph>
                                                </div>
                                                <div
                                                    className={`${COMPONENT_CLASS}__list__item__section desktop`}></div>
                                                <div
                                                    className={`${COMPONENT_CLASS}__list__item__section desktop`}>
                                                    <button
                                                        type="button"
                                                        className="c-button medium primary"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            history.push(
                                                                `/procedures/${hit.objectID}`
                                                            );
                                                        }}>
                                                        <ChevronRightIcon />
                                                    </button>
                                                </div>
                                                <div
                                                    className={`${COMPONENT_CLASS}__list__item__section mobile`}>
                                                    <Label>Code</Label>
                                                    <Paragraph size="small">
                                                        {hit.code}
                                                    </Paragraph>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </InfiniteScroll>
                    </div>
                )}
            </CartLayout>
        </div>
    );
}

export default ProcedureResultPage;
