import env from "react-dotenv";

export interface ICognitoConfig {
    appClientID: string;
    identityProvider: string;
    displayName: string;
    config: {
        userPoolId: string;
        userPoolWebClientId: string;
        defaultRegion: string;
        oauth: {
            domain: string;
            scope: string[];
            redirectSignIn: string;
            redirectSignOut: string;
            responseType: string;
            identityProvider: string;
            options: {
                AdvancedSecurityDataCollectionFlag: boolean;
            };
        };
    };
}

export const CognitoConfig: ICognitoConfig[] = [
    {
        appClientID: env.HCN_AUTH_USERPOOL_CLIENT_ID,
        identityProvider: "okta-users",
        displayName: "Okta",
        config: {
            userPoolId: env.HCN_AUTH_USERPOOL_ID,
            userPoolWebClientId: env.HCN_AUTH_USERPOOL_CLIENT_ID,
            defaultRegion: env.HCN_AUTH_USERPOOL_ENV,
            oauth: {
                domain: env.HCN_AUTH_USERPOOL_OAUTH_DOMAIN,
                scope: (env.HCN_AUTH_USERPOOL_OAUTH_SCOPES as string)?.split(
                    ","
                ),
                redirectSignIn: env.HCN_AUTH_USERPOOL_OAUTH_REDIRECT,
                redirectSignOut: env.HCN_AUTH_USERPOOL_OAUTH_SIGNOUT,
                responseType: "code",
                identityProvider: "okta-users",
                options: {
                    AdvancedSecurityDataCollectionFlag: false,
                },
            },
        },
    },
    {
        appClientID:
            "amzn1.application-oa2-client.69ab23cad6c74a7fac7f8b02d8164353",
        identityProvider: "LoginWithAmazon",
        displayName: "Amazon",
        config: {
            userPoolId: env.HCN_AUTH_USERPOOL_ID,
            userPoolWebClientId: env.USERPOOL_CLIENT_ID,
            defaultRegion: env.USERPOOL_ENV,
            oauth: {
                domain: env.HCN_AUTH_USERPOOL_OAUTH_DOMAIN,
                scope: (env.HCN_AUTH_USERPOOL_OAUTH_SCOPES as string)?.split(
                    ","
                ),
                redirectSignIn: env.HCN_AUTH_USERPOOL_OAUTH_REDIRECT,
                redirectSignOut: env.HCN_AUTH_USERPOOL_OAUTH_SIGNOUT,
                responseType: "code",
                identityProvider: "LoginWithAmazon",
                options: {
                    AdvancedSecurityDataCollectionFlag: false,
                },
            },
        },
    },
    {
        appClientID: env.HCN_AUTH_USERPOOL_CLIENT_ID,
        identityProvider: "LoginWithGoogle",
        displayName: "Google",
        config: {
            userPoolId: env.HCN_AUTH_USERPOOL_ID,
            userPoolWebClientId: env.USERPOOL_CLIENT_ID,
            defaultRegion: env.USERPOOL_ENV,
            oauth: {
                domain: env.HCN_AUTH_USERPOOL_OAUTH_DOMAIN,
                scope: (env.HCN_AUTH_USERPOOL_OAUTH_SCOPES as string)?.split(
                    ","
                ),
                redirectSignIn: env.HCN_AUTH_USERPOOL_OAUTH_REDIRECT,
                redirectSignOut: env.HCN_AUTH_USERPOOL_OAUTH_SIGNOUT,
                responseType: "code",
                identityProvider: "LoginWithGoogle",
                options: {
                    AdvancedSecurityDataCollectionFlag: false,
                },
            },
        },
    },
];

export default CognitoConfig;
