import { MailIcon } from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { TextInput } from "atoms/forms/text-input";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import React, { FormEvent, useEffect, useState } from "react";
import {
    AuthError,
    AuthErrorCodes,
    useAuth,
} from "utilities/contexts/auth-state-context";
import StringUtils from "utilities/string-utils";

interface UpdateEmailFormProps {
    initialEmail: string;
}

const UpdateEmailForm = ({ initialEmail }: UpdateEmailFormProps) => {
    const [email, setEmail] = useState(initialEmail);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState<AuthError>();
    const { error: authError, isLoading, updateEmail, user } = useAuth();
    const [submitted, setSubmitted] = useState(false);

    const hasUpdatedEmail = user?.email === email && submitted;

    useEffect(() => {
        setError(authError);
    }, [authError]);

    const handleChangeEmailBtnClick = () => {
        setIsEditing(true);
    };

    const handleEmailChange = (value: string) => {
        setSubmitted(false);
        setError(undefined);
        setEmail(value);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setError(undefined);

        if (!StringUtils.isEmail(email)) {
            setError({
                code: AuthErrorCodes.General,
                message: "Please enter a valid email address",
            });
            return;
        }

        if (email === initialEmail) {
            setError({
                code: AuthErrorCodes.General,
                message: "This is already your email address",
            });
            return;
        }

        setSubmitted(true);
        updateEmail(email);
    };

    const handleCancelBtnClick = () => {
        setEmail(initialEmail);
        setError(undefined);
        setIsEditing(false);
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)} className="space-y-4">
            <Heading size="h3" className="text-3xl">
                Your Email
            </Heading>
            <Paragraph>
                This is the email address you use to log in to your account and
                receive email notifications. You'll be required to verify this
                change before you can log in using your new email address.
            </Paragraph>
            {!hasUpdatedEmail && (
                <TextInput
                    isLocked={!isEditing || isLoading}
                    errorMessage={error?.message}
                    label="Email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                />
            )}
            {!isEditing ? (
                <Button onClick={handleChangeEmailBtnClick} size="medium">
                    Change Email
                </Button>
            ) : !hasUpdatedEmail ? (
                <div className="space-x-2">
                    <Button onClick={handleCancelBtnClick} type="clear">
                        Cancel
                    </Button>
                    <Button disabled={isLoading} isSubmit={true} size="medium">
                        Update Email
                    </Button>
                </div>
            ) : (
                <div className="flex w-full items-center justify-between rounded-md bg-primary-400 px-4 py-2 font-semibold text-primary-900">
                    <div className="flex items-center space-x-2">
                        <MailIcon className="h-8 w-8" />
                        <p>We sent a verification link to {email}</p>
                    </div>
                    <button
                        onClick={handleSubmit}
                        className="font-semibold hover:underline">
                        Resend
                    </button>
                </div>
            )}
        </form>
    );
};

export default UpdateEmailForm;
