import { CartStatus } from "models/enumerations/cart-status";
import { MemberRelationship } from "models/enumerations/member-relationship";
import { Address, emptyAddress } from "./address";
import MemberInsurance from "./member-insurance";

export default interface Member {
    cartCount?: number;
    cartId?: string;
    cartStatus?: CartStatus;
    firstName: string;
    middleInitial?: string;
    lastName: string;
    id: string;
    ssn?: string;
    dob?: string;
    email: string;
    phone: string;
    relationship?: MemberRelationship;
    address?: Address;
    memberInsurances: MemberInsurance[];
    userId: string;
}

export const emptyMember = (): Member => ({
    firstName: "",
    middleInitial: "",
    lastName: "",
    id: "",
    ssn: "",
    dob: "",
    email: "",
    phone: "",
    relationship: undefined,
    address: emptyAddress(),
    memberInsurances: [],
    userId: "",
});
