import { Anchor } from "atoms/typography/anchor";
import HcnLogoGrey from "assets/images/hcn-logo-grey-2@2x.png";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import { DateTime } from "luxon";
import React from "react";
import "./privacy-policy-page.scss";
import { Button } from "atoms/buttons/button";
import { useHistory } from "react-router-dom";

const COMPONENT_CLASS = "c-privacy-policy-page";

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const PrivacyPolicyPage: React.FC<any> = () => {
    const history = useHistory();

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <div className={`${COMPONENT_CLASS}__window`}>
                <div className={`${COMPONENT_CLASS}__container__header`}>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        <div
                            className={`${COMPONENT_CLASS}__container__header__content`}>
                            <div className="space-y-7 pt-7">
                                <Button
                                    onClick={() => history.goBack()}
                                    size="small"
                                    type="light">
                                    &lt; Back
                                </Button>
                                <Heading size="h1" type="white">
                                    Privacy Policy
                                </Heading>
                            </div>
                        </div>
                    </div>
                </div>
                <main className={`${COMPONENT_CLASS}__content`}>
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <div>
                            <div
                                className="article-container"
                                id="article-container">
                                <article className="article">
                                    <section className="article-info">
                                        <div className="article-content">
                                            <div className="article-body">
                                                <Paragraph>
                                                    <a href="https://support.hcn.arxchange.com/hc/en-us/articles/360026530331-Advertising-on-HCN">
                                                        <strong>
                                                            <em>
                                                                Information
                                                                about
                                                                advertising on
                                                                HCN
                                                            </em>
                                                        </strong>
                                                    </a>
                                                </Paragraph>
                                                <Paragraph>
                                                    Last modified: August 9th,
                                                    2022&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    This privacy policy governs
                                                    your access or use of the
                                                    content, products, and
                                                    services made available
                                                    through the Healthcare
                                                    Commerce Network Service
                                                    (the "Services").
                                                </Paragraph>
                                                <Paragraph>
                                                    Your use of our Services,
                                                    including any dispute
                                                    concerning privacy, is
                                                    subject to this Privacy
                                                    Policy and our Terms of
                                                    Use.&nbsp;
                                                    <strong>
                                                        BY USING OUR SERVICES,
                                                        YOU ARE ACCEPTING THIS
                                                        PRIVACY POLICY AND OUR
                                                        TERMS OF USE AND
                                                        AUTHORIZING THE
                                                        COLLECTION, USES AND
                                                        DISCLOSURES SET FORTH
                                                        HEREIN.&nbsp;
                                                    </strong>
                                                    Do not access or use the
                                                    Services if you disagree
                                                    with this Privacy Policy and
                                                    Terms of Use. This Privacy
                                                    Policy and our Terms of Use
                                                    expressly supersede prior
                                                    agreements or arrangements
                                                    with you.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        California Residents
                                                    </strong>
                                                    : Please click&nbsp;
                                                    <a href="https://support.hcn.arxchange.com/hc/en-us/articles/4411678336539">
                                                        here
                                                    </a>
                                                    &nbsp;to read
                                                    the&nbsp;Privacy&nbsp;Policy&nbsp;Addendum
                                                    for California Residents for
                                                    information about California
                                                    consumer rights.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        <u>
                                                            Information&nbsp;Collected&nbsp;by
                                                            HCN
                                                        </u>
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        <em>
                                                            Information You
                                                            Provide to HCN&nbsp;
                                                        </em>
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    The information you provide
                                                    includes:
                                                </Paragraph>
                                                <ul>
                                                    <li>
                                                        Name, date of birth, and
                                                        demographic information
                                                        (e.g., age, gender,
                                                        etc.)
                                                    </li>
                                                    <li>
                                                        Home, work, billing,
                                                        and/or shipping
                                                        addresses
                                                    </li>
                                                    <li>Email address</li>
                                                    <li>
                                                        Home, work and/or mobile
                                                        phone number
                                                    </li>
                                                    <li>Password</li>
                                                    <li>
                                                        Health, prescription,
                                                        pharmacy, and related
                                                        information
                                                    </li>
                                                    <li>Messages from you</li>
                                                    <li>
                                                        Credit card information
                                                    </li>
                                                    <li>
                                                        Insurance information
                                                    </li>
                                                    <li>
                                                        Driver's license or
                                                        other government
                                                        identification number
                                                    </li>
                                                    <li>
                                                        Professional information
                                                        (e.g., National Provider
                                                        Number ("NPI"), medical
                                                        specialty)
                                                    </li>
                                                </ul>
                                                <Paragraph>
                                                    If you choose to provide us
                                                    with information about your
                                                    family members or any other
                                                    individual, you represent
                                                    and warrant that you have
                                                    permission to provide this
                                                    information.
                                                </Paragraph>
                                                <Paragraph>
                                                    You agree to let us use and
                                                    share the information you
                                                    provide in order to provide
                                                    and customize the Services,
                                                    to run our business
                                                    (including for the purposes
                                                    of internal business
                                                    operations, analytics, and
                                                    to provide, develop, change,
                                                    market or optimize our
                                                    services and products), and
                                                    to communicate and market to
                                                    you (directly or via third
                                                    parties).&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    You agree that HCN may
                                                    contact you by mail,
                                                    telephone, email, or text
                                                    message (including by an
                                                    automatic telephone dialing
                                                    system) at any of the
                                                    addresses, phone numbers or
                                                    other information provided
                                                    by you or on your behalf in
                                                    connection with your
                                                    account, including for
                                                    marketing purposes. HCN
                                                    advertising and
                                                    communications may include
                                                    health, prescription,
                                                    pharmacy, and related
                                                    information. Should you send
                                                    HCN a direct SMS message
                                                    through a mobile device,
                                                    such a message constitutes
                                                    your prior express written
                                                    consent to receive, review,
                                                    and respond to the messages
                                                    you request to that SMS
                                                    device.
                                                </Paragraph>
                                                <Paragraph>
                                                    In addition to any
                                                    information that you choose
                                                    to submit to us, we and the
                                                    third parties we work with
                                                    may use a variety of
                                                    technologies that
                                                    automatically (or passively)
                                                    collect certain information
                                                    whenever you use the
                                                    Services. This information
                                                    includes but is not limited
                                                    to:
                                                </Paragraph>
                                                <ul>
                                                    <li>
                                                        Geo-location information
                                                    </li>
                                                    <li>
                                                        Internet Protocol (IP)
                                                        address
                                                    </li>
                                                    <li>
                                                        Information about your
                                                        operating system and
                                                        browser, such as its
                                                        maker or version
                                                    </li>
                                                    <li>
                                                        The pages and content of
                                                        our websites/screens
                                                        that you view (including
                                                        URL and screen name)
                                                    </li>
                                                    <li>
                                                        What information,
                                                        content, or
                                                        advertisements you view,
                                                        hover over, or click on
                                                    </li>
                                                    <li>Referrer websites</li>
                                                    <li>Unique identifiers</li>
                                                    <li>Login information</li>
                                                    <li>
                                                        Mobile device
                                                        information, such as
                                                        your telephone number,
                                                        mobile device ID, the
                                                        make, model, operating
                                                        system, capacity and
                                                        settings of your device
                                                    </li>
                                                    <li>
                                                        For healthcare providers
                                                        who are participants in
                                                        HCN, we may receive
                                                        information such as
                                                        names and professional
                                                        information (e.g., NPI,
                                                        medical specialty).
                                                    </li>
                                                </ul>
                                                <Paragraph>
                                                    We and/or third parties we
                                                    work with collect this
                                                    information using
                                                    technologies including but
                                                    not limited to the
                                                    following:
                                                </Paragraph>
                                                <ul>
                                                    <li>
                                                        <strong>
                                                            <u>Cookies</u>
                                                        </strong>
                                                        . We use cookies, tags,
                                                        pixels, SDKs and other
                                                        tracking technology to
                                                        collect, store and track
                                                        your information and use
                                                        of the Services,
                                                        including to save your
                                                        settings, provide
                                                        customizable and
                                                        personalized Services
                                                        and information and
                                                        associate information
                                                        with your account and
                                                        session. Your browser
                                                        and/or device may allow
                                                        you to disable and/or
                                                        control the use of
                                                        cookies. We do not
                                                        control the use of any
                                                        cookies that are
                                                        deposited on your
                                                        computer when you visit
                                                        third-party websites,
                                                        and we expressly
                                                        disclaim responsibility
                                                        for information
                                                        collected through them.
                                                        We permit you to control
                                                        the cookies collected
                                                        via the "Cookie
                                                        Preferences" located at
                                                        the bottom of the
                                                        applicable webpage or
                                                        applicable website.
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            <u>
                                                                Marketing and
                                                                remarketing
                                                                technology
                                                            </u>
                                                        </strong>
                                                        . We may use Google and
                                                        Facebook, and other
                                                        marketing and
                                                        remarketing providers to
                                                        advertise online and
                                                        offline. We may place
                                                        cookies, tags, pixels,
                                                        SDKs and other tracking
                                                        technology on your
                                                        computer or other
                                                        devices, or otherwise
                                                        collect and provide your
                                                        information to assist
                                                        Google, Facebook, other
                                                        third parties, or HCN to
                                                        feed advertisements or
                                                        to send direct mail
                                                        campaigns to you based
                                                        on your past visits to
                                                        HCN and other websites,
                                                        to market to you via and
                                                        to measure the
                                                        effectiveness of
                                                        marketing and
                                                        remarketing; and we may
                                                        also allow other third
                                                        parties, including
                                                        Google, to do so as
                                                        well. We may permit
                                                        third parties to collect
                                                        your personal
                                                        information over time or
                                                        across third-party
                                                        websites, mobile
                                                        applications and/or
                                                        online services,
                                                        including those that
                                                        assist us in operating
                                                        our Services.
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            <u>Web beacons</u>
                                                        </strong>
                                                        .&nbsp;Web beacons are
                                                        graphic image files that
                                                        collect information
                                                        about your use of and
                                                        interactions with the
                                                        Services.
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            <u>
                                                                Embedded scripts
                                                            </u>
                                                        </strong>
                                                        . An embedded script is
                                                        programming code that
                                                        collects information
                                                        about your use of and
                                                        interactions with the
                                                        Services.
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            <u>Plug</u>
                                                        </strong>
                                                        <u>
                                                            -
                                                            <strong>Ins</strong>
                                                        </u>
                                                        <strong>.&nbsp;</strong>
                                                        We may use plug-ins,
                                                        such as Facebook or
                                                        Twitter. Each plug-in is
                                                        the responsibility of
                                                        the plug-in provider,
                                                        and we have no control
                                                        over the information
                                                        that the respective
                                                        provider collects
                                                        through its plug-in or
                                                        how that provider uses
                                                        such information. If you
                                                        are not yet a user of
                                                        the provider or have not
                                                        consented to having your
                                                        data collected and used
                                                        by that provider, you
                                                        should not click on
                                                        those plug-ins. If you
                                                        are already a user of
                                                        the plug-in provider,
                                                        you should refer
                                                        directly to the relevant
                                                        provider for information
                                                        on the kind of personal
                                                        data that the provider
                                                        collects and uses, and
                                                        for what purposes.
                                                    </li>
                                                </ul>
                                                <Paragraph>
                                                    By using our Services, you
                                                    agree to let us use and
                                                    share such information in
                                                    order to provide and
                                                    customize the Services, to
                                                    run our business (including
                                                    for the purposes of internal
                                                    business operations,
                                                    analytics, and to provide,
                                                    develop, change, market or
                                                    optimize our services and
                                                    products), and to
                                                    communicate and market to
                                                    you (directly or via third
                                                    parties).&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    <a href="https://support.hcn.arxchange.com/hc/en-us/articles/360026530331-Advertising-on-HCN">
                                                        <strong>
                                                            Advertising on HCN's
                                                            Websites and Apps
                                                        </strong>
                                                    </a>
                                                </Paragraph>
                                                <Paragraph>
                                                    We use third-party
                                                    advertising companies to
                                                    serve ads when you use the
                                                    Services. These companies
                                                    may use&nbsp;internet or
                                                    electronic&nbsp;information
                                                    about our Services and other
                                                    websites and mobile
                                                    applications in order to
                                                    provide advertisements about
                                                    goods and services of
                                                    interest to you. HCN adheres
                                                    to Digital Advertising
                                                    Alliance principles. If you
                                                    would like more information
                                                    about this practice and to
                                                    know your choices about not
                                                    having this information used
                                                    by these
                                                    companies,&nbsp;click&nbsp;
                                                    <a href="http://www.networkadvertising.org/">
                                                        here
                                                    </a>
                                                    .
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        <em>
                                                            Demographics and
                                                            Interest Information
                                                        </em>
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    The Services have
                                                    implemented Google Analytics
                                                    Demographics and Interest
                                                    reporting. This
                                                    tool&nbsp;requires that we
                                                    permit access to cookie
                                                    identification information
                                                    and&nbsp;enables us to view
                                                    demographic and other
                                                    information aggregated from
                                                    the overall population of
                                                    the visitors to our
                                                    website.&nbsp;The
                                                    information we receive does
                                                    not include your name or
                                                    contact
                                                    information.&nbsp;&nbsp;&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        <em>
                                                            Information From
                                                            Third Parties
                                                        </em>
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    We receive information about
                                                    you from third parties,
                                                    including as detailed
                                                    below:&nbsp;
                                                </Paragraph>
                                                <ol>
                                                    <li>
                                                        <strong>
                                                            <u>
                                                                Healthcare
                                                                professionals
                                                            </u>
                                                        </strong>
                                                        . Some healthcare
                                                        professionals who use
                                                        HCN may text or email
                                                        you a coupon. In order
                                                        to receive the coupon
                                                        from your healthcare
                                                        professional, you must
                                                        provide your contact
                                                        information and your
                                                        prescription
                                                        information. By
                                                        providing
                                                        your&nbsp;healthcare
                                                        professional with your
                                                        contact information and
                                                        your prescription
                                                        information, you are
                                                        requesting to
                                                        be&nbsp;contacted with
                                                        prescription-related
                                                        information, and you
                                                        acknowledge that there
                                                        may be some&nbsp;risk
                                                        that the message could
                                                        be read by a third
                                                        party.&nbsp;We also
                                                        receive information from
                                                        our Medical Groups and
                                                        other healthcare
                                                        partners.
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            <u>
                                                                Marketing
                                                                partners
                                                            </u>
                                                        </strong>
                                                        . We may work with third
                                                        parties to conduct
                                                        marketing campaigns
                                                        and/or receive
                                                        information for
                                                        potential users of our
                                                        Services. Such third
                                                        parties may collect and
                                                        provide to us personal
                                                        information such as your
                                                        name, contact
                                                        information, medical
                                                        information (such as
                                                        condition and
                                                        prescription) and
                                                        demographic information
                                                        (such as age and
                                                        location).&nbsp;Third
                                                        parties may also include
                                                        friends and family
                                                        members who participate
                                                        in our referral program.
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            <u>Data partners</u>
                                                        </strong>
                                                        . We may supplement the
                                                        information we collect
                                                        about you with
                                                        information from third
                                                        parties, including data
                                                        regarding online
                                                        activities across
                                                        devices and online
                                                        platforms.
                                                    </li>
                                                </ol>
                                                <Paragraph>
                                                    By using the Services, you
                                                    agree to let us use and
                                                    share such personal
                                                    information in order to
                                                    provide and customize the
                                                    Services, to run our
                                                    business (including for the
                                                    purposes of internal
                                                    business operations,
                                                    analytics, and to provide,
                                                    change, market or optimize
                                                    our services and products),
                                                    and to communicate and
                                                    market to you (directly or
                                                    via third parties).&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        When do we share
                                                        information that can
                                                        identify&nbsp;you with a
                                                        third party?
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    HCN does not sell personal
                                                    medical information. We do
                                                    not provide your
                                                    personally-identifiable
                                                    medical information to third
                                                    parties in exchange for
                                                    payment.&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    Like many companies, HCN
                                                    uses personal information in
                                                    order to provide and
                                                    customize Services, to run
                                                    our business (including for
                                                    the purposes of internal
                                                    business operations and
                                                    analytics, to provide,
                                                    change, market or optimize
                                                    our services and products),
                                                    and to communicate and
                                                    market to you. In some
                                                    instances, we may use third
                                                    parties to help assist us in
                                                    the above.&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    Here are some examples where
                                                    we may share information
                                                    with third parties:
                                                </Paragraph>
                                                <ul>
                                                    <li>
                                                        To market and advertise
                                                        to you and measure
                                                        performance of marketing
                                                        and advertisements
                                                    </li>
                                                    <li>
                                                        To provide or send you a
                                                        discount card, coupons,
                                                        pharmaceutical
                                                        manufacturer copay cards
                                                        (in cases where you
                                                        provide us with your
                                                        information to determine
                                                        your eligibility for
                                                        such copay card and you
                                                        meet the eligibility
                                                        criteria for such copay
                                                        card) and/or
                                                        communications (such as
                                                        information about your
                                                        account and marketing)
                                                    </li>
                                                    <li>
                                                        To process your credit
                                                        card information (in
                                                        cases where you provide
                                                        us with your credit card
                                                        information) and track
                                                        payments
                                                    </li>
                                                    <li>
                                                        To supplement our data
                                                    </li>
                                                    <li>
                                                        To fulfill, deliver,
                                                        develop and enhance
                                                        services and products
                                                    </li>
                                                    <li>To store data</li>
                                                    <li>To analyze data</li>
                                                    <li>
                                                        To address or handle
                                                        patient advocacy support
                                                        requests
                                                    </li>
                                                    <li>
                                                        For information
                                                        security, fraud
                                                        detection and prevention
                                                    </li>
                                                    <li>
                                                        For identity and
                                                        professional credential
                                                        verification
                                                    </li>
                                                    <li>
                                                        For healthcare
                                                        providers, we may
                                                        provide third parties we
                                                        work with to provide
                                                        sponsored programs,
                                                        content or
                                                        advertisements on our
                                                        websites or mobile
                                                        applications, with your
                                                        information, such as
                                                        name, NPI, medical
                                                        specialty and practice
                                                        information, and
                                                        information about your
                                                        activities and
                                                        engagement with the
                                                        Services
                                                    </li>
                                                    <li>
                                                        If we are required to do
                                                        so by law or if we have
                                                        a good faith belief that
                                                        disclosure is necessary
                                                        to (1) comply with the
                                                        law or with legal
                                                        process served on us;
                                                        (2) protect and defend
                                                        our rights or property;
                                                        or (3) act in an
                                                        emergency to protect
                                                        someone's safety.
                                                    </li>
                                                </ul>
                                                <Paragraph>
                                                    HCN may transfer its user
                                                    database, including personal
                                                    information contained
                                                    therein, to a third party
                                                    who acquires all or
                                                    substantially all of the
                                                    assets or stock of HCN
                                                    whether by merger,
                                                    acquisition, reorganization
                                                    or otherwise.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        Additional Privacy
                                                        Disclosures Relating to
                                                        HCN
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    HCN offers a platform that
                                                    enables health care
                                                    professionals ("Providers")
                                                    and patients to connect. If
                                                    you are under 18, please do
                                                    not attempt to register with
                                                    us or provide any personal
                                                    information about yourself
                                                    to us at any time for the
                                                    Services. We assume our
                                                    Services are used by adults.
                                                    If a parent believes that
                                                    his or her child has
                                                    provided us with personal
                                                    information, he or she can
                                                    contact us via email or
                                                    postal mail as listed below.
                                                </Paragraph>

                                                <Paragraph>
                                                    <strong>
                                                        What about kids'
                                                        personally identifiable
                                                        information?
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    The Services are only for
                                                    users of the age of 18 or
                                                    older. If you are under the
                                                    age of 18, please do not
                                                    attempt to register with us
                                                    or provide any personal
                                                    information about yourself
                                                    to us at any time for the
                                                    Services. We assume our
                                                    Services are used by adults.
                                                    We respect the privacy of
                                                    parents and children and are
                                                    committed to complying with
                                                    the Children's Online
                                                    Privacy Protection Act
                                                    ("COPPA"). With respect to
                                                    those Services that ask for
                                                    age, we do not collect
                                                    personal information from
                                                    individuals who identify
                                                    themselves as under the age
                                                    of 13 except in those
                                                    limited circumstances where
                                                    we believe that such
                                                    collection is permitted
                                                    under COPPA.
                                                </Paragraph>
                                                <Paragraph>
                                                    We may ask prospective users
                                                    to provide their age during
                                                    the registration process and
                                                    at other times while using
                                                    the Services. If a user
                                                    indicates that he or she is
                                                    under the age of 13 then all
                                                    personal data collected
                                                    during the registration
                                                    process is automatically
                                                    deleted except that we may
                                                    retain and use limited
                                                    information, such as the
                                                    child's email address, for a
                                                    limited period of time, in
                                                    those cases where we believe
                                                    that the collection and use
                                                    of such information is
                                                    permitted under COPPA.
                                                </Paragraph>
                                                <Paragraph>
                                                    If a parent believes that
                                                    his or her child has
                                                    provided us with personal
                                                    information, he or she can
                                                    contact us via email or
                                                    postal mail as listed below.
                                                    We will promptly delete the
                                                    information upon learning
                                                    that it relates to a child
                                                    under the age of 13.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        Additional privacy
                                                        related information
                                                    </strong>
                                                    &nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>Security</strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    Although we will have
                                                    reasonable security
                                                    safeguards with respect to
                                                    the Services, we cannot
                                                    ensure or warrant the
                                                    security of any information
                                                    transmitted to us over the
                                                    internet or other electronic
                                                    means (such as text
                                                    messages), and we accept no
                                                    liability for any
                                                    unintentional disclosure.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        European Union Visitors
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    Please do not use the
                                                    Services if you are not a
                                                    resident of the United
                                                    States. The Services are
                                                    intended for use only by
                                                    residents of the United
                                                    States. Use by visitors from
                                                    the European Economic Area,
                                                    or EEA, is prohibited.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        Deletion Requests,
                                                        Unsubscribing and
                                                        Opt-Outs
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    All users nationwide can
                                                    request deletion of their
                                                    data as specified in the
                                                    California Consumer Privacy
                                                    Act. You can submit a
                                                    request by visiting&nbsp;
                                                    <a href="https://www.hcn.arxchange.com/requestdeletion">
                                                        https://www.hcn.arxchange.com/requestdeletion
                                                    </a>
                                                    . Please note that we will
                                                    need to verify your request
                                                    before we can fulfill it,
                                                    including verifying your
                                                    identity.&nbsp;
                                                </Paragraph>
                                                <Paragraph>
                                                    If you cancel or suspend the
                                                    HCN service, we may still
                                                    use the information about
                                                    your use of our Services for
                                                    record-keeping purposes, to
                                                    improve our Services, to
                                                    provide you with discounts,
                                                    and to market to you (unless
                                                    you have opted out).
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        Changes to this Privacy
                                                        Policy
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    We may make changes to our
                                                    privacy policy from time to
                                                    time. When we do so, we will
                                                    post the revised privacy
                                                    policy on our website.
                                                    Please check the revision
                                                    date at the top of this page
                                                    to determine if the policy
                                                    has been modified since you
                                                    last reviewed it. The
                                                    modified privacy policy will
                                                    be effective immediately
                                                    upon posting on the service.
                                                    Your continued use of the
                                                    Services after the posting
                                                    of the modified privacy
                                                    policy constitutes your
                                                    agreement to abide and be
                                                    bound by it. We encourage
                                                    you to periodically review
                                                    this page for the latest
                                                    information on our privacy
                                                    practices. If you object to
                                                    any modification, your sole
                                                    recourse is to stop using
                                                    the Services.
                                                </Paragraph>
                                                <Paragraph>
                                                    <strong>
                                                        Contacting HCN
                                                    </strong>
                                                </Paragraph>
                                                <Paragraph>
                                                    Please address our comments
                                                    or questions about this
                                                    privacy policy via phone by
                                                    calling 1-800-237-5044
                                                </Paragraph>
                                            </div>
                                        </div>
                                    </section>
                                </article>
                            </div>
                        </div>
                        <div
                            className={`${COMPONENT_CLASS}__container__footer`}>
                            <div className={`${COMPONENT_CLASS}__footer__logo`}>
                                <img
                                    alt="Healthcare Commerce Network Logo"
                                    src={HcnLogoGrey}
                                />
                            </div>
                            <div
                                className={`${COMPONENT_CLASS}__footer__links`}>
                                <Anchor to="/terms-of-service" size="xsmall">
                                    Terms of Service
                                </Anchor>{" "}
                                |{" "}
                                <Anchor to="/privacy-policy" size="xsmall">
                                    Privacy Policy
                                </Anchor>
                                |{" "}
                                <Anchor to="/contact-us" size="xsmall">
                                    Contact Us
                                </Anchor>
                            </div>
                            <Paragraph size="xsmall" type="white">
                                &copy; {DateTime.now().toFormat("yyyy")}
                            </Paragraph>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;
