import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Label } from "atoms/typography/label";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import { PersonalizeProgress } from "molecules/personalize-progress/personalize-progress";
import ProcedureBanner, {
    ProcedureBannerType,
} from "molecules/procedure-banner/procedure-banner";
import React from "react";
import { useHistory } from "react-router-dom";
import useCart from "utilities/hooks/use-cart";
import usePriceDetails from "utilities/hooks/use-price-details";
import StringUtils from "utilities/string-utils";
import CareNowPayLater from "./care-now-pay-later";
import "./personalize-pay-page.scss";

const COMPONENT_CLASS = "c-personalize-pay";
const getCurrencyIfNotNull = (x: number | null) =>
    x != null ? StringUtils.formatCurrency(x) : "N/A";

function PersonalizePayPage() {
    const history = useHistory();
    const {
        cart,
        clearPaymentTerms,
        hasInsurance,
        isExpired,
        member,
        subtotal,
    } = useCart({
        autoLoadCart: true,
        autoLoadPriceJob: true,
        autoLoadInsurance: true,
    });

    const { discountTotal: discount, personalizedPriceTotal: personalPrice } =
        cart ?? {};

    const priceDetails = usePriceDetails({
        cart,
        hasInsurance,
        grossCharges: subtotal,
    });

    const copayAmount = getCurrencyIfNotNull(priceDetails.copayAmount);
    const deductibleAmount = getCurrencyIfNotNull(
        priceDetails.deductibleAmount
    );
    const coinsuranceAmount = getCurrencyIfNotNull(
        priceDetails.coinsuranceAmount
    );
    const yourSubTotal = getCurrencyIfNotNull(priceDetails.yourSubTotal);
    const hasPersonalizedDiscount = priceDetails.yourPersonalizedDiscount > 0;
    const hasPayInFullDiscount = priceDetails.payInFullDiscount > 0;

    const handlePayNowClick = () => {
        clearPaymentTerms();
        history.push(`/payment`);
    };

    return (
        <div>
            <CartLayout
                isLocked={true}
                header={
                    <PersonalizeProgress
                        title="Your Price"
                        currentStep={-1}
                        showProgress={false}
                    />
                }>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__content`}>
                        {cart != null && member != null && (
                            <ProcedureBanner
                                completedOn={cart.completedOn}
                                expiresOn={cart.expiresOn}
                                cartId={cart.id}
                                member={member}
                                displayType={
                                    isExpired
                                        ? ProcedureBannerType.Expired
                                        : ProcedureBannerType.Decline
                                }
                            />
                        )}
                        <Heading size="h2">Choose Payment</Heading>
                        <Paragraph>
                            Please select your preferred form of payment.
                        </Paragraph>
                        <div className={`${COMPONENT_CLASS}__section primary`}>
                            <div
                                className={`${COMPONENT_CLASS}__section__content two`}>
                                <div
                                    className={`${COMPONENT_CLASS}__section__content__item`}>
                                    <div>
                                        <Label type="light">Copay Amount</Label>
                                        <Paragraph size="small" type="white">
                                            {copayAmount}
                                        </Paragraph>
                                    </div>
                                    <div>
                                        <Label type="light">
                                            Deductible Amount
                                        </Label>
                                        <Paragraph size="small" type="white">
                                            {deductibleAmount}
                                        </Paragraph>
                                    </div>
                                    <div>
                                        <Label type="light">
                                            Coinsurance Amount
                                        </Label>
                                        <Paragraph size="small" type="white">
                                            {coinsuranceAmount}
                                        </Paragraph>
                                    </div>
                                    <div>
                                        <Label type="light">
                                            Your Subtotal
                                        </Label>
                                        <Paragraph size="small" type="white">
                                            {yourSubTotal}
                                        </Paragraph>
                                    </div>
                                </div>
                                <div
                                    className={`${COMPONENT_CLASS}__section__content__item between`}>
                                    <div>
                                        <div>
                                            <Heading size="h4">You Pay</Heading>
                                            <Paragraph
                                                cssClassName={`${COMPONENT_CLASS}__amount`}
                                                size="large"
                                                type="white">
                                                {StringUtils.formatCurrency(
                                                    personalPrice || 0
                                                )}
                                            </Paragraph>
                                        </div>
                                    </div>
                                    {hasPersonalizedDiscount && (
                                        <div>
                                            <Label type="light">
                                                Including Personalized Discount
                                            </Label>
                                            <Paragraph
                                                size="small"
                                                type="white">
                                                {StringUtils.formatCurrency(
                                                    discount || 0
                                                )}
                                            </Paragraph>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`${COMPONENT_CLASS}__section white`}>
                            <div className={`${COMPONENT_CLASS}__pay-now`}>
                                <div
                                    className={`${COMPONENT_CLASS}__pay-now__intro`}>
                                    <Heading size="h4">Pay Now</Heading>
                                    <Paragraph size="small">
                                        Pay online with your credit card or bank
                                        account and receive a pre-appointment
                                        discount.
                                    </Paragraph>
                                    {hasPayInFullDiscount && (
                                        <Paragraph
                                            size="xsmall"
                                            weight="semibold">
                                            SAVE{" "}
                                            {StringUtils.formatCurrency(
                                                priceDetails.payInFullDiscount
                                            )}
                                        </Paragraph>
                                    )}
                                </div>
                                <Button onClick={handlePayNowClick}>
                                    Select &gt;
                                </Button>
                            </div>
                        </div>
                        <CareNowPayLater />
                    </div>
                </div>
            </CartLayout>
        </div>
    );
}

export default PersonalizePayPage;
