import algoliasearch from "algoliasearch/lite";
import { Settings } from "models/interfaces/settings";

const client = (settings: Settings) =>
    algoliasearch(settings.algoliaAppId, settings.algoliaApiKey);

const indexByType = (settings: Settings, type: "insurance" | "bundle") =>
    type === "insurance"
        ? client(settings).initIndex(settings.algoliaInsuranceIndex)
        : client(settings).initIndex(settings.algoliaBundleIndex);

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const SearchUtils = {
    indexByType,
};

export default SearchUtils;

// #endregion Exports
