import React from "react";
import "./spinner.scss";

const COMPONENT_CLASS = "c-spinner";

export interface SpinnerProps {
    size?: "small" | "large";
    variant?: "primary" | "secondary";
}

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Spinner: React.FC<any> = ({
    size = "large",
    variant = "primary",
}: SpinnerProps) => {
    return <div className={`${COMPONENT_CLASS} ${size} ${variant}`}></div>;
};

export { Spinner };
