import { useEffect, useMemo, useState } from "react";
import MemberCart from "models/interfaces/member-cart";
import PaymentOptions from "models/interfaces/payment-options";
import useCart from "utilities/hooks/use-cart";

const usePaymentPlans = () => {
    const { cart, paymentTerms } = useCart();
    const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(0);

    const { filteredPaymentPlanOptions, maxPaymentTermLength } = useMemo(
        () => getFilteredPaymentPlanOptions(cart),
        [cart]
    );

    const selectedPlan = filteredPaymentPlanOptions[selectedPlanIndex];

    useEffect(() => {
        const defaultTermLength = Math.ceil(
            filteredPaymentPlanOptions.length / 2
        );

        if (paymentTerms == null) {
            setSelectedPlanIndex(defaultTermLength);
            return;
        }

        const paymentPlanOptionIndex = filteredPaymentPlanOptions.findIndex(
            (p) => p.termInMonths === paymentTerms
        );

        if (paymentPlanOptionIndex === -1) {
            setSelectedPlanIndex(defaultTermLength);
            return;
        }

        setSelectedPlanIndex(paymentPlanOptionIndex);
    }, [filteredPaymentPlanOptions, paymentTerms]);

    return {
        selectedPlanIndex,
        filteredPaymentPlanOptions,
        minPaymentTermLength: 2,
        maxPaymentTermLength,
        selectedPlan,
        setSelectedPlanIndex,
    };
};

const getFilteredPaymentPlanOptions = (cart?: MemberCart) => {
    const numDiscountResponses = cart?.priceJob?.discountResponses?.length ?? 0;

    const paymentPlanOptions =
        cart?.priceJob?.discountResponses?.[numDiscountResponses - 1]
            ?.paymentOptions ?? [];

    const paymentOptions = paymentPlanOptions
        .filter((e) => e.termInMonths >= 2)
        .sort(
            (a: PaymentOptions, b: PaymentOptions) =>
                a.termInMonths - b.termInMonths
        );

    if (paymentOptions.length < 1) {
        return { filteredPaymentPlanOptions: [], maxPaymentTermLength: 0 };
    }

    const {
        termInMonths: maxPaymentTermLength,
        monthlyInstallmentAmount: maxMonthlyInstallmentAmount,
    } = paymentOptions[paymentOptions.length - 1];

    const total = maxPaymentTermLength * maxMonthlyInstallmentAmount;

    const filteredPaymentPlanOptions = Array.from(
        { length: maxPaymentTermLength - 1 },
        () => 0
    ).map((_n, i) => {
        const termInMonths = i + 2;

        return {
            termInMonths,
            monthlyInstallmentAmount: total / termInMonths,
        };
    });

    return { filteredPaymentPlanOptions, maxPaymentTermLength };
};

export default usePaymentPlans;
