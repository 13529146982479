import {
    ClipboardIcon,
    IdentificationIcon,
    InformationCircleIcon,
    SearchIcon,
    XIcon,
} from "@heroicons/react/outline";
import { Button } from "atoms/buttons/button";
import { Search } from "atoms/forms/search";
import { TextInput } from "atoms/forms/text-input";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import CartLayout from "layouts/cart-layout/cart-layout";
import Category from "models/interfaces/category";
import { Modal } from "molecules/modal/modal";
import CategorySearch from "organisms/category-search/category-search";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCartState } from "utilities/contexts/cart-state-context";
import StringUtils from "utilities/string-utils";
import "../../organisms/member-form/member-form.scss";
import "./procedure-search-page.scss";

const COMPONENT_CLASS = "c-procedure-search";
const FORM_COMPONENT_CLASS = "c-member-form";

function ProcedureSearchPage() {
    const [isCptOpen, setIsCptOpen] = useState(false);
    const [isInsuranceCheckOpen, setIsInsuranceCheckOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const history = useHistory();
    const { state: cartState } = useCartState();

    useEffect(() => {
        const historyPath: any = {
            pathname: history.location.pathname,
        };
        if (!StringUtils.isEmpty(selectedCategory?.categoryKey)) {
            historyPath.search = `?categoryKey=${selectedCategory?.categoryKey}`;
        }
        history.replace(historyPath);
    }, [history, selectedCategory?.categoryKey]);

    const member = cartState.member;
    const primaryInsurance =
        member?.memberInsurances && member.memberInsurances.length > 0
            ? member?.memberInsurances[0]
            : null;

    return (
        <div>
            <CartLayout fullscreen={true} fullwidth={true} isLocked={false}>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__content__wrapper`}>
                        <div className={`${COMPONENT_CLASS}__content`}>
                            <Heading size="h2" type="white">
                                What Procedure does {member?.firstName} Need?
                            </Heading>
                            <Paragraph type="light">
                                Search by procedure name, current procedural
                                terminology (CPT) code, or keyword for the
                                non-urgent procedure ordered by your physician.
                            </Paragraph>
                            <Search
                                placeholder="Name, CPT Code, or Keyword"
                                isLocked={false}
                                onChange={(text) => setSearchText(text)}
                                onSearch={() =>
                                    history.push(
                                        `/procedures/result?q=${searchText}`
                                    )
                                }
                                value={searchText}
                            />
                            <div
                                className={`${COMPONENT_CLASS}__content__links`}>
                                <Button
                                    onClick={() => setIsCptOpen(true)}
                                    size="large"
                                    type="plain">
                                    How to use a CPT Code
                                </Button>
                            </div>
                            {/* <Anchor
                            to={'/procedures'}
                            type="purple">Have an order from a physician?</Anchor> */}
                        </div>
                    </div>
                    <CategorySearch
                        handleCategorySearch={(categoryKey: string) =>
                            history.push(
                                `/procedures/result?q=&categoryKey=${categoryKey}`
                            )
                        }
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        type="white"
                    />
                    <div className={`${COMPONENT_CLASS}__cta__wrapper`}>
                        <div className={`${COMPONENT_CLASS}__cta`}>
                            <div>
                                <IdentificationIcon />
                            </div>
                            <div>
                                <Paragraph size="small" type="color">
                                    Insurance information provided for this
                                    patient will be used to determine in-and
                                    out-of-network coverage. Please make sure
                                    insurance information is up-to-date before
                                    searching.
                                </Paragraph>
                            </div>
                            <div>
                                <Button
                                    onClick={() =>
                                        setIsInsuranceCheckOpen(true)
                                    }>
                                    Check Insurance
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CartLayout>
            <Modal
                isFullWidth={true}
                isOpen={isCptOpen}
                isLoading={false}
                onClose={setIsCptOpen}
                title="">
                <div className={`${COMPONENT_CLASS}__cpt-code`}>
                    <div
                        className={`${COMPONENT_CLASS}__cpt-code__close`}
                        onClick={() => setIsCptOpen(false)}>
                        <XIcon />
                        Close
                    </div>
                    <div
                        className={`${COMPONENT_CLASS}__cpt-code__header c-modal__header`}>
                        <div className="c-modal__header__title">
                            <Heading size="h2">Using a CPT Code</Heading>
                        </div>
                    </div>
                    <div className={`${COMPONENT_CLASS}__cpt-code__section`}>
                        <Paragraph>
                            Current Procedural Terminology code or CPT code is a
                            number assigned to a medical, surgical, or
                            diagnostic task or service ordered by a healthcare
                            provider.
                        </Paragraph>
                        <div
                            className={`${COMPONENT_CLASS}__cpt-code__content`}>
                            <div>
                                <ClipboardIcon />
                                <Paragraph size="small">
                                    You can find your CPT code on your Physician
                                    Order. A Physician Order is required to
                                    perform medical procedures.
                                </Paragraph>
                            </div>
                            <div>
                                <SearchIcon />
                                <Paragraph size="small">
                                    To search by CPT code, you can enter the
                                    number following "CPT" or all alphanumeric
                                    characters.
                                </Paragraph>
                            </div>
                            <div>
                                <InformationCircleIcon />
                                <Paragraph size="small">
                                    For assistance identifying your CPT code,
                                    please contact your healthcare provider.
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                buttons={[
                    {
                        label: "Update Insurance",
                        onClick: () =>
                            history.push(`/dashboard/profile/${member?.id}`, {
                                from: "procedure-search-page",
                            }),
                        type: "clear",
                    },
                    {
                        label: "Continue To Search",
                        onClick: () => setIsInsuranceCheckOpen(false),
                        type: "primary",
                    },
                ]}
                isFullWidth={true}
                isOpen={isInsuranceCheckOpen}
                isLoading={false}
                onClose={setIsInsuranceCheckOpen}
                title="Check Insurance">
                <div className={`${COMPONENT_CLASS}__confirm`}>
                    <div
                        className={`${FORM_COMPONENT_CLASS}__section__wrapper`}>
                        <Paragraph size="large">
                            Insurance information provided for this patient will
                            be used to determine in-and out-of-network coverage.
                            Please make sure insurance information is up-to-date
                            before searching.
                        </Paragraph>
                    </div>
                    {!primaryInsurance && (
                        <div
                            className={`${FORM_COMPONENT_CLASS}__section__wrapper`}>
                            <Paragraph>No Insurance Provided</Paragraph>
                        </div>
                    )}
                    {primaryInsurance && (
                        <div
                            className={`${FORM_COMPONENT_CLASS}__section__wrapper double`}>
                            <TextInput
                                isLocked={true}
                                name="PrimaryPolicyFirstName"
                                label="Primary Policy Holder First Name"
                                placeholder="Primary Policy Holder First Name"
                                value={primaryInsurance.firstName}
                            />
                            <TextInput
                                isLocked={true}
                                name="PrimaryPolicyLastName"
                                label="Primary Policy Holder Last Name"
                                placeholder="Primary Policy Holder Last Name"
                                value={primaryInsurance.lastName}
                            />
                            <TextInput
                                name="PrimaryInsurancePlanKey"
                                isLocked={true}
                                label="Insurance Plan"
                                placeholder="Insurance Plan"
                                value={
                                    primaryInsurance.insurancePlanName ||
                                    primaryInsurance.insurancePlanKey
                                }
                            />
                            <div
                                className={`${FORM_COMPONENT_CLASS}__section__wrapper double`}>
                                <TextInput
                                    isLocked={true}
                                    name="PrimaryInsuranceMemberId"
                                    label="Member ID"
                                    placeholder="Member ID"
                                    value={primaryInsurance.insuranceMemberId}
                                />
                                <TextInput
                                    isLocked={true}
                                    maxLength={50}
                                    name="PrimaryGroupNum"
                                    label="Group No."
                                    placeholder="Group No."
                                    value={primaryInsurance.groupNum}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default ProcedureSearchPage;
