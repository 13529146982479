import axios, { AxiosResponse } from "axios";
import { Settings } from "models/interfaces/settings";
import env from "react-dotenv";

// Set the base URL for Axios requests
axios.defaults.baseURL = env.API_URL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

/**
 * Fetch settings from the backend API.
 *
 * @param {string | undefined} idToken - Cognito user ID token for authentication.
 * @returns {Promise<AxiosResponse<Settings>>}
 */
const getSettings = async (
    idToken?: string
): Promise<AxiosResponse<Settings>> => {
    // Set the Authorization header only if idToken is provided
    if (idToken) {
        axios.defaults.headers.common["Authorization"] = `${idToken}`;
    }

    return axios.get<Settings>("/api/settings").then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const SettingsService = {
    getSettings,
};

export default SettingsService;
