import { MemberRelationship } from "./enumerations/member-relationship";
import { ListOptions } from "./interfaces/list-options";

export const MemberRelationshipOptions: ListOptions[] = [
    {
        value: MemberRelationship.Self.toString(),
        label: MemberRelationship[MemberRelationship.Self],
    },
    {
        value: MemberRelationship.Spouse.toString(),
        label: MemberRelationship[MemberRelationship.Spouse],
    },
    {
        value: MemberRelationship.Child.toString(),
        label: MemberRelationship[MemberRelationship.Child],
    },
    {
        value: MemberRelationship.Parent.toString(),
        label: MemberRelationship[MemberRelationship.Parent],
    },
    {
        value: MemberRelationship.LegalGuardian.toString(),
        label: "Legal Guardian",
    },
];
