import React from "react";
import "./toggle.scss";

const COMPONENT_CLASS = "c-toggle";

interface ToggleOption {
    label: string;
    isSelected: boolean;
    url: string;
}

interface ToggleProps {
    options: ToggleOption[];
    onClick: (option: ToggleOption) => void;
}

/**
 * Primary UI component for user interaction
 */
export const Toggle = ({ options = [], onClick }: ToggleProps) => {
    return (
        <div className={COMPONENT_CLASS}>
            <div
                className={`${COMPONENT_CLASS}__overlay ${
                    options[0].isSelected ? "left" : "right"
                }`}></div>
            {options.map((option, index) => (
                <div
                    className={`${COMPONENT_CLASS}__option ${
                        option.isSelected ? "selected" : ""
                    }`}
                    key={index}
                    onClick={() => onClick(option)}>
                    {option.label}
                </div>
            ))}
        </div>
    );
};
