import axios, { AxiosResponse } from "axios";
import Member from "models/interfaces/member";
import StringUtils from "utilities/string-utils";
import env from "react-dotenv";

//axios.defaults.baseURL = env.API_URL;

const createMember = async (
    member: Member,
    idToken: string
): Promise<AxiosResponse<Member>> => {
    try {
        const response = await axios.post<Member>("/api/members", member, {
            headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
            },
        });
        return response;
    } catch (error) {
        console.error("Error in createMember function:", error);
        throw error;
    }
};

const deleteMember = async (
    member: Member,
    idToken: string
): Promise<AxiosResponse> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.delete(`/api/members/${member.id}`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const getMember = async (
    id: string,
    idToken: string
): Promise<AxiosResponse<Member> | null> => {
    if (StringUtils.isEmpty(id)) {
        return null;
    }
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<Member>(`/api/members/${id}`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const getMembers = async (
    idToken: string
): Promise<AxiosResponse<Member[]>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<Member[]>("/api/members").then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const updateMember = async (
    id: string,
    member: Member,
    idToken: string
): Promise<AxiosResponse<Member>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.put<Member>(`/api/members/${id}`, member).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const MemberService = {
    createMember,
    deleteMember,
    getMember,
    getMembers,
    updateMember,
};

export default MemberService;
