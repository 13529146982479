import React from "react";
import { Link } from "react-router-dom";
import "./anchor.scss";

interface AnchorProps {
    cssClassName?: string;
    children: React.ReactNode;
    size?: "xsmall" | "small" | "medium" | "large";
    type?: "primary" | "color" | "primary-button" | "plain";
    to: string;
}

/**
 * Primary UI component
 */
export const Anchor = ({
    cssClassName,
    children,
    size = "medium",
    type = "primary",
    to,
    ...props
}: AnchorProps) => {
    return (
        <Link
            to={to}
            className={`c-anchor ${[size, type, cssClassName || ""].join(" ")}`}
            {...props}>
            {children}
        </Link>
    );
};
