import { useCallback, useEffect, useMemo } from "react";
import { useCartState } from "utilities/contexts/cart-state-context";
import PriceJob from "models/interfaces/price-job";
import PriceService from "services/price-service";
import { PriceJobStatus } from "models/enumerations/price-job-status";
import CartState from "models/interfaces/cart-state";
import MemberCart from "models/interfaces/member-cart";

// Interfaces

export interface UsePriceJobServiceHookOptions {
    autoLoadPriceJob?: boolean;
    refreshRateMs?: number;
}

export interface UsePriceJobServiceHook {
    isProcessing: boolean;
    isProcessed: boolean;
    data?: PriceJob;
    load(): void;
    statusDescription: string;
}

// Constants

const REFRESH_INTERVAL = 1000 * 5; // 5 seconds

/**
 * Hook to get the price job for a cart.
 */
export default function usePriceJobService({
    autoLoadPriceJob = false,
    refreshRateMs = REFRESH_INTERVAL,
}: UsePriceJobServiceHookOptions = {}): UsePriceJobServiceHook {
    const {
        state: { cart, member, priceJob: priceJobLoadingState },
        setState: setCartState,
    } = useCartState();

    const {
        data: priceJob,
        isLoaded: isProcessed = false,
        isLoading: isProcessing = autoLoadPriceJob,
    } = priceJobLoadingState ?? {};

    const { status } = priceJob ?? {};

    const statusDescription = useMemo(
        () => getStatusDescription(status),
        [status]
    );

    const cartId = cart?.id;
    const memberId = member?.id;
    const priceJobId = priceJob?.id;

    const setPriceJobLoading = useCallback(
        () => setCartState(priceJobLoading),
        [setCartState]
    );

    const setPriceJobLoaded = useCallback(
        (data?: PriceJob) => setCartState(priceJobLoaded(data)),
        [setCartState]
    );

    const setPriceJob = useCallback(
        (data?: PriceJob) => {
            setCartState(priceJobUpdated(data));
        },
        [setCartState]
    );

    const load = useCallback(
        (abortSignal?: AbortSignal) => {
            if (!cartId || !memberId || !priceJobId) {
                return;
            }

            setPriceJobLoading();

            const callApi = async () => {
                try {
                    const response = await PriceService.getJob(
                        memberId,
                        cartId,
                        priceJobId,
                        abortSignal
                    );
                    const isLoaded =
                        response?.data?.status === PriceJobStatus.Complete;

                    setCartState((prev): CartState => {
                        const cart = prev.cart?.id
                            ? {
                                  ...prev.cart,
                                  priceJob: response?.data,
                                  priceJobId: response?.data.id,
                              }
                            : undefined;

                        return {
                            ...prev,
                            cart,
                            priceJob: {
                                data: response?.data,
                                isLoaded,
                                isLoading: !isLoaded,
                            },
                        };
                    });

                    if (isLoaded && timer) {
                        setPriceJobLoaded(response?.data);
                        clearInterval(timer);
                        return;
                    }

                    setPriceJob(response?.data);
                } catch (e) {
                    console.error("Error loading price job api", e);
                }
            };

            const timer = setInterval(callApi, refreshRateMs);

            if (abortSignal != null) {
                abortSignal.onabort = () => {
                    if (timer) {
                        clearInterval(timer);
                    }
                };
            }

            callApi();
        },
        [
            cartId,
            memberId,
            priceJobId,
            refreshRateMs,
            setCartState,
            setPriceJob,
            setPriceJobLoaded,
            setPriceJobLoading,
        ]
    );

    useEffect(() => {
        if (!autoLoadPriceJob) {
            return;
        }

        const abortController = new AbortController();

        try {
            load(abortController.signal);
        } catch (error) {
            console.error("Error in usePriceJobService useEffect:", error);
        }

        return () => abortController.abort();
    }, [autoLoadPriceJob, load]);
    return {
        isProcessed,
        isProcessing,
        data: priceJob,
        load: () => load(),
        statusDescription,
    };
}

// Local Functions

const calculatePersonalizedPriceTotal = (priceJob?: PriceJob) => {
    if (priceJob == null) {
        return 0;
    }

    const { discountResponses = [], status } = priceJob;

    if (status !== PriceJobStatus.Complete || discountResponses.length < 1) {
        return 0;
    }
    return discountResponses.reduce((prev, curr) => {
        return (prev += curr.personalPrice);
    }, 0);
};

const getStatusDescription = (status?: PriceJobStatus): string => {
    switch (status) {
        case PriceJobStatus.Scheduled:
        case PriceJobStatus.PatientSearch:
        case PriceJobStatus.PatientNew:
        case PriceJobStatus.PatientPendingCreate:
            return "Step 1 - Finding & Updating Patient Record";
        case PriceJobStatus.OrderCreate:
            return "Step 2 - Validating Patient's Insurance Coverage";
        case PriceJobStatus.EstimateSubmit:
            return "Step 3 - Checking for Financial Aid Eligibility";
        case PriceJobStatus.DiscountScoring:
            return "Step 4 - Personalizing Out-of-Pocket Price";
        case PriceJobStatus.Error:
            return "Error - There was an error processing your request. Please try again";
        case PriceJobStatus.Complete:
        default:
            return "";
    }
};

const priceJobLoaded =
    (priceJob?: PriceJob) =>
    (cartState: CartState): CartState => {
        const { personalizedPriceTotal = 0 } = cartState.cart ?? {};

        const cart: MemberCart | undefined =
            cartState.cart == null
                ? undefined
                : {
                      ...cartState.cart,
                      priceJob: priceJob,
                      personalizedPriceTotal:
                          personalizedPriceTotal > 0
                              ? personalizedPriceTotal
                              : calculatePersonalizedPriceTotal(priceJob),
                  };

        return {
            ...cartState,
            cart,
            priceJob: {
                ...cartState.priceJob,
                data: priceJob ?? cartState.priceJob?.data,
                isLoaded: true,
                isLoading: false,
            },
        };
    };

const priceJobLoading = (cartState: CartState): CartState => {
    return {
        ...cartState,
        priceJob: {
            isLoaded: false,
            ...cartState.priceJob,
            isLoading: true,
        },
    };
};

const priceJobUpdated =
    (priceJob?: PriceJob) =>
    (cartState: CartState): CartState => {
        return {
            ...cartState,
            priceJob: {
                isLoaded: false,
                isLoading: false,
                ...cartState.priceJob,
                data: priceJob ?? cartState.priceJob?.data,
            },
        };
    };
