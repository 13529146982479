import axios, { AxiosResponse } from "axios";
import PriceJob from "models/interfaces/price-job";
import StringUtils from "utilities/string-utils";

const getJob = async (
    memberId: string,
    cartId: string,
    priceJobId?: string,
    abortSignal?: AbortSignal
): Promise<AxiosResponse<PriceJob> | null> => {
    if (StringUtils.isEmpty(priceJobId)) {
        return null;
    }
    try {
        const response = await axios.get<PriceJob>(
            `/api/members/${memberId}/cart/${cartId}/job/${priceJobId}`,
            {
                signal: abortSignal,
            }
        );
        return response;
    } catch (error) {
        // Handle errors here
        if (axios.isAxiosError(error)) {
            console.error("Axios error:", error.response?.data);
            throw error.response;
        } else {
            console.error("Unexpected error:", error);
            throw error;
        }
    }
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const PriceService = {
    getJob,
};

export default PriceService;

// #endregion Exports
