import React from "react";
import { useAuth } from "utilities/contexts/auth-state-context";

interface LogoProps {
    className?: string;
}

const Logo = ({ className }: LogoProps) => {
    const { settings } = useAuth();

    return settings?.siteLogo ? (
        <img
            className={className}
            alt={`${settings?.siteName} Logo`}
            src={settings?.siteLogo}
        />
    ) : null;
};

export default Logo;
