import React from "react";
import { ShoppingCartIcon } from "@heroicons/react/outline";

const NoActivePaymentPlans = () => {
    return (
        <div className="flex w-full flex-col items-center justify-center space-y-2 rounded-md bg-white px-8 py-12">
            <ShoppingCartIcon className="h-10 w-10 text-primary-700" />
            <h4 className="text-grey-700">No Active Payment Plans</h4>
            <p className="text-center text-grey-500">
                You have no active payment plans. Any new payment plans will
                appear here.
            </p>
        </div>
    );
};

export default NoActivePaymentPlans;
