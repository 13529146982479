import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import { CartStatus } from "models/enumerations/cart-status";
import { MemberRelationship } from "models/enumerations/member-relationship";
import Member from "models/interfaces/member";
import MemberCart from "models/interfaces/member-cart";
import { Card } from "molecules/cards/card";
import ProcedureBanner, {
    ProcedureBannerProps,
    ProcedureBannerType,
} from "molecules/procedure-banner/procedure-banner";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CartService from "services/cart-service";
import MemberService from "services/member-service";
import PaymentPlans from "./payment-plans";
import "./dashboard-page.scss";
import { useAuth } from "utilities/contexts/auth-state-context";
import useCart from "utilities/hooks/use-cart";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-dashboard";

function DashboardPage() {
    const [expandedMemberIds, setExpandedMemberIds] = useState<string[]>([]);
    const [members, setMembers] = useState<Member[]>([]);
    const [memberCarts, setMemberCarts] = useState<MemberCart[]>([]);
    const [currentMember, setCurrentMember] = useState<Member>();
    const history = useHistory();
    const { isAuthenticated } = useAuth();
    const { update } = useCart();

    const procedures = useMemo<ProcedureBannerProps[]>(
        () => getProcedures(members, memberCarts),
        [memberCarts, members]
    );

    useEffect(() => {
        const checkLoginAndLoadData = async () => {
            if (!isAuthenticated) {
                history.push("/sign-in");
                return;
            }

            try {
                var idToken = await CognitoUtils.getIdToken();
                const memberResponse = await MemberService.getMembers(idToken);

                if (
                    memberResponse?.data == null ||
                    memberResponse.data.filter(
                        (m) => m.relationship === MemberRelationship.Self
                    ).length === 0
                ) {
                    history.push("/onboarding");
                    return;
                }

                if (memberResponse.status === 200) {
                    setMembers(memberResponse.data);
                    setCurrentMember(
                        memberResponse.data.find(
                            (m) => m.relationship === MemberRelationship.Self
                        )
                    );

                    const carts = await getAllMemberCarts(memberResponse.data);
                    setMemberCarts(carts);
                }
            } catch (error) {
                console.error("Error fetching member data:", error);
            }
        };

        checkLoginAndLoadData();
    }, [isAuthenticated]);

    const renderCartButtonText = (member: Member) => {
        if (
            member.cartStatus === CartStatus.Open &&
            member.cartCount != null &&
            member.cartCount > 0
        ) {
            return "Continue";
        }
        if (member.cartStatus === CartStatus.Locked) {
            return "Processing Price";
        }
        if (member.cartStatus === CartStatus.Personalized) {
            return "Review Price";
        }
        return "New Procedure";
    };

    const handleCartClick = (member: Member) => {
        // Don't provide a cartId when loading if cart has expired, so users can start a new cart
        const cartId =
            member.cartStatus !== CartStatus.Expired ? member.cartId : "";

        update({
            member,
            cartId,
            priceJob: undefined,
        });

        if (member.cartStatus === CartStatus.Locked) {
            return; // Do nothing if cart is locked
        }

        if (member.cartStatus === CartStatus.Personalized) {
            return history.push(`/personalize/price/${member.cartId}`);
        }
        history.push("/procedures");
    };

    const handleExpandClick = (member: Member) => {
        let ids = Array.from(expandedMemberIds);
        if (ids.indexOf(member.id) > -1) {
            ids = ids.filter((id) => id !== member.id);
        } else {
            ids.push(member.id);
        }
        setExpandedMemberIds(ids);
    };

    return (
        <div>
            <DashboardLayout coloredBackground={true}>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <Heading size="h1" type="white">
                            Hi
                            {currentMember?.firstName
                                ? `, ${currentMember.firstName}`
                                : ""}
                        </Heading>
                        {procedures.map((procedure) => (
                            <ProcedureBanner
                                key={procedure.cartId}
                                {...procedure}
                            />
                        ))}
                    </div>
                    <div className={`${COMPONENT_CLASS}__wrapper`}>
                        <div className={`${COMPONENT_CLASS}__household`}>
                            <div
                                className={`${COMPONENT_CLASS}__household__header`}>
                                <Heading size="h2" type="light">
                                    Your Household
                                </Heading>
                                <Button
                                    onClick={() =>
                                        history.push("/dashboard/add-member", {
                                            from: "dashboard-page",
                                        })
                                    }
                                    size="small"
                                    type="light">
                                    Add Member
                                </Button>
                            </div>
                            <div
                                className={`${COMPONENT_CLASS}__household__wrapper`}>
                                {members?.map((member, index) => (
                                    <Card
                                        buttons={[
                                            {
                                                label: "Profile",
                                                onClick: () =>
                                                    history.push(
                                                        `/dashboard/profile/${member.id}`
                                                    ),
                                                type: "clear",
                                            },
                                            {
                                                label: renderCartButtonText(
                                                    member
                                                ),
                                                onClick: () =>
                                                    handleCartClick(member),
                                                type:
                                                    member.cartStatus ===
                                                    CartStatus.Locked
                                                        ? "clear"
                                                        : "dark",
                                            },
                                        ]}
                                        cartItems={member.cartCount}
                                        cartStatus={member.cartStatus}
                                        key={`household-${index}`}
                                        isExpanded={
                                            expandedMemberIds.indexOf(
                                                member.id
                                            ) > -1
                                        }
                                        member={member}
                                        onCartClick={() =>
                                            handleCartClick(member)
                                        }
                                        onExpandClick={() =>
                                            handleExpandClick(member)
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        <PaymentPlans />
                    </div>
                </div>
            </DashboardLayout>
        </div>
    );
}

// Private Functions

async function getAllMemberCarts(members: Member[]): Promise<MemberCart[]> {
    if (members.length < 1) {
        return [];
    }

    try {
        const results = await Promise.all(
            members.map((member) =>
                member.cartId == null
                    ? Promise.resolve(undefined)
                    : CartService.getCartById(member.id, member.cartId).then(
                          (result) => {
                              return result;
                          }
                      )
            )
        );

        const memberCarts = results
            .map((r) => r?.data)
            .filter(
                (memberCart): memberCart is MemberCart => memberCart != null
            );
        return memberCarts;
    } catch (error) {
        console.error("Error fetching member carts:", error);
        return [];
    }
}
function getProcedures(members: Member[], memberCarts: MemberCart[]) {
    if (members.length === 0 || memberCarts.length === 0) {
        return [];
    }

    return (
        members
            .map((member): ProcedureBannerProps | undefined => {
                if (
                    member.cartId == null ||
                    member.id == null ||
                    member.cartStatus === CartStatus.None ||
                    member.cartStatus === CartStatus.Open
                ) {
                    return undefined;
                }

                const memberCart = memberCarts.find(
                    (memberCart) => memberCart.id === member.cartId
                );

                if (memberCart == null) {
                    return undefined;
                }

                if (member.cartStatus === CartStatus.Locked) {
                    return {
                        completedOn: memberCart.completedOn,
                        expiresOn: memberCart.expiresOn,
                        cartId: member.cartId,
                        member,
                        displayType: ProcedureBannerType.ManualWorkQueue,
                    };
                }
                const isExpired = member.cartStatus === CartStatus.Expired;

                return {
                    completedOn: memberCart.completedOn,
                    expiresOn: memberCart.expiresOn,
                    cartId: member.cartId,
                    member,
                    displayType: isExpired
                        ? ProcedureBannerType.DashboardExpired
                        : ProcedureBannerType.ReadyForReview,
                };
            })
            .filter(
                (procedure): procedure is ProcedureBannerProps =>
                    procedure != null
            ) ?? []
    );
}

export default DashboardPage;
