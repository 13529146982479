import { defaultLoadingState } from "models/interfaces/cart-state";
import CartState from "models/interfaces/cart-state";
import PriceJob from "models/interfaces/price-job";
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import CartService from "services/cart-service";
import CognitoUtils from "utilities/cognito-utils";
import StringUtils from "utilities/string-utils";

interface CartStateContextProps {
    state: CartState;
    setState: Dispatch<SetStateAction<CartState>>;
}

function loadPersistedCartState(): Partial<CartState> {
    try {
        const persistedState = localStorage.getItem("cartState");
        if (persistedState) {
            const { isPriceJobProcessing, ...result } =
                JSON.parse(persistedState);
            return result;
        }
    } catch (e) {
        console.error(e);
    }

    return {};
}

export const CartStateContext = createContext({} as CartStateContextProps);

export const CartStateProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const initialState: CartState = {
        cartIsLoading: true,
        priceJob: defaultLoadingState<PriceJob>(),
        ...loadPersistedCartState(),
    };

    const [state, setState] = useState(initialState);
    const history = useHistory();

    useEffect(() => {
        const getCart = async () => {
            const memberId = state.member?.id;

            if (StringUtils.isEmpty(memberId)) {
                return;
            }
            const idToken = await CognitoUtils.getIdToken();
            if (StringUtils.isEmpty(state.cartId)) {
                CartService.getCart(memberId, idToken).then((result) => {
                    setState({
                        ...state,
                        ...{
                            cart: result.data,
                            cartId: result.data.id,
                            cartIsLoading: false,
                        },
                    });
                });
            } else {
                CartService.getCartById(memberId, state.cartId)
                    .then((result) => {
                        setState({
                            ...state,
                            ...{
                                cart: result.data,
                                cartIsLoading: false,
                            },
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                        setState({
                            ...state,
                            ...{
                                cartId: undefined,
                            },
                        });
                    });
            }
        };

        if (
            state.member?.id &&
            (!state.cart ||
                state.cart?.memberId !== state.member?.id ||
                state.cartId !== state.cart?.id)
        ) {
            setState({
                ...state,
                ...{
                    cartIsLoading: true,
                },
            });
            getCart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.member?.id, state.cart, state.cartId]);

    useEffect(() => {
        const persistedState = getPersistedState(state);
        localStorage.setItem("cartState", JSON.stringify(persistedState));
    }, [state]);

    useEffect(() => {
        if (!state.cart) {
            return;
        }
        if (
            state.cart.priceJobId &&
            history.location.pathname.indexOf("/procedures") >= 0
        ) {
            history.replace("/personalize/price");
        }
    }, [history, state.cart]);

    return (
        <CartStateContext.Provider value={{ state, setState }}>
            {children}
        </CartStateContext.Provider>
    );
};

function getPersistedState(state: CartState): CartState {
    const { cart, cartIsLoading, ...persistedState } = Object.assign({}, state);
    return persistedState;
}

export const useCartState = () => {
    const context = useContext(CartStateContext);
    if (!context) {
        throw new Error(
            "useGlobalState must be used within a CartStateContext"
        );
    }
    return context;
};
