import axios, { AxiosResponse } from "axios";
import Category from "models/interfaces/category";

const get = async (idToken: string): Promise<AxiosResponse<Category[]>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<Category[]>("/api/categories").then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const getByKey = async (
    categoryKey: string,
    idToken: string
): Promise<AxiosResponse<Category>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<Category>(`/api/categories/${categoryKey}`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

const getParent = async (
    categoryKey: string,
    idToken: string
): Promise<AxiosResponse<Category>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios.get<Category>(`/api/categories/${categoryKey}/parent`).then(
        (response) => {
            return response;
        },
        (error) => {
            throw error.response;
        }
    );
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const CategoriesService = {
    get,
    getByKey,
    getParent,
};

export default CategoriesService;

// #endregion Exports
