import axios, { AxiosResponse } from "axios";
import Bundle from "models/interfaces/bundle";

const getBundle = async (
    key: string,
    idToken: string,
    insurancePlanKey?: string
): Promise<AxiosResponse<Bundle>> => {
    axios.defaults.headers.common["Authorization"] = `${idToken}`;
    return axios
        .get<Bundle>(
            `/api/bundles/${key}?insurancePlanKey=${insurancePlanKey || ""}`
        )
        .then(
            (response) => {
                return response;
            },
            (error) => {
                throw error.response;
            }
        );
};

// -----------------------------------------------------------------------------------------
// #region Exports
// -----------------------------------------------------------------------------------------

const BundleService = {
    getBundle,
};

export default BundleService;

// #endregion Exports
