import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "utilities/contexts/auth-state-context";
import UpdateEmailForm from "./update-email-form";
import UpdatePasswordForm from "./update-password-form";

const AccountSettingsPage = () => {
    const history = useHistory();
    const { user } = useAuth();

    const handleDashboardBtnClick = () => {
        history.push("/dashboard");
    };
    return (
        <DashboardLayout
            showSettingsBtn={false}
            header={
                <div className="space-y-7 pt-7">
                    <Button
                        onClick={handleDashboardBtnClick}
                        size="small"
                        type="light">
                        &lt; Back to Dashboard
                    </Button>
                    <Heading size="h1" type="white">
                        Account Settings
                    </Heading>
                </div>
            }>
            <div className="space-y-12 pt-12">
                <Paragraph>
                    Use this page to update the email address or password for
                    your HCN account.
                </Paragraph>
                <UpdateEmailForm initialEmail={user?.email ?? ""} />
                <UpdatePasswordForm email={user?.email ?? ""} />
            </div>
        </DashboardLayout>
    );
};

export default AccountSettingsPage;
