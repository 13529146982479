import { Address } from "./address";

export enum PaymentType {
    CreditCard,
    ACH,
}

export enum BankAccountType {
    Checking,
    Savings,
}

export interface Purchase {
    name: string;
    paymentType: PaymentType;

    // ACH
    bankAccountType?: BankAccountType;
    firstName: string;
    lastName: string;
    accountNumber: string;
    accountNumberConfirmation: string;
    routingNumber: string;

    // CC
    address?: Address;
    cardNumber: string;
    expirationDate: string;
    code: string;
}

export const emptyPurchase = (
    paymentType = PaymentType.CreditCard
): Purchase => ({
    accountNumber: "",
    accountNumberConfirmation: "",
    cardNumber: "",
    code: "",
    expirationDate: "",
    firstName: "",
    lastName: "",
    name: "",
    routingNumber: "",
    paymentType: paymentType,
});
