import { UserCircleIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { FormatDateType } from "models/enumerations/format-date-type";
import { PaymentStatus } from "models/enumerations/payment-status";
import React from "react";
import { Link } from "react-router-dom";
import { OrderItem } from "utilities/hooks/use-orders";
import StringUtils from "utilities/string-utils";

interface PaymentPlanProps {
    order: OrderItem;
}

const PaymentPlan = ({ order }: PaymentPlanProps) => {
    const purchaseDateLbl = StringUtils.formatDate(
        order.purchaseDate,
        FormatDateType.DateOnly
    );
    const amountRemainingLbl = StringUtils.formatCurrency(order.remaining);

    return (
        <Link to={`/purchase-details/${order.id}`}>
            <div className="flex items-center justify-center rounded-md bg-white p-5">
                <div className="w-full space-y-2">
                    <div className="flex w-full justify-between">
                        <div className="w-1/2 leading-7">
                            <h6 className="text-2xs font-bold uppercase tracking-widest text-grey-200">
                                Purchase Date
                            </h6>
                            <span>{purchaseDateLbl}</span>
                        </div>
                        <div className="w-1/2 leading-7">
                            <h6 className="text-2xs font-bold uppercase tracking-widest text-grey-200">
                                Remaining
                            </h6>
                            <span className="flex items-center space-x-2">
                                <span>{amountRemainingLbl}</span>
                                {order.status === PaymentStatus.Failed && (
                                    <span>
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-900" />
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    {order.status === PaymentStatus.Failed && (
                        <div className="w-full rounded-md bg-red-900/20 p-2">
                            <p className="text-sm text-red-900">
                                It looks like there was a problem processing
                                your most recent payment. Review your payment
                                method.
                            </p>
                        </div>
                    )}
                    <div className="flex w-full items-center space-x-1">
                        <UserCircleIcon className="h-6 w-6 text-primary-700" />
                        <h4 className="text-grey-700">
                            {order.firstName} {order.lastName.charAt(0)}.
                        </h4>
                    </div>
                </div>
                <ChevronRightIcon className="h-6 w-6 text-primary-700" />
            </div>
        </Link>
    );
};

export default PaymentPlan;
