import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import React from "react";
import { useHistory } from "react-router-dom";
import useOrders from "utilities/hooks/use-orders";
import OrderList from "./order-list";

const AllPurchagesPage = () => {
    const {
        oneTimePaymentOrders,
        paymentPlanOrders,
        loading: isLoadingOrders,
        hasFailedPayment,
    } = useOrders();
    const history = useHistory();

    const handleDashboardBtnClick = () => {
        history.push("/dashboard");
    };

    return (
        <div>
            <DashboardLayout
                showPageLoader={isLoadingOrders}
                header={
                    <div className="space-y-7 pt-7">
                        <Button
                            onClick={handleDashboardBtnClick}
                            size="small"
                            type="light">
                            &lt; Back to Dashboard
                        </Button>
                        <Heading size="h1" type="white">
                            All Purchases
                        </Heading>
                    </div>
                }>
                <div className="w-full space-y-12 pt-8">
                    <div className="w-full space-y-4">
                        {hasFailedPayment && (
                            <div className="flex w-full items-center space-x-1 rounded-md bg-red-900/20 px-2 py-1 text-red-900">
                                <ExclamationCircleIcon className="h-6 w-6" />
                                <p className="font-semibold">
                                    It looks like there was a problem processing
                                    your most recent payment.
                                </p>
                            </div>
                        )}
                        <Heading size="h3" className="text-3xl">
                            Active Payment Plans
                        </Heading>
                        {paymentPlanOrders.length > 0 ? (
                            <OrderList orders={paymentPlanOrders} />
                        ) : (
                            <Paragraph>
                                You have no active payment plans.
                            </Paragraph>
                        )}
                    </div>
                    <div className="w-full space-y-4">
                        <Heading size="h3" className="text-3xl">
                            Completed Payments
                        </Heading>
                        {oneTimePaymentOrders.length > 0 ? (
                            <OrderList orders={oneTimePaymentOrders} />
                        ) : (
                            <Paragraph>You have no purchase history</Paragraph>
                        )}
                    </div>
                </div>
            </DashboardLayout>
        </div>
    );
};

export default AllPurchagesPage;
