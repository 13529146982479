import { Button } from "atoms/buttons/button";
import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import HeaderOnlyLayout from "layouts/header-only-layout/header-only-layout";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSearchParams } from "utilities/hooks/use-search-params";
import "./complete-page.scss";

const BASE_CLASS = "c-complete";

function CompletePage() {
    const { referenceNumber } = useSearchParams();
    const history = useHistory();

    const handleDashboardButtonClick = () => {
        history.push("/dashboard");
    };

    return (
        <HeaderOnlyLayout>
            <div
                className={`${BASE_CLASS}__wrapper flex items-center justify-center`}>
                <div className="flex max-w-3xl flex-col items-center justify-center space-y-16">
                    <div className="flex flex-col items-center justify-center space-y-2">
                        <Heading>Thank You</Heading>
                        <Paragraph cssClassName="text-center text-l">
                            We've received your order, and we're processing your
                            payment. You'll receive a payment confirmation
                            shortly.
                        </Paragraph>
                        <Paragraph cssClassName="text-center text-l pt-6">
                            You have been assigned a healthcare concierge who
                            will reach out to coordinate your appointment.
                        </Paragraph>
                    </div>
                    <div className="flex w-full max-w-lg items-center justify-center space-x-4 rounded border border-grey-200 py-4">
                        <h6 className="font-semibold uppercase text-grey-200">
                            Account Number
                        </h6>
                        <span
                            className={`${BASE_CLASS}__reference-number font-serif`}>
                            #{referenceNumber}
                        </span>
                    </div>
                    <Button onClick={handleDashboardButtonClick}>
                        Go to your dashboard &gt;
                    </Button>
                </div>
            </div>
        </HeaderOnlyLayout>
    );
}

export default CompletePage;
