import { Heading } from "atoms/typography/heading";
import { Paragraph } from "atoms/typography/paragraph";
import DashboardLayout from "layouts/dashboard-layout/dashboard-layout";
import Member from "models/interfaces/member";
import MemberForm from "organisms/member-form/member-form";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MemberService from "services/member-service";
import "./add-member-page.scss";
import CognitoUtils from "utilities/cognito-utils";

const COMPONENT_CLASS = "c-add-member-page";

const AddMemberPage: React.FC<any> = (props: any) => {
    const [error, setError] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const goBackToPrevious = () => {
        if (history?.location?.state?.from === "onboarding-household-page") {
            history.push("/onboarding/household");
            return;
        }
        history.push("/");
    };

    const handleSubmit = async (member: Member) => {
        setIsLoading(true);
        const idToken = await CognitoUtils.getIdToken();
        MemberService.createMember(member, idToken).then(
            (memberResult: any) => {
                if (memberResult.status !== 200) {
                    setError(
                        "There was an error setting up your account. Please try again."
                    );
                    return;
                }

                goBackToPrevious();
            },
            (error: any) => {
                if (error.status === 401) {
                    setError("Invalid request. Please try again.");
                } else {
                    setError(
                        error.data?.exceptionMessage ||
                            error.data?.message ||
                            error.statusText
                    );
                }
                setIsLoading(false);
            }
        );
    };

    return (
        <div>
            <DashboardLayout
                coloredBackground={true}
                showPageLoader={isLoading}>
                <div className={COMPONENT_CLASS}>
                    <div className={`${COMPONENT_CLASS}__header`}>
                        <Heading size="h2">Add Household Member</Heading>
                        <Paragraph size="medium">
                            Please enter the information below to create a
                            profile for your family member. A profile is needed
                            to shop for procedures.
                        </Paragraph>
                    </div>
                    <MemberForm
                        error={error}
                        isEditing={true}
                        isSelf={false}
                        onSubmit={handleSubmit}
                        setIsEditing={() => goBackToPrevious()}
                        showAuthorizedCheck={true}
                    />
                </div>
            </DashboardLayout>
        </div>
    );
};

export default AddMemberPage;
