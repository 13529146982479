import { CheckCircleIcon } from "@heroicons/react/outline";
import React from "react";
import "./confirm.scss";

const COMPONENT_CLASS = "c-confirm";

export interface ConfirmProps {
    icon?: React.ReactNode;
    isErrored?: boolean;
    text: string;
}

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const Confirm: React.FC<any> = ({
    icon,
    isErrored = false,
    text,
}: ConfirmProps) => {
    return (
        <div className={`${COMPONENT_CLASS} ${isErrored ? "errored" : ""}`}>
            {icon != null ? icon : <CheckCircleIcon />}
            {text}
        </div>
    );
};

export { Confirm };
