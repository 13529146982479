import { DateTime } from "luxon";
import { LoadingState } from "models/interfaces/cart-state";
import MemberCart from "models/interfaces/member-cart";
import { useCallback, useEffect, useMemo, useState } from "react";
import CartService from "services/cart-service";
import { useCartState } from "utilities/contexts/cart-state-context";

interface UseMemberCartOptions {
    memberId?: string;
    cartId?: string;
}

interface UseMemberCartHookResult extends LoadingState<MemberCart> {
    isExpired: boolean;
    refresh: () => void;
    subtotal: number;
}

export default function useMemberCart({
    cartId,
    memberId,
}: UseMemberCartOptions): UseMemberCartHookResult {
    const [memberCartLoaded, setMemberCartLoaded] = useState(false);
    const [memberCartLoading, setMemberCartLoading] = useState(true);
    const [memberCart, setMemberCart] = useState<MemberCart>();
    const [error, setError] = useState<any>();
    const { setState } = useCartState();

    const { expiresOn, memberCartBundles = [] } = memberCart ?? {};

    const isExpired = useMemo(() => {
        if (expiresOn != null) {
            return (
                Math.ceil(
                    DateTime.now()
                        .diff(DateTime.fromISO(expiresOn), "days")
                        .toObject().days || 0
                ) > 0
            );
        }

        return false;
    }, [expiresOn]);

    const subtotal = useMemo(() => {
        return memberCartBundles.length > 0
            ? memberCartBundles
                  .map((bundle) => bundle.cost || 0)
                  ?.reduce((prev, next) => prev + next)
            : 0;
    }, [memberCartBundles]);

    const loadMemberCart = useCallback(
        (cartId: string, memberId: string) => {
            setMemberCartLoading(true);

            CartService.getCartById(memberId, cartId)
                .then(({ data }) => {
                    setMemberCartLoaded(true);
                    setMemberCart(data);
                    setState((prev) => ({
                        ...prev,
                        cart: data,
                        cartId: data.id,
                    }));
                })
                .catch((err) => setError(err))
                .finally(() => {
                    setMemberCartLoading(false);
                });
        },
        [setState]
    );

    const refresh = useCallback(() => {
        if (memberCartLoading || !cartId || !memberId) {
            return;
        }

        loadMemberCart(cartId, memberId);
    }, [cartId, loadMemberCart, memberCartLoading, memberId]);

    useEffect(() => {
        if (!cartId || !memberId) {
            return;
        }

        loadMemberCart(cartId, memberId);
    }, [cartId, loadMemberCart, memberId]);

    return {
        isLoaded: memberCartLoaded,
        isLoading: memberCartLoading,
        data: memberCart,
        error,
        isExpired,
        refresh,
        subtotal,
    };
}
