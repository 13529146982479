import { useEffect, useRef, useState } from "react";
import { PaymentStatus } from "models/enumerations/payment-status";
import MemberOrder from "models/interfaces/member-order";
import { Payment } from "models/interfaces/payment";
import OrdersService from "services/orders-service";
import { OrderItem } from "utilities/hooks/use-orders";
import CognitoUtils from "utilities/cognito-utils";

interface UseOrderOptions {
    memberOrderId: string;
}

const useOrder = ({ memberOrderId }: UseOrderOptions) => {
    const executedRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<MemberOrder>();
    const isOneTimePayment = order != null && order.payment != null;
    const isRecurringPayment = order != null && order.recurringPayment != null;
    const mostRecentRecurringPayment = getMostRecentRecurringPayment({
        isRecurringPayment,
        order,
    });

    const orderItem = getOrderItem({
        isOneTimePayment,
        order,
        isRecurringPayment,
        mostRecentRecurringPayment,
    });

    useEffect(() => {
        if (executedRef.current === true) return;

        executedRef.current = true;
        const loadOrder = async () => {
            setLoading(true);

            try {
                const idToken = await CognitoUtils.getIdToken();
                const response = await OrdersService.getOrder(
                    memberOrderId,
                    idToken
                );

                if (response.data == null || response.status !== 200) {
                    throw new Error();
                }

                setOrder(response.data);
            } catch (error) {
                console.log(error); // TODO
            } finally {
                setLoading(false);
            }
        };

        loadOrder();
    }, [memberOrderId]);

    return {
        loading,
        order: order,
        orderItem,
        isOneTimePayment,
        isRecurringPayment,
        mostRecentRecurringPayment,
        amountRemaining: orderItem?.remaining,
    };
};

function getMostRecentRecurringPayment({
    isRecurringPayment,
    order,
}: {
    isRecurringPayment: boolean;
    order?: MemberOrder;
}) {
    if (!isRecurringPayment) {
        return undefined;
    }

    const { payments = [] } = order?.recurringPayment ?? {};

    if (payments.length === 0) {
        return undefined;
    }

    return payments[payments.length - 1];
}

function getOrderItem({
    isOneTimePayment,
    order,
    isRecurringPayment,
    mostRecentRecurringPayment,
}: {
    isOneTimePayment: boolean;
    isRecurringPayment: boolean;
    order?: MemberOrder;
    mostRecentRecurringPayment?: Payment;
}): OrderItem | undefined {
    if (isOneTimePayment && order?.payment != null) {
        return {
            id: order.id,
            firstName: order.firstName,
            lastName: order.lastName,
            purchaseDate: order.payment.purchaseDate,
            referenceNumber: order.payment!.referenceNumber,
            total: order.payment.total,
            remaining: 0,
            status: order.payment.status,
        };
    }

    if (isRecurringPayment && order?.recurringPayment != null) {
        const totalPaymentSum =
            order?.recurringPayment?.payments
                .filter(
                    (p) =>
                        p.status === PaymentStatus.Succeeded ||
                        p.status === PaymentStatus.Pending
                )
                .map(() => order?.recurringPayment?.monthlyAmount ?? 0)
                .reduce((prev, curr) => (prev += curr), 0) ?? 0;

        return {
            id: order.id,
            firstName: order.firstName,
            lastName: order.lastName,
            purchaseDate: order.recurringPayment.startDate,
            referenceNumber: order.recurringPayment.referenceNumber,
            total: order.total,
            remaining: order?.total ? order?.total - totalPaymentSum : 0,
            status: mostRecentRecurringPayment?.status ?? PaymentStatus.Pending,
        };
    }
}

export default useOrder;
