import { useCallback, useEffect, useMemo, useState } from "react";
import Member from "models/interfaces/member";
import MemberService from "services/member-service";
import { LoadingState } from "models/interfaces/cart-state";
import CognitoUtils from "utilities/cognito-utils";

interface UseMemberHookResult extends LoadingState<Member> {
    hasInsurance: boolean;
}

/**
 * Hook that handles members and member carts
 */
export function useMember(memberId?: string): UseMemberHookResult {
    const [error, setError] = useState<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [member, setMember] = useState<Member>();

    const { memberInsurances = [] } = member ?? {};

    const hasInsurance = useMemo(
        () => memberInsurances.length > 0,
        [memberInsurances.length]
    );

    const loadData = useCallback(async (memberId: string) => {
        setIsLoading(true);

        const idToken = await CognitoUtils.getIdToken();

        const memberResponse = await MemberService.getMember(memberId, idToken);

        setIsLoading(false);

        if (memberResponse?.status !== 200) {
            setError("An error occurred getting the member list.");
            return;
        }

        setMember(memberResponse.data);
        setIsLoaded(true);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (!memberId) {
            return;
        }

        loadData(memberId);
    }, [loadData, memberId]);

    return {
        error,
        isLoading,
        isLoaded,
        data: member,
        hasInsurance,
    };
}
