import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useCart from "./use-cart";

const useRedirectExpiredCart = () => {
    const { isExpired } = useCart();
    const history = useHistory();

    useEffect(() => {
        if (isExpired) {
            history.push("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpired]);
};

export default useRedirectExpiredCart;
